import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsers } from "../../../api/DocumentApi";
import GenericInput from "../../../components/FormComponents/GenericInput";
import When from "../../../components/When";
import { CompanyRole } from "../../../core/enums/enums";
import { IPerson } from "../../../core/interfaces/common";
import { IDocument } from "../../../core/interfaces/document";
import useAccessCheck from "../../../hooks/useAccessCheck";


type CreatedByFormProps = {
    document: IDocument;
    isEditMode: boolean;
    onChange: (createdBy: IPerson | null) => void,
}

interface AdjustedObj {
    adjustedCreatedBy?: string;
}

export const CreatedByForm: React.FC<CreatedByFormProps> = (props) => {
    const {t} = useTranslation();
    const [companyUsers] = useGetCompanyUsers();
    const [checkAccess] = useAccessCheck();
    const [chosen, setChosen] = useState(props.document.adjustedCreatedBy ?? props.document.createdBy);
    const [object, setObject] = useState<AdjustedObj>({adjustedCreatedBy: chosen?.id})

    const change = (obj: AdjustedObj) => {
        const createdBy = companyUsers.filter(v => v.userId != props.document?.createdBy?.id).find(v => v.userId == obj.adjustedCreatedBy)
        props.onChange(createdBy ? {id: createdBy?.userId, name: createdBy.name} as IPerson : null)
        setObject(obj)
    }


    useEffect(() => {
        setChosen(props.document.adjustedCreatedBy ?? props.document.createdBy);
    }, [props.document])
    return <Form.Group as={Row}>
        <Row>
            <Form.Label column className={"pt-1 pb-0"}><strong>{t('CreatedByForm.CREATED_BY_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <When condition={props.isEditMode && checkAccess(CompanyRole.ADMIN)}
                      otherwise={<span>{chosen?.name ?? "-"}</span>}>
                    <GenericInput
                        type={'select'}
                        isEditMode={true}
                        name={'adjustedCreatedBy'}
                        object={object}
                        setValue={change}
                    >
                        <option selected={chosen?.id === props.document?.createdBy?.id}
                                value={props.document?.createdBy?.id}>{props.document?.createdBy?.name}</option>
                        <>{companyUsers.filter(v => v.userId !== props.document?.createdBy?.id).map(v => <option
                            key={v.userId} selected={chosen?.id === v.userId}
                            value={v.userId}>{v.name}</option>)}</>
                    </GenericInput>
                </When>


            </Col>
        </Row>
    </Form.Group>
}
