import Container, {ContainerProps} from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import styles from "./ActionsContainer.module.css"

interface IActionContainer extends ContainerProps {
    placement: "top" | "bottom";
}

const ActionsContainer: React.FC<IActionContainer> = ({
    placement,
    ...props }) => {

    return <Container
        fluid
        as={props.as}
        className={placement === "bottom" ? "mt-3" : "mb-3"}
    >
        <Row xs="auto" className={[styles.col, "d-flex flex-row-reverse"].join(" ")}>
            {props.children}
        </Row>
    </Container>
}

export default ActionsContainer;