import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { DateToYYYYMMDDHHMM } from "../../../../../../core/helpers/dates/datesHelper";
import { IDataRowProps, IDataRowSignatureField } from "../../../../../../core/interfaces/document";
import { useGetUser } from "../../../../../../hooks/useAuth";
import DataRowActionButtons from "./DataRowActionButtons";


const SignatureDataRow = (
    {
        dataRow,
        setDataRow,
        removeDataRow,
        copyDataRow,
        isFieldEnabled,
        isHeaderEnabled,
        checklistViewTable,
        isEditMode,
        isAnswerMode,
        isActive,
    }: IDataRowProps<IDataRowSignatureField>) => {

    const {t} = useTranslation();
    const user = useGetUser();
    const [signatureName, setSignatureName] = useState("");
    const [signatureDate, setSignatureDate] = useState("");

    useEffect(() => {
        setSignatureName(dataRow.signatureName ?? "");
        setSignatureDate(dataRow.signatureDate ? DateToYYYYMMDDHHMM(dataRow.signatureDate) : "");
    }, [dataRow.signatureDate, dataRow.signatureName]);

    const onChangeHeader = (e: any) => {
        setDataRow({
            ...dataRow,
            header: e.target.value
        });
    }

    const handleSignatureCheck = (e: any) => {
        if (dataRow.isClosed) return;
        if (e.target.checked) {
            setDataRow({
                ...dataRow,
                signatureUserId: user.userId,
                signatureName: user.firstName + " " + user.lastName,
                signatureDate: new Date().toISOString(),
            });
            setSignatureName(user.firstName + " " + user.lastName);
            setSignatureDate(DateToYYYYMMDDHHMM(new Date()));
        } else {
            setDataRow({
                ...dataRow,
                signatureUserId: null,
                signatureName: undefined,
                signatureDate: undefined,
            });
            setSignatureName("");
            setSignatureDate("");
        }
    }

    return <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
        <When condition={!checklistViewTable}>
            <Col md={3}>
                <CustomTextInput
                    checklistTableView={checklistViewTable}
                    isEditMode={isEditMode}
                    disabled={!isHeaderEnabled}
                    placeholder={t('SignatureDataRow.SIGNATURE_1')}
                    name={"header"}
                    value={getDataRowHeader(dataRow)}
                    onChange={onChangeHeader}
                    disableAutocomplete
                    required
                />
            </Col>
        </When>

        <When condition={!!checklistViewTable}>
            <Row>
                <When condition={(isEditMode && isActive) || (!!isAnswerMode && !dataRow.isClosed)}>
                    <Col md={3} className={"pt-1"}>
                        <Form.Switch
                            style={{fontSize: ".8rem"}}
                            checked={dataRow.signatureUserId !== null}
                            disabled={!isFieldEnabled || dataRow.isClosed}
                            label={checklistViewTable && signatureName ? "" : t('SignatureDataRow.SIGN_1')}
                            onChange={handleSignatureCheck}/>
                    </Col>
                </When>
            </Row>
            <Row>
                <Col>
                    <div style={{fontSize: ".8rem"}}>
                        {signatureName}
                    </div>
                    <div style={{fontSize: ".8rem"}}>
                        {DateToYYYYMMDDHHMM(signatureDate)}
                    </div>
                </Col>
            </Row>
        </When>

        <When condition={!checklistViewTable}>
            <Col md={7}>
                <Row>
                    <When condition={(isEditMode && isActive) || (!!isAnswerMode && !dataRow.isClosed)}>
                        <Col md={3} className={"pt-1"}>
                            <Form.Switch
                                checked={dataRow.signatureUserId !== null}
                                disabled={!isFieldEnabled || dataRow.isClosed}
                                label={checklistViewTable && signatureName ? "" : t('SignatureDataRow.SIGN_2')}
                                onChange={handleSignatureCheck}/>
                        </Col>
                    </When>

                    <Col className={"pt-1"}>
                        <span>{signatureName} {DateToYYYYMMDDHHMM(signatureDate)}</span>
                    </Col>
                </Row>
            </Col>
        </When>
        <DataRowActionButtons
            dataRow={dataRow}
            removeDataRow={removeDataRow}
            copyDataRow={copyDataRow}
        />
    </Row>
};

export default SignatureDataRow;
