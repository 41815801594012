// Status
export const STATUS_IDLE = "idle";
export const STATUS_PENDING = "pending";
export const STATUS_SUCCEEDED = "succeeded";
export const STATUS_REJECTED = "rejected";
export const STATUS_UNAUTHORIZED = "unauthorized";
export const STATUS_FORBIDDEN = "forbidden";
export const STATUS_AUTH_SUCESS = "authSuccess";

// Actions
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_INCIDENT_VIEW_INFO = "GET_INCIDENT_VIEW_INFO";
export const GET_DOCUMENT_FOLDERS = "GET_DOCUMENT_FOLDERS";
