import { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { getIcon, Icon } from "../../assets/Icons/IconClassNames";

export enum DropdownToggleType {
    DOTS,
    BARS,
    GEAR,
}

interface ICustomDropDown {
    toggleType: DropdownToggleType;
    children: any;
    iconSize?: string;
    horizontal?: boolean;
}

interface IDropdownButton extends ICustomDropDown {
    onClick: (e: any) => void;
}

interface IDropdownButtonIcon {
    toggleType: DropdownToggleType;
    horizontal?: boolean;
    iconSize?: string;
}

const DropdownButtonIcon = (
    {
        toggleType,
        iconSize,
        horizontal,
    }: IDropdownButtonIcon) => {
    switch (toggleType) {
        case DropdownToggleType.DOTS:
            return <span className={getIcon(horizontal ? Icon.THREE_DOTS_HORIZONTAL : Icon.THREE_DOTS_VERTICAL)}
                         style={{fontSize: iconSize ?? "20px"}}/>
        case DropdownToggleType.BARS:
            return <span className={getIcon(Icon.BARS)}
                         style={{fontSize: iconSize ?? "20px", paddingTop: 2}}/>
        case DropdownToggleType.GEAR:
            return <span className={getIcon(Icon.GEAR)} style={{fontSize: iconSize ?? "20px"}}/>
        default:
            return <span className={getIcon(Icon.THREE_DOTS_VERTICAL)} style={{fontSize: "20px"}}></span>
    }
}

const DropdownButton = forwardRef((
    {
        children,
        onClick,
        toggleType,
        iconSize,
        horizontal,
    }: IDropdownButton, ref: any) => {
    return (
        <span
            className={"cursor-pointer"}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <DropdownButtonIcon
                toggleType={toggleType}
                iconSize={iconSize}
                horizontal={horizontal}
            />
    </span>
    )
});

const CustomDropdown = (
    {
        toggleType,
        children,
        iconSize,
        horizontal
    }: ICustomDropDown) => {
    return <Dropdown as={"span"}>
        <Dropdown.Toggle
            size="sm"
            as={DropdownButton}
            toggleType={toggleType}
            iconSize={iconSize}
            horizontal={horizontal}
        />
        <DropdownMenu>
            {children}
        </DropdownMenu>
    </Dropdown>
}


export default CustomDropdown;