import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCreateUserSkill } from '../../../api/CompanyApi';
import FormContainer from '../../../components/FormContainer/FormContainer';
import SpinnerButton from '../../../components/SpinnerButton';
import { DataRowSubType } from '../../../core/enums/enums';
import { DataRowType, UserSkillDataRows } from '../../../core/helpers/dataRowHelper';
import { ICompanyUserExtended } from '../../../core/interfaces/company';
import { IDataRow, IUserDataRow } from '../../../core/interfaces/document';
import GeneralizedDataRow from '../../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedDataRow';
import GeneralizedFormHeader from '../../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedFormHeader';


interface INewUserSkill {
    users: ICompanyUserExtended[];
    companyUserId?: string;
    show: boolean;
    setShow: () => void;
}

const NewUserSkillModal = (props: INewUserSkill) => {
    const { t } = useTranslation();
    const [validated, setValidate] = useState(false);
    const [formDataRows, setFormDataRows] = useState(_.cloneDeep(UserSkillDataRows));

    const userDataRow = formDataRows.find(dr => dr.checklistFormDataRowTypeSymbol === DataRowType.USER_FIELD) as IUserDataRow;
    const [createUserSkill, isCreatingUserSkill] = useCreateUserSkill(props.companyUserId || props.users?.find(u => u.userId === userDataRow.userId)?.id || '');

    useEffect(() => {
        setValidate(false);
        setFormDataRows(_.cloneDeep(UserSkillDataRows));

        if (props.companyUserId) {
            setFormDataRows(prevRows => {
                return prevRows.map(row => {
                    if (row.checklistFormDataRowTypeSymbol === DataRowType.USER_FIELD) {
                        return {
                            ...row,
                            userId: props.users.find(u => u.id === props.companyUserId)?.userId,
                            isClosed: true,
                        };
                    }
                    return row;
                });
            });
        }
    }, [props.show]);

    const handleUpdateDataRow = (dataRow: IDataRow) => {
        let rowRef = formDataRows;
        setFormDataRows(rowRef.map((row) => (row.checklistFormDataRowTypeSymbol === dataRow.checklistFormDataRowTypeSymbol && row.subType === dataRow.subType) ? dataRow : row));
    }

    const handleSubmit = async (e: any) => {
        setValidate(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        e.preventDefault();
        e.stopPropagation();

        const newRows = [...formDataRows];

        createUserSkill(newRows.map(v => {
            delete v.id
            return v;
        })).then((isCreated) => {
            if (isCreated)
                props.setShow();
        });
    }


    return (
        <Modal show={props.show} size={"xl"} centered onHide={() => props.setShow()}>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>
                    <FormContainer>

                        <GeneralizedFormHeader
                            isExpanded={false}
                            setExpanded={() => NaN}
                            title={t('NewUserSkillModal.ADD_1')}
                            description={" "}
                            isHeaderLocked={true}
                            showExpand={false} />

                        <GeneralizedDataRow
                            dataRows={formDataRows}
                            handleUpdateDataRow={handleUpdateDataRow}
                            isActiveMode={!isCreatingUserSkill}
                            isAnswerMode={!isCreatingUserSkill}
                            isEditMode={false}
                            handleRemoveDataRow={() => NaN}
                            handleCopyDataRow={() => NaN}
                            requiredSubType={[DataRowSubType.NONE]}
                        />

                    </FormContainer>
                </Modal.Body>
                <Modal.Footer>
                    <SpinnerButton onClick={() => props.setShow()} text={t('NewUserSkillModal.CANCEL_1')} variant={"danger"}
                        isLoading={isCreatingUserSkill} />
                    <SpinnerButton type={"submit"}
                        text={t('NewUserSkillModal.SAVE_1')}
                        isLoading={isCreatingUserSkill}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default NewUserSkillModal;