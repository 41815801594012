import Row from "react-bootstrap/Row";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import PositionLevelTable from "./PositionLevelTable";
import { useTranslation } from "react-i18next";

const PositionLevels = () => {
    const { t } = useTranslation();
    return (
        <MainContainer>
            <HeaderContainer title={t('PositionLevels.POSITION_LEVELS_1')}/>
            <Row>
                <PositionLevelTable/>
            </Row>
        </MainContainer>
    );
};

export default PositionLevels;