import { useQueryClient } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import i18n from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DataRowMultiChoiceType } from "../core/enums/enums";
import { downloadFileResponse, fileResponse } from "../core/helpers/fileHelper";
import { toastError, toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, ICustomQueryKeys, IResponse } from "../core/interfaces/common";
import { IDataRowMultiChoiceOption, IDocumentModule, IDocumentModuleChecklistForm } from "../core/interfaces/document";
import { AppQueryKey, GetQueryKey, useForceFetchQuery, useInvalidateQuery } from "../core/queryKeys";
import { useDelete, useGet, usePost, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient from "./api";
import {
    URL_ANSWER_CHECKLIST,
    URL_CHECKLIST_FORM,
    URL_DATA_ROW_DOWNLOAD_IMAGE,
    URL_DATA_ROW_FILE,
    URL_DELETE_CHECKLIST_FORM,
    URL_MULTI_CHOICE_OPTIONS
} from "./endpoints/endpoints";

export const useAddChecklistForm = (
    moduleId: string,
): [((req: IDocumentModuleChecklistForm) => Promise<IResponse<IDocumentModuleChecklistForm>>), boolean] => {
    const {documentId} = useParams();
    const dispatch = useAppDispatch();
    const documentQueryKey = GetQueryKey(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId ?? ""]});

    const usePostMutation = usePost<IDocumentModuleChecklistForm>(
        URL_CHECKLIST_FORM(documentId ?? "", moduleId),
        documentQueryKey,
        true
    );

    const refetchDocumentModules = useForceFetchQuery(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId ?? ""]});

    const request = (req: IDocumentModuleChecklistForm) => usePostMutation.mutateAsync(req).then(async (response) => {
        if (response.success) {
            await refetchDocumentModules();
            dispatch(addToast(toastSuccess(i18n.t('DocumentModuleChecklistApi.FORM_ADDED_1'), "")));
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
};

export const useDeleteChecklistForm = (
    moduleId: string,
): [((request: string) => Promise<IResponse<string>>), boolean] => {
    const {cId, documentId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "modules", cId: cId, extraKeys: [documentId ?? ""]};

    const useDeleteMutation = useDelete<string>(
        URL_DELETE_CHECKLIST_FORM(documentId ?? "", moduleId),
        queryKeys,
        true
    );

    const request = (request: string) => useDeleteMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            await queryClient.fetchQuery(["modules", cId, documentId]);
            dispatch(addToast(toastSuccess(i18n.t('DocumentModuleChecklistApi.FORM_DELETED_1'), "")));
        }
        return response;
    });
    return [request, useDeleteMutation.isLoading];
};

export const useCopyChecklistForm = (
    moduleId: string,
): [((request: { documentModuleChecklistFormResponse: IDocumentModuleChecklistForm }) => Promise<IResponse<{ documentModuleChecklistFormResponse: IDocumentModuleChecklistForm }>>), boolean] => {
    const {cId, documentId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "modules", cId: cId, extraKeys: [documentId ?? ""]};

    const usePutMutation = usePut<{ documentModuleChecklistFormResponse: IDocumentModuleChecklistForm }>(
        URL_CHECKLIST_FORM(documentId ?? "", moduleId),
        queryKeys,
        true
    );

    const request = (request: { documentModuleChecklistFormResponse: IDocumentModuleChecklistForm }) => usePutMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            await queryClient.fetchQuery(["modules", cId, documentId]);
            dispatch(addToast(toastSuccess(i18n.t('DocumentModuleChecklistApi.FORM_COPIED_1'), i18n.t('DocumentModuleChecklistApi.FORM_COPIED_1'))));
        }
        return response;
    });
    return [request, usePutMutation.isLoading];
}

export const useAnswerChecklist = (
    moduleId: string,
): [((request: IDocumentModule) => Promise<IResponse<IDocumentModule>>), boolean] => {
    const {documentId} = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId ?? ""]});

    const usePutMutation = usePut<IDocumentModule>(
        URL_ANSWER_CHECKLIST(documentId ?? "", moduleId),
        queryKeys,
        true
    );

    const refetchModule = useForceFetchQuery(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId ?? ""]});
    const invalidateDocument = useInvalidateQuery(AppQueryKey.DOCUMENTS, {extraKeys: [documentId ?? ""]});
    const request = (request: IDocumentModule) => usePutMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            await invalidateDocument();
            await refetchModule();
            dispatch(addToast(toastSuccess(i18n.t('DocumentModuleChecklistApi.CHECKLIST_SAVED_1'), i18n.t('DocumentModuleChecklistApi.CHECKLIST_SAVED_WITH_YOUR_ANSWERS_1'))));
        }
        return response;
    });
    return [request, usePutMutation.isLoading];
}

export const useGetMultiChoiceOptions = (
    dataRowTypeSymbol: DataRowMultiChoiceType,
) => {
    const {cId} = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.MULTI_CHOICE_OPTIONS);
    let url = URL_MULTI_CHOICE_OPTIONS() + `?dataRowTypeSymbol=${dataRowTypeSymbol}`;
    if (cId) {
        url += `&companyId=${cId}`;
    }
    const query: ICustomQuery = {url: url, queryKeys: queryKeys};
    return useGet<IDataRowMultiChoiceOption[]>(query);
}

export const useCreateNewDataRowMultiChoiceOption = (): [((request: IDataRowMultiChoiceOption) => Promise<IResponse<IDataRowMultiChoiceOption>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.MULTI_CHOICE_OPTIONS)

    const usePostMutation = usePost<IDataRowMultiChoiceOption>(
        URL_MULTI_CHOICE_OPTIONS(),
        queryKeys,
        true
    );

    const request = (request: IDataRowMultiChoiceOption) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentModuleChecklistApi.ALTERNATIVE_ADDED_1'), i18n.t('DocumentModuleChecklistApi.ALTERNATIVE_ADDED_2'))));
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
}

export const useDeleteDataRowMultiChoiceOption = (
    optionId: string,
): [((placeholder: string) => Promise<IResponse<string>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "multiChoiceOptions"};
    const useDeleteMutation = useDelete<string>(
        URL_MULTI_CHOICE_OPTIONS(),
        queryKeys,
        true
    );

    const request = (placeholder: string) => useDeleteMutation.mutateAsync(optionId).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentModuleChecklistApi.ALTERNATIVE_DELETED_1'), `${i18n.t('DocumentModuleChecklistApi.ALTERNATIVE_DELETED_2')} "${placeholder}" ${i18n.t('DocumentModuleChecklistApi.ALTERNATIVE_DELETED_3')}`)));
        }
        return response;
    });
    return [request, useDeleteMutation.isLoading];
}

export const useDownloadDataRowImageAttachment = (
    moduleId: string,
    formId: string,
    dataRowId: string,
    previewDocumentId?: string,
): [() => void, boolean] => {
    const {documentId} = useParams();
    const dispatch = useAppDispatch();
    const [isDownloading, setIsDownloading] = useState(false);
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    const download = () => {
        setIsDownloading(true);
        AxiosClient.get(URL_DATA_ROW_DOWNLOAD_IMAGE(previewDocumentId ? previewDocumentId : documentId ?? "", moduleId, formId, dataRowId), config).then((response) => {
            downloadFileResponse(fileResponse(response))
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('DocumentModuleChecklistApi.COULD_NOT_FETCH_IMAGE_1'), i18n.t('DocumentModuleChecklistApi.ERROR_RETRIEVING_IMAGE_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}

export const useDownloadDataRowFile = (
    dataRowFileId: string,
    documentId: string
): [() => void, boolean] => {
    const dispatch = useAppDispatch();
    const [isDownloading, setIsDownloading] = useState(false);
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };
    const download = () => {
        setIsDownloading(true);
        AxiosClient.get(URL_DATA_ROW_FILE(documentId ?? "", dataRowFileId), config).then((response) => {
            downloadFileResponse(fileResponse(response))
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('DocumentModuleChecklistApi.COULD_NOT_FETCH_THE_FILE_1'), i18n.t('DocumentModuleChecklistApi.ERROR_FETCHING_FILE_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}
