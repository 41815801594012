import { useTranslation } from "react-i18next";
import CustomSelect from "../../../components/FormComponents/CustomSelect";
import When from "../../../components/When";
import { IPerson } from "../../../core/interfaces/common";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";

export function ActionUserForm(props: { editMode: boolean, companyUsers: IPerson[], newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'formActionUser'} title={t('ActionUserForm.RESPONSIBLE_FOR_ACTIONS_1')}
                         colAs={props.editMode ? 'large' : 'sm'}>
        <When condition={props.editMode} otherwise={<span
            data-test-id={"formActionUser"}>{props.newIncident?.actionUser?.name ?? t('ActionUserForm.NO_USER')}</span>}>
            <CustomSelect
                isEditMode={props.editMode}
                name={"actionUser"}
                value={props.newIncident?.actionUser?.id}
                options={
                    <>
                        <option value={""}>{t('ActionUserForm.SELECT_USER_1')}</option>
                        {(props.companyUsers ?? []).map(person =>
                            <option value={person.id} key={person.id}>{person.name}</option>
                        )
                        }
                    </>
                }
                onChange={props.onChange}
                required
            />
        </When>
    </FormProvider>;
}
