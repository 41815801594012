import {ICustomQuery, ICustomQueryKeys} from "../../core/interfaces/common";

const GET_USERS_COUNT = "/statistics/countUsers";
const GET_COMPANIES_COUNT = "/statistics/countCompanies";

export const GetUsersCount = (): ICustomQuery => {
    const queryKeys: ICustomQueryKeys = {
        root: "usersCount"
    }

    return {url: GET_USERS_COUNT, queryKeys: queryKeys};
};

export const GetCompaniesCount = (days?: number): ICustomQuery => {
    let url = GET_COMPANIES_COUNT;
    url = days ? url + `?days=${days}` : url;

    let queryKeys: ICustomQueryKeys = {
        root: "companiesCount"
    };

    if (days) {
        queryKeys.extraKeys = [days.toString()]
    }
    return {url: url, queryKeys: queryKeys};
};