import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import React from "react";
import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../../assets/Icons/IconClassNames";
import When from "../../../../components/When";
import { IDocumentNodeModel, IFolderNodeModel } from "../../../../core/interfaces/directory";
import { isFolder } from "../../../DocumentDirectory/Helpers/DirectoryHelper";
import styles from "./CloseDocument.module.scss";

interface ICloseDocumentDirectoryNode {
    node: NodeModel<IFolderNodeModel | IDocumentNodeModel>;
    depth: number;
    isOpen: boolean;
    isSelected: boolean;
    onToggle: (id: NodeModel["id"]) => void;
    onClickNode: (treeNode: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => void;
    handleExpandedFoldersToggle: (id: string) => void;
}

const CloseDocumentDirectoryNode = (
    {
        node,
        depth,
        isOpen,
        isSelected,
        onToggle,
        onClickNode,
        handleExpandedFoldersToggle
    }: ICloseDocumentDirectoryNode) => {
    const {t} = useTranslation();
    const dragOverProps = useDragOver(node.id, isOpen, onToggle);

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleExpandedFoldersToggle("" + node.id);
        onToggle(node.id);
    };

    const voidFunction = () => NaN;

    return <div
        className={`tree-node ${styles.nodeRoot}`}
        style={{
            paddingInlineStart: depth * 12,
            fontWeight: isSelected ? "bold" : "normal",
            border: isSelected && isFolder(node) ? "1px solid black" : "",
        }}
        {...dragOverProps}
    >
        <div
            className={`expandIconWrapper ${isOpen ? 'isOpen' : ''}`}
            onClick={handleToggle}
        >
            <When condition={isFolder(node)}>
                <div>
                    <i className={getIcon(Icon.ARROW_RIGHT, IconSize.XS)}/>
                </div>
            </When>
        </div>
        <When condition={isFolder(node)}>
            <div
                className={styles.content}
                style={{opacity: isFolder(node) ? undefined : 0.7}}
                onClick={isFolder(node) ? () => onClickNode(node) : voidFunction}
            >
                <div>
                    <span className={getIcon(Icon.FOLDER, IconSize.XS)}/>
                    <span> {node.text}</span>
                </div>
                <div>
                    {isSelected && <span>{t('CloseDocumentDirectoryNode.MOVE_HERE_1')}</span>}
                </div>
            </div>
        </When>
        <When condition={!isFolder(node)}>
            <div
                style={{opacity: 0.7}}
                onClick={() => voidFunction}
            >
                <span className={getIcon(Icon.FILE_LINES, IconSize.XS)}/>
                <span> {node.text}</span>
            </div>
        </When>
    </div>
};

export default CloseDocumentDirectoryNode;
