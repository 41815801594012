import { createContext, useContext, useEffect, useState } from "react";
import {
    IDataRow,
    IDataRowActionPlan,
    IDataRowFileField,
    IDataRowSignatureField,
    IDocumentModule,
    IDocumentModuleChecklistForm,
    IDocumentModuleChecklistResponse
} from "../../../../core/interfaces/document";

import * as _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useUpdateModule } from "../../../../api/DocumentApi";
import { ChecklistView, DataRowType } from "../../../../core/enums/enums";
import { useDocumentModulesContext } from "../DocumentModules";

function generateRandomId(length: number): string {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

type ChecklistContextType = {
    addForm: (form: IDocumentModuleChecklistForm) => void,
    updateForm: (form: IDocumentModuleChecklistForm, lightUpdate?: boolean) => void,
    addHeaderForm: (title: string, description: string) => void,


    moveForm: (oldIndex: number, newIndex: number) => void,
    addDataRowToForms: (dataRow: IDataRow, ids: string[]) => void;

    removeForm: (form: IDocumentModuleChecklistForm) => void,

    copyForm: (form: IDocumentModuleChecklistForm, cleanData?: boolean) => void,
    copyMultipleForm: (form: IDocumentModuleChecklistForm, amount: number, cleanData?: boolean) => void,
    changeTitleOnForms: (title: string, ids: string[]) => void;

    changeHeaderOnFormsDataRow: (index: number, header: string, ids: string[]) => void;
    changeHeaderTooltipOnFormsDataRow: (index: number, ids: string[], headerTooltipText?: string) => void;

    documentContext: { isEditMode: boolean },

    isTableView: boolean,

    moveDataRow: (oldIndex: number, newIndex: number, ids: string[]) => void;
    removeDataRowsOnForms: (index: number, ids: string[]) => void;

    getModuleId: string;

    isHideActionPlan: boolean
    addActionPlanDataRow: (form: IDocumentModuleChecklistForm) => void;
    removeActionPlanDataRow: (form: IDocumentModuleChecklistForm) => void;
    isHideHeaderColumnTableView: boolean;
}


const ChecklistContext = createContext<ChecklistContextType>({} as ChecklistContextType)


const ChecklistContextProvider = (
    {
        children,
        updateModule,
        module,

    }: {
        children: JSX.Element | JSX.Element[],
        module: IDocumentModule,
        updateModule: (module: IDocumentModule) => void
    }) => {

    const {t} = useTranslation();
    const documentModulesContext = useDocumentModulesContext();
    const [isCopying, setIsCopying] = useState(false);
    const [request] = useUpdateModule();


    useEffect(() => {
        documentModulesContext.addSaveFunction(module.id, async () => {
            const forms = module.documentModuleChecklist?.documentModuleChecklistForms.map(v => {
                const vForm = v as IDocumentModuleChecklistResponse;
                if (vForm.isNew) {
                    delete vForm.id;
                }
                vForm.documentModuleChecklistFormDataRows.forEach(r => {
                    if (r.isNew) delete r.id;
                })
                return vForm;
            }) ?? [];
            if (module.documentModuleChecklist == null) return;
            module.documentModuleChecklist.documentModuleChecklistForms = forms as any as IDocumentModuleChecklistForm[];
            await request(module)
        });
    }, [module])

    const _getForms = () => module.documentModuleChecklist?.documentModuleChecklistForms.filter(v => !v.isDeleted) ?? [];
    const _updateModule = () => {
        updateModule(_.cloneDeep(module));

    }
    const addForm = (form: IDocumentModuleChecklistForm) => {
        form.id = generateRandomId(25);
        form.isNew = true;
        form.sequenceNo = module.documentModuleChecklist?.documentModuleChecklistForms.length ?? -1;
        module.documentModuleChecklist!.documentModuleChecklistForms.push(form);
        _updateModule()
    }


    const _handleCopyForm = (form: IDocumentModuleChecklistForm, cleanData = false) => {
        const index = module.documentModuleChecklist?.documentModuleChecklistForms.findIndex(f => f.id === form.id);

        if (index === undefined) return;
        const clonedForm = _.cloneDeep(form);
        clonedForm.id = generateRandomId(25);
        clonedForm.isNew = true;

        if (cleanData) clonedForm.description = "";

        const allowedKeys = ['checklistFormDataRowTypeSymbol', 'header', 'headerTooltipText', 'maxLength', 'rows', 'numMaxValue', 'numMinValue', 'isMinMaxChecked', 'classifiedAs', 'multiChoiceDisplayType', 'dateOnly', 'multiPicks', 'multiChoiceValueClassified', 'multiChoiceAlternatives', 'alternatives', 'companyId']

        clonedForm.documentModuleChecklistFormDataRows.forEach(v => {
            if (cleanData) {
                Object.keys(v).forEach((key) => {
                    if (!allowedKeys.includes(key)) {
                        (v as any)[key] = null;
                    }
                });
            }

            switch (v.checklistFormDataRowTypeSymbol) {
                case DataRowType.SIGNATURE_FIELD:
                    (v as IDataRowSignatureField).signatureName = undefined;
                    (v as IDataRowSignatureField).signatureDate = null;
                    (v as IDataRowSignatureField).signatureUserId = null;
                    break;
                case DataRowType.FILES_FIELD:
                    (v as IDataRowFileField).newFiles = [];
                    (v as IDataRowFileField).files = [];
                    break;
                default:
                    break;
            }

            v.isNew = true;
            v.isClosed = false;
            v.id = generateRandomId(25);
        });

        module.documentModuleChecklist?.documentModuleChecklistForms.splice(index + 1, 0, clonedForm);
        module.documentModuleChecklist?.documentModuleChecklistForms.filter(v => !v.isDeleted).forEach((v, index) => v.sequenceNo = index);
    }

    return (
        <ChecklistContext.Provider value={{
            isTableView: documentModulesContext.getChecklistView(module.id) === ChecklistView.TABLE,
            documentContext: {isEditMode: documentModulesContext.isEditMode && documentModulesContext?.activeModule?.id == module.id},
            addForm,
            updateForm: (form) => {
                const forms = _getForms();
                const index = forms.findIndex(f => f.id === form.id);
                if (index === -1) return;
                forms[index] = _.cloneDeep(form);
                _updateModule()
            },
            addHeaderForm: (title, description) => {
                const documentModuleChecklistFormDataRows: IDataRow[] = []
                addForm({title, description, documentModuleChecklistFormDataRows} as IDocumentModuleChecklistForm)
            },
            addDataRowToForms: (dataRow, ids) => {
                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(form => {
                    if (ids.includes(form.id)) {
                        form.documentModuleChecklistFormDataRows.push({...dataRow, id: generateRandomId(25)});
                    }
                })
                _updateModule();
            },
            removeForm: (form: IDocumentModuleChecklistForm) => {
                const index = module.documentModuleChecklist?.documentModuleChecklistForms.findIndex(f => f.id == form.id);
                if (index == undefined || module.documentModuleChecklist?.documentModuleChecklistForms[index] == undefined) return;

                const pointer = module.documentModuleChecklist.documentModuleChecklistForms[index];
                if (pointer.isNew) module.documentModuleChecklist.documentModuleChecklistForms.splice(index, 1);
                else pointer.isDeleted = true;
                module.documentModuleChecklist?.documentModuleChecklistForms.filter(v => !v.isDeleted).forEach((v, index) => v.sequenceNo = index);

                _updateModule();
            },
            copyForm: (form: IDocumentModuleChecklistForm, cleanData = false) => {
                if (!isCopying) {
                    setIsCopying(true);
                    _handleCopyForm(form, cleanData)
                    _updateModule();
                    setTimeout(() => {
                        setIsCopying(false);
                    }, 1000)
                }
            },
            copyMultipleForm: (form: IDocumentModuleChecklistForm, amount, cleanData = false) => {
                for (let i = 0; i < amount; i++) {
                    _handleCopyForm(form, cleanData);
                }
                _updateModule();
            },
            changeTitleOnForms: (title, ids) => {
                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(form => {
                    if (ids.includes(form.id)) {
                        form.title = title;

                    }
                })
                _updateModule();
            },
            changeHeaderOnFormsDataRow: (index, header, ids) => {
                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(form => {
                    if (ids.includes(form.id)) {
                        form.documentModuleChecklistFormDataRows[index].header = header;

                    }
                })
                _updateModule();
            },
            changeHeaderTooltipOnFormsDataRow: (index, ids, headerTooltipText) => {
                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(form => {
                    if (ids.includes(form.id)) {
                        form.documentModuleChecklistFormDataRows[index].headerTooltipText = headerTooltipText;
                    }
                })
                _updateModule();
            },
            removeDataRowsOnForms: (index, ids) => {
                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(form => {
                    if (ids.includes(form.id)) {
                        form.documentModuleChecklistFormDataRows.splice(index, 1);
                    }
                })
                _updateModule();
            },
            getModuleId: module.id,
            isHideActionPlan: module.documentModuleChecklist?.hideSendToActionPlan ?? false,
            isHideHeaderColumnTableView: module.documentModuleChecklist?.hideHeaderColumnTableView ?? false,
            addActionPlanDataRow: (form: IDocumentModuleChecklistForm) => {
                const actionPlanDataRow = {
                    id: "Handle_Action_Row",
                    isNew: true,
                    header: t('ChecklistContext.RESPONSIBLE_FOR_ACTION_1'),
                    checklistFormDataRowTypeSymbol: DataRowType.ACTION_PLAN_FIELD
                } as IDataRowActionPlan

                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(f => {
                    if (form.id === f.id) {
                        form.documentModuleChecklistFormDataRows.push({...actionPlanDataRow});
                        form.sendToActionPlan = true;
                    }
                });
                _updateModule();
                // moduleForm.documentModuleChecklistFormDataRows.push(actionPlanDataRow);
            },
            removeActionPlanDataRow: (form: IDocumentModuleChecklistForm) => {
                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(f => {
                    if (form.id === f.id) {
                        const indexOfActionDataRow = form.documentModuleChecklistFormDataRows.findIndex(dr => dr.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD);
                        if (indexOfActionDataRow < 0) return;
                        form.documentModuleChecklistFormDataRows.splice(indexOfActionDataRow, 1);
                        form.sendToActionPlan = false;
                    }
                });
                _updateModule();
            },
            moveForm: (oldIndex, newIndex) => {
                if (oldIndex === newIndex) {
                    return; // no need to move anything
                }
                if (!module.documentModuleChecklist) return;
                const itemToMove = module.documentModuleChecklist.documentModuleChecklistForms[oldIndex];
                if (!itemToMove) return;

                module.documentModuleChecklist.documentModuleChecklistForms.splice(oldIndex, 1); // remove item from old index
                module.documentModuleChecklist.documentModuleChecklistForms.splice(newIndex, 0, itemToMove); // insert item at new index

                module.documentModuleChecklist.documentModuleChecklistForms.forEach((v, index) => {
                    v.sequenceNo = index;
                })
                _updateModule();
            },
            moveDataRow: (oldIndex, newIndex, ids) => {
                module.documentModuleChecklist?.documentModuleChecklistForms.forEach(form => {
                    if (ids.includes(form.id)) {

                        const itemToMove = form.documentModuleChecklistFormDataRows[oldIndex];

                        form.documentModuleChecklistFormDataRows.splice(oldIndex, 1); // remove item from old index
                        form.documentModuleChecklistFormDataRows.splice(newIndex, 0, itemToMove); // insert item at new index

                        form.documentModuleChecklistFormDataRows.forEach((v, index) => {
                            v.sequenceNo = index;
                        })

                    }
                })
                _updateModule();
            }

        }}>
            {children}
        </ChecklistContext.Provider>
    )
}

const useChecklistContext = () => useContext(ChecklistContext);


export { ChecklistContextProvider, useChecklistContext };
