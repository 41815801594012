import i18n from "i18next";
import { TaskActionType, TaskEntityType } from "../../../../../core/enums/enums";
import { INotification } from "../../../../../core/interfaces/notification";
import { ITaskMaterial } from "../../../../../core/interfaces/task";

const _DocumentNotificationTypes = new Map<TaskActionType, (notification: INotification) => ITaskMaterial>(
    [
        [TaskActionType.READ_RECEIPT, (n) => NotificationMessage(n, i18n.t('NotificationTypes.DOCUMENT_1'), Creator(n) + i18n.t('NotificationTypes.REQUEST_READ_DOCUMENT_1'))],
        [TaskActionType.APPROVE_DOCUMENT, (n) => NotificationMessage(n, i18n.t('NotificationTypes.DOCUMENT_1'), Creator(n) + i18n.t('NotificationTypes.DOCUMENT_APPROVAL_REQUEST_1'))],
        [TaskActionType.ACTIONPLAN_ACCIDENT, (n) => NotificationMessage(n, i18n.t('NotificationTypes.ACCIDENT_1'), Creator(n) + i18n.t('NotificationTypes.ACTION_REQUIRED_1'), n.entityId)],
        [TaskActionType.ACTIONPLAN_DEVIATION, (n) => NotificationMessage(n, i18n.t('NotificationTypes.ACTION_1'), Creator(n) + i18n.t('NotificationTypes.ACTION_REQUIRED_1'), n.entityId)],
        [TaskActionType.ACTIONPLAN_INCIDENT, (n) => NotificationMessage(n, i18n.t('NotificationTypes.INCIDENT_1'), Creator(n) + i18n.t('NotificationTypes.ACTION_REQUIRED_1'), n.entityId)],
        [TaskActionType.ACTIONPLAN_STANDALONE, (n) => NotificationMessage(n, i18n.t('NotificationTypes.ACTIONPLAN_1'), Creator(n) + i18n.t('NotificationTypes.ACTION_REQUIRED_1'), n.entityId)],
        [TaskActionType.ACTIONPLAN_COMPLETED, (n) => NotificationMessage(n, i18n.t('NotificationTypes.ACTIONPLAN_1'), Creator(n) + i18n.t('NotificationTypes.ACTION_REQUIRED_1'), n.entityId)]
    ]
)

const NotificationMessage = (notification: INotification, linkText: string, header: string, state?: string) => {
    const linkGenerator = _NotificationLink.get(notification.type);
    return ({
        header,
        link: linkGenerator ? linkGenerator(notification) : '',
        linkText,
        state
    } as ITaskMaterial);
}

const Creator = (notification: INotification) => [notification.createdBy.firstName, notification.createdBy.lastName].join(" ");

const _NotificationTypes = new Map<TaskEntityType, Map<TaskActionType, (notification: INotification) => ITaskMaterial>>(
    [
        [TaskEntityType.DOCUMENT, _DocumentNotificationTypes],
        [TaskEntityType.FILE, _DocumentNotificationTypes],
        [TaskEntityType.ACTIONPLAN, _DocumentNotificationTypes],
    ])

const _NotificationLink = new Map<TaskActionType, (notification: INotification) => string>(
    [
        [TaskActionType.APPROVE_DOCUMENT, (notification) => `/c/${notification.companyId}/documents/${notification.entityId}`],
        [TaskActionType.READ_RECEIPT, (notification) => {
            if (notification.entityType.toUpperCase() === TaskEntityType.FILE) {
                const folderKey = notification.referencedKeys.find(item => item.key === "folderId");
                return `/c/${notification.companyId}/documents/${folderKey?.value ? "#F_" + folderKey.value : ""}`
            }
            return `/c/${notification.companyId}/documents/${notification.entityId}`
        }],
        [TaskActionType.ACTIONPLAN_ACCIDENT, (notification) => `/c/${notification.companyId}/action-plan`],
        [TaskActionType.ACTIONPLAN_DEVIATION, (notification) => `/c/${notification.companyId}/action-plan`],
        [TaskActionType.ACTIONPLAN_INCIDENT, (notification) => `/c/${notification.companyId}/action-plan`],
        [TaskActionType.ACTIONPLAN_STANDALONE, (notification) => `/c/${notification.companyId}/action-plan`],
        [TaskActionType.ACTIONPLAN_COMPLETED, (notification) => `/c/${notification.companyId}/action-plan`],
    ])


const GetNotificationMaterial = (notification: INotification): ITaskMaterial | undefined => {
    const exe = _NotificationTypes?.get(notification.entityType)?.get(notification.type);
    if (exe === undefined) return undefined;
    return exe(notification);
}
export default GetNotificationMaterial
