import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import React, {ReactNode} from "react";
import getIcon, {Icon} from "../../assets/Icons/IconClassNames";
import styles from "./DragAndDropComponent.module.scss";
import When from "../When";

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export interface IDraggableItem {
    allowedToDrag: boolean;
    allowedToDrop: boolean;
    type: string;
    id: string;
    index: number;
    onDrop: (dragIndex: number, hoverIndex: number) => void;
    children: ReactNode;
    style?: React.CSSProperties;
    dragIconLeftRight?: boolean;
    hideDragHandle?: boolean;
}

const DraggableItem = (
    {
        allowedToDrag,
        allowedToDrop,
        type,
        id,
        index,
        onDrop,
        children,
        style = {left: "50%"},
        dragIconLeftRight,
        hideDragHandle,
    }: IDraggableItem) => {

    const [{isDragging}, drag, preview] = useDrag(
        () => ({
            type: type,
            item: {id: id, index},
            canDrag: () => allowedToDrag,
            collect: (monitor: DragSourceMonitor<{ id: string, index: number }>) => ({
                isDragging: monitor.isDragging(),
                item: monitor.getItem(),
                itemType: monitor.getItemType(),
            }),
        }),
        [allowedToDrag, onDrop]
    );

    const [{activeDropItem}, drop] = useDrop(() => ({
        accept: type,
        canDrop: () => allowedToDrop,
        drop: (item: DragItem) => onDrop(item.index, index),
        collect: (monitor: DropTargetMonitor<{ id: string, index: number }>) => ({
            activeDropItem: monitor.canDrop() && monitor.isOver(),
        }),
    }), [allowedToDrag, onDrop]);

    return (
        <div
            ref={(node) => preview(drop(node))}
            style={{
                opacity: isDragging ? 0.2 : 1,
                position: 'relative',
                border: activeDropItem ? "2px dashed #0f5132" : "",

            }}
        >
            <When condition={!hideDragHandle}>
                <div ref={drag} className={styles.dragHandleWrapper} style={style}>
                    <div className={styles.dragHandle}>
                        {allowedToDrag && (
                            <span
                                className={getIcon(Icon.SORT)}
                                style={dragIconLeftRight ? {transform: "rotate(90deg)"} : {}}
                            />
                        )}
                    </div>
                </div>
            </When>
            <>{children}</>
        </div>
    );
};

export default DraggableItem;