import { useTranslation } from "react-i18next";
import CustomDate from "../../../components/FormComponents/CustomDate";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function IncidentTimeForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId="formIncidentTime" title={t('IncidentTimeForm.TIME_FOR_EVENT_1')}>
            <CustomDate
                isEditMode={props.editMode}
                type={"time"}
                name={"date"}
                testId={'formIncidentTime'}
                value={props.newIncident.timeIncluded ? props.newIncident.date : ""}
                onChange={props.onChange}
            />
        </FormProvider>
}
