import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { REDO_COMMAND } from "lexical";
import { useTranslation } from "react-i18next";
import { getIcon, Icon } from "../../../../../../assets/Icons/IconClassNames";
import styles from "../ToolbarPlugin.module.scss";
import { ToolbarPluginButton } from "./ToolbarPlugin";

const RedoButton = ({disabled}: ToolbarPluginButton) => {
    const {t} = useTranslation();
    const [editor] = useLexicalComposerContext();

    return <button
        title={"ctrl+shift+z"}
        className={styles.toolbarItem}
        disabled={disabled}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        aria-label={t('RedoButton.REPEAT_1')}
    >
        <span className={getIcon(Icon.REDO)}/>
    </button>
};

export default RedoButton;
