import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCreateNewDataRowMultiChoiceOption } from "../../../../../../api/DocumentModuleChecklistApi";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import SimpleModal from "../../../../../../components/ModalComponents/SimpleModal";
import When from "../../../../../../components/When";
import { DataRowMultiChoiceType, Role } from "../../../../../../core/enums/enums";
import capitalize from "../../../../../../core/helpers/strings/stringHelper";
import {
    IDataRowMultiChoiceOption,
    IDataRowMultiChoiceOptionAlternative
} from "../../../../../../core/interfaces/document";
import useAccessCheck from "../../../../../../hooks/useAccessCheck";
import styles from "../DataRows/dataRowRadioButtonGroup.module.scss";


const newMultiChoiceOption = (type: DataRowMultiChoiceType): IDataRowMultiChoiceOption => {
    return {
        dataRowTypeSymbol: type,
        alternatives: [],
        placeholder: ""
    }
}

interface ICreateNewDataRowMultiChoiceOption {
    dataRowMultiChoiceType: DataRowMultiChoiceType;
    closeCreateNewMultiChoiceOptionModal: () => void;
}

const CreateNewDataRowMultiChoiceOption = (
    {
        dataRowMultiChoiceType,
        closeCreateNewMultiChoiceOptionModal,
    }: ICreateNewDataRowMultiChoiceOption) => {

    let { cId } = useParams();
    const {t} = useTranslation();
    const [checkCompanyRole, checkAccess] = useAccessCheck();
    const [newOption, setNewOption] = useState(newMultiChoiceOption(dataRowMultiChoiceType));

    const initAlternative: IDataRowMultiChoiceOptionAlternative = { name: "" }
    const [newAlternative, setNewAlternative] = useState<IDataRowMultiChoiceOptionAlternative>(initAlternative);

    const [errorMessage, setErrorMessage] = useState("");
    const [isGlobal, setGlobal] = useState(false);

    const [createMultiChoiceOption, isCreatingMultiChoiceOption] = useCreateNewDataRowMultiChoiceOption();
    const handleCreateNewMultiChoiceOption = async () => {
        if (!newOption.alternatives.length) {
            return;
        }

        newOption.companyId = isGlobal ? undefined : cId;

        await createMultiChoiceOption(newOption).then((response) => {
            if (response.success) {
                closeCreateNewMultiChoiceOptionModal();
            }
        });
    }

    const addAlternative = () => {
        setErrorMessage("");
        if (newAlternative.name === "") return;

        const updatedAlternative = { ...newAlternative };
        updatedAlternative.name = capitalize(updatedAlternative.name);

        if (newOption.alternatives.some((alt) => alt.name.toLowerCase() === updatedAlternative.name.toLowerCase())) {
            setErrorMessage(`${t('CreateNewDataRowMultiChoiceOption.OPTION_EXISTS_1')} "${updatedAlternative.name}" ${t('CreateNewDataRowMultiChoiceOption.OPTION_EXISTS_2')}`);
            setNewAlternative(initAlternative);
            return;
        }

        const newAlternatives: IDataRowMultiChoiceOptionAlternative[] = [...newOption.alternatives, updatedAlternative];
        const updatedOption = { ...newOption };

        let newPlaceholder = newOption.placeholder;
        if (newPlaceholder === "") {
            newPlaceholder = updatedAlternative.name;
        } else {
            newPlaceholder += "/" + updatedAlternative.name;
        }
        updatedOption.placeholder = newPlaceholder;
        updatedOption.alternatives = newAlternatives;

        setNewOption(updatedOption);
        // reset alternative
        setNewAlternative(initAlternative);
    }

    const onChange = (e: any) => {
        setNewAlternative({
            ...newAlternative,
            name: e.target.value,
        });
    }

    const renderAlternatives = () => (
        newOption.alternatives.map((alternative) => (
            <option key={alternative.name}>{alternative.name}</option>
        ))
    );

    const renderRadio = () => (
        <div className={styles.radioButtonGroup}>
            {newOption.alternatives?.map((alternative) => (
                <label
                    key={alternative.name}
                    className={styles.radioButton}
                >
                    <input
                        disabled
                        type="radio"
                        value={alternative.name}
                    />
                    {alternative.name}
                </label>
            ))}
        </div>
    );

    const handleOnEnter = (event: any) => {
        if (event.keyCode === 13) {
            addAlternative();
        }
    }

    const modalBody = () => (<div className={"pb-5"}>
        <Row className={"mb-3"}>
            <Col md={3} className={"pt-2"}>
                {t('CreateNewDataRowMultiChoiceOption.OPTION_1')}
            </Col>
            <Col md={4}>
                <CustomTextInput onKeyDown={handleOnEnter} name={"alternative"} value={newAlternative.name}
                    onChange={onChange} />
            </Col>
            <Col md={4}>
                <Button disabled={!newAlternative.name} onClick={addAlternative}>{t('CreateNewDataRowMultiChoiceOption.ADD_1')}</Button>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <When condition={!!errorMessage}>
                    <div style={{ color: "red" }}>{errorMessage}</div>
                </When>
            </Col>
        </Row>
        <Row>
            <Col>
                {t('CreateNewDataRowMultiChoiceOption.PREVIEW_1')}
            </Col>
        </Row>
        <Row>
            <Col>
                <CustomSelect
                    isEditMode={true}
                    name={"multiChoiceValue"}
                    value={newOption.placeholder}
                    defaultValue={undefined}
                    options={<>
                        {newOption.placeholder &&
                            <option key={"placeholder"} disabled hidden>{newOption.placeholder}</option>}
                        {renderAlternatives()}
                    </>}
                    onChange={() => NaN}
                />
            </Col>
        </Row>
        <Row className={"mt-3"}>
            <Col>
                {renderRadio()}
            </Col>
        </Row>
        <When condition={checkAccess(Role.SUPERADMIN)}>
            <Row className="mt-3">
                <Col>
                    <Form.Switch label={t('CreateNewDataRowMultiChoiceOption.GLOBAL_1')} onChange={({ target }) => setGlobal(target.checked)} />
                </Col>
            </Row>
        </When>
    </div>);

    const modalFooter = () => (<>
        <Button disabled={!newOption.alternatives.length} onClick={handleCreateNewMultiChoiceOption}>{t('CreateNewDataRowMultiChoiceOption.SAVE_1')}</Button>
        <Button onClick={closeCreateNewMultiChoiceOptionModal}>{t('CreateNewDataRowMultiChoiceOption.CANCEL_1')}</Button>
    </>);

    return <SimpleModal
        show={true}
        size={"lg"}
        centered={false}
        header={<>{t('CreateNewDataRowMultiChoiceOption.MULTIPLE_CHOICE_OPTION_1')}</>}
        body={modalBody()}
        footer={modalFooter()}
    />
};

export default CreateNewDataRowMultiChoiceOption;
