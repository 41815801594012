import React from "react";

interface IDev {
    children: JSX.Element[] | JSX.Element,
}
//Todo check if there's an way to remove dev code from the build.
const Dev: React.FC<IDev> = ({children}) => {
    if((process.env.REACT_APP_ENV === "development" || process.env.NODE_ENV === 'development')) {
        return <>{children}</>
    }
    return null;
};

export default Dev;