import { useEffect, useState } from "react";
import { Alert, Dropdown, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    useGetCompanyUsers,
    useGetDocumentById,
    useGetDocumentModulesById,
    useViewDocument
} from "../../api/DocumentApi";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import ChangesModal from "../../components/ChangesModal";
import ClickableIconButton from "../../components/ClickableIconButton/ClickableIconButton";
import ActionsContainer from "../../components/ContainerComponents/ActionsContainer";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import LoadingSpinner from "../../components/LoadingSpinner";
import When from "../../components/When";
import { ChecklistView, CompanyRole, DocumentTypes, PublishStatusType, Role, TemplateType } from "../../core/enums/enums";
import { toastInfo } from "../../core/helpers/toastHelper";
import { IChecklistView, IDocument, IDocumentModule } from "../../core/interfaces/document";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useGetUser } from "../../hooks/useAuth";
import { useAppDispatch } from "../../store/hooks";
import { addToast } from "../../store/slices/uiSlice";
import OpenDownloadAsPDF from "../Pdf/OpenDownloadAsPDF";
import CloseDocumentModal from "./Components/CloseDocument/CloseDocumentModal";
import { MarkAsImportant } from "./Components/MarkAsImportant";
import MarkAsImportantHandled from "./Components/MarkAsImportantHandled";
import { NewVersion } from "./Components/NewVersion";
import { PublishHandle, RequestPublish } from "./Components/PublishHandle";
import { MarkReadReceipt, ReadReceipt } from "./Components/ReadReceipt";
import { TogglePrivateTemplate, TogglePublishTemplate } from "./Components/Templates/PublishTemplateHandle";
import SaveAsTemplate from "./Components/Templates/SaveAsTemplate";
import { Versions } from "./Components/Versions";
import DocumentForm from "./DocumentForm";
import DocumentModules from "./DocumentModules/DocumentModules";
import styles from './document.module.scss';

interface DocumentProps {
    isPreview?: boolean,
    previewDocumentId?: string;
    isFromTemplate?: boolean;
}

const Document = (
    {
        isPreview,
        previewDocumentId,
        isFromTemplate,
    }: DocumentProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const { cId, documentId } = useParams();
    const docId = (isPreview ? previewDocumentId : documentId) ?? "";
    const user = useGetUser();
    const navigate = useNavigate();
    const location = useLocation();
    const fromCreated: boolean = location.state?.fromCreated ?? false;
    const fromHash = location.state?.hash ?? "";

    const [isTemplate, setIsTemplate] = useState(false);
    const [initEditMode, setInitEditMode] = useState(true);
    const [isEditMode, setEditMode] = useState(false);
    const [isActiveModule, setIsActiveModule] = useState(false);
    const [isActiveForm, setIsActiveForm] = useState(false);
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
    const [showSaveSAMGTemplateModal, setShowSaveSAMGTemplateModal] = useState(false);
    const [showCloseDocumentModal, setShowCloseDocumentModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [pdfVariant, setPdfVariant] = useState<'DOWNLOAD' | 'OPEN'>('OPEN');

    const handleShowPdfModal = (variant: 'OPEN' | 'DOWNLOAD') => {
        setPdfVariant(variant);
        setShowPdfModal(true);
    }

    const [checkCompanyRole, checkAccess] = useAccessCheck();

    const [disableQueries, setDisableQueries] = useState(false);

    const [viewDocs] = useViewDocument();

    useGetCompanyUsers();

    // Fetch document
    const {
        isFetching: isFetchingDocument,
        data: documentResponse,
    } = useGetDocumentById(docId ?? "", !isEditMode && !disableQueries);

    useEffect(() => {
        if (isFromTemplate) return;
        viewDocs()
            .catch(_ => NaN)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (fromCreated) {
            setEditMode(true);
            navigate(location.pathname, { replace: true });
        }
    }, [fromCreated]); // eslint-disable-line react-hooks/exhaustive-deps

    const [document, setDocument] = useState<IDocument | undefined>();
    const [newDocument, setNewDocument] = useState<IDocument | undefined>();
    useEffect(() => {
        if (documentResponse) {
            setDocument(documentResponse.data.data);
            setNewDocument(documentResponse.data.data);
        }
    }, [documentResponse]);

    useEffect(() => {
        if (document) {
            setIsTemplate(DocumentTypes.TEMPLATE === document.documentType);
            if (initEditMode) {
                if (checkCompanyRole(CompanyRole.ADMIN) && !document?.archived && document?.publishStatus !== PublishStatusType.CLOSED) setEditMode(true);
                setInitEditMode(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document]);


    const [checklistView, setChecklistView] = useState<IChecklistView[]>([]);
    const getChecklistView = (moduleId: string): ChecklistView => checklistView.find(item => Object.keys(item)[0] === moduleId)?.[moduleId] ?? ChecklistView.TABLE;

    const handleSetChecklistView = (moduleId: string, value: ChecklistView) => {
        const index = checklistView.findIndex(item => Object.keys(item)[0] === moduleId);
        if (index === -1) {
            setChecklistView([...checklistView, { [moduleId]: value }])
        } else {
            const updatedChecklist = [...checklistView];
            updatedChecklist[index][moduleId] = value;
            setChecklistView(updatedChecklist);
        }
    }


    // Fetch modules
    const {
        data: modulesResponse,
        isFetching: isFetchingModules,
        refetch: refetchModules,
    } = useGetDocumentModulesById(docId, !isEditMode && !disableQueries);
    const [modules, setModules] = useState<IDocumentModule[]>([]);
    useEffect(() => {
        if (modulesResponse) {
            setModules([...modulesResponse.data.data] ?? []);
        }
    }, [modulesResponse]);

    // Handle cancel
    const cancel = async () => {
        await refetchModules();
        setEditMode(false);
    }

    const goToDocuments = () => {
        if (document?.companyUserDocumentId) {
            navigate(`/c/${user.companyId}/users/${document?.companyUserDocumentId}/documents`);
            return;
        }
        let url;
        if (isFromTemplate) {
            url = cId ? `/c/${cId}/templates` : "/templates";
            if (fromHash) url += fromHash;
            navigate(url);
        } else {
            url = `/c/${user.companyId}/documents`;
            if (fromHash) url += fromHash;
            navigate(url);
        }
    }

    const renderToggleOpenDownloadPdf = () => (<>
        <CustomDropdownItem
            icon={Icon.DOWNLOAD}
            text={t('Document.DOWNLOAD_THE_PDF_1')}
            onClick={() => handleShowPdfModal('DOWNLOAD')}
        />
        <CustomDropdownItem
            icon={Icon.PDF}
            text={t('Document.OPEN_PDF_1')}
            onClick={() => handleShowPdfModal('OPEN')}
        />
    </>)

    const renderDocumentNotFound = () => (
        <>
            <ContentContainer>{t('Document.CAN_NOT_FIND')} {isFromTemplate ? t('Document.TEMPLATE') : t('Document.DOCUMENT')}</ContentContainer>
        </>
    );

    const renderTemplateActionsContainer = () => (
        <ActionsContainer placement={"top"}>
            <When condition={checkCompanyRole(CompanyRole.ADMIN)}>
                <CustomDropdown toggleType={DropdownToggleType.BARS} iconSize={"1.5em"}>
                    <TogglePublishTemplate document={document!} isEditMode={isEditMode} />
                    <TogglePrivateTemplate document={document!} isEditMode={isEditMode} />
                    {renderToggleOpenDownloadPdf()}
                </CustomDropdown>
            </When>
            {renderClosedIcon()}
            {renderEditButton()}
        </ActionsContainer>
    );

    const handleCloseDocument = () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('Document.LEAVE_EDITING_MODE_1'), t('Document.EDITING_MODE_1'))));
            return;
        }
        setShowCloseDocumentModal(true);
    }

    const renderClosedIcon = () => (
        <When condition={PublishStatusType.CLOSED === document?.publishStatus}>
            <div className={"pt-0"}>
                <span
                    className={getIcon(Icon.LOCK, IconSize.XL, "lightblue")}
                    title={t('Document.READY_FOR_USE_1')}
                />
            </div>
        </When>
    );

    const handleCloseEditMode = () => (isActiveForm || isActiveModule) ? setShowChangesModal(true) : setEditMode(false);

    const isAllowedToEdit = (checkCompanyRole(CompanyRole.ADMIN) || document?.createdBy?.id === user.userId) && !document?.archived && document?.publishStatus !== PublishStatusType.CLOSED;
    const renderEditButton = () => (<>
        <When condition={!isEditMode && isAllowedToEdit}>
            <ClickableIconButton
                disabled={false}
                buttonType={"EDIT"}
                icon={getIcon(Icon.DOCUMENT_EDIT, IconSize.XL)}
                onClick={() => setEditMode(true)}
                title={t('Document.EDITING_MODE_2')}
            />
        </When>
        <When condition={isEditMode}>
            <div className="d-flex align-content-end flex-row-reverse pt-0">
                <div className={"pt-1"}>
                    <ClickableIconButton
                        disabled={false}
                        buttonType={"CANCEL"}
                        icon={getIcon(Icon.X_MARK, IconSize.XL)}
                        onClick={handleCloseEditMode}
                        title={t('Document.LEAVE_EDITING_MODE_2')}
                    />
                </div>
                <strong>{t('Document.EDIT_MODE_1')}</strong>
            </div>
        </When>
    </>)

    const renderActionsContainer = () => (
        <ActionsContainer placement={"top"}>
            <CustomDropdown toggleType={DropdownToggleType.BARS} iconSize={"1.5em"}>
                <When
                    condition={document?.publishStatus === PublishStatusType.CREATED || document?.publishStatus === PublishStatusType.REJECTED}>
                    <When condition={checkAccess(Role.SUPERADMIN) || checkCompanyRole(CompanyRole.ADMIN)}>
                        <PublishHandle document={document!} isEditMode={isEditMode} adminApprove />
                    </When>
                    <RequestPublish document={document!} isEditMode={isEditMode} />
                </When>

                {renderToggleOpenDownloadPdf()}

                <CustomDropdownItem
                    icon={Icon.LOCK}
                    iconColor={"lightblue"}
                    text={t('Document.HIGHLIGHT_1')}
                    disabled={
                        document?.publishStatus !== PublishStatusType.APPROVED ||
                        (!checkCompanyRole(CompanyRole.ADMIN) && document.createdById !== user.userId)
                    }
                    onClick={handleCloseDocument}
                />
                <NewVersion
                    document={document!}
                    disabled={isActiveForm || isActiveModule}
                    isEditMode={isEditMode}
                    setEditMode={setEditMode}
                />

                <Versions document={document!} isEditMode={isEditMode} />
                <When condition={checkCompanyRole(CompanyRole.ADMIN)}>
                    <Dropdown.Divider />
                    <MarkAsImportant
                        document={document!}
                        disabled={isActiveForm || isActiveModule}
                        isEditMode={isEditMode}
                    />
                    <MarkAsImportantHandled
                        document={document!}
                        disabled={isActiveForm || isActiveModule}
                        isEditMode={isEditMode}
                    />
                </When>
                <When condition={document?.approvedById === user.userId}>
                    <PublishHandle document={document!} isEditMode={isEditMode} />
                </When>
                <When condition={checkCompanyRole(CompanyRole.ADMIN)}>
                    <ReadReceipt
                        document={document!}
                        disableQuery={disableQueries}
                        isEditMode={isEditMode}
                    />
                    <CustomDropdownItem
                        icon={Icon.CLIPBOARD}
                        text={t('Document.TEMPLATE_1')}
                        onClick={() => setShowSaveTemplateModal(true)}
                    />
                </When>
                <When condition={checkAccess(Role.SUPERADMIN)}>
                    <CustomDropdownItem
                        icon={Icon.CLIPBOARD}
                        text={t('Document.SAMG_TEMPLATE_1')}
                        onClick={() => setShowSaveSAMGTemplateModal(true)}
                    />
                </When>
            </CustomDropdown>
            {
                renderClosedIcon()
            }
            {
                renderEditButton()
            }
        </ActionsContainer>
    )
        ;

    const renderDocument = () => {
        return <When
            condition={document !== undefined && newDocument !== undefined}
            otherwise={renderDocumentNotFound()}
        >
            <>
                <HeaderContainer title={(document?.archived ? t('Document.ARCHIVED_1') : '') + document?.name ?? ""} />
                <Row>
                    <Col>
                        <p onClick={goToDocuments}
                            className={styles.clickableLink}>&lt; {isFromTemplate ? t('Document.TO_TEMPLATE_LIBRARY') : (document?.companyUserDocumentId ? t('Document.TO_USER_DOCUMENTS') : t('Document.TO_COMPANY_DOCUMENTS'))}</p>

                    </Col>
                    <Col>
                        <When
                            condition={DocumentTypes.TEMPLATE === document?.documentType && document.template !== null}
                            then={renderTemplateActionsContainer()}
                            otherwise={renderActionsContainer()}
                        />
                    </Col>
                </Row>
                <ContentContainer fluid>
                    <When condition={document !== undefined && isTemplate}>
                        <When condition={PublishStatusType.CREATED === document?.publishStatus}>
                            <Alert>{t('Document.NOT_PUBLISHED_1')}</Alert>
                        </When>
                    </When>
                    <DocumentForm
                        originalDocument={document!}
                        newDocument={newDocument!}
                        setNewDocument={setNewDocument}
                        isEditMode={isEditMode}
                        setIsActiveForm={setIsActiveForm}
                        viewEditModeButton={(checkCompanyRole(CompanyRole.ADMIN) || document?.createdBy?.id === user.userId) && !document?.archived && document?.publishStatus !== PublishStatusType.CLOSED}
                        setEditMode={(value) => {
                            if (value) setEditMode(value)
                            else {
                                if (isActiveForm || isActiveModule) setShowChangesModal(true)
                                else cancel()

                            }
                        }
                        }
                    />
                    <DocumentModules
                        checklistView={getChecklistView}
                        setChecklistView={handleSetChecklistView}
                        isDocumentPreview={false}
                        modules={modules}
                        isFetchingModules={isFetchingModules}
                        refetchModules={() => refetchModules(true)}
                        isEditMode={isEditMode}
                        document={document}
                        setIsActiveModule={setIsActiveModule}
                        setDisableQueries={setDisableQueries}
                        isDocumentArchived={document?.archived}
                    />

                    {!isFromTemplate && (
                        <MarkReadReceipt hide={(isEditMode || !!isPreview)} document={document!}
                            disableQuery={disableQueries} />
                    )}
                </ContentContainer>
            </>
        </When>

    }

    return <MainContainer>
        <When
            condition={!isFetchingDocument}
            then={renderDocument()}
            otherwise={<LoadingSpinner />}
        />

        <ChangesModal show={showChangesModal} setShow={(value) => {
            setShowChangesModal(false);
            if (value) cancel();
        }} />

        <When condition={showSaveTemplateModal}>
            <SaveAsTemplate
                showModal={showSaveTemplateModal}
                setShowModal={setShowSaveTemplateModal}
                templateType={TemplateType.DOCUMENT}
                documentId={document?.id}
                companyId={user.companyId}
            />
        </When>

        <When condition={showSaveSAMGTemplateModal}>
            <SaveAsTemplate
                showModal={showSaveSAMGTemplateModal}
                setShowModal={setShowSaveSAMGTemplateModal}
                templateType={TemplateType.DOCUMENT}
                documentId={document?.id}
            />
        </When>

        <When condition={showCloseDocumentModal}>
            <CloseDocumentModal
                isUserDocument={!!document?.companyUserDocumentId}
                documentId={documentId!}
                showCloseDocumentModal={showCloseDocumentModal}
                setShowCloseDocumentModal={setShowCloseDocumentModal}
            />
        </When>

        <When condition={showPdfModal}>
            <OpenDownloadAsPDF
                showPdfModal={showPdfModal}
                closePdfModal={() => setShowPdfModal(false)}
                pdfVariant={pdfVariant}
            />
        </When>
    </MainContainer>
}


export default Document;
