import { useTranslation } from "react-i18next";
import { useGetCompanyReadReceipts } from "../../../../api/DocumentApi";
import { useAcceptModalContext } from "../../../../providers/AcceptModalProvider";
import DashboardCard from "../../DashboardCard";
import { DashboardCardContentStatistics } from "../../DashboardCardContent";
import ReadReceiptsTable from "./ReadReceiptTable";



const DashboardUnHandledReadReceipts = () => {
    const {t} = useTranslation();
    const [readReceipts, isFetching] = useGetCompanyReadReceipts(true);

    const acceptModal = useAcceptModalContext();
    return (
        <DashboardCard fetching={false} theme={"lightblue"} height="12rem" onClick={() => acceptModal.open({
            header: t('DashboardUnHandledReadReceipts.UNREAD_RECEIPTS_1'),
            declineButtonText: t('DashboardUnHandledReadReceipts.CLOSE_1'),
            size: 'lg',
            body: <ReadReceiptsTable readReceipts={readReceipts} />,
            hideAcceptButton: true,
        })}>
            <DashboardCardContentStatistics loading={isFetching}
                                            valueAsString={readReceipts.length.toString()}
                                            title={t('DashboardUnHandledReadReceipts.UNREAD_RECEIPTS_2')}/>
        </DashboardCard>
    )
}

export default DashboardUnHandledReadReceipts
