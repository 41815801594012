import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfirmToken } from "../../api/UserApi";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import CustomForm from "../../components/FormComponents/CustomForm";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import SpinnerButton from "../../components/SpinnerButton";
import When from "../../components/When";
import { createNewConfirmToken, validatePassword } from "../../core/helpers/userHelper";
import { IConfirmResponse, IConfirmUserToken } from "../../core/interfaces/user";
import { useSearchParam } from "../../hooks/useSearchParam";
import useUpdatableObject from "../../hooks/useUpdatableObject";
import scss from './Confirm.module.scss';


const Group: React.FC<{ controlId?: string, title?: string, className?: string, children: JSX.Element }> = ({
                                                                                                                controlId = "",
                                                                                                                title = "",
                                                                                                                className,
                                                                                                                children
                                                                                                            }) =>
    <FormBase controlId={controlId} title={title}
              labelProps={{lg: {span: 2, offset: 1}, xxl: {span: 2}}}
              inputProps={{lg: {span: 10}, xxl: {span: 8}}}
              className={className + ' pb-2'}>
        {children}
    </FormBase>

const Confirm = () => {
    const {t} = useTranslation();
    const token = useSearchParam('token', '/login');
    const navigate = useNavigate();
    const type = useSearchParam('type');
    const email = useSearchParam('email');
    const [newEmailSent, setNewEmailSent] = useState(false);


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [updated, setUpdated, _, submit, loading] = useUpdatableObject<IConfirmUserToken, IConfirmResponse>(createNewConfirmToken(), (confirmUserToken) => ConfirmToken(confirmUserToken, type), {
        skipErrorToast: (_, data) => {
            return !!data.failedValidation;
        }
    })

    const [error, setErrors] = useState({
        password: true,
        passwordAgain: true,
    });

    useEffect(() => {
        const updatedObject = {
            ...updated,
            email: email,
            token: encodeURIComponent(token)
        };
        setUpdated(updatedObject)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, email, setUpdated])

    const handleSubmit = () =>
        submit().then((resp) => {
            if (resp.newConfirmationEmail) {
                setNewEmailSent(true);
            } else {
                navigate('/login', {
                    state: {
                        fromConfirmed: true,
                    }
                })
            }
        }).catch(data => {
            if(data.failedValidation) {
                navigate('/forgot-password', {
                    state: {
                        fromConfirmed: true,
                    }
                })
            }
        })

    const validate = (activeUser: IConfirmUserToken, name: string) => {
        const errors = {
            ...error
        };

        switch (name) {
            case 'password':
            case 'passwordAgain':
                errors.password = !validatePassword(activeUser.password);
                errors.passwordAgain = activeUser.password !== activeUser.passwordAgain
                break;
        }
        setErrors(errors)
        return activeUser;
    }

    const commonProps = {
        setValue: setUpdated,
        object: updated,
        isEditMode: true,
        errorObject: error,
        onChangeMiddleWare: validate
    }
    return (
        <MainContainer className={scss.container}>
            <ContentContainer>
                <Row>
                    <Col>
                        <h4 className="text-center">
                            <When condition={type === 'password'} otherwise={t('Confirm.VERIFY_YOUR_ACCOUNT_1')}>
                                {t('Confirm.NEW_PASSWORD_1')}
                            </When>
                        </h4>
                    </Col>
                </Row>
                <Row className={'pt-0'}>
                    <When condition={!newEmailSent}
                          otherwise={<p className={'text-center'}>{t('Confirm.VERIFICATION_TIMEOUT_1')}<br/>{t('Confirm.CHECK_SPAM_1')}</p>}>
                        <CustomForm onSubmit={handleSubmit} useValidated={false}>
                            <Group controlId={t('Confirm.EMAIL_1')} title={t('Confirm.E_MAIL_1')}>
                                <GenericInput type={'email'} name={'email'} disabled={true} {...commonProps}/>
                            </Group>
                            <Group controlId={t('Confirm.PASSWORD_1')} title={t('Confirm.PASSWORD_2')}>
                                <GenericInput name={'password'} type={'password'} {...commonProps}
                                              description={t('Confirm.PASSWORD_REQUIREMENTS_1')}/>
                            </Group>
                            <Group controlId={'Password_again'} title={t('Confirm.PASSWORD_AGAIN_1')}>
                                <GenericInput name={'passwordAgain'} type={'password'} {...commonProps}
                                              description={t('Confirm.COMPLY_WITH_PREVIOUS_PASSWORD_1')}/>
                            </Group>
                            <Group className={'pt-2'}>
                                <SpinnerButton type={"submit"} text={t('Confirm.VERIFY_1')} isLoading={loading}
                                               disabled={!!Object.values(error).filter(s => s).length}></SpinnerButton>
                            </Group>
                        </CustomForm>
                    </When>
                </Row>
            </ContentContainer>
        </MainContainer>)
}

export default Confirm
