import { useTranslation } from "react-i18next";
import CustomMultiSelect from "../../../components/FormComponents/CustomMultiSelect";
import { IPerson } from "../../../core/interfaces/common";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


type PersonsInvolvedFormProps = {
    editMode: boolean,
    companyUsers: IPerson[],
    newIncident: IIncident,
    onChange: (e: any) => void
}
export const PersonsInvolvedForm: React.FC<PersonsInvolvedFormProps> = (props) => {
    const {t} = useTranslation();
    return <FormProvider controlId={"formInvolvedPersons"} title={t('PersonsInvolvedForm.OWN_COMPANY_1')} colAs={'large'}>
        {!props.editMode && !props.newIncident.involvedUsers?.length ?
            <span>{t('PersonsInvolvedForm.NO_PERSONS_SELECTED_1')}</span>
            :
            <CustomMultiSelect
                isEditMode={props.editMode}
                name={"involvedUsers"}
                options={props.companyUsers?.map(p => ({value: p.id, label: p.name})) ?? []}
                selected={props.newIncident?.involvedUsers?.map(p => ({value: p.id, label: p.name})) ?? []}
                onChange={props.onChange}
            />
        }
    </FormProvider>
}
