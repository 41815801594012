import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import When from "../../../components/When";
import { DocumentEventType, PublishStatusType } from "../../../core/enums/enums";
import { DateToLocale } from "../../../core/helpers/dates/datesHelper";
import { IDocument, IDocumentEvent } from "../../../core/interfaces/document";


const PublishStatusAlert = ({document}: { document: IDocument }) => {
    const {t} = useTranslation();
    const [rejectionEvent, setRejectionEvent] = useState<IDocumentEvent>();
    useEffect(() => {

        const latestRejectionEvent = document.eventInformations?.filter(e => e.event === DocumentEventType.PUBLISHED_REJECTED).sort((a, b) => new Date(b.createdAt ?? "").getTime() - new Date(a.createdAt ?? "").getTime())
        if (latestRejectionEvent) {
            setRejectionEvent(latestRejectionEvent[0]);
        }
    }, [document.eventInformations])
    return (
        <>
            <When condition={document.publishStatus === PublishStatusType.REJECTED}>
                <Row>
                    <Alert variant="danger">
                        <When condition={rejectionEvent != null} otherwise={t('PublishStatusAlert.REJECTED_DOCUMENT_1')}>
                            <p>
                                {DateToLocale(rejectionEvent?.createdAt)} | {rejectionEvent?.createdBy?.name} {t('PublishStatusAlert.REJECTED_DOCUMENT_2')}
                            </p>
                            <When condition={rejectionEvent?.comment !== ""}>
                                <hr/>
                                {t('PublishStatusAlert.COMMENT_1')} {rejectionEvent?.comment}
                            </When>
                        </When>
                    </Alert>
                </Row>
            </When>
            <When condition={document.publishStatus === PublishStatusType.AWAITING_APPROVAL}>
                <Row>
                    <Alert>
                        {t('PublishStatusAlert.AWAITING_APPROVAL_1')}
                    </Alert>
                </Row>
            </When>
        </>
    )
}

export default PublishStatusAlert
