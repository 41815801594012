import { useTranslation } from "react-i18next";
import { IIncident } from "../../../core/interfaces/incident";
import { BooleanForm } from "./BooleanForm";



export function HasExternalUsersForm (props: { editMode: boolean, newIncident: IIncident, onChange: (value: boolean | null, e: any) => void })
{
   const {t} = useTranslation();
   return <BooleanForm {...props} header={t('HasExternalUsersForm.EXTERNAL_PERSONS_INVOLVED_1')} propertyName={"hasExternalUsers"}
                 property={props.newIncident.hasExternalUsers}/>
}
