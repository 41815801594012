import { ReactNode } from "react";
import {useTranslation} from "react-i18next";

interface IDataTableNoData {
    children?: ReactNode;
}

const DataTableNoData = ({children}: IDataTableNoData) => {
    const {t} = useTranslation();
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '2rem',
        paddingBottom: '2rem',
    }}>
        {children ?? t('DataTableNoData.NO_RESULTS_1')}
    </div>
};

export default DataTableNoData;
