import { useTranslation } from "react-i18next";
import { useMarkDocument } from "../../../api/DocumentApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import { CompanyRole } from "../../../core/enums/enums";
import { toastInfo } from "../../../core/helpers/toastHelper";
import { IDocument } from "../../../core/interfaces/document";
import useAccessCheck from "../../../hooks/useAccessCheck";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";

export const MarkAsImportant = (
    {
        document,
        disabled,
        isEditMode,
        documentId,
    }: { document: IDocument, disabled: boolean, isEditMode: boolean, documentId?: string, }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [checkCompanyRole] = useAccessCheck();
    const [request, isLoading] = useMarkDocument(documentId);

    const handleRequest = async () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('MarkAsImportant.LEAVE_EDITING_MODE_1'), t('MarkAsImportant.LEAVE_EDITING_MODE_2'))));
            return;
        }
        await request(!document.important);
    }

    return (<CustomDropdownItem
            disabled={disabled || isLoading || !((checkCompanyRole(CompanyRole.ADMIN)))}
            onClick={handleRequest}
        text={`${document.important ? t('MarkAsImportant.UNSELECT_1') : t('MarkAsImportant.SELECT_1')} ${t('MarkAsImportant.IMPORTANT_1')}`}
            icon={Icon.CIRCLE}
            iconColor={"red"}
        />
    )
}
