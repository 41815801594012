import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ICustomQuery, ICustomQueryKeys, IPaginated } from "../core/interfaces/common";
import { INotification } from "../core/interfaces/notification";
import { useDelete, useGet, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import {
    URL_NOTIFICATIONS,
    URL_NOTIFICATIONS_DELETE,
    URL_NOTIFICATIONS_READ,
    URL_NOTIFICATIONS_UNREAD
} from "./endpoints/endpoints";

export const useGetNotifications = (): [IPaginated<INotification>, boolean] => {
    const queryKeys: ICustomQueryKeys = {root: "notifications"};
    const query: ICustomQuery = {
        url: URL_NOTIFICATIONS(),
        queryKeys: queryKeys,
        options: {
            staleTime: 600000
        }
    };

    const {
        isFetching,
        data,
    } = useGet<IPaginated<INotification>>(query);

    return [data?.data as unknown as IPaginated<INotification>, isFetching]
}

const useReadNotifications = (): [() => Promise<boolean>, boolean] => {
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "notifications"}

    const usePutMutation = usePut<{}>(
        URL_NOTIFICATIONS_READ(),
        queryKeys,
        true
    );

    const request = () => usePutMutation.mutateAsync({}).then(async (response) => {
        queryClient.invalidateQueries(["notificationsCount"]);
        return !!response.success
    });
    return [request, usePutMutation.isLoading];
}
export const useGetUnreadNotificationsCount = (): [IPaginated<INotification>, number, () => void, boolean] => {
    const [notifications] = useGetNotifications();
    const [read] = useReadNotifications();
    const queryClient = useQueryClient();
    const [previousCount, setPreviousCount] = useState(0);
    const queryKeys: ICustomQueryKeys = {root: "notificationsCount"};
    const query: ICustomQuery = {
        url: URL_NOTIFICATIONS_UNREAD(),
        queryKeys: queryKeys,
        options: {
            staleTime: 30000,
            refetchInterval: 60000 * 3,
        }
    };

    const {
        isFetching,
        data,
    } = useGet<number>(query);


    useEffect(() => {
        if (data?.data === undefined) return;
        const num = data?.data as unknown as number;
        if (num === previousCount) return;
        setPreviousCount(num);
        queryClient.invalidateQueries(["notifications"]);
        // eslint-disable-next-line
    }, [data])
    return [notifications, data?.data as unknown as number ?? 0, () => previousCount > 0 && read(), isFetching]
}


export const useDeleteNotification = (notificationId: string): [() => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "notifications"};

    const useDeleteMutation = useDelete<void>(
        URL_NOTIFICATIONS_DELETE(notificationId),
        queryKeys,
        true
    );

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            await queryClient.invalidateQueries(["notifications"]);
        }
        return !!response.success
    });
    return [request, useDeleteMutation.isLoading];
}