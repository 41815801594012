import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Overlay, Tooltip} from 'react-bootstrap';

interface Props {
    tooltipText?: string;
    children: ReactNode;
}

const TooltipWrapper = ({tooltipText, children}: Props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const targetRef = useRef(null);
    const overlayRef = useRef(null);

    const handleToggle = () => setShowTooltip(!showTooltip);

    useEffect(() => {
        if (!showTooltip) return;
        const handleClickOutside = (event: MouseEvent) => {
            if (targetRef.current && overlayRef.current && !(targetRef.current as any).contains(event.target)) {
                setShowTooltip(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
    }, [showTooltip, targetRef, overlayRef]);

    return (
        <>
            {React.cloneElement(children as React.ReactElement, {
                ref: targetRef,
                onClick: handleToggle,
                title: tooltipText,
            })}
            <Overlay target={targetRef.current} show={showTooltip} placement="top" offset={[0, 0]} ref={overlayRef}>
                {(props) => (
                    <Tooltip id="tooltip-top" {...props} className="custom-tooltip">
                        {tooltipText}
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
};

export default TooltipWrapper;
