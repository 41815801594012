import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import {
    useDeleteDataRowMultiChoiceOption,
    useGetMultiChoiceOptions
} from "../../../../../../api/DocumentModuleChecklistApi";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import SimpleConfirmModal from "../../../../../../components/ModalComponents/SimpleConfirmModal";
import When from "../../../../../../components/When";
import {
    DataRowMultiChoiceType,
    DataRowType,
    MultiChoiceAlternativeClassified,
    MultiChoiceDisplayType
} from "../../../../../../core/enums/enums";
import { toastError } from "../../../../../../core/helpers/toastHelper";
import { IDataRowMultiChoiceField, IDataRowMultiChoiceOption } from "../../../../../../core/interfaces/document";
import { useAppDispatch } from "../../../../../../store/hooks";
import { addToast } from "../../../../../../store/slices/uiSlice";
import CreateNewDataRowMultiChoiceOption from "./CreateNewDataRowMultiChoiceOption";


const newDataRowMultiChoice: IDataRowMultiChoiceField = {
    checklistFormDataRowTypeSymbol: DataRowType.MULTI_CHOICE_FIELD,
    isNew: true,
    header: "",
    isClosed: false,
}

interface IAddMultiChoice {
    multiChoiceType: DataRowMultiChoiceType;
    onAdd: (dataRow: IDataRowMultiChoiceField) => void;
    onCancel: () => void;
}

const AddMultiChoice = (
    {
        multiChoiceType,
        onAdd,
        onCancel,
    }: IAddMultiChoice) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [dataRow, setDataRow] = useState(newDataRowMultiChoice);
    const [multiChoiceOptions, setMultiChoiceOptions] = useState<IDataRowMultiChoiceOption[]>([]);
    const [showCreateNewMultiChoiceOptionModal, setShowCreateNewMultiChoiceOptionModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState<IDataRowMultiChoiceOption | null>(null);

    const {
        data: multiChoicesResponse,
    } = useGetMultiChoiceOptions(multiChoiceType);

    useEffect(() => {
        setMultiChoiceOptions(multiChoicesResponse?.data.data ?? []);
    }, [multiChoicesResponse?.data.data]);

    const handleOnAdd = () => {
        onAdd(dataRow);
    }

    const [showDeleteOptionModal, setShowDeleteOptionModal] = useState(false);
    const [deleteOption] = useDeleteDataRowMultiChoiceOption(selectedOption?.id ?? "");
    const handleDeleteOption = async () => {
        setShowDeleteOptionModal(false);
        if (!selectedOption) {
            dispatch(addToast(toastError(t('AddMultiChoice.ERROR_OCCURRED_1'), t('AddMultiChoice.TRY_AGAIN_OR_CONTACT_SUPPORT_1'))));
            return;
        }
        await deleteOption(selectedOption.placeholder).then((response) => {
            if (response.status === 202) {
                setDataRow({
                    ...dataRow,
                    multiChoiceAlternatives: undefined
                });
                setSelectedOption(null);
                return;
            }
        });
    }

    const onChangeOption = (e: any) => {
        if (e.target.value === "") {
            setDataRow({
                ...dataRow,
                multiChoiceAlternatives: undefined
            });
            setSelectedOption(null);
            return;
        }
        const multiChoiceOptionsClone = _.cloneDeep(multiChoiceOptions);
        const selectedIndex = multiChoiceOptionsClone.findIndex(opt => opt.id === e.target.value);
        if (selectedIndex < 0) {
            dispatch(addToast(toastError(t('AddMultiChoice.ERROR_OCCURRED_2'), t('AddMultiChoice.COULD_NOT_SELECT_OPTION_1'))));
            return;
        }
        const option = multiChoiceOptionsClone[selectedIndex];
        option.alternatives.forEach((opt) => opt.classifiedAs = MultiChoiceAlternativeClassified.TRANSPARENT);
        setDataRow({
            ...dataRow,
            multiChoiceAlternatives: option.alternatives,
        });
        setSelectedOption(option);
    }

    const renderMultiChoiceOptions = () => {
        if (multiChoiceOptions.length === 0) return <option value={""}>{t('AddMultiChoice.NO_CHOICES_1')}</option>
        return <>
            <option value={""}>{t('AddMultiChoice.CHOOSE_HERE_1')}</option>
            {multiChoiceOptions.map((option) => (
                <option key={option.id} value={option.id}>{option.placeholder}</option>
            ))}
        </>
    }

    const handleOnChange = (e: any, alternativeName: string) => {
        if (!dataRow.multiChoiceAlternatives) {
            dispatch(addToast(toastError(t('AddMultiChoice.ERROR_OCCURRED_3'), t('AddMultiChoice.COULD_NOT_SELECT_OPTION_2'))));
            return;
        }
        const alternativeIndex = dataRow.multiChoiceAlternatives.findIndex((obj) => obj.name === alternativeName);
        if (alternativeIndex < 0) {
            dispatch(addToast(toastError(t('AddMultiChoice.ERROR_OCCURRED_4'), t('AddMultiChoice.COULD_NOT_SELECT_OPTION_3'))));
            return;
        }
        dataRow.multiChoiceAlternatives[alternativeIndex].classifiedAs = e.target.value;
        setDataRow({...dataRow});
    }

    const onChange = (e: any) => {
        setDataRow({
            ...dataRow,
            [e.target.name]: e.target.value,
        })
    }

    const colorPositive = "rgb(0, 128, 0)";
    const colorNegative = "rgb(255, 0, 0)";
    const colorNeutral = "rgb(245,165,0)";
    const colorTransparent = "";

    const renderValues = () => {
        if (!dataRow.multiChoiceAlternatives) return <></>
        return dataRow.multiChoiceAlternatives.map((alternative) => {
            return <tr key={alternative.name}>
                <td>{alternative.name}</td>
                <td>
                    <Form.Check
                        style={{color: colorPositive}}
                        type={"radio"}
                        inline
                        label={t('AddMultiChoice.POSITIVE_1')}
                        checked={MultiChoiceAlternativeClassified.POSITIVE === alternative.classifiedAs}
                        name={alternative.name}
                        id={"positive_" + alternative.name}
                        value={MultiChoiceAlternativeClassified.POSITIVE}
                        onChange={(e: any) => handleOnChange(e, alternative.name)}
                    />
                    <Form.Check
                        style={{color: colorNegative}}
                        type={"radio"}
                        inline
                        label={t('AddMultiChoice.NEGATIVE_1')}
                        name={alternative.name}
                        id={"negative_" + alternative.name}
                        checked={MultiChoiceAlternativeClassified.NEGATIVE === alternative.classifiedAs}
                        value={MultiChoiceAlternativeClassified.NEGATIVE}
                        onChange={(e: any) => handleOnChange(e, alternative.name)}
                    />
                    <Form.Check
                        style={{color: colorNeutral}}
                        type={"radio"}
                        inline
                        label={t('AddMultiChoice.NEUTRAL_1')}
                        name={alternative.name}
                        id={"neutral_" + alternative.name}
                        checked={MultiChoiceAlternativeClassified.NEUTRAL === alternative.classifiedAs}
                        value={MultiChoiceAlternativeClassified.NEUTRAL}
                        onChange={(e: any) => handleOnChange(e, alternative.name)}
                    />
                    <Form.Check
                        style={{color: colorTransparent}}
                        type={"radio"}
                        inline
                        label={t('AddMultiChoice.TRANSPARENT_1')}
                        name={alternative.name}
                        id={"transparent_" + alternative.name}
                        checked={MultiChoiceAlternativeClassified.TRANSPARENT === alternative.classifiedAs}
                        value={MultiChoiceAlternativeClassified.TRANSPARENT}
                        onChange={(e: any) => handleOnChange(e, alternative.name)}
                    />
                </td>
            </tr>
        });
    }

    return <>
        <Row>
            <Col md={3}>{t('AddMultiChoice.TITLE_1')}</Col>
            <Col md={4}>
                <CustomTextInput
                    name={"header"}
                    value={dataRow.header}
                    onChange={onChange}
                    disableAutocomplete
                    required
                    autofocus={true}
                />
            </Col>
        </Row>

        <Row className={"mt-3"}>
            <Col md={3}>{t('AddMultiChoice.TYPE_OF_CONTENT_1')}</Col>
            <Col md={4}>
                <CustomSelect
                    isEditMode={true}
                    name={"option"}
                    value={dataRow.multiChoiceValue ?? undefined}
                    onChange={onChangeOption}
                    options={
                        <>
                            {renderMultiChoiceOptions()}
                        </>
                    }
                />
            </Col>
            <Col md={3}>
                <Button
                    className={"me-2"}
                    onClick={() => setShowCreateNewMultiChoiceOptionModal(true)}>
                    {t('AddMultiChoice.NEW_LIST_1')}
                </Button>
                <Button
                    disabled={!selectedOption}
                    onClick={() => setShowDeleteOptionModal(true)}>
                    {t('AddMultiChoice.DELETE_2')}
                </Button>
            </Col>
        </Row>

        <Row className={"mt-3"}>
            <Col md={3}>{t('AddMultiChoice.SHOW_AS_1')}</Col>
            <Col md={4}>
                <CustomSelect
                    isEditMode={true}
                    name={"multiChoiceDisplayType"}
                    value={dataRow.multiChoiceDisplayType}
                    onChange={onChange}
                    options={<>
                        <option value={""}>{t('AddMultiChoice.CHOOSE_HERE_2')}</option>
                        <option value={MultiChoiceDisplayType.DROPDOWN} key={MultiChoiceDisplayType.DROPDOWN}>Dropdown</option>
                        <option value={MultiChoiceDisplayType.RADIO} key={MultiChoiceDisplayType.RADIO}>Radio</option>
                    </>
                    }

                />
            </Col>
        </Row>

        <When condition={!!dataRow.multiChoiceAlternatives}>
            <Row className={"mt-3"}>
                <Col md={3} className={"pt-2"}>{t('AddMultiChoice.SPECIFY_1')}</Col>
                <Col md={9} className={"mt-3"}>
                    <p>{t('AddMultiChoice.SELECT_VALUE_FOR_MULTIPLE_CHOICE_OPTIONS_1')}</p>
                    <Table striped bordered responsive>
                        <thead>
                        <tr>
                            <th>{t('AddMultiChoice.ALTERNATIVE_1')}</th>
                            <th>{t('AddMultiChoice.CLASSIFICATION_1')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderValues()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </When>

        <Row className={"mt-3"}>
            <Col md={3}></Col>
            <Col md={4}>
                <Button
                    className={"me-3"}
                    disabled={!dataRow.header || !dataRow.multiChoiceDisplayType || !dataRow.multiChoiceAlternatives || dataRow.multiChoiceAlternatives.some(opt => opt.classifiedAs === null)}
                    onClick={handleOnAdd}>{t('AddMultiChoice.INSERT_1')}</Button>
                <Button onClick={onCancel}>{t('AddMultiChoice.CANCEL_1')}</Button>
            </Col>
        </Row>

        <When condition={showCreateNewMultiChoiceOptionModal}>
            <CreateNewDataRowMultiChoiceOption
                dataRowMultiChoiceType={DataRowMultiChoiceType.CHECKLIST}
                closeCreateNewMultiChoiceOptionModal={() => setShowCreateNewMultiChoiceOptionModal(false)}
            />
        </When>

        <When condition={showDeleteOptionModal}>
            <SimpleConfirmModal
                centered={false}
                show={true}
                header={<>{t('AddMultiChoice.DELETE_OPTION_1')}</>}
                text={<>
                    {t('AddMultiChoice.CONFIRM_DELETE_1')} "{selectedOption?.placeholder}"? <br/>
                    {t('AddMultiChoice.CONFIRM_DELETE_2')}
                </>}
                onClickOKText={t('AddMultiChoice.DELETE_1')}
                onClickOK={handleDeleteOption}
                onClickCancelText={t('AddMultiChoice.CANCEL_2')}
                onClickCancel={() => setShowDeleteOptionModal(false)}
            />
        </When>
    </>
};

export default AddMultiChoice;
