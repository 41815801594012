import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    useGetCompanyUsers,
    usePublishApprove,
    usePublishReject,
    usePublishRequestApproval
} from "../../../api/DocumentApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import CustomSelect from "../../../components/FormComponents/CustomSelect";
import CustomTextAreaInput from "../../../components/FormComponents/CustomTextAreaInput";
import When from "../../../components/When";
import { CompanyRole, PublishStatusType } from "../../../core/enums/enums";
import { toastInfo } from "../../../core/helpers/toastHelper";
import { IDocument } from "../../../core/interfaces/document";
import { useGetUser } from "../../../hooks/useAuth";
import { useAcceptModalContext } from "../../../providers/AcceptModalProvider";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";


const RejectModalBody = ({setText}: { setText: (t: string) => void }) => {
    const {t} = useTranslation();
    const [comment, setComment] = useState('')
    return <Row>
        <Col>
            <p>{t('PublishHandle.REJECT_DOCUMENT_1')}</p>
            <CustomTextAreaInput isEditMode={true} rows={2} name={'me'} value={comment} onChange={(v) => {
                if (v.target.value.length < 999) {
                    setText(v.target.value)
                    setComment(v.target.value)
                }
            }}/>
        </Col>
    </Row>
}

export const RequestPublish = ({document, isEditMode}: { document: IDocument, isEditMode: boolean }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const acceptModalContext = useAcceptModalContext();
    const user = useGetUser();

    const [companyUsers] = useGetCompanyUsers();
    const [chosenUserId, setChosenUserId] = useState<string>();

    const [request, isLoading] = usePublishRequestApproval(document.id);

    const handleSelect = (e: any) => {
        const value = (e.target.value ?? '');
        setChosenUserId(value);
    }

    useEffect(() => {
        acceptModalContext.disableAcceptButton(chosenUserId === undefined || chosenUserId === '');
        acceptModalContext.setAcceptFunction(() => request(chosenUserId!));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenUserId]);

    const modalData = companyUsers
        .filter(u => (u.roleSymbol === CompanyRole.ADMIN || u.roleSymbol === CompanyRole.EDITOR) && u.userId !== user.userId)
        .map(person => <option value={person.userId}>{person.name}</option>);

    const handleRequestPublish = () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('PublishHandle.LEAVE_EDITING_MODE_1'), t('PublishHandle.EDITING_MODE_1'))));
            return;
        }
        acceptModalContext.open({
            header: t('PublishHandle.REQUEST_APPROVAL_1'),
            declineButtonText: t('PublishHandle.CANCEL_1'),
            acceptButtonText: t('PublishHandle.REQUEST_APPROVAL_2'),
            acceptButtonVariant: "primary",
            disableAcceptButton: true,
            body: <CustomSelect
                isEditMode={true}
                name={'userId'}
                onChange={handleSelect}
                options={<>
                    <option value={''}>{t('PublishHandle.SELECT_USER_1')}</option>
                    {modalData}
                </>}
            />
        })
    }

    return (
        <CustomDropdownItem
            icon={Icon.CALENDAR}
            text={t('PublishHandle.REQUEST_APPROVAL_3')}
            disabled={isLoading || !(user.userId === document.createdBy?.id && (document.publishStatus === PublishStatusType.CREATED || document.publishStatus === PublishStatusType.REJECTED))}
            onClick={handleRequestPublish}
        />
    )
}

export const PublishHandle = ({document, isEditMode, adminApprove}: {
    document: IDocument,
    isEditMode: boolean,
    adminApprove?: boolean
}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [approve, isApproveLoading] = usePublishApprove(document.id);
    const [reject, isRejectLoading] = usePublishReject(document.id);

    const [comment, setComment] = useState('');

    const acceptModalContext = useAcceptModalContext();

    const handleReject = async (comment: string) => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('PublishHandle.EXIT_EDITING_MODE_1'), t('PublishHandle.LEAVE_EDITING_MODE_2'))));
            return;
        }
        await reject(comment);
    }

    useEffect(() => {
        acceptModalContext.disableAcceptButton(comment.length === 0);
        acceptModalContext.setAcceptFunction(() => handleReject(comment));
        // eslint-disable-next-line
    }, [comment]);

    const handleApprove = async () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('PublishHandle.LEAVE_EDITING_MODE_3'), t('PublishHandle.EDITING_MODE_2'))));
            return;
        }
        acceptModalContext.trigger(async () => await approve(), {
            header: t('PublishHandle.ACCEPT_1'),
            declineButtonText: t('PublishHandle.CLOSE_1'),
            body: <p>{t('PublishHandle.APPROVE_DOCUMENT_1')}</p>,
            acceptButtonVariant: 'primary',
            acceptButtonText: t('PublishHandle.YES_1'),
        })
    }

    const renderAdminApprove = () => (<>
        <CustomDropdownItem icon={Icon.CALENDAR} text={t('PublishHandle.APPROVE_DOCUMENT_DIRECTLY_1')}
                            disabled={isApproveLoading || isRejectLoading}
                            onClick={handleApprove}/>
    </>);

    const renderDefaultDropdowns = () => (<>
        <CustomDropdownItem icon={Icon.CALENDAR} text={t('PublishHandle.ACCEPT_DOCUMENT_1')}
                            disabled={isApproveLoading || isRejectLoading || (document.publishStatus !== PublishStatusType.AWAITING_APPROVAL)}
                            onClick={handleApprove}/>
        <CustomDropdownItem icon={Icon.CALENDAR} text={t('PublishHandle.REJECT_DOCUMENT_2')}
                            disabled={isApproveLoading || isRejectLoading || (document.publishStatus !== PublishStatusType.AWAITING_APPROVAL)}
                            onClick={() => {
                                if (isEditMode) {
                                    dispatch(addToast(toastInfo(t('PublishHandle.LEAVE_EDITING_MODE_4'), t('PublishHandle.EDITING_MODE_3'))));
                                    return;
                                }
                                setComment("");
                                acceptModalContext.open({
                                    header: t('PublishHandle.REJECT_DOCUMENT_3'),
                                    declineButtonText: t('PublishHandle.CLOSE_2'),
                                    acceptButtonText: t('PublishHandle.REJECT_1'),
                                    disableAcceptButton: true,
                                    body: <RejectModalBody setText={setComment}/>
                                })
                            }}/>
    </>);

    return (
        <>
            <When
                condition={!!adminApprove}
                then={renderAdminApprove()}
                otherwise={renderDefaultDropdowns()}
            />
        </>
    )
        ;
}
