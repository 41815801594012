import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FORMAT_TEXT_COMMAND } from "lexical";
import { useTranslation } from "react-i18next";
import styles from "../../ToolbarPlugin.module.scss";
import { ToolbarPluginTextFormatButton } from "../ToolbarPlugin";
import TextFormats from "./TextFormats";

const TextFormatButton = ({formatType, isActiveFormatType, disabled}: ToolbarPluginTextFormatButton) => {
    const {t} = useTranslation();
    const [editor] = useLexicalComposerContext();

    const format = TextFormats.find((f) => f.key === formatType);
    if (!format) {
        console.error(t('TextFormatButton.FORMAT_NOT_FOUND_1'));
        return <></>;
    }

    const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, format!.key);
    }
    return <button
        disabled={disabled}
        title={t(format!.title)}
        className={[styles.toolbarItem, isActiveFormatType ? styles.toolbarItemActive : ''].join(" ")}
        onClick={handleClick}
        aria-label={format!.key}
    >
        <span className={format!.icon}/>
    </button>
};

export default TextFormatButton;
