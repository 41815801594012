import { NodeModel } from "@minoru/react-dnd-treeview";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import { useCloseDocument, useCopyDocument } from "../../../../api/DocumentApi";
import { useGetNodes } from "../../../../api/DocumentDirectory";
import ModalForm from "../../../../components/FormComponents/ModalForm";
import When from "../../../../components/When";
import { CustomFolders } from "../../../../core/enums/enums";
import { IDocumentNodeModel, IFolderNodeModel } from "../../../../core/interfaces/directory";
import { IDocumentCloseRequest, IDocumentCopyRequest } from "../../../../core/interfaces/document";
import { convertDirectoryNodesToModels, getParentIds } from "../../../DocumentDirectory/Helpers/DirectoryHelper";
import styles from "./CloseDocument.module.scss";
import CloseDocumentDirectoryTree from "./CloseDocumentDirectoryTree";

interface ICloseDocumentModal {
    documentId: string;
    showCloseDocumentModal: boolean;
    setShowCloseDocumentModal: (val: boolean) => void;
    isUserDocument: boolean;
}

const CloseDocumentModal = (
    {
        documentId,
        isUserDocument,
        showCloseDocumentModal,
        setShowCloseDocumentModal,
    }: ICloseDocumentModal) => {
    const {t} = useTranslation();
    const [originalFolderId, setOriginalFolderId] = useState<string | null | undefined>(undefined);
    const [showCloseDocumentDirectoryTree, setShowCloseDocumentDirectoryTree] = useState(false);
    const [initExpandedFolders, setInitExpandedFolders] = useState(false);
    const [createCopyChecked, setCreateCopyChecked] = useState(false);
    const [treeData, setTreeData] = useState<NodeModel<IFolderNodeModel | IDocumentNodeModel>[]>([]);
    const [expandedFolders, setExpandedFolders] = useState(new Set<string>());
    const [selectedNode, setSelectedNode] = useState<NodeModel<IFolderNodeModel | IDocumentNodeModel> | null>(null);

    // Fetch data
    const {
        isFetching: isFetchingDocumentDirectoryNodes,
        data: documentDirectoryNodes,
    } = useGetNodes(true);
    const [closeDocument, isClosingDocument] = useCloseDocument(documentId);
    const [copyDocument, isCopyingDocument] = useCopyDocument(documentId);

    const handleToggleCloseDocumentDirectoryTree = () => {
        if (showCloseDocumentDirectoryTree) {
            setSelectedNode(null);
            setExpandedFolders(new Set<string>());
            setInitExpandedFolders(false);
        }
        setShowCloseDocumentDirectoryTree(!showCloseDocumentDirectoryTree);
    }

    const getAllParentIds = (id: string) => {
        const parentIds: string[] = [];
        const item = treeData.find(item => item.id === id);
        if (!item) return [];
        parentIds.push(...getParentIds(treeData, "" + item.parent));
        return parentIds;
    }

    const handleExpandedFolders = (nodeId: string) => {
        const parentIds = getAllParentIds(nodeId);
        setExpandedFolders(prevState => new Set([...prevState, ...parentIds]));
    }

    const handleSetSelectedFolder = (node: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => {
        if (selectedNode && selectedNode === node) {
            return setSelectedNode(null);
        }
        handleExpandedFolders("" + node.id);
        return setSelectedNode(node)
    }

    const handleExpandedFoldersToggle = (id: string) => {
        if (!expandedFolders.has(id)) {
            setExpandedFolders(new Set([...expandedFolders, id]));
        } else {
            setExpandedFolders(prevStrings => {
                const newSet = new Set(prevStrings);
                newSet.delete(id);
                return newSet;
            });
        }
    }

    const handleHide = () => {
        setCreateCopyChecked(false);
        setShowCloseDocumentModal(false);
    }
    const handleCloseDocument = async () => {
        const closeDocumentRequest: IDocumentCloseRequest = {...(selectedNode && {moveToFolder: selectedNode.data!.id})};
        await closeDocument(closeDocumentRequest).then(async (closeSuccess) => {
            if (closeSuccess && createCopyChecked) {
                const copyRequest: IDocumentCopyRequest = {
                    documentId: documentId,
                }
                if (selectedNode) {
                    copyRequest.forceFolderId = true;
                    copyRequest.folderId = originalFolderId;
                }
                await copyDocument(copyRequest);
            }
            handleHide();
        });
    }

    const renderFolderStructure = () => {
        if (!selectedNode) return "";
        const parentIds = getAllParentIds("" + selectedNode.id);
        const text: string = parentIds
            .map(id => {
                const myObject = treeData.find(item => item.id === id);
                return myObject?.text ?? "";
            })
            .reverse()
            .join("/");
        return text + "/" + selectedNode.text;
    }

    useEffect(() => {
        if (treeData.length > 0 && !initExpandedFolders) {
            const item = treeData.find(item => item.data!.id === documentId);
            if (item) {
                setOriginalFolderId(item.data!.parentId);
                handleExpandedFolders("" + item.id);
            }
            setInitExpandedFolders(true);
        }
    }, [treeData, initExpandedFolders]);

    // Convert fetched data to NodeModels
    useEffect(() => {
        if (!isFetchingDocumentDirectoryNodes) {
            if (documentDirectoryNodes && documentDirectoryNodes.data && documentDirectoryNodes.data.data) {
                setTreeData(convertDirectoryNodesToModels(documentDirectoryNodes.data.data)
                    .filter(item =>
                        item.id !== CustomFolders.TRASHBIN_PREFIXED &&
                        item.id !== CustomFolders.COMPLETEDBIN_PREFIXED));
            }
        }
    }, [isFetchingDocumentDirectoryNodes, documentDirectoryNodes]);

    return <ModalForm
        show={showCloseDocumentModal}
        hide={handleHide}
        title={t('CloseDocumentModal.HIGHLIGHT_DOCUMENT_1')}
        isEdited={() => false}
        submit={handleCloseDocument}
        submitText={t('CloseDocumentModal.HIGHLIGHT_1')}
        isLoading={isClosingDocument || isCopyingDocument}
        size={"lg"}
        centered={false}
    >
        <Row className={"mb-2"}>
            <Col>
                <span>{t('CloseDocumentModal.MARK_DOCUMENT_COMPLETE_1')}</span>
            </Col>
        </Row>
        <When condition={!isUserDocument}>
            <Row className={"mt-2"}>
                <Col>
                    <Form.Check
                        checked={createCopyChecked}
                        type="switch"
                        id="switchCreateCopy"
                        label={t('CloseDocumentModal.COMPLETION_ACTION_1')}
                        onChange={(e: any) => {
                            setCreateCopyChecked(e.target.checked)
                        }}
                    />
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col>
                    <Button onClick={handleToggleCloseDocumentDirectoryTree}>
                        <When
                            condition={showCloseDocumentDirectoryTree}
                            then={<span>{t('CloseDocumentModal.CANCEL_MOVE_1')}</span>}
                            otherwise={<span>{t('CloseDocumentModal.MOVE_DOCUMENT_1')}</span>}
                        />
                    </Button>
                </Col>
            </Row>
            <When condition={showCloseDocumentDirectoryTree}>
                <Row className={`mt-2 ms-1 me-1 ${styles.treeRow} ${styles.documentsDirectoryTreeBorder}`}>
                    <Col>
                        <CloseDocumentDirectoryTree
                            treeData={treeData}
                            expandedFolders={Array.from(expandedFolders)}
                            onClickNode={handleSetSelectedFolder}
                            selectedNode={selectedNode}
                            handleExpandedFoldersToggle={handleExpandedFoldersToggle}
                        />
                    </Col>
                </Row>
                <When condition={selectedNode !== null}>
                    <Row className={"ps-1 mt-3"}>
                        <Col>
                            <i style={{color: "#842029"}}>{t('CloseDocumentModal.CANCEL_MOVE_1')} "{renderFolderStructure()}"</i>
                        </Col>
                    </Row>
                </When>
            </When>
        </When>
    </ModalForm>
};

export default CloseDocumentModal;
