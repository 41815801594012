import { Image, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../../components/ContainerComponents/MainContainer";
import Dev from "../../../components/Dev";
import styles from './BottomMenu.module.scss';

const BottomMenu = () => {
    const navigate = useNavigate();

    return (
        <>

            <Navbar bg="dark" variant="dark" fixed="bottom" className={styles.visible}>
                <MainContainer>
                    <Navbar.Brand onClick={() => navigate("/")}>
                        <Image fluid width={120} style={{cursor: "pointer"}} src="/cc_icon_bw.png"/>
                    </Navbar.Brand>
                    <Dev>
                        <div className="p-2" style={{
                            backgroundColor: "green",
                            color: "white",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            borderRadius: "8px"
                        }}>
                            <span className="ps-2 pe-2">Development</span>
                        </div>
                    </Dev>
                </MainContainer>
            </Navbar>
            <Dev>
                <div className={styles.devVisible} style={{
                    position: 'sticky',
                    bottom: 5,
                    width: "100%",
                    zIndex: 1080, // Fixed bottom gives 1030 -.
                    display: "flex",
                    justifyContent: 'end',

                }}>
                    <div style={{
                        alignSelf: 'flex-end', maxWidth: 150, backgroundColor: "green",
                        color: "white",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        borderRadius: "8px",
                        padding: 8,
                    }}>
                        <span className="ps-2 pe-2">Development</span>
                    </div>

                </div>
            </Dev>
        </>
    )
}

export default BottomMenu;