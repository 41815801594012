import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import CustomTextInput from "../../../components/FormComponents/CustomTextInput";
import { IDocument } from "../../../core/interfaces/document";


type DocumentNameFormProps = {
    document: IDocument;
    isEditMode: boolean;
    onChange: (e: any) => void
}

export const DocumentNameForm: React.FC<DocumentNameFormProps> = (props) => {
    const {t} = useTranslation();
    return <Form.Group as={Row} controlId="formDocumentName">
        <Row>
            <Form.Label column className={"pt-0 pb-0"}><strong>{t('DocumentNameForm.DOCUMENT_NAME_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <CustomTextInput
                    isEditMode={props.isEditMode}
                    name={"name"}
                    value={props.document.name}
                    onChange={props.onChange}
                    required
                    disableAutocomplete
                />
            </Col>
        </Row>
    </Form.Group>
}
