import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import uiSlice from "./slices/uiSlice";
import changeModalSlice from "./slices/changesModalSlice";

const store = configureStore({
    reducer: {
        user: authReducer,
        ui: uiSlice,
        changesModal: changeModalSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;