import getIcon, {Icon as I, IconSize} from "../assets/Icons/IconClassNames";
import React from "react";

interface IIconProps {
    iconSize: IconSize,
    icon: I,
    onClick?: () => void,
}
const RIcon: React.FC<IIconProps> = ({icon, iconSize, onClick}) => {
    return <i onClick={onClick && onClick} className={getIcon(icon, iconSize)} />

}

export default RIcon