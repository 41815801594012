import { $isTextNode, DOMConversionOutput, LexicalEditor, TextFormatType, TextNode } from "lexical";

const extractStrongTag = (node: HTMLElement): any => {
    const className = node.className;
    // Strong should always include editor-text-bold. Ignore others.
    if (!className.includes("editor-text-bold")) {
        return null;
    }

    // If only bold, return as bold
    if (className === "editor-text-bold") {
        return {
            conversion: extractSingleStyle('bold'),
            priority: 4
        }
    }

    // If bold plus more, extract all styles
    const classNameSplit = node.className.split(" ");
    const formats: TextFormatType[] = [];

    classNameSplit.forEach((className) => {
        switch (className) {
            case "editor-text-italic":
                formats.push("italic");
                break;
            case "editor-text-underline":
                formats.push("underline");
                break;
            case "editor-text-strikethrough":
                formats.push("strikethrough");
                break;
            default:
                formats.push("bold");
        }
    });

    return {
        conversion: extractStylesFromSpans(formats),
        priority: 4
    }
}

const extractSingleStyle = (format: TextFormatType) => {
    return (): DOMConversionOutput => {
        return {
            forChild: lexicalNode => {
                if ($isTextNode(lexicalNode)) {
                    lexicalNode.toggleFormat(format);
                }
                return lexicalNode;
            },
            node: null,
        };
    };
};

const extractStylesFromSpans = (formats: TextFormatType[]) => {
    return (): DOMConversionOutput => {
        return {
            forChild: lexicalNode => {
                if ($isTextNode(lexicalNode)) {
                    formats.forEach((format) => {
                        lexicalNode.toggleFormat(format);
                    })
                }
                return lexicalNode;
            },
            node: null,
        };
    };
};

/**
 * Handle import and export html-data to/from Lexical which has missing tag format.
 * Modifies and corrects <strong> tags to append multiple text-formats correctly.
 */
export default function ApplyHtmlStylingOnDOM() {
    const importers = TextNode.importDOM();
    TextNode.importDOM = function _() {
        return {
            ...importers,
            strong: (node: Node) => {
                return extractStrongTag(node as HTMLElement);
            },
            u: () => ({
                conversion: extractSingleStyle('underline'),
                priority: 0
            }),
            s: () => ({
                conversion: extractSingleStyle('strikethrough'),
                priority: 0
            }),
        }
    }

    const missedFormatTag: Array<[TextFormatType, string]> = [
        ['underline', 'u'],
        ['strikethrough', 's'],
    ];

    const exportDOM = TextNode.prototype.exportDOM;
    TextNode.prototype.exportDOM = function _(this: TextNode, editor: LexicalEditor) {
        const {element} = exportDOM.apply(this, [editor]);
        if (!element) {
            return {element};
        }

        let wrapped = element;

        for (const [format, tag] of missedFormatTag) {
            if (this.getFormatFlags(format, null) === 0) {
                const wrapper = document.createElement(tag);
                wrapper.appendChild(element);
                wrapped = wrapper;
            }
        }

        return {element: wrapped};
    };
}