import {ToastType} from "../enums/enums";
import {IToast} from "../interfaces/toast";

export const customToast = (
    toastType: ToastType,
    title: string,
    message: string,
    autohide?: boolean,
): IToast => {
    return {
        toastType: toastType,
        title: title,
        message: message,
        autohide: autohide ?? true
    }
}

export const toastSuccess = (title: string, message: string): IToast => {
    return customToast(ToastType.SUCCESS, title, message);
}

export const toastError = (title: string, message: string): IToast => {
    return customToast(ToastType.DANGER, title, message);
}

export const toastInfo = (title: string, message: string): IToast => {
    return customToast(ToastType.INFO, title, message);
}
