import { AxiosError, AxiosRequestConfig } from "axios";
import { downloadFileResponse, fileResponse } from "../core/helpers/fileHelper";
import { IIncident, IIncidentToSend, IncidentFile } from "../core/interfaces/incident";
import AxiosClient from "./api";
import { URL_INCIDENT, URL_INCIDENT_DOWNLOAD_FILE } from "./endpoints/endpoints";

export const updateIncident = async (companyId: string | undefined, incidentId: string | undefined, incident: IIncidentToSend): Promise<{ data: IIncident, status: number }> => {
    return await AxiosClient.put<IIncidentToSend>(URL_INCIDENT(companyId ?? "", incidentId ?? ""), incident).then((response) => {
        return {data: response.data, status: response.status};
    }).catch((error: AxiosError) => {
        return {data: {} as IIncident, status: error.response?.status ?? 500};
    });
};

export const downloadIncidentFile = async (companyId: string, incidentId: string, incidentFile: IncidentFile) => {
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    return await AxiosClient.get(URL_INCIDENT_DOWNLOAD_FILE(companyId, incidentId, incidentFile.id), config)
        .then((response) => downloadFileResponse(fileResponse(response, incidentFile.name) as any))
        .catch((error: AxiosError) => {
            console.error("AxiosError: ", error.message); // TODO handle error?
        });
}