import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import React from "react";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import When from "../../components/When";
import { NodeType, PublishStatusType } from "../../core/enums/enums";
import { ITemplateNodeData } from "../../core/interfaces/template";

interface ITemplatesNode {
    node: NodeModel<ITemplateNodeData>;
    depth: number;
    isOpen: boolean;
    isSelected: boolean;
    onToggle: (id: NodeModel["id"]) => void;
    onClickNode: (treeNode: NodeModel<ITemplateNodeData>) => void;
    nodeTypeAllowedToSelect?: NodeType;
    handleExpandedFoldersToggle: (id: string) => void;
}

const TemplatesNode = (
    {
        node,
        depth,
        isOpen,
        isSelected,
        onToggle,
        onClickNode,
        nodeTypeAllowedToSelect,
        handleExpandedFoldersToggle
    }: ITemplatesNode) => {
    const dragOverProps = useDragOver(node.id, isOpen, onToggle);

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleExpandedFoldersToggle("" + node.id);
        onToggle(node.id);
    };


    const isFoldersAllowedToSelect = !nodeTypeAllowedToSelect ? true : NodeType.FOLDER === nodeTypeAllowedToSelect;
    const isDocumentsAllowedToSelect = !nodeTypeAllowedToSelect ? true : NodeType.DOCUMENT === nodeTypeAllowedToSelect;
    const isApprovedDocument = PublishStatusType.APPROVED === node.data?.publishStatus;
    const voidFunction = () => NaN;

    return <div
        className={`tree-node root text-nowrap ${NodeType.DOCUMENT === node.data?.nodeType && !isApprovedDocument ? 'unpublishedItemColor' : ''}`}
        style={{
            paddingInlineStart: depth * 12,
            fontWeight: isSelected ? "bold" : "normal",
        }}
        {...dragOverProps}
    >
        <div
            className={`expandIconWrapper ${isOpen ? 'isOpen' : ''}`}
            onClick={handleToggle}
        >
            <When condition={NodeType.FOLDER === node.data?.nodeType}>
                <div>
                    <i className={getIcon(Icon.ARROW_RIGHT, IconSize.XS)}/>
                </div>
            </When>
        </div>
        <When condition={NodeType.FOLDER === node.data?.nodeType}>
            <div
                style={{opacity: isFoldersAllowedToSelect ? undefined : 0.7}}
                onClick={isFoldersAllowedToSelect ? () => onClickNode(node) : voidFunction}
            >
                <span className={getIcon(Icon.FOLDER, IconSize.XS)}/>
                <span className={`labelGridItem`}> {node.text}</span>
            </div>
        </When>
        <When condition={NodeType.DOCUMENT === node.data?.nodeType}>
            <div
                style={{opacity: isDocumentsAllowedToSelect ? undefined : 0.7}}
                onClick={isDocumentsAllowedToSelect ? () => onClickNode(node) : voidFunction}
            >
                <span className={getIcon(Icon.FILE_LINES, IconSize.XS)}/>
                <span className={`labelGridItem`}> {node.text} </span>
            </div>
        </When>
    </div>
};

export default TemplatesNode;