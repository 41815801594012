import * as _ from 'lodash';
import When from "../../../../components/When";
import { ChecklistView } from "../../../../core/enums/enums";
import {
    IDocumentModule,
    IDocumentModuleChecklist,
    IDocumentModuleChecklistForm
} from "../../../../core/interfaces/document";
import { useDocumentModulesContext } from "../DocumentModules";
import { ChecklistContextProvider } from "./ChecklistContext";
import ChecklistForm from "./ChecklistForm/ChecklistForm";
import { ChecklistFormContextProvider } from "./ChecklistForm/ChecklistFormContext";
import TableView from "./ChecklistForm/TableView/TableView";
import ChecklistModuleActionButtons from "./ChecklistModuleActionButtons";
import ChecklistModuleHeader from "./ChecklistModuleHeader";
import styles from "./checklistModule.module.scss";


interface IChecklistModule {
    isActiveModule: boolean;
    module: IDocumentModule;
}

const ModuleHeader = ({module, isActiveModule}: { module: IDocumentModule, isActiveModule: boolean }) => {
    const {

        activeModule,
        updateActiveModule,
        handleSetChecklistToAnswer,
    } = useDocumentModulesContext();

    const handleUpdateHeader = (header: string) => {
        if (!activeModule?.documentModuleChecklist) return;
        const updatedChecklist: IDocumentModuleChecklist = {...activeModule.documentModuleChecklist, header: header};
        const updatedModule: IDocumentModule = {...activeModule, documentModuleChecklist: updatedChecklist};
        updateActiveModule(updatedModule);
    }

    return (
        <ChecklistModuleHeader
            setHideActionPlan={(value: boolean) => {
                if (module.documentModuleChecklist) {
                    module.documentModuleChecklist.hideSendToActionPlan = value;
                    const mod = _.cloneDeep(activeModule);
                    if (mod === null || !mod.documentModuleChecklist) return;
                    mod.documentModuleChecklist.hideSendToActionPlan = value;
                    updateActiveModule(mod);
                }
            }}
            hideActionPlan={!!(isActiveModule ? activeModule : module)?.documentModuleChecklist?.hideSendToActionPlan}
            hideHeaderColumnTableView={!!(isActiveModule ? activeModule : module)?.documentModuleChecklist?.hideHeaderColumnTableView}
            setHideHeaderColumnTableView={(value: boolean) => {
                if (module.documentModuleChecklist) {
                    module.documentModuleChecklist.hideHeaderColumnTableView = value;
                    const mod = _.cloneDeep(activeModule);
                    if (mod === null || !mod.documentModuleChecklist) return;
                    mod.documentModuleChecklist.hideHeaderColumnTableView = value;
                    updateActiveModule(mod);
                }
            }}
            isActiveModule={isActiveModule}
            header={isActiveModule ? activeModule?.documentModuleChecklist?.header : module.documentModuleChecklist?.header}
            setHeader={handleUpdateHeader}
            setChecklistToAnswer={() => handleSetChecklistToAnswer(module)}
        />
    )
}


const RenderChecklistView = ({forms}: { forms: IDocumentModuleChecklistForm[] }) => {
    return (<>{forms.filter(v => !v.isDeleted).map((form, index) => <ChecklistFormContextProvider
        form={form}><ChecklistForm
        form={form}
        key={index}
        index={index}
    /></ChecklistFormContextProvider>)}</>)
}

const ChecklistModule = (
    {
        isActiveModule,
        module,
    }: IChecklistModule) => {

    const {
        isEditMode,
        getChecklistView,

        updateActiveModule
    } = useDocumentModulesContext();


    return <ChecklistContextProvider module={module!} updateModule={(inModule) => updateActiveModule(inModule)}>
        <div className={styles.checklistContainer}>
            <ModuleHeader module={module} isActiveModule={isActiveModule}/>
            <When condition={getChecklistView(module.id) == ChecklistView.LIST}
                  otherwise={<TableView forms={module.documentModuleChecklist!.documentModuleChecklistForms}/>}>
                <RenderChecklistView forms={module.documentModuleChecklist!.documentModuleChecklistForms}/>
            </When>

            {isEditMode && isActiveModule && <ChecklistModuleActionButtons moduleId={module.id}/>}
        </div>
    </ChecklistContextProvider>
};
export default ChecklistModule;
