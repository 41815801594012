import i18n from "i18next";
import { IPerson } from "../interfaces/common";
import { IIncident } from "../interfaces/incident";
import { DateToLocale, DateToUTC } from "./dates/datesHelper";


enum INCIDENT_STATUS_CODES {
    NEW = 10,
    IN_PROGRESS = 20,
    COMPLETED = 30,
}

const INCIDENT_STATUS_CODES_TO_UI_NAME: { [key: number]: string } = {
    10: i18n.t('incidentHelper.NEWLY_ARRIVED_1'),
    20: i18n.t('incidentHelper.PROCESSED_1'),
    30: i18n.t('incidentHelper.FIXED_1'),
}

const StatusCodeToName = (code: number): string => INCIDENT_STATUS_CODES_TO_UI_NAME[code] ?? i18n.t('incidentHelper.UNKNOWN_STATUS_1');

const TypeToName = (type: number): string => type === 1 ? i18n.t('incidentHelper.ACCIDENT_1') : i18n.t('incidentHelper.OFFER_1');

const newIncidentPerson = (): IPerson =>
    ({
        id: "",
        name: ""
    });

const newIncident = (): IIncident => {
    return {
        actionDate: "",
        actionUser: newIncidentPerson(),
        causeOfEvent: "",
        createdAt: "",
        createdBy: newIncidentPerson(),
        date: "",
        description: "",
        followUp: "",
        id: "",
        instantAction: "",
        involvedUsers: [],
        hasExternalUsers: undefined,
        location: "",
        longTermAction: "",
        medicalAssistance: false,
        reportedTos: [],
        rootCause: "",
        sicknessAbsenceIntervalId: "",
        status: 10,
        timeIncluded: false,
        type: 0,
        files: [],
    }
}

const transformDate = (incident: IIncident, transformFunc: (date?: string) => string): IIncident => {
    incident.date = transformFunc(incident.date);
    incident.actionDate = incident.actionDate ? transformFunc(incident.actionDate) : undefined;
    incident.createdAt = transformFunc(incident.createdAt);
    return incident;
}
const transformDatesToUTC = (incident: IIncident): IIncident => transformDate(incident, DateToUTC)

const transformDatesToLocale = (incident: IIncident): IIncident => transformDate(incident, DateToLocale)

export { INCIDENT_STATUS_CODES, StatusCodeToName, TypeToName, newIncident, transformDatesToLocale, transformDatesToUTC };
