import React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteSkill } from "../../api/SkillApi";
import ModalForm from "../../components/FormComponents/ModalForm";
import { ISkill } from "../../core/interfaces/skill";

const RemoveSkillModal: React.FC<{
    show: boolean,
    hide: () => void,
    skill: ISkill | null
}> = ({
    show,
    hide,
    skill
}) => {
    const { t } = useTranslation();
    const [remove, isRemoving] = useDeleteSkill(skill?.id ?? '');
    if (skill == null)
        return <></>

        return (
            <ModalForm
                show={show}
                hide={hide}
                title={t('RemoveSkillModal.EDIT_SKILL_1')}
                isEdited={() => false}
                submit={() => remove().then(_ => hide())}
                submitText={t('RemoveSkillModal.YES_1')}
                isLoading={isRemoving}>
                    <p>{t('RemoveSkillModal.CONFIRM_DELETE_1')} '{skill.name}'?</p>
            </ModalForm>
        )
    }

export default RemoveSkillModal