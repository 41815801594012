import {useGetUser} from "./useAuth";
import {CompanyRole, Role} from "../core/enums/enums";

const useAccessCheck = () => {
    const user = useGetUser();

    const checkCompanyRole = (role: CompanyRole): boolean => {
        if (user.role === Role.SUPERADMIN) return true;
        switch (role) {
            case CompanyRole.ADMIN:
                return user.companyRole === role;
            case CompanyRole.EDITOR:
                return user.companyRole !== CompanyRole.READER;
        }
        return true;
    }

    const checkRole = (role: Role): boolean => {
        return user.role === Role.SUPERADMIN || role === user.role;
    }

    return [checkCompanyRole, checkRole] as const;
}

export default useAccessCheck