import { NodeModel } from "@minoru/react-dnd-treeview";
import i18n from "i18next";
import { downloadDocument } from "../../../api/DocumentApi";
import { CustomFolders, PublishStatusType } from "../../../core/enums/enums";
import { downloadFileResponse } from "../../../core/helpers/fileHelper";
import { IDirectoryNode, IDocumentNodeModel, IFolderNode, IFolderNodeModel, } from "../../../core/interfaces/directory";


const findParent = (nodes: IFolderNode[], id: string | null) => {
    return nodes.find((parentNode) => parentNode.id === id);
}

const findFolderNode = (nodes: IFolderNode[], id: string | null) => {
    return nodes.find((node) => node.id === id);
}

const getRelatedFolders = (nodes: IFolderNode[], nodeId: string | null, prefix: string) => {
    if (nodeId === null) return [];

    let node = findFolderNode(nodes, nodeId);
    if (node === undefined) {
        console.error("error!!")
        return [];
    }

    let relatedNodes: string[] = [];
    if (node.parentFolderId === null) return [prefix + node.id];

    let nodeCopy: IFolderNode = {...node};
    let isRoot = false;
    let parent;

    do {
        if (nodeCopy) {
            relatedNodes.push(prefix + nodeCopy.id);
            parent = findFolderNode(nodes, nodeCopy.parentFolderId);
            if (parent === undefined) {
                isRoot = true;
            } else {
                nodeCopy = parent;
                isRoot = parent.parentFolderId === null;
                if (isRoot) {
                    relatedNodes.push(prefix + nodeCopy.id);
                }
            }
        }
    } while (!isRoot);

    return relatedNodes;
}

const getRelatedSequenceNumbers = (nodes: IFolderNode[], nodeIds: string[]) => {
    let result: number[] = [];
    nodeIds.map((id) => {
        let parentNode = findParent(nodes, id.substring(2));
        if (parentNode) {
            result.push(parentNode.sequenceNo);
        }
    });
    return result.reverse();
}

export const convertDirectoryNodesToModels = (nodes: IDirectoryNode): NodeModel<IFolderNodeModel | IDocumentNodeModel>[] => {
    const folderPrefix = "F_";
    const documentPrefix = "D_";
    let result: NodeModel<IFolderNodeModel | IDocumentNodeModel>[] = [];


    const trashbin = {
        id: CustomFolders.TRASHBIN,
        name: i18n.t('DirectoryHelper.TRASH_1'),
        companyId: "Random_Long_id",
        parentFolderId: null,

        sequenceNo: 9999999,
    } as IFolderNode;

    const completedBin = {
        id: CustomFolders.COMPLETEDBIN,
        name: i18n.t('DirectoryHelper.PERFORMED_1'),
        companyId: "Random_Long_id",
        parentFolderId: null,
        sequenceNo: 9999998,
    } as IFolderNode;

    const deletedFoldersIds: string[] = [];
    let folders: NodeModel<IFolderNodeModel>[] = [trashbin, completedBin, ...nodes.folders].map((node) => {
        const isDeleted = node.deletedAt != null;
        const isNormalFolder = ![CustomFolders.TRASHBIN, CustomFolders.COMPLETEDBIN].includes(node.id as CustomFolders) && !isDeleted;

        const parentFolderId = isDeleted ? CustomFolders.TRASHBIN : node.parentFolderId;

        let relatedNodes: string[] = !isNormalFolder ? [] : getRelatedFolders(nodes.folders, node.id, folderPrefix);
        let relatedSequenceNumbers: number[] = !isNormalFolder ? [] : getRelatedSequenceNumbers(nodes.folders, relatedNodes);

        if (isDeleted) {
            deletedFoldersIds.push(node.id);
        }
        return {
            id: folderPrefix + node.id,
            parent: parentFolderId ? folderPrefix + parentFolderId : 0,
            droppable: isNormalFolder,
            text: node.name,
            data: {
                id: node.id,
                companyId: node.companyId,
                parentId: node.parentFolderId,
                sequenceNumber: node.sequenceNo,
                allRelatedNodes: relatedNodes,
                allRelatedSequenceNumbers: relatedSequenceNumbers,
                isFolder: true,
                isDeleted,
                deletedAt: node.deletedAt
            }
        }
    }).filter(n => !deletedFoldersIds.includes(n.data?.parentId ?? ""));
    result = result.concat(folders);
    const extraDocuments: NodeModel<IDocumentNodeModel>[] = [];
    let documents: NodeModel<IDocumentNodeModel>[] = nodes.documents.filter(node => !deletedFoldersIds.includes(node.parentFolderId ?? "")).map((node) => {
        const isCompleted = node.publishStatus === PublishStatusType.CLOSED;
        const isDeleted = node.deletedAt != null;


        let parentFolderId = node.parentFolderId;
        if (isDeleted) parentFolderId = CustomFolders.TRASHBIN;

        let relatedNodes: string[] = isDeleted ? [] : getRelatedFolders(nodes.folders, parentFolderId, folderPrefix);
        let relatedSequenceNumbers: number[] = isDeleted ? [] : getRelatedSequenceNumbers(nodes.folders, relatedNodes);
        relatedSequenceNumbers.push(node.sequenceNo);


        const returnObject = {
            id: documentPrefix + node.id,
            parent: parentFolderId ? folderPrefix + parentFolderId : 0,
            droppable: false,
            text: node.name,
            data: {
                id: node.id,
                companyId: node.companyId,
                parentId: parentFolderId,
                sequenceNumber: node.sequenceNo,
                allRelatedNodes: relatedNodes,
                allRelatedSequenceNumbers: relatedSequenceNumbers,
                documentType: node.documentType,
                fileExtension: node.fileExtension,
                isDocument: true,
                publishStatus: node.publishStatus,
                version: node.version,
                isDeleted,
                deletedAt: node.deletedAt,
                important: node.important,
                importantHandledAt: node.importantHandledAt,
            }
        }
        if (isCompleted && !isDeleted) {
            extraDocuments.push({
                ...returnObject,
                id: folderPrefix + '_' + node.id,
                parent: folderPrefix + CustomFolders.COMPLETEDBIN,
                data: {...returnObject.data, allRelatedSequenceNumbers: [], allRelatedNodes: []}
            })
        }
        return returnObject;
    });
    result = result.concat(documents).concat(extraDocuments);

    result = result.sort((a, b) => a.data!.sequenceNumber - b.data!.sequenceNumber);
    return result;
}

export const goToDocument = async (documentId: string) => {
    await downloadDocument(documentId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.contentType}));
        window.open(url);
    });
}

export const downloadSelectedDocument = async (id: string) => {
    await downloadDocument(id).then(downloadFileResponse);
}

export function isDocument(node: NodeModel<any>): node is NodeModel<IDocumentNodeModel> {
    return 'isDocument' in node.data;
}

export function isFolder(node: NodeModel<any>): node is NodeModel<IFolderNodeModel> {
    return 'isFolder' in node.data;
}

export const renderNodeText = (node: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => {
    const text = node.text;
    if (isDeleted(node) || (isDocument(node) && !isPublished(node))) return text;
    let prefix = "";
    if (node.data) {
        node.data.allRelatedSequenceNumbers.map((sequenceNumber, index) => {
            if(index == 0) return '';
            prefix += sequenceNumber + "."
        });
    }
    return prefix.substring(0, prefix.length - 1) + " " + text;
}

export function isTrashbin(node: NodeModel<any>): node is NodeModel<IFolderNodeModel> {
    return 'isFolder' in node.data && (node.id === CustomFolders.TRASHBIN || node.id === CustomFolders.TRASHBIN_PREFIXED);
}

export function isPublished(node: NodeModel<any>): node is NodeModel<IDocumentNodeModel> {
    return 'isDocument' in node.data && [PublishStatusType.APPROVED, PublishStatusType.CLOSED].includes(node.data.publishStatus)
}

export function isClosed(node: NodeModel<any>): node is NodeModel<IDocumentNodeModel> {
    return 'isDocument' in node.data && [PublishStatusType.CLOSED].includes(node.data.publishStatus)
}

export function isDeleted(node: NodeModel<any>) {
    return node.data && node.data.isDeleted;
}

export function getParentIds<T>(data: NodeModel<T>[], id: string) {
    const parentIds: string[] = [];
    const parentItem = data.find(item => item.id === id);
    if (parentItem) {
        parentIds.push("" + parentItem.id);
        if (parentItem.parent) {
            parentIds.push(...getParentIds(data, "" + parentItem.parent));
        }
    }
    return parentIds;
}
