import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import { mergeRegister, } from '@lexical/utils';
import {
    $getSelection,
    $isRangeSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_LOW,
    ElementNode
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import getIcon, { Icon } from "../../../../../assets/Icons/IconClassNames";
import { Role } from "../../../../../core/enums/enums";
import useAccessCheck from "../../../../../hooks/useAccessCheck";
import When from "../../../../When";
import CompanyNameButton from "./Buttons/CompanyNameButton";
import RedoButton from "./Buttons/RedoButton";
import TableButton from "./Buttons/TableButton";
import TextFormatButton from "./Buttons/TextFormatButton/TextFormatButton";
import BlockTypeButton from "./Buttons/ToolbarDropdown/DropdownButtons/BlockTypeButton";
import ColorPickerButton from "./Buttons/ToolbarDropdown/DropdownButtons/ColorPickerButton";
import FontFamilyButton from "./Buttons/ToolbarDropdown/DropdownButtons/FontFamilyButton";
import FontSizeButton from "./Buttons/ToolbarDropdown/DropdownButtons/FontSizeButton";
import ToolbarDropdown from "./Buttons/ToolbarDropdown/ToolbarDropdown";
import UndoButton from "./Buttons/UndoButton";
import styles from './ToolbarPlugin.module.scss';
import { BlockTypes } from "./enums";

const Divider = () => (
    <div className={styles.divider}/>
);

const FONT_FAMILY_OPTIONS: [string, string][] = [
    ['Arial', 'Arial'],
    ['Courier New', 'Courier New'],
    ['Outfit', 'Outfit'],
    ['Times New Roman', 'Times New Roman'],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
    ['8pt', '8'],
    ['10pt', '10'],
    ['12pt', '12'],
    ['14pt', '14'],
    ['16pt', '16'],
    ['18pt', '18'],
    ['20pt', '20'],
    ['22pt', '22'],
    ['24pt', '24'],
    ['26pt', '26'],
    ['28pt', '28'],
    ['32pt', '32'],
    ['36pt', '36'],
    ['40pt', '40'],
    ['48pt', '48'],
    ['72pt', '72'],
];

const DEFAULT_FONT_FAMILY = "Outfit";
const DEFAULT_FONT_SIZE = "12";
const DEFAULT_COLOR = "#646464";

const ToolbarPlugin = () => {
    const {t} = useTranslation();
    const [, checkAccess] = useAccessCheck();
    const [editor] = useLexicalComposerContext();

    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState<BlockTypes>(BlockTypes.PARAGRAPH);
    const [isDropdownBlockTypesOpen, setDropdownBlockTypesOpen] = useState(false);
    const [isDropdownFontFamilyOpen, setDropdownFontFamilyOpen] = useState(false);
    const [isDropdownFontSizeOpen, setDropdownFontSizeOpen] = useState(false);
    const [fontFamily, setFontFamily] = useState<string>(DEFAULT_FONT_FAMILY);
    const [fontSize, setFontSize] = useState<string>(DEFAULT_FONT_SIZE);
    const [selectedColor, setSelectedColor] = useState(DEFAULT_COLOR);

    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);

    const addBoldStyleToOrderedListItemNumber = (hasBold: boolean, parent: ElementNode | null) => {
        const listItems = document.querySelectorAll('ol.editor-ordered-list1 li.editor-list-item');
        listItems.forEach(listItem => {
            const childElements = Array.from(listItem.children);
            if (childElements.length === 1 && (childElements[0].nodeName === 'STRONG' || childElements[0].classList.contains('editor-text-bold'))) {
                listItem.setAttribute('style', 'font-weight: bold;');
            } else {
                listItem.removeAttribute('style');
            }
        });
    }

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                if ("list" === type) {
                    if (elementDOM.classList.contains("editor-unordered-list1")) {
                        setBlockType(BlockTypes.LIST_UNORDERED);
                    } else if (elementDOM.classList.contains("editor-ordered-list1")) {
                        setBlockType(BlockTypes.LIST_ORDERED);
                    }
                } else {
                    setBlockType(type as BlockTypes);
                }
            }

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));

            // Update Font Size
            setFontSize($getSelectionStyleValueForProperty(selection, "font-size", DEFAULT_FONT_SIZE));

            // update Font Family
            setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', DEFAULT_FONT_FAMILY));

            setSelectedColor($getSelectionStyleValueForProperty(selection, 'color', DEFAULT_COLOR));

            addBoldStyleToOrderedListItemNumber(selection.hasFormat("bold"), anchorNode.getParent());
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand<boolean>(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
            editor.registerCommand<boolean>(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateToolbar]);

    const convertBlockType = (blockType: BlockTypes) => {
        switch (blockType) {
            case BlockTypes.PARAGRAPH:
                return t('ToolbarPlugin.NORMAL_1');
            case BlockTypes.HEADER_H1:
                return t('ToolbarPlugin.HEADER_H1_1');
            case BlockTypes.HEADER_H2:
                return t('ToolbarPlugin.HEADER_H2_1');
            case BlockTypes.HEADER_H3:
                return t('ToolbarPlugin.HEADER_H3_1');
            case BlockTypes.LIST_UNORDERED:
                return t('ToolbarPlugin.LIST_1');
            case BlockTypes.LIST_ORDERED:
                return t('ToolbarPlugin.NUMERICAL_LIST_1');
            default:
                return t('ToolbarPlugin.NORMAL_2');
        }
    };

    const getIconByType = (blockType: BlockTypes): string => {
        switch (blockType) {
            case BlockTypes.LIST_UNORDERED:
                return getIcon(Icon.LIST_BULLET);
            case BlockTypes.LIST_ORDERED:
                return getIcon(Icon.LIST_NUMBER);
            default:
                return "";
        }
    }

    const getText = (map: [string, string][], option: string, defaultOption: string) => {
        const foundOption = map.find(([optionValue]) => optionValue === option);
        if (foundOption) {
            return foundOption[1];
        } else {
            return defaultOption;
        }
    }

    const BLOCK_TYPE_OPTIONS: [BlockTypes, string, Icon?][] = [
        [BlockTypes.PARAGRAPH, t('ToolbarPlugin.NORMAL_3')],
        [BlockTypes.HEADER_H1, t('ToolbarPlugin.HEADER_H1_2')],
        [BlockTypes.HEADER_H2, t('ToolbarPlugin.HEADER_H2_2')],
        [BlockTypes.HEADER_H3, t('ToolbarPlugin.HEADING_H3_1')],
        [BlockTypes.LIST_UNORDERED, t('ToolbarPlugin.LIST_2'), Icon.LIST_BULLET],
        [BlockTypes.LIST_ORDERED, t('ToolbarPlugin.NUMBERED_LIST_1'), Icon.LIST_NUMBER],
    ];

    const renderBlockTypeButtons = () => (
        BLOCK_TYPE_OPTIONS.map(
            ([blockType, text, icon], index) => (
                <BlockTypeButton
                    key={index}
                    blockType={blockType}
                    text={text}
                    onSelect={() => setDropdownBlockTypesOpen(false)}
                    icon={icon}
                />
            )
        )
    );

    const renderFontFamilyButtons = () => (
        FONT_FAMILY_OPTIONS.map(
            ([option, text], index) => (
                <FontFamilyButton
                    key={index}
                    fontFamily={option}
                    text={text}
                    onSelect={() => setDropdownFontFamilyOpen(false)}
                />
            )
        )
    );

    const renderFontSizeButtons = () => (
        FONT_SIZE_OPTIONS.map(
            ([option, text], index) => (
                <FontSizeButton
                    key={index}
                    fontSize={option}
                    text={text}
                    onSelect={() => setDropdownFontSizeOpen(false)}
                />
            )
        )
    );

    return (
        <div className={styles.toolbar} ref={toolbarRef}>
            <UndoButton disabled={!canUndo}/>
            <RedoButton disabled={!canRedo}/>
            <Divider/>
            <ToolbarDropdown
                isOpen={isDropdownBlockTypesOpen}
                setIsOpen={setDropdownBlockTypesOpen}
                label={convertBlockType(blockType)}
                icon={getIconByType(blockType)}
            >
                {renderBlockTypeButtons()}
            </ToolbarDropdown>
            <ToolbarDropdown
                isOpen={isDropdownFontFamilyOpen}
                setIsOpen={setDropdownFontFamilyOpen}
                label={getText(FONT_FAMILY_OPTIONS, fontFamily, DEFAULT_FONT_FAMILY)}
            >
                {renderFontFamilyButtons()}
            </ToolbarDropdown>
            <ToolbarDropdown
                isOpen={isDropdownFontSizeOpen}
                setIsOpen={setDropdownFontSizeOpen}
                label={getText(FONT_SIZE_OPTIONS, fontSize, DEFAULT_FONT_SIZE)}
            >
                {renderFontSizeButtons()}
            </ToolbarDropdown>
            <Divider/>
            <TextFormatButton formatType={"bold"} isActiveFormatType={isBold}/>
            <TextFormatButton formatType={"italic"} isActiveFormatType={isItalic}/>
            <TextFormatButton formatType={"underline"} isActiveFormatType={isUnderline} disabled={isStrikethrough}/>
            <TextFormatButton formatType={"strikethrough"} isActiveFormatType={isStrikethrough} disabled={isUnderline}/>
            <Divider/>
            <TableButton />
            <ColorPickerButton selectedColor={selectedColor}/>
            <When condition={checkAccess(Role.SUPERADMIN)}>
                <CompanyNameButton/>
            </When>
        </div>
    )
};

export default ToolbarPlugin;
