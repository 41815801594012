import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../assets/Icons/IconClassNames";
import When from "../../../components/When";
import { PublishStatusType } from "../../../core/enums/enums";
import { IDocumentNodeModel } from "../../../core/interfaces/directory";


interface IDocumentType {
    data: IDocumentNodeModel;
    hideDocumentIcons?: boolean;
    iconSize?: IconSize;
}

const _DocumentType = ({data, iconSize}: IDocumentType) => {
    switch (data.fileExtension) {
        case ".pdf":
            return <span className={getIcon(Icon.PDF, iconSize)}/>
        case ".xls":
            return <span className={getIcon(Icon.EXCEL, iconSize)}/>
        case ".png":
        case ".jpg":
            return <span className={getIcon(Icon.IMAGE, iconSize)}/>
        default:
            return <span className={getIcon(Icon.FILE_LINES, iconSize)}/>
    }
};

const DocumentType = (props: IDocumentType) => {
    const {t} = useTranslation();
    const renderDocumentIcons = () => (<>
        <When condition={props.data.important}>
            <span
                className={[getIcon(Icon.CIRCLE, IconSize.XS, "red"), "pe-2"].join(' ')}
                style={{paddingRight: 4}}
                title={t('DocumentType.IMPORTANT_1')}
            />
        </When>
        <When condition={!!props.data.importantHandledAt}>
            <span
                className={[getIcon(Icon.CIRCLE, IconSize.XS, "green"), "pe-2"].join(' ')}
                style={{paddingRight: 4}}
                title={t('DocumentType.MANAGED_1')}
            />
        </When>
        <When condition={props.data.publishStatus === PublishStatusType.CLOSED}>
            <span
                className={[getIcon(Icon.LOCK, IconSize.X1, 'lightblue'), 'pe-2'].join(' ')}
                title={t('DocumentType.MARKED_AS_READY_1')}
            />
        </When>
    </>);

    return <>
        <When condition={!props.hideDocumentIcons}>
            {renderDocumentIcons()}
        </When>
        <_DocumentType {...props} />
    </>
}

export default DocumentType;
