import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../../../assets/Icons/IconClassNames";
import CustomTextInput from "../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../components/When";
import styles from "./checklistForm.module.scss";


interface IGeneralizedForm {
    index?: number;
    isActiveModule?: boolean;
    isExpanded: boolean;
    setExpanded: (value: boolean) => void;
    title: string;
    setTitle?: (value: string) => void;
    description: string;
    setDescription?: (value: string) => void;
    isHeaderLocked: boolean;

    showExpand?: boolean;
    isEditMode?: boolean;
}

const GeneralizedFormHeader = (
    {
        index = -1,
        isActiveModule = false,
        isEditMode = false,
        isExpanded,
        setExpanded,
        title,
        setTitle = () => NaN,
        description,
        setDescription = () => NaN,
        isHeaderLocked,
        showExpand = false,
    }: IGeneralizedForm) => {

    const {t} = useTranslation();
    const [isActive] = useState(isEditMode && isActiveModule);
    return <>
        <div className={styles.checklistFormContainerHeader}>
            <When condition={isEditMode && index >= 0}>
                <div><span style={{fontSize: "12px"}}>#{index + 1}</span></div>
            </When>
            <When condition={isActive}>
                <div><span className={getIcon(Icon.SORT)}/></div>
            </When>
        </div>

        <Row className={"mt-3 mb-3 ms-2 me-2"}>
            <Col md={3}>
                <CustomTextInput
                    disabled={isHeaderLocked}
                    isEditMode={isActive}
                    name={"title"}
                    placeholder={t('GeneralizedFormHeader.CHECKPOINT_1')}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    disableAutocomplete
                    required
                />
            </Col>
            <Col md={isActive || showExpand ? 7 : 9} style={{lineHeight: "24px", fontSize: "20px"}}>
                <CustomTextInput
                    disabled={isHeaderLocked || (!isActiveModule)}
                    isEditMode={isEditMode}
                    name={"description"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disableAutocomplete
                    required
                    showInputFieldInReadOnly={isEditMode}
                />
            </Col>
            <When condition={(isEditMode && isActiveModule) || showExpand}>
                <Col md={2} className={"d-flex justify-content-end"}>
                <span className={`cursor-pointer ${isExpanded ?
                    getIcon(Icon.CIRCLE_CHEVRON_DOWN, IconSize.X2) :
                    getIcon(Icon.CIRCLE_CHEVRON_UP, IconSize.X2)}`}
                      onClick={() => setExpanded(!isExpanded)}/>
                </Col>
            </When>
        </Row>
    </>
};

export default GeneralizedFormHeader;
