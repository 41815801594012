import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUpdateDocument } from "../../api/DocumentApi";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../components/ClickableIconButton/ClickableIconButton";
import CompanyLogo from "../../components/CompanyLogo/CompanyLogo";
import When from "../../components/When";
import { DateToUTC } from "../../core/helpers/dates/datesHelper";
import { IDocument, IUpdateDocument } from "../../core/interfaces/document";
import PublishStatusAlert from "../Dashboard/Components/PublishStatusAlert";
import ApprovedByForm from "./Forms/ApprovedByForm";
import { AttachmentForm } from "./Forms/AttachmentForm";
import { CreatedByForm } from "./Forms/CreatedByForm";
import { DocumentCreatedAtForm } from "./Forms/DocumentCreatedAtForm";
import { DocumentModifiedAtForm } from "./Forms/DocumentModifiedAtForm";
import { DocumentNameForm } from "./Forms/DocumentNameForm";
import DocumentPositionLevelForm from "./Forms/DocumentPositionLevelForm";
import ResponsibleByForm from "./Forms/ResponsibleByForm";
import { VersionForm } from "./Forms/VersionForm";

interface IDocumentForm {
    originalDocument: IDocument;
    newDocument: IDocument;
    setNewDocument: (document: IDocument) => void;
    isEditMode: boolean;
    setIsActiveForm: (val: boolean) => void;

    setEditMode?: (val: boolean) => void;
    viewEditModeButton?: boolean
}

const DocumentForm = (
    {
        originalDocument,
        newDocument,
        setNewDocument,
        isEditMode,
        setIsActiveForm,
        setEditMode,
        viewEditModeButton = false,
    }: IDocumentForm) => {

    const {t} = useTranslation();
    const {cId} = useParams();
    const [validatedForm, setValidatedForm] = useState(false);
    const [isEditForm, setEditForm] = useState(false);

    useEffect(() => {
        setEditForm(false);
        setIsActiveForm(false);
        // eslint-disable-next-line
    }, [isEditMode]);

    const [updateDocument, isUpdatingDocument] = useUpdateDocument();
    const handleUpdateDocument = async () => {
        const documentToUpload = newDocument as IUpdateDocument;

        if (originalDocument.createdAt !== documentToUpload.createdAt) {
            documentToUpload.adjustedCreatedAt = DateToUTC(documentToUpload.createdAt);
        }
        if (originalDocument.adjustedCreatedBy !== documentToUpload.adjustedCreatedBy) {
            documentToUpload.adjustedCreatedById = documentToUpload.adjustedCreatedBy?.id;
        }

        documentToUpload.responsibleById = documentToUpload.responsibleBy?.id;
        documentToUpload.adjustedApprovedById = documentToUpload.adjustedApprovedBy?.id;

        await updateDocument(documentToUpload).then((response) => {
            if (response.success) {
                setEditForm(false);
                setIsActiveForm(false);
            }
        })
    }

    const handleChange = (e: any) => {
        if (!newDocument) return;
        const newVal = e.target.value;
        setNewDocument({
            ...newDocument,
            [e.target.name]: newVal
        });
    }
    const handleChangeDate = (e: any) => {
        if (!newDocument) return;
        const newVal = e.target.value;
        setNewDocument({
            ...newDocument,
            [e.target.name]: newVal.toLocaleString()
        });
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const form = e.currentTarget;
        e.stopPropagation();


        if (form.checkValidity() === false) {
            setValidatedForm(true);
            return;
        }

        await handleUpdateDocument();
    }

    const isNoChanges = () => {
        return JSON.stringify(originalDocument) === JSON.stringify(newDocument);
    }

    const handleCancelEdit = () => {
        if (isNoChanges()) {
            setEditForm(false);
            setIsActiveForm(false);
            return;
        }

        // FIXME Det funkar inte att använda useChangeModal / usePrompt i 2 komponenter på samma vy
        // FIXME Se om detta kan förbättras. För nu kör vi window.confirm i form för det är mindre kritisk info
        if (window.confirm(t('DocumentForm.UNSAVED_CHANGES_1'))) {
            setNewDocument(originalDocument);
            setEditForm(false);
            setIsActiveForm(false);
        }
    }

    const topPartNewDocument = () => (
        <DocumentNameForm
            document={newDocument}
            isEditMode={isEditMode}
            onChange={handleChange}
        />
    );

    const handleEdit = () => {
        setEditForm(true);
        setIsActiveForm(true);
    }

    const submitButtonRef = useRef<any>(null);
    const topPartExistingDocument = () => (
        <>
            <PublishStatusAlert document={newDocument}/>
            <Row>
                <Col md={12} lg={4}>
                    <DocumentNameForm
                        document={newDocument}
                        isEditMode={isEditForm}
                        onChange={handleChange}
                    />
                    <ApprovedByForm
                        isEditMode={isEditForm}
                        document={newDocument}
                        onChange={(v) => setNewDocument({
                            ...newDocument,
                            adjustedApprovedBy: v ?? undefined
                        })}
                    />
                    <ResponsibleByForm
                        isEditMode={isEditForm}
                        document={newDocument}
                        onChange={(person) => setNewDocument({
                            ...newDocument,
                            responsibleBy: person ?? undefined
                        })}
                    />
                    <DocumentPositionLevelForm
                        isEditMode={isEditForm}
                        document={newDocument}
                        onChange={(positionLevels) => setNewDocument({
                            ...newDocument,
                            positionLevels: positionLevels ?? undefined
                        })}
                    />
                </Col>

                <Col md={12} lg={4}>
                    <VersionForm document={newDocument}/>
                    <CreatedByForm isEditMode={isEditForm} document={newDocument} onChange={(v) => setNewDocument({
                        ...newDocument,
                        adjustedCreatedBy: v ?? undefined
                    })} />
                    <DocumentCreatedAtForm document={newDocument} isEditMode={isEditForm} onChange={handleChangeDate} />
                    <DocumentModifiedAtForm document={newDocument} />
                </Col>

                <Col>
                    <Row>
                        <Col className={"d-flex justify-content-end"}>
                            <When condition={isEditMode}>
                                {isEditForm ? (
                                    <div className="pb-1 mb-3 d-flex align-content-end flex-row-reverse">
                                        <ClickableIconButton
                                            disabled={false}
                                            buttonType={"CANCEL"}
                                            icon={getIcon(Icon.X_MARK, IconSize.XL)}
                                            onClick={handleCancelEdit}
                                            title={t('DocumentForm.CANCEL_1')}
                                        />
                                        <ClickableIconButton
                                            disabled={isNoChanges()}
                                            buttonType={"SAVE"}
                                            icon={getIcon(Icon.SAVE, IconSize.XL)}
                                            isLoading={isUpdatingDocument}
                                            title={t('DocumentForm.SAVE_1')}
                                            onClick={() => submitButtonRef?.current.click()}
                                        />
                                    </div>
                                ) : (
                                    <div className="pb-1 mb-3 d-flex align-content-end flex-row-reverse">
                                        <ClickableIconButton
                                            disabled={false}
                                            buttonType={"EDIT"}
                                            icon={getIcon(Icon.EDIT, IconSize.XL)}
                                            onClick={handleEdit}
                                            title={t('DocumentForm.CHANGE_NAME_OR_DATE_1')}
                                        />
                                    </div>
                                )}
                            </When>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"d-flex justify-content-end pe-3"}>
                            <CompanyLogo
                                companyId={cId ?? ""}
                                hideImageMissing
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );

    return (<Form noValidate validated={validatedForm} onSubmit={handleSubmit}>
        <When
            condition={newDocument.id === ""}
            then={topPartNewDocument()}
            otherwise={topPartExistingDocument()}
        />
        <button type={"submit"} hidden ref={submitButtonRef}/>
        <div className={"mt-1"}>
            <AttachmentForm attachments={originalDocument.attachments} isEditMode={isEditMode}
                            documentId={originalDocument.id}/>
        </div>

    </Form>)
};

export default DocumentForm;
