import { ReactNode, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface ICreateNewModal {
    showModal: boolean;
    closeModal: () => void;
    children: ReactNode;
}

const CreateNewModal = ({showModal, closeModal, children}: ICreateNewModal) => {
    const [isOpen, setIsOpen] = useState(showModal);

    useEffect(() => {
        setIsOpen(showModal);
    }, [showModal]);

    const handleCloseModal = () => {
        setIsOpen(false);
        setTimeout(function () {
            closeModal();
        }, 500);
    }

    return <Modal
        show={isOpen}
        size="sm"
        centered
        onHide={handleCloseModal}
    >
        {children}
    </Modal>
};

export default CreateNewModal;