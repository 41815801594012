import { AxiosRequestConfig } from "axios";
import i18n from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { downloadFileResponse, fileResponse } from "../core/helpers/fileHelper";
import { toastError } from "../core/helpers/toastHelper";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient from "./api";
import {
    URL_ACTION_PLAN_DOWNLOAD_PDF,
    URL_DOCUMENT_DOWNLOAD_PDF
} from "./endpoints/endpoints";


export const useDownloadAsPdf = (orientation: 'landscape' | 'portrait', actionPlanId: string | undefined): [(orientation: 'landscape' | 'portrait') => void, boolean] => {
    return _useDownloadHandlePdf(orientation, actionPlanId, (fileResp) => {
        downloadFileResponse(fileResp)
    });
}

export const useOpenPdf = (orientation: 'landscape' | 'portrait', actionPlanId: string | undefined): [(orientation: 'landscape' | 'portrait') => void, boolean] => {
    return _useDownloadHandlePdf(orientation, actionPlanId, (fileResp) => {
        const url = window.URL.createObjectURL(new Blob([fileResp.data], { type: fileResp.contentType }));
        window.open(url);
    })
}

const _useDownloadHandlePdf = (orientation: 'landscape' | 'portrait', actionPlanId: string | undefined, func: (fileResp: {
    data: any
    contentType: string,
    filename: string,
}) => void): [() => void, boolean] => {
    const [isDownloading, setIsDownloading] = useState(false);
    const dispatch = useAppDispatch();
    const { documentId } = useParams();
    const { cId } = useParams();
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    const download = () => {
        setIsDownloading(true);
        const url = actionPlanId
            ? URL_ACTION_PLAN_DOWNLOAD_PDF(cId!, actionPlanId, orientation)
            : URL_DOCUMENT_DOWNLOAD_PDF(documentId ?? "", orientation);

        AxiosClient.get(url, config).then((response) => {
            func(fileResponse(response))
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('PdfApi.DOWNLOAD_FAILED_1'), i18n.t('PdfApi.ERROR_DURING_RETRIEVAL_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}