import {Modal} from "react-bootstrap";
import React, {useRef, useState} from "react";
import ChangesModal from "../../components/ChangesModal";
import Button from "react-bootstrap/esm/Button";
import SpinnerButton from "../../components/SpinnerButton";
import CustomForm from "./CustomForm";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

interface FormModal {
    show: boolean;
    hide: () => void;
    title: string,
    children: JSX.Element | JSX.Element[];
    isEdited: () => boolean;
    submit: () => void;
    isLoading: boolean;
    disableSubmit?: boolean;
    submitText?: string;
    centered?: boolean;
    size?: "sm" | "lg" | "xl";
    hideFooter?: boolean;
    disableClickOutside?: boolean;
}

const ModalForm = (
    {
        show,
        hide,
        title,
        isEdited,
        submit,
        isLoading,
        children,
        disableSubmit = false,
        centered = true,
        size = "sm",
        submitText,
        hideFooter = false,
        disableClickOutside,
    }: FormModal) => {

    const {t} = useTranslation();
    const [changesModal, setChangesModal] = useState(false);
    const ref = useRef<HTMLButtonElement>(null);

    const handleHide = () => {
        if (isEdited()) {
            setChangesModal(true);
        } else {
            hide();
        }

    }

    return (
        <>
            <Modal
                show={show && !changesModal}
                size={size}
                centered={centered}
                onHide={handleHide}
                backdrop={disableClickOutside ? "static" : undefined}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomForm onSubmit={submit} ref={ref} row={false}>
                        {children}
                    </CustomForm>
                </Modal.Body>
                {!hideFooter && (
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleHide}>{t('ModalForm.CANCEL_1')}</Button>
                        <SpinnerButton text={submitText ?? t('ModalForm.SAVE_1')} isLoading={isLoading} disabled={disableSubmit} onClick={() => {
                            ref.current?.click()
                        }}/>
                    </Modal.Footer>
                )}
            </Modal>
            <ChangesModal show={changesModal} setShow={(value) => {
                if (value) {
                    hide();
                }
                setChangesModal(false);
            }}/>
        </>

    )
}

export default ModalForm
