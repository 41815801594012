import DataTableLoadingSpinner from "./DataTableLoadingSpinner";
import DataTableNoData from "./DataTableNoData";
import DataTable, {TableColumn, TableStyles} from "react-data-table-component";
import DataTableFilter from "./DataTableFilter";
import {useTranslation} from "react-i18next";

interface IPaginatedDataTableClientSide<T> {
    data: T[];
    progressPending: boolean;
    columns: TableColumn<T>[];
    pointerOnHover?: boolean;
    onRowClick?: (obj: T) => void;

    withFilter?: boolean;
    filterText?: string;
    setFilterText?: (prev: string) => void;
}

const PaginatedDataTableClientSide = <T extends unknown>(
    {
        data,
        progressPending,
        columns,
        pointerOnHover,
        onRowClick,
        withFilter,
        filterText,
        setFilterText,
    }: IPaginatedDataTableClientSide<T>) => {

    const {t} = useTranslation();
    
    const paginationComponentOptions = {
        rowsPerPageText: t('PaginatedDataTable.NUMBER_PER_PAGE_1'),
        rangeSeparatorText: t('PaginatedDataTable.OF_1'),
    };

    const filterSubHeaderComponent = () => (
        <DataTableFilter
            filterText={filterText!}
            setFilterText={setFilterText!}
            inputPlaceholder={t('PaginatedDataTable.FILTER_1')}
        />
    );

    const customStyle: TableStyles = {
        table: {
            style: {
                minHeight: '50vh',
            },
        },
    }

    return <>
        <DataTable
            customStyles={customStyle}
            columns={columns}
            data={data}
            progressPending={progressPending}
            progressComponent={<DataTableLoadingSpinner/>}
            noDataComponent={<DataTableNoData/>}
            persistTableHead
            pagination
            highlightOnHover
            pointerOnHover={pointerOnHover}
            onRowClicked={onRowClick}
            subHeader={withFilter}
            subHeaderComponent={withFilter && filterSubHeaderComponent()}
            paginationComponentOptions={paginationComponentOptions}
        />
    </>
};

export default PaginatedDataTableClientSide;
