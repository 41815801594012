import { useTranslation } from "react-i18next";
import { useGetCompanyUserTodos } from "../../../../api/TodoApi";
import ContentContainer from "../../../../components/ContainerComponents/ContentContainer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import When from "../../../../components/When";
import Todo from "./Todo";



const DashboardTodo = () => {

    const {t} = useTranslation();
    const [todolist, isFetching] = useGetCompanyUserTodos();

    return (
        <>
            <ContentContainer style={{padding: "10px", overflow: "auto", height: "25rem"}}>
                {isFetching ? <LoadingSpinner spinnerAnimation="border"/> :
                    <When condition={todolist.length > 0} otherwise={t('DashboardTodo.TASKS_COMPLETED_1')}>
                        {todolist.map((value, index) =>
                            <Todo key={index} todo={value}/>
                        )}
                    </When>
                }
            </ContentContainer>
        </>
    )
}

export default DashboardTodo
