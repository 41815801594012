import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { handleQueryKeys } from "../hooks/useCustomQuery";
import { ICustomQueryKeys } from "./interfaces/common";

export enum AppQueryKey {
    ACTION_PLANS = "actionPlans",
    COMPANIES = "companies",
    COMPANY_PARENTS = "company_parents",
    COMPANIES_COUNT = "companiesCount",
    COMPANY_READ_RECEIPT = "company_read_receipt",
    COMPANY_USERS = "company_users",
    COMPANY_USERS_EXTENDED = "company_users_extended",

    CHECKLIST_TEMPLATES = "checklistTemplates",
    DOCUMENTS = "documents",
    DOCUMENT_MODULES = "modules",
    DOCUMENT_TEMPLATES = "documentTemplates",
    FOLDERS = "folders",
    LAST_DOCUMENTS = "lastDocuments",
    MULTI_CHOICE_OPTIONS = "multiChoiceOptions",
    NOTIFICATIONS = "notifications",
    NOTIFICATIONS_COUNT = "notificationsCount",
    POSITION_LEVELS = "positionLevels",
    READ_RECEIPT = "readreceipt",
    SKILLS = "skills",
    USERS_COUNT = "usersCount",
    USERS_PUBLISH = "user_publish",
    USER_DOCUMENTS = "user_documents",
    USER_SKILLS = "user_skills",
    USERS = "users",
    TODO = "todo",
}

export const useForceFetchQuery = (queryKey: AppQueryKey, options: {
    includeCompanyId?: boolean,
    extraKeys?: string[]
} = {}) => {
    const finalOptions = {
        includeCompanyId: true, // default value
        ...options, // override default value with any explicit options passed
    };

    const queryClient = useQueryClient();
    const {cId} = useParams();
    const companyId = cId ?? "";
    const queryKeys: ICustomQueryKeys = {
        root: queryKey,
        cId: finalOptions.includeCompanyId ? companyId : "",
        extraKeys: finalOptions.extraKeys ?? undefined
    };

    return async () => {
        await queryClient.fetchQuery(handleQueryKeys(queryKeys));
    };
}

export const useInvalidateQuery = (queryKey: AppQueryKey, options: {
    includeCompanyId?: boolean,
    extraKeys?: string[]
} = {}) => {
    const finalOptions = {
        includeCompanyId: true, // default value
        ...options, // override default value with any explicit options passed
    };

    const queryClient = useQueryClient();
    const {cId} = useParams();
    const companyId = cId ?? "";
    const queryKeys: ICustomQueryKeys = {
        root: queryKey,
        cId: finalOptions.includeCompanyId ? companyId : "",
        extraKeys: finalOptions.extraKeys ?? undefined
    };

    return async () => {
        await queryClient.invalidateQueries(handleQueryKeys(queryKeys));
    };
}

export const useRemoveQuery = (queryKey: AppQueryKey, options: {
    includeCompanyId?: boolean,
    extraKeys?: string[]
} = {}) => {
    const finalOptions = {
        includeCompanyId: true, // default value
        ...options, // override default value with any explicit options passed
    };

    const queryClient = useQueryClient();
    const {cId} = useParams();
    const companyId = cId ?? "";
    const queryKeys: ICustomQueryKeys = {
        root: queryKey,
        cId: finalOptions.includeCompanyId ? companyId : "",
        extraKeys: finalOptions.extraKeys ?? undefined
    };

    return async () => {
        await queryClient.removeQueries(handleQueryKeys(queryKeys));
    };
}

export const GetQueryKey = (queryKey: AppQueryKey, options: {
    includeCompanyId?: boolean,
    extraKeys?: string[]
} = {includeCompanyId: true}) => {
    if (options?.includeCompanyId === undefined) {
        options.includeCompanyId = true;
    }
    const {cId} = useParams();
    const companyId = cId ?? "";
    return {
        root: queryKey,
        cId: options.includeCompanyId ? companyId : "",
        extraKeys: options.extraKeys ? options.extraKeys : undefined
    };
}