import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const ChangesModal = (props: {show: boolean, setShow: (value: boolean) => void}) => {
    const {t} = useTranslation();
    
    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            size={"sm"}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('ChangesModal.CANCEL_1')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('ChangesModal.CANCEL_CONFIRMATION_1')}</p>
                {t('ChangesModal.CANCEL_CONFIRMATION_2')}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'secondary'} onClick={() => props.setShow(false)}>{t('ChangesModal.CANCEL_2')}</Button>
                <Button variant={'danger'} onClick={() => props.setShow(true)}>{t('ChangesModal.YES_1')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangesModal
