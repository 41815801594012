import { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useCreateNewDocument } from "../../../api/DocumentApi";
import CustomTextInput from "../../../components/FormComponents/CustomTextInput";
import SpinnerButton from "../../../components/SpinnerButton";
import When from "../../../components/When";
import { handleOnEnter } from "../../../core/helpers/utils";
import { IDocumentCreateRequest } from "../../../core/interfaces/document";

interface INewDocumentModal {
    folderId: string | null;
    closeModal: () => void;
    parentFolderName?: string;
    companyUserId?: string;
}

const NewDocumentModal = (
    {
        folderId,
        closeModal,
        parentFolderName,
        companyUserId,
    }: INewDocumentModal) => {
    const initialDocument: IDocumentCreateRequest = {
        id: "",
        folderId: folderId,
        name: ""
    }
    const {t} = useTranslation();
    const [newDocument, setNewDocument] = useState<IDocumentCreateRequest>(initialDocument);

    const [createDocument, isCreatingDocument] = useCreateNewDocument();
    const handleCreateDocument = async () => {
        const request = {...newDocument, companyUserId: companyUserId}
        if (disableCondition) return;
        await createDocument(request).then((response) => {
            if (response.success) {
                closeModal();
            }
        });
    }

    const changeDocumentName = (e: any) => {
        setNewDocument({
            ...newDocument,
            name: e.target.value,
        });
    }

    const disableCondition = (newDocument.name.length === 0 || isCreatingDocument)

    return <>
        <Modal.Header closeButton>
            <Modal.Title>{t('NewDocumentModal.TEMPLATE_CREATION_1')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label className={'pb-2'}>{t('NewDocumentModal.DOCUMENT_NAME_1')}</label>
            <CustomTextInput
                name="name"
                value={newDocument.name}
                onChange={changeDocumentName}
                onKeyDown={(event) => handleOnEnter(event, handleCreateDocument)}
                autofocus={true}
            />
            <When condition={!!parentFolderName}>
                <p><i>{t('NewDocumentModal.CREATED_IN_PARENT_FOLDER', {parentFolder: parentFolderName})} {(parentFolderName ?? t('NewDocumentModal.IN_FOLDER', {folder: parentFolderName}))+'\\'}</i></p>                
            </When>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>{t('NewDocumentModal.CANCEL_1')}</Button>
            <SpinnerButton
                text={t('NewDocumentModal.OK_1')}
                isLoading={isCreatingDocument}
                disabled={disableCondition}
                onClick={handleCreateDocument}
            />
        </Modal.Footer>
    </>
};

export default NewDocumentModal;
