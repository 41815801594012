import { useTranslation } from "react-i18next";
import CustomMultiSelect from "../../../components/FormComponents/CustomMultiSelect";
import { IIncident, IReportTo } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function ReportedToForm(props: { editMode: boolean, newIncident: IIncident, reportTos: IReportTo[], onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'formReportedTo'} title={t('ReportedToForm.REPORTED_TO_1')}
                         colAs={props.editMode ? 'large' : 'sm'}>
        {!props.editMode && !props.newIncident.reportedTos?.length ?
            <span>{t('ReportedToForm.NOTHING_SELECTED_1')}</span>
            :
            <CustomMultiSelect
                isEditMode={props.editMode}
                name={"involvedUsers"}
                options={props.reportTos?.map(p => ({value: p.id, label: p.value})) ?? []}
                selected={props.reportTos?.map(p => ({
                    value: p.id,
                    label: p.value
                })).filter(p => props.newIncident.reportedTos.find(s => s.id === p.value)) ?? []}
                onChange={props.onChange}
            />
        }
    </FormProvider>
}
