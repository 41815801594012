import i18n from "i18next";
import { useDispatch } from "react-redux";
import { toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, ICustomQueryKeys } from "../core/interfaces/common";
import { IIncidentLight, IIncidentToSend } from "../core/interfaces/incident";
import { useGet, usePost } from "../hooks/useCustomQuery";
import { addToast } from "../store/slices/uiSlice";
import { URL_INCIDENTS } from "./endpoints/endpoints";

export const useGetIncidents = (cId: string) => {
    const queryKeys: ICustomQueryKeys = {root: "incidents", cId: cId}
    const query: ICustomQuery = {url: URL_INCIDENTS(cId), queryKeys: queryKeys}
    return useGet<IIncidentLight[]>(query);
}

export const useCreateIncident = (
    cId: string,
) => {
    const dispatch = useDispatch();
    const queryKeys: ICustomQueryKeys = {root: "incidents", cId: cId};

    const usePostMutation = usePost<IIncidentToSend>(
        URL_INCIDENTS(cId),
        queryKeys,
        true
    );

    return (incidentReq: IIncidentToSend) => usePostMutation.mutateAsync(incidentReq).then((response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('Incident.INCIDENT_CREATED_1'), i18n.t('Incident.YOUR_INCIDENT_CREATED_1'))));
        }
        return response;
    });
};
