export enum HTTP_STATUS {
    STATUS_200 = 200,
    STATUS_300 = 300,
    STATUS_400 = 400,
    STATUS_500 = 500,

    OK_200 = 200,
    CREATED_201 = 201,
    ACCEPTED_202 = 202,

    NO_CONTENT_204 = 204,
    BAD_REQUEST_400 = 400,
    UNAUTHORIZED_401 = 401,
    FORBIDDEN_403 = 403,
    NOT_FOUND_404 = 404,
    UNPROCESSABLE_ENTITY_422 = 422,
    INTERNAL_SERVER_ERROR_500 = 500,

}

export enum ImageVersion {
    ACTIONPLANFORM = "ACTIONPLANFORM",
    ACTIONPLANCOMPLEMENTARYFORM = "ACTIONPLANCOMPLEMENTARYFORM",
}
export enum Role {
    SUPERADMIN = 'SUPERADMIN',
    USER = 'USER',
}

export enum CompanyRole {
    ADMIN = 'ADMIN',
    READER = 'READER',
    EDITOR = 'EDITOR',
}

export enum CompanyType {
    COMPANY = 'Company',
    PARENT = 'Parent'
}

export enum ToastType {
    SUCCESS = 'success',
    DANGER = 'danger',
    INFO = 'info',
}

export enum NodeType {
    FOLDER = 'FOLDER',
    DOCUMENT = 'DOCUMENT'
}

export enum DocumentTypes {
    FILE = "File",
    NEW = "New",
    TEMPLATE = "Template",
}

export enum ModuleType {
    TEXT = "Text",
    CHECKLIST = "Checklist",
}

export enum DataRowType {
    TEXT_FIELD = "TEXT",
    NUMERIC_FIELD = "NUMERIC",
    SIGNATURE_FIELD = "SIGNATURE",
    RISK_ASSESSMENT_FIELD = "RISK_ASSESSMENT",
    MULTI_CHOICE_FIELD = "MULTI_CHOICE",
    ACTION_PLAN_FIELD = "ACTION_PLAN",
    IMAGE_FIELD = "IMAGE",
    FILES_FIELD = "FILES",
    MULTI_USER_FIELD = "MULTI_USER",

    MULTI_PICK_FIELD = "MULTI_PICK",
    DATE_FIELD = "DATE",
    USER_FIELD = "USER",
    ASSESSMENT_SCOPE_SEVERITY_FIELD = "ASSESSMENT_SCOPE_SEVERITY",
    USER_AND_DATE_FIELD = "USER_AND_DATE",
    SKILL_FIELD = "SKILL"
}

export enum DataRowSubType {
    NONE = "NONE",
    INCIDENT_LOCATION = "INCIDENT_LOCATION",
    INCIDENT_DESCRIPTION = "INCIDENT_DESCRIPTION",
    INCIDENT_CAUSE = "INCIDENT_CAUSE",
    INCIDENT_EXTERNAL_PERSONAL = "INCIDENT_EXTERNAL_PERSONAL",
    INCIDENT_TYPE = "INCIDENT_TYPE",
    INCIDENT_EXTERNAL_PERSONAL_EXISTS = "INCIDENT_EXTERNAL_PERSONAL_EXISTS",
    INCIDENT_USERS = "INCIDENT_USERS",

    INCIDENT_DATE = "INCIDENT_DATE",
    INCIDENT_ROOT_CAUSE = "INCIDENT_ROOT_CAUSE",
    INCIDENT_LONGTERM_ACTION = "INCIDENT_LONGTERM_ACTION",
    INCIDENT_FOLLOW_UP = "INCIDENT_FOLLOW_UP",
    INCIDENT_SICKDAYS = "INCIDENT_SICKDAYS",
    INCIDENT_MEDICAL_ASSISTANCE = "INCIDENT_MEDICAL_ASSISTANCE",
    INCIDENT_REPORTED_TO = "INCIDENT_REPORTED_TO",
    INCIDENT_INSTANT_ACTION = "INCIDENT_INSTANT_ACTION",
    INCIDENT_HEAD_DESCRIPTION = "INCIDENT_HEAD_DESCRIPTION",
    INCIDENT_IMAGE = "INCIDENT_IMAGE",
    INCIDENT_FILES = "INCIDENT_FILES",
    INCIDENT_ACTION = "INCIDENT_ACTION",

    DEVIATION_TYPE = "DEVIATION_TYPE",
    DEVIATION_DESCRIPTION = "DEVIATION_DESCRIPTION",
    DEVIATION_CAUSE = "DEVIATION_CAUSE",
    DEVIATION_INSTANT_ACTION = "DEVIATION_INSTANT_ACTION",
    DEVIATION_LONGSHORTTERM_ACTION = "DEVIATION_LONGSHORTTERM_ACTION",

    STANDALONE_CATEGORY = "STANDALONE_CATEGORY",
    STANDALONE_DESCRIPTION = "STANDALONE_DESCRIPTION",
    STANDALONE_ACTION = "STANDALONE_ACTION",
    STANDALONE_RESOURCES = "STANDALONE_RESOURCES",
    STANDALONE_INSTANT_ACTION = "STANDALONE_INSTANT_ACTION",

    ACTIONPLAN_PROJECT_NUMBER = "ACTIONPLAN_PROJECT_NUMBER",
    ACTIONPLAN_SUBTYPE_IMAGE = "ACTIONPLAN_SUBTYPE_IMAGE",
    ACTIONPLAN_SUBTYPE_FILES = "ACTIONPLAN_SUBTYPE_FILES",

    COMMON_MULTIPLE_USER = "COMMON_MULTIPLE_USER",

    SKILL_TYPE = "SKILL_TYPE",
    SKILL_COURSE_COMPLETED = "SKILL_COURSE_COMPLETED",
    SKILL_COURSE_COMPLETED_DATE = "SKILL_COURSE_COMPLETED_DATE",
    SKILL_COURSE_VALID_DATE = "SKILL_COURSE_VALID_DATE",
    SKILL_DESCRIPTION = "SKILL_DESCRIPTION",
    SKILL_COURSE_FILES = "SKILL_COURSE_FILES",
    SKILL_LEVEL = "SKILL_LEVEL"
}

export enum DataRowMultiChoiceType {
    CHECKLIST = "CHECKLIST",
}

export enum MultiChoiceAlternativeClassified {
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE",
    NEUTRAL = "NEUTRAL",
    TRANSPARENT = "TRANSPARENT",
}

export enum MultiChoiceDisplayType {
    DROPDOWN = "DROPDOWN",
    RADIO = "RADIO",
}

export enum PublishStatusType {
    CREATED = 'CREATED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',

    CLOSED = 'CLOSED',
}

export enum DocumentEventType {
    PUBLISH_REQUEST_APPROVAL = "REQUESTED_PUBLISH_APPROVAL",
    PUBLISH_APPROVED = "PUBLISH_APPROVED",
    PUBLISHED_REJECTED = "PUBLISH_REJECTED",
}

export enum CustomFolders {
    TRASHBIN = "TRASHBIN",
    TRASHBIN_PREFIXED = 'F_TRASHBIN',
    COMPLETEDBIN = "COMPLETEDBIN",

    COMPLETEDBIN_PREFIXED = 'F_COMPLETEDBIN',
}

export enum TemplateType {
    DOCUMENT = "DOCUMENT",
    CHECKLIST = "CHECKLIST",
}


export enum ActionPlanStatus {
    CREATED = "CREATED",
    ONGOING = "ONGOING",
    COMPLETED = "COMPLETED"
}

export enum ChecklistView {
    LIST = "LIST",
    TABLE = "TABLE",

}

export enum IncidentType {
    INCIDENT = "INCIDENT",
    ACCIDENT = "ACCIDENT",
    DEVIATION = "DEVIATION"
}

export enum ActionPlanType {
    INCIDENT = "INCIDENT",
    DEVIATION = "DEVIATION",
    ACCIDENT = "ACCIDENT",
    CHECKLIST = "CHECKLIST",
    STANDALONE = "STANDALONE",
}

export enum NotificationStatus {
    CREATED = "CREATED",
    READ = "READ"
}

export enum TaskEntityType {
    DOCUMENT = 'DOCUMENT',
    FILE = 'FILE',
    ACTIONPLAN = 'ACTIONPLAN'
}

export enum EmploymentType {
    NOT_SPECIFIED = 'NOT_SPECIFIED',
    NOT_EMPLOYED = 'NOT_EMPLOYED',
    HIRED = 'HIRED',
    CONSULTANT = 'CONSULTANT',
    APPRENTICE = 'APPRENTICE',
    INTERNSHIP = 'INTERNSHIP',
    PROJECT_EMPLOYMENT = 'PROJECT_EMPLOYMENT',
    PROBATIONARY_EMPLOYMENT = 'PROBATIONARY_EMPLOYMENT',
    SEASONAL_WORKER = 'SEASONAL_WORKER',
    PERMANENT = 'PERMANENT',
    HOURLY_EMPLOYEE = 'HOURLY_EMPLOYEE',
    SUBSTITUTE = 'SUBSTITUTE',
    FIXED_TERM_EMPLOYMENT = 'FIXED-TERM_EMPLOYMENT'
}


export enum TaskActionType {
    APPROVE_DOCUMENT = "APPROVE_DOCUMENT",
    READ_RECEIPT = "READ_RECEIPT",
    ACTIONPLAN_ACCIDENT = "ACTIONPLAN_ACCIDENT",
    ACTIONPLAN_DEVIATION = "ACTIONPLAN_DEVIATION",
    ACTIONPLAN_INCIDENT = "ACTIONPLAN_INCIDENT",
    ACTIONPLAN_STANDALONE = "ACTIONPLAN_STANDALONE",
    ACTIONPLAN_COMPLETED = "ACTIONPLAN_COMPLETED"
}

export enum StandaloneCategory {
    NOT_SPECIFIED = "NOT_SPECIFIED",
    RISK = "RISK",
    DEVIATION = "DEVIATION",
    INCIDENT_ACCIDENT = "INCIDENT_ACCIDENT",
    IMPROVEMENT_SUGGESTION = "IMPROVEMENT_SUGGESTION"
}

export enum DeviationType {
    NOT_SPECIFIED = "NOT_SPECIFIED",
    WORK_ENVIRONMENT = "WORK_ENVIRONMENT",
    QUALITY = "QUALITY",
    ENVIRONMENT = "ENVIRONMENT",
    IMPROVEMENT_SUGGESTION = "IMPROVEMENT_SUGGESTION",
    REVISION = "REVISION",
    OPINION = "OPINION",
    CUSTOMER_COMPLAINT = "CUSTOMER_COMPLAINT",
    DEFICIENT_SELF_INSPECTIONS_AT_WORKPLACE = "DEFICIENT_SELF_INSPECTIONS_AT_WORKPLACE",
    INADEQUATE_SECURITY_WORKPLACE = "INADEQUATE_SECURITY_WORKPLACE",
    DEFICIENT_CHEMICAL_MANAGEMENT = "DEFICIENT_CHEMICAL_MANAGEMENT",
    INADEQUATE_FINANCIAL_MONITORING = "INADEQUATE_FINANCIAL_MONITORING",
    RESOURCE_SHORTAGE = "RESOURCE_SHORTAGE",
    INSUFFICIENT_KNOWLEDGE_SKILLS = "INSUFFICIENT_KNOWLEDGE_SKILLS",
    OTHER = "OTHER"
}

export enum IncidentExternalPersonExists {
    NOT_SPECIFIED = "NOT_SPECIFIED",
    YES = "YES",
    NO = "NO"
}

export enum TemplateActionType {
    COPY_TEMPLATE = "COPY_TEMPLATE",
    CREATE_FROM_TEMPLATE = "CREATE_FROM_TEMPLATE"
}

export enum UserSkillCourseCompleted {
    NOT_SPECIFIED = "NOT_SPECIFIED",
    YES = "YES",
    NO = "NO"
}

export enum UserSkillLevel {
    NOT_SPECIFIED = "NOT_SPECIFIED",
    LEVEL_1 = "LEVEL_1",
    LEVEL_2 = "LEVEL_2",
    LEVEL_3 = "LEVEL_3",
    LEVEL_4 = "LEVEL_4",
    LEVEL_5 = "LEVEL_5",
}