import getIcon, { Icon } from "../../../../../../../assets/Icons/IconClassNames";
import { TextFormatButtonTypes } from "../ToolbarPlugin";

const TextFormats: TextFormatButtonTypes[] = [
    {
        key: "bold",
        title: 'TextFormats.BOLD_TEXT_1',
        icon: getIcon(Icon.TEXT_FORMAT_BOLD),
    },
    {
        key: "italic",
        title: 'TextFormats.ITALIC_CONTROL_1',
        icon: getIcon(Icon.TEXT_FORMAT_ITALIC),
    },
    {
        key: "underline",
        title: 'TextFormats.UNDERLINE_1',
        icon: getIcon(Icon.TEXT_FORMAT_UNDERLINE),
    },
    {
        key: "strikethrough",
        title: 'TextFormats.STRIKETHROUGH_1',
        icon: getIcon(Icon.TEXT_FORMAT_STRIKETHROUGH),
    },
];

export default TextFormats;
