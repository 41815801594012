import { useState } from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CustomTextInput from "../../../components/FormComponents/CustomTextInput";
import When from "../../../components/When";
import { getDataRowHeader } from "../../../core/helpers/dataRowHelper";
import { IDataRow, IDataRowTextField } from "../../../core/interfaces/document";

interface IActionPlanDescription {
    isNew: boolean;
    isEditMode: boolean;
    dataRow: IDataRow;
    setDataRow: (dr: IDataRow) => void;
}

const ActionPlanDescription = (
    {
        isNew,
        isEditMode,
        dataRow,
        setDataRow
    }: IActionPlanDescription) => {

    const [modifiedRow, setModifiedRow] = useState<IDataRow | undefined>(dataRow);

    const handleUpdate = (updatedRow: IDataRow) => {
        setDataRow(updatedRow);
        setModifiedRow(isNew ? updatedRow : { ...updatedRow });
    }

    const onChange = (e: any) => {
        let updated = { ...modifiedRow } as IDataRowTextField;
        updated.textValue = e.target.value;
        handleUpdate(updated);
    }

    return (
        <When condition={dataRow !== null}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Row className={"mt-3 mb-3 ms-2 me-2"}>
                    <Col md={3}>
                        <CustomTextInput
                            isEditMode={false}
                            disabled
                            name={"header"}
                            value={getDataRowHeader(dataRow)}
                            onChange={() => NaN}
                        />
                    </Col>
                    <Col md={9}>
                        <CustomTextInput
                            isEditMode={true}
                            disabled={dataRow.isClosed || !isEditMode}
                            name={"textValue"}
                            value={modifiedRow?.textValue ?? ""}
                            onChange={onChange}
                            disableAutocomplete
                            type={"text"}
                            maxLength={80}
                        />
                    </Col>
                </Row>
            </div>
        </When>
    );
};

export default ActionPlanDescription;
