import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, IResponse } from "../core/interfaces/common";
import { IPositionLevel, IPositionLevelResponse } from "../core/interfaces/positionLevel";
import { AppQueryKey, GetQueryKey, useInvalidateQuery } from "../core/queryKeys";
import { useDelete, useGet, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient from "./api";
import { URL_CREATE_POSITION_LEVEL, URL_DELETE_POSITION_LEVEL, URL_GET_POSITION_LEVELS, URL_UPDATE_POSITION_LEVEL } from "./endpoints/endpoints";
import i18n from "i18next";


export const CreatePositionLevel = async (positionLevel: IPositionLevel, companyId: string): Promise<IResponse<IPositionLevelResponse>> => {
    return await AxiosClient.post<IPositionLevelResponse>(URL_CREATE_POSITION_LEVEL(companyId), positionLevel)
        .then(resp => ({data: resp.data, status: resp.status}))
        .catch((error: AxiosError) => ({ data: {} as IPositionLevelResponse, status: error.response?.status ?? 500}));
}

export const useGetPositionLevels = (): [IPositionLevelResponse[], boolean] => {
    const { cId } = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.POSITION_LEVELS, { includeCompanyId: true });
    const query: ICustomQuery = {
        url: URL_GET_POSITION_LEVELS(cId!),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<IPositionLevelResponse[]>(query);


    return [data?.data?.data ?? [], isFetching]
}

export const useUpdatePositionLevel = (positionLevelId: string): [(positionLevel: IPositionLevel) => Promise<boolean>, boolean] => {
    const { cId } = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.POSITION_LEVELS);

    const usePostMutation = usePut<IPositionLevel>(
        URL_UPDATE_POSITION_LEVEL(cId!, positionLevelId),
        queryKeys,
        false
    );

    const invalidatePositionLevels = useInvalidateQuery(AppQueryKey.POSITION_LEVELS);


    const request = (positionLevel: IPositionLevel) => usePostMutation.mutateAsync(positionLevel).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('PositionLevelApi.POSITIONLEVEL_UPDATED_1'), i18n.t('PositionLevelApi.POSITIONLEVEL_UPDATED_2'))));
            await invalidatePositionLevels();
        }
        return !!response.success;
    }).catch(_ => {
        dispatch(addToast(toastError(i18n.t('PositionLevelApi.POSITIONLEVEL_UPDATE_FAILED_1'), i18n.t('PositionLevelApi.POSITIONLEVEL_UPDATE_FAILED_2'))))
        return false;
    });

    return [request, usePostMutation.isLoading];
}


export const useDeletePositionLevel = (positionLevelId: string): [() => Promise<boolean>, boolean] => {
    const { cId } = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.POSITION_LEVELS);
    const useDeleteMutation = useDelete<void>(
        URL_DELETE_POSITION_LEVEL(cId!, positionLevelId),
        queryKeys,
        true
    );

    const invalidatePositionLevels = useInvalidateQuery(AppQueryKey.POSITION_LEVELS);

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('PositionLevelApi.POSITIONLEVEL_DELETED_1'), i18n.t('PositionLevelApi.POSITIONLEVEL_DELETED_2'))));
            await invalidatePositionLevels();
        }
        return !!response.success;
    });
    return [request, useDeleteMutation.isLoading];
}
