import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useCopyDocument,
    useDeleteDocument,
    useDeleteFolder,
    useGetDocumentById,
    useRestoreDocument,
    useRestoreFolder
} from "../../../api/DocumentApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import CustomDropdown, { DropdownToggleType } from "../../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import OverlayComponent from "../../../components/OverlayComponent";
import When from "../../../components/When";
import { CompanyRole, DocumentTypes } from "../../../core/enums/enums";
import { IDocument } from "../../../core/interfaces/document";
import useAccessCheck from "../../../hooks/useAccessCheck";
import { useAcceptModalContext } from "../../../providers/AcceptModalProvider";
import { MarkAsImportant } from "../../Document/Components/MarkAsImportant";
import MarkAsImportantHandled from "../../Document/Components/MarkAsImportantHandled";
import { MarkReadReceipt, ReadReceipt } from "../../Document/Components/ReadReceipt";
import { downloadSelectedDocument } from "../Helpers/DirectoryHelper";


const DeletedItemDropdown = ({documentId}: { documentId: string }) => {
    const {t} = useTranslation();
    const [restore, isFetching] = useRestoreDocument(documentId)
    return (
        <OverlayComponent showOverlay={isFetching} showSpinner={isFetching}>
            <CustomDropdownItem icon={Icon.REDO} text={t('TableDropdown.RESET_1')} disabled={isFetching} onClick={() => restore()}/>
        </OverlayComponent>
    )
}

const DeletedFolderDropdown = ({folderId}: { folderId: string }) => {
    const {t} = useTranslation();
    const [restore, isFetching] = useRestoreFolder(folderId)
    return (
        <OverlayComponent showOverlay={isFetching} showSpinner={isFetching}>
            <CustomDropdownItem icon={Icon.REDO} text={t('TableDropdown.RESET_2')} disabled={isFetching} onClick={() => restore()}/>
        </OverlayComponent>
    )
}

const DeleteDocumentItemDropdown = ({documentId, documentName}: { documentId: string, documentName: string }) => {
    const {t} = useTranslation();
    const [doDelete, isDeleting] = useDeleteDocument(documentId);
    const acceptModal = useAcceptModalContext();
    return (
        <OverlayComponent showOverlay={isDeleting} showSpinner={isDeleting}>
            <CustomDropdownItem
                icon={Icon.REMOVE}
                text={t('TableDropdown.DELETE_1')}
                disabled={isDeleting}
                onClick={() => acceptModal.trigger(() => doDelete(), {
                    header: t('TableDropdown.DELETE_DOCUMENT_1'),
                    body: <p>{t('TableDropdown.DELETE_CONFIRMATION_1')}<i>{documentName}</i></p>
                })}
            />
        </OverlayComponent>
    )
}

const CopyDocumentItemDropdown = ({documentId, isUserDoc}: { documentId: string, isUserDoc: boolean }) => {
    const {t} = useTranslation();
    const [doCopy, isCopying] = useCopyDocument(documentId);
    return (
        <OverlayComponent showOverlay={isCopying} showSpinner={isCopying}>
            <CustomDropdownItem icon={Icon.COPY} text={t('TableDropdown.COPY_1')} disabled={isCopying}
                                onClick={() => doCopy({documentId: documentId, isUserDoc})}/>
        </OverlayComponent>
    )
}

const DeleteFolderItemDropdown = ({folderId}: { folderId: string }) => {
    const {t} = useTranslation();
    const [doDelete, isDeleting] = useDeleteFolder(folderId);
    const acceptModal = useAcceptModalContext();
    return (
        <OverlayComponent showOverlay={isDeleting} showSpinner={isDeleting}>
            <CustomDropdownItem
                icon={Icon.REMOVE}
                text={t('TableDropdown.DELETE_2')}
                disabled={isDeleting}
                onClick={() => acceptModal.trigger(() => doDelete(), {
                    header: t('TableDropdown.DELETE_FOLDER_1'),
                    body: <p>{t('TableDropdown.DELETE_FOLDER_2')}<br />{t('TableDropdown.DELETE_FOLDER_3')}</p>
                })
                }/>
        </OverlayComponent>
    )
}

const ReadReceiptItemDropdown = ({documentId}: { documentId: string }) => {
    const {t} = useTranslation();
    const [checkCompanyRole] = useAccessCheck();
    // Fetch document
    const {
        isFetching: isFetchingDocument,
        data: documentResponse,
    } = useGetDocumentById(documentId);
    const [document, setDocument] = useState<IDocument>();
    useEffect(() => {
        if (documentResponse) {
            setDocument(documentResponse.data.data);
        }
    }, [documentResponse]);

    const renderLoadingPlaceholders = () => (<>
            <CustomDropdownItem icon={Icon.FILE_LINES} text={t('TableDropdown.READ_RECEIPTS_1')} disabled={true} onClick={() => NaN}/>
            <CustomDropdownItem icon={Icon.FILE_LINES} text={t('TableDropdown.REQUEST_READ_RECEIPT_1')} disabled={true} onClick={() => NaN}/>
        </>
    )

    return (<>
        <When condition={checkCompanyRole(CompanyRole.ADMIN)}>
            <When condition={!document || isFetchingDocument} then={renderLoadingPlaceholders()}
                  otherwise={<ReadReceipt document={document!} isEditMode={false}/>}/>
        </When>
        <When condition={!!document}>
            <MarkReadReceipt hide={false} document={document!}/>
            <MarkAsImportant document={document!} documentId={document?.id} disabled={false} isEditMode={false}/>
            <MarkAsImportantHandled document={document!} disabled={false} isEditMode={false}/>
        </When>
    </>)
}

interface ITableDropdownProps {
    isDeleted: boolean;
    isDocument: boolean;
    documentId?: string;
    folderId?: string;
    documentType?: DocumentTypes;
    onClickEditName: () => void;
    isUserDoc?: boolean;
    documentName: string;
}

const TableDropdown: React.FC<ITableDropdownProps> = (
    {
        documentId,
        isDocument,
        isDeleted,
        folderId,
        documentType,
        onClickEditName,
        isUserDoc = false,
        documentName,
    }) => {
    const {t} = useTranslation();
    return <CustomDropdown toggleType={DropdownToggleType.DOTS}>
        <When condition={!isDeleted}
              otherwise={isDocument ? <DeletedItemDropdown documentId={documentId!}/> :
                  <DeletedFolderDropdown folderId={folderId!}/>}>
            <CustomDropdownItem icon={Icon.EDIT} text={t('TableDropdown.CHANGE_NAME_1')} onClick={() => onClickEditName()}/>
            <When condition={isDocument}>
                <CopyDocumentItemDropdown isUserDoc={isUserDoc} documentId={documentId!} />
            </When>
            <When condition={isDocument} otherwise={<DeleteFolderItemDropdown folderId={folderId!}/>}>
                <When condition={documentType === DocumentTypes.FILE}>
                    <CustomDropdownItem
                        icon={Icon.DOWNLOAD}
                        text={t('TableDropdown.DOWNLOAD_1')}
                        onClick={() => downloadSelectedDocument(documentId!)}
                    />
                </When>
                <DeleteDocumentItemDropdown documentId={documentId!} documentName={documentName}/>
            </When>
            <When condition={isDocument}>
                <ReadReceiptItemDropdown
                    documentId={documentId!}
                />
            </When>
        </When>
    </CustomDropdown>
}

export default TableDropdown
