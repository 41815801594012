import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
    $getTableCellNodeFromLexicalNode,
    $getTableNodeFromLexicalNodeOrThrow,
    INSERT_TABLE_COMMAND,
    InsertTableCommandPayload
} from "@lexical/table";
import { $getSelection, $isRangeSelection } from "lexical";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import CustomTextInput from "../../../../../FormComponents/CustomTextInput";
import ModalForm from "../../../../../FormComponents/ModalForm";
import styles from "../ToolbarPlugin.module.scss";

interface ITableButton {
}

const initPayload: InsertTableCommandPayload = {
    columns: "3",
    rows: "3",
    includeHeaders: false,
}

const TableButton = ({}: ITableButton) => {
    const {t} = useTranslation();
    const [editor] = useLexicalComposerContext();
    const [isTableSelected, setTableSelected] = useState(false);
    const [showInsertTableModal, setshowInsertTableModal] = useState(false);

    const [payload, setPayload] = useState<InsertTableCommandPayload>(initPayload);

    const updateTableButton = useCallback(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
            return true;
        }
        const isTableCellNode = $getTableCellNodeFromLexicalNode(selection.anchor.getNode());
        setTableSelected(isTableCellNode !== null);
    }, []);

    useEffect(() => {
        return editor.registerUpdateListener(({editorState}) => {
            editorState.read(() => {
                updateTableButton();
            });
        });
    }, [editor, updateTableButton]);

    const handleChangeInput = (e: any) => {
        setPayload({
            ...payload,
            [e.target.name]: e.target.value
        });
    }

    const handleShowInsertTableModal = () => {
        setPayload(initPayload);
        setshowInsertTableModal(true);
    }

    const closeModal = () => {
        setshowInsertTableModal(false);
    }


    const insertTable = () => {
        editor.dispatchCommand(INSERT_TABLE_COMMAND, payload);
        closeModal();
    };

    const deleteTable = () => {
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) {
                return true;
            }

            const tableNode = $getTableNodeFromLexicalNodeOrThrow(selection.anchor.getNode());
            if (!tableNode) return;
            tableNode.remove();
        });
    }

    return <>
        {isTableSelected ? (
            <button
                className={styles.toolbarItem}
                onClick={deleteTable}
            >
                <span className="text">{t('TableButton.REMOVE_TABLE_1')}</span>
            </button>
        ) : (
            <button
                onClick={handleShowInsertTableModal}
                className={styles.toolbarItem}
            >
                <span className="text">{t('TableButton.ADD_TABLE_1')}</span>
            </button>
        )}

        <ModalForm
            show={showInsertTableModal}
            size={"sm"}
            hide={closeModal}
            title={t('TableButton.ADD_TABLE_2')}
            isEdited={() => {return false}}

            submit={insertTable}
            submitText={t('TableButton.ADD_1')}
            isLoading={false}
            disableSubmit={payload.columns === "" || payload.rows === ""}
        >
            <Form.Group as={Row}>
                <Form.Label column>{t('TableButton.NUMBER_OF_COLUMNS_1')}</Form.Label>
                <Col>
                    <CustomTextInput
                        autofocus
                        type={"number"}
                        name={"columns"}
                        value={payload.columns}
                        onChange={handleChangeInput}
                        min={1}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column>{t('TableButton.NUMBER_OF_ROWS_1')}</Form.Label>
                <Col>
                    <CustomTextInput
                        type={"number"}
                        name={"rows"}
                        value={payload.rows}
                        onChange={handleChangeInput}
                        min={1}
                        max={100}
                    />
                </Col>
            </Form.Group>
        </ModalForm>
    </>
};

export default TableButton;
