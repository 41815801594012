import {AxiosResponse} from "axios";

export const fileResponse = (response: AxiosResponse, name?: string) => {
    const contentType = response.headers["content-type"];
    const contentDisposition = response.headers["content-disposition"];
    let filename = name ?? '';
    if (filename === '') {
        const patternFilename = /filename=\s*([^\n\r]*);/;
        const filenameRegex = new RegExp(patternFilename);
        const matchFilename = contentDisposition.match(filenameRegex);
        if (matchFilename) {
            filename = matchFilename[1];
            if (filename.startsWith("\"")) {
                filename = filename.substring(1);
            }
            if (filename.endsWith("\"")) {
                filename = filename.substring(0, filename.length - 1);
            }
        }
    }
    return {
        data: response.data,
        contentType: contentType,
        filename: filename,
    }
}

export const downloadFileResponse = (response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.contentType}));
    const link = window.document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.filename);
    window.document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
}

export const getBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve((reader.result as string).split(',')[1]);
        reader.onerror = () => reject();
    })
}
