import { useTranslation } from "react-i18next";
import CustomSelect from "../../../components/FormComponents/CustomSelect";
import When from "../../../components/When";
import { TypeToName } from "../../../core/helpers/incidentHelper";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";

export function TypeForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId="formType" title={t('TypeForm.TYPE_1')} colClassName={"d-flex align-content-end"}>
        <When condition={props.editMode}
              otherwise={<span data-test-id={"formType"}>{TypeToName(props.newIncident.type)}</span>}>
            <CustomSelect
                isEditMode={props.editMode}
                size="sm"
                name={"type"}
                value={props.newIncident.type + ''}
                options={<>
                    <option value={0}>{TypeToName(0)}</option>
                    <option value={1}>{TypeToName(1)}</option>
                </>
                }
                onChange={props.onChange}
                required
            />
        </When>
    </FormProvider>;
}
