import { useState } from "react";
import { Alert } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { useTranslation } from "react-i18next";
import When from "../../../../components/When";
import styles from './headerSection.module.scss';

export function HeaderSection() {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);

    return (
        <When condition={show}>
            <Container className={styles.container}>
                <Alert variant="info" onClose={() => setShow(false)} dismissible>{t('HeaderSection.ALERT_1')}</Alert>
            </Container>
        </When>
    );
}