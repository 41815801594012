import { useTranslation } from "react-i18next";
import { IIncident } from "../../../core/interfaces/incident";
import { BooleanForm } from "./BooleanForm";



export function MedicalAssistanceForm(props: { editMode: boolean, newIncident: IIncident, onChange: (value: boolean | null, e: any) => void }) {
    const {t} = useTranslation();
    return <BooleanForm {...props} header={t('MedicalAssistanceForm.MEDICAL_OR_PSYCHOLOGICAL_CARE_NEEDED_1')} propertyName={"medicalAssistance"}
                        property={props.newIncident.medicalAssistance}/>
}
