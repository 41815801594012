import {ReactNode} from "react";
import {Spinner} from "react-bootstrap";

interface IOverlayComponent {
    showOverlay: boolean;
    showSpinner: boolean;
    children?: ReactNode;
}

const OverlayComponent = (
    {
        showOverlay,
        showSpinner,
        children,
    }: IOverlayComponent) => {

    return <>
        <div className="overlayWrapper">
            {children}
            {showOverlay && (<div className="overlaySpinnerDiv">
                {showSpinner && <Spinner animation="grow" variant="secondary"/> }
            </div>)}
        </div>
    </>
};

export default OverlayComponent;