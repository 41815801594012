import { useState } from "react";
import { Image } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useGetCompanyLogo, useRemoveCompanyLogo } from "../../../api/CompanyApi";
import getIcon, { Icon } from "../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../components/ClickableIconButton/ClickableIconButton";
import CompanyLogo from "../../../components/CompanyLogo/CompanyLogo";
import ModalForm from "../../../components/FormComponents/ModalForm";
import SimpleConfirmModal from "../../../components/ModalComponents/SimpleConfirmModal";
import When from "../../../components/When";
import { ICompany } from "../../../core/interfaces/company";
import UploadCompanyLogo from "../UploadCompanyLogo";

interface ICompanyEditLogoModal {
    company: ICompany;
    showEditLogoModal: boolean;
    setShowEditLogoModal: (val: boolean) => void;
}

const CompanyEditLogoModal = (
    {
        company,
        showEditLogoModal,
        setShowEditLogoModal,
    }: ICompanyEditLogoModal) => {

    const {t} = useTranslation();
    const [newLogoPreview, setNewLogoPreview] = useState<string | null>(null);
    const [showRemoveLogoModal, setShowRemoveLogoModal] = useState(false);
    const [removeLogo, isRemovingLogo] = useRemoveCompanyLogo(company.id);
    const {logoUrl, isFetching} = useGetCompanyLogo(company.id);

    const closeModal = () => {
        setShowEditLogoModal(false);
    }

    const handleRemoveLogo = async () => {
        await removeLogo().then((removed) => {
            if (removed) {
                setNewLogoPreview(null);
            }
        });
        setShowRemoveLogoModal(false);
    }

    const renderPreviewLogo = () => (
        <Image
            thumbnail
            fluid
            width={"120px"}
            style={{maxHeight: "56px"}}
            src={`data:image/png;base64,` + newLogoPreview}
        />
    );

    return <>
        <ModalForm
            size={"sm"}
            show={showEditLogoModal}
            hide={closeModal}
            title={t('CompanyEditLogoModal.MANAGE_LOGO_1')}
            isEdited={() => !!newLogoPreview}
            submit={closeModal}
            submitText={t('CompanyEditLogoModal.CLOSE_1')}
            isLoading={false}
            disableClickOutside
            hideFooter
        >
            <Row>
                <Col className={"d-flex justify-content-center align-items-center"}>
                    <When
                        condition={!!newLogoPreview}
                        then={renderPreviewLogo()}
                        otherwise={<CompanyLogo companyId={company.id}/>}
                    />
                </Col>
            </Row>
            <Row className={"mt-3"}>
                <Col>
                    <UploadCompanyLogo
                        company={company}
                        setNewLogoPreview={setNewLogoPreview}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <ClickableIconButton
                        title={t('CompanyEditLogoModal.DELETE_LOGO_1')}
                        disabled={!logoUrl}
                        buttonType={"CANCEL"}
                        icon={getIcon(Icon.REMOVE)}
                        onClick={() => setShowRemoveLogoModal(true)}
                    />
                </Col>
            </Row>
        </ModalForm>

        <SimpleConfirmModal
            size={"sm"}
            show={showRemoveLogoModal}
            header={<>{t('CompanyEditLogoModal.DELETE_LOGO_2')}</>}
            text={<>{t('CompanyEditLogoModal.DELETE_LOGO_3')}</>}
            onClickOKText={t('CompanyEditLogoModal.DELETE_1')}
            onClickOK={handleRemoveLogo}
            isLoadingClickOk={isRemovingLogo}
            onClickCancelText={t('CompanyEditLogoModal.CANCEL_1')}
            onClickCancel={() => setShowRemoveLogoModal(false)}
        />
    </>
};

export default CompanyEditLogoModal;
