import React from "react";
import {Dropdown} from "react-bootstrap";
import {getIcon, Icon, IconSize} from "../../../assets/Icons/IconClassNames";
import styles from './customDropdownitem.module.scss';

interface ICustomDropdownItem {
    icon: Icon,
    iconColor?: string;
    text: string,
    disabled?: boolean,
    onClick: () => void
}

export const CustomDropdownItem = (
    {
        icon,
        iconColor,
        text,
        disabled,
        onClick,
    }: ICustomDropdownItem) => {
    return (
        <Dropdown.Item disabled={disabled} className={styles.cont} onClick={() => !disabled && onClick()}>
            <span style={{flex: 1, margin: 'auto'}} className={[getIcon(icon, IconSize.X1, iconColor ?? '')].join(' ')} />
            <span className={"ps-1"} style={{flex: 5}}>{text}</span>
        </Dropdown.Item>
    )
}