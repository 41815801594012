import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import When from "../../../components/When";
import { INCIDENT_STATUS_CODES } from "../../../core/helpers/incidentHelper";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function IsCompletedForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'isComplete'} title={t('IsCompletedForm.CASE_CLOSED_1')}>
        <When condition={props.editMode}
              otherwise={
                  <span>{props.newIncident.status === INCIDENT_STATUS_CODES.COMPLETED ? t('IsCompletedForm.YES') : t('IsCompletedForm.NO')}</span>}>
            <Form.Check
                disabled={!props.editMode}
                type={'checkbox'}
                label={''}
                id={''}
                onChange={props.onChange}
                checked={props.newIncident.status === INCIDENT_STATUS_CODES.COMPLETED}
            />
        </When>
    </FormProvider>
}
