import { NodeModel } from "@minoru/react-dnd-treeview";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import {
    useCreateNewDocumentTemplateByCompany,
    useGetTemplatesByCompany
} from "../../../../api/TemplateApi";
import CustomTextInput from "../../../../components/FormComponents/CustomTextInput";
import FormBase from "../../../../components/FormComponents/FormBase";
import ModalForm from "../../../../components/FormComponents/ModalForm";
import { NodeType, TemplateType } from "../../../../core/enums/enums";
import { ITemplateNodeData, NewTemplateRequest } from "../../../../core/interfaces/template";
import CreateNewTemplateFolder from "./CreateNewTemplateFolder";
import styles from "./SaveAsTemplate.module.scss";
import TemplatesDirectoryTree from "./TemplatesDirectoryTree";

interface ISaveAsTemplate {
    showModal: boolean;
    setShowModal: (val: boolean) => void;
    templateType: TemplateType;
    documentId?: string;
    companyId?: string;
}

const SaveAsTemplate = (
    {
        showModal,
        setShowModal,
        templateType,
        documentId,
        companyId
    }: ISaveAsTemplate) => {
        
    const {t} = useTranslation();
    const [showCreateNewFolderSection, setShowCreateNewFolderSection] = useState(false);
    const [name, setName] = useState('');
    const [selectedNode, setSelectedNode] = useState<NodeModel<ITemplateNodeData> | null>(null);
    const [allowCompanies, setAllowCompanies] = useState(false);

    const { data: templates, isFetching: isFetchingTemplates } = useGetTemplatesByCompany(templateType, false, companyId);
    const [data, setData] = useState<NodeModel<ITemplateNodeData>[]>([]);

    useEffect(() => {
        if (templates) {
            setData(templates.data.data as NodeModel<ITemplateNodeData>[]);
        }
    }, [templates]);

    const [createTemplate, isCreatingTemplate] = useCreateNewDocumentTemplateByCompany(companyId);

    useEffect(() => {
        if (showModal) {
            setShowCreateNewFolderSection(false);
            setName("");
            setSelectedNode(null);
            setAllowCompanies(false);
        }
    }, [showModal]);

    const handleSetSelectedNode = (node: NodeModel<ITemplateNodeData>) => {
        if (selectedNode && selectedNode === node) {
            return setSelectedNode(null);
        }
        return setSelectedNode(node);
    }

    const handleCreateTemplate = async () => {
        const request: NewTemplateRequest = {
            name: name,
            allowCompanies: allowCompanies,
            documentId: documentId,
            parentFolderId: selectedNode?.data?.id,
        }
        await createTemplate(request).then((isCreated) => {
            if (isCreated) {
                setShowModal(false);
            }
        });
    }

    const handleIsEdited = (): boolean => {
        return name !== "";
    }

    const isDisabled = (): boolean => {
        return !name || showCreateNewFolderSection;
    }

    const renderBody = () => (<>
        <Row className={"mb-2"}>
            <Col>{t('SaveAsTemplate.SELECT_PLACEMENT_OF_TEMPLATE_1')}</Col>
        </Row>
        <Row>
            <Col className={styles.templatesDirectoryTreeBorder}>
                <TemplatesDirectoryTree
                    templatesData={data}
                    nodeTypeAllowedToSelect={NodeType.FOLDER}
                    onClickNode={handleSetSelectedNode}
                    selectedNode={selectedNode}
                />
            </Col>
        </Row>
        <Row className={"mt-2"}>
            <Col>
                <Button
                    disabled={showCreateNewFolderSection}
                    onClick={() => setShowCreateNewFolderSection(true)}>{t('SaveAsTemplate.NEW_FOLDER_1')}</Button>
            </Col>
        </Row>
        <Row className={`${styles.createNewFolderSection} ${showCreateNewFolderSection ? styles.visible : ""}`}>
            <CreateNewTemplateFolder
                templateType={templateType}
                parentFolder={selectedNode}
                closeCreateNewFolderSection={() => setShowCreateNewFolderSection(false)}
            />
        </Row>
        <Row className={showCreateNewFolderSection ? styles.disabledSection : ""}>
            <Col>
                <FormBase
                    row={false}
                    controlId={'formName'}
                    title={t('SaveAsTemplate.TEMPLATE_NAME_1')}
                    className={'pb-2'}
                >
                    <CustomTextInput
                        disableAutocomplete
                        name={''}
                        isEditMode={true}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormBase>
                <FormBase
                    row={false}
                    controlId={'formToggle'}
                    title={companyId ? t('SaveAsTemplate.AVAILABLE_TO_POSSIBLE_SUBSIDIARIES_1') : t('SaveAsTemplate.AVAILABLE_TO_COMPANIES_1')}
                >
                    <Form.Switch
                        label={""}
                        onChange={({target}) => setAllowCompanies(target.checked)}
                    />
                </FormBase>
            </Col>
        </Row>
    </>);

    return <div>
        <ModalForm
            show={showModal}
            size={"lg"}
            hide={() => setShowModal(false)}
            title={companyId ? t('SaveAsTemplate.SAVE_AS_TEMPLATE_1') : t('SaveAsTemplate.SAVE_AS_SAMG_TEMPLATE_1')}
            isEdited={handleIsEdited}
            submit={handleCreateTemplate}
            isLoading={isCreatingTemplate || isFetchingTemplates}
            centered={false}
            disableSubmit={isDisabled()}
            hideFooter={showCreateNewFolderSection}
        >
            {renderBody()}
        </ModalForm>
    </div>
};

export default SaveAsTemplate;
