import { Badge, Col, Container, Nav, NavDropdown, Row } from "react-bootstrap";

import { useDeleteNotification, useGetUnreadNotificationsCount } from "../../../../../api/UserNotificationApi";
import { getRegularIcon, Icon, IconSize } from "../../../../../assets/Icons/IconClassNames";
import When from "../../../../../components/When";
import { INotification } from "../../../../../core/interfaces/notification";
import styles from './Notifications.module.scss';
import GetNotificationMaterial from "./NotificationTypes";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const DocumentsNotification = ({notification}: { notification: INotification }) => {
    const {t} = useTranslation();
    const [remove, isRemoving] = useDeleteNotification(notification.id);
    const notMaterial = GetNotificationMaterial(notification);
    const navigate = useNavigate();
    if (notMaterial === undefined) return <p>{t('Notifications.NOT_IMPLEMENTED_1')}</p>

    const navigateToPathWithRootDelay = (link: string) => {
        navigate("/");
        setTimeout(() => {
            navigate(link, {
                state: notMaterial.state ? {fromTodo: true, stateId: notMaterial.state} : undefined
            });
        }, 1);
    }

    return <><Row style={{paddingLeft: 10, width: 300, opacity: isRemoving ? 0.2 : 1}}>
        <Col xs={10}>
            <>{notMaterial.header}</>

            <br/>
            <span className={"cursor-pointer " + styles.click}
                  onClick={() => navigateToPathWithRootDelay(notMaterial.link)}>{notMaterial.linkText}</span>
        </Col>
        <Col xs={1} className={styles.click}>
            <i className={getRegularIcon(Icon.X, IconSize.SMALL)} onClick={() => !isRemoving && remove()}/>
        </Col>
    </Row>
    </>
}


const Bell = ({count}: { count: number }) => {
    return (
        <span style={{position: 'relative'}}>
            <i className={getRegularIcon(Icon.BELL, IconSize.LARGE)}/>
            <Badge pill bg={'primary'} style={{
                fontSize: 10,
                position: 'absolute',
                left: 8,
                bottom: 8
            }}>{count ? '' + count + '' : ''}</Badge>
        </span>
    )
}
const Notifications = () => {
    const { t } = useTranslation();
    const [data, count, read] = useGetUnreadNotificationsCount();

    return (<>
        <NavDropdown align={"end"} style={{width: 'auto'}} className={styles.dropdown}
                     title={<Bell count={typeof (count) == "number" ? count : 0}/>} onClick={() => read()}>
            <When condition={data?.data?.length > 0}
                  otherwise={<Nav.Item><Container fluid style={{width: 200}}>{t('Notifications.NO_NOTIFICATIONS_1')}</Container></Nav.Item>}>
                {((data?.data) ?? []).map(v =>
                    <Nav.Item key={v.id}>
                        <Container fluid>
                            <DocumentsNotification notification={v}/>
                        </Container>

                        <NavDropdown.Divider/>
                    </Nav.Item>)}
            </When>

        </NavDropdown>
    </>)
}

export default Notifications
