import styles from "../views/Users/Users.module.scss";
import {Icon, IconSize} from "../assets/Icons/IconClassNames";
import {Dropdown} from "react-bootstrap";
import React from "react";
import RIcon from "./RIcon";

const CustomToggle = React.forwardRef(({ children, onClick, iconSize, icon, title}: {children: JSX.Element, onClick:(e:any) => any, icon: Icon, iconSize: IconSize, title?: string}, ref: any) => {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
            href=""
            title={title}
            className={styles.clickable}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <RIcon icon={icon} iconSize={iconSize} />
            {children}
        </a>
    );
});

const DropDownIconToggle = ({icon, iconSize, text = "", title = ""} : {icon: Icon, iconSize: IconSize, text?: string, title?: string}) => {
    return(
        <Dropdown.Toggle as={CustomToggle} icon={icon} iconSize={iconSize} title={title}>
            <span>{text}</span>
        </Dropdown.Toggle>
    )
}

export default DropDownIconToggle