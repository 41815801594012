import _ from "lodash";
import { createContext, useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteModule, useUpdateModule } from "../../../api/DocumentApi";
import OverlayComponent from "../../../components/OverlayComponent";
import When from "../../../components/When";
import { ChecklistView, ModuleType, PublishStatusType } from "../../../core/enums/enums";
import {
    IDocument,
    IDocumentModule,
    IDocumentModuleChecklistForm,
    IDocumentModuleChecklistResponse
} from "../../../core/interfaces/document";
import { useChangesModal } from "../../../hooks/useChangesModal";
import { usePrompt } from "../../../hooks/usePrompt";
import AddDocumentModulesContainer from "./AddDocumentModulesContainer";
import AnswerChecklistContainer from "./ChecklistModule/AnswerChecklist/AnswerChecklistContainer";
import RenderDocumentModules from "./RenderDocumentModules";

interface IDocumentModulesProvider {
    document?: IDocument;
    isDocumentPreview: boolean;
    previewDocumentId?: string;
    modules: IDocumentModule[];
    originalActiveModule: IDocumentModule | null;
    activeModule: IDocumentModule | null;
    setActiveModule: (module: IDocumentModule | null, moduleIndex: number | undefined) => void;
    isNoChangesActiveModule: boolean;
    updateActiveModule: (module: IDocumentModule) => void;
    setActiveModuleIndex: (index: number) => void;
    cancelEditModule: () => void;
    deleteModule: (moduleId: string) => void;
    saveChanges: () => void;
    isEditMode: boolean;
    refetchModules: () => void;
    handleSetChecklistToAnswer: (module: IDocumentModule) => void;
    handleCloseAnswerChecklist: () => void;
    isAnswerMode: boolean;

    isSaveDisabled: boolean;

    isDocumentClosed: boolean;
    setSaveState: (state: boolean) => void;
    getChecklistView: (moduleId: string) => ChecklistView;
    updateChecklistView: (moduleId: string, value: ChecklistView) => void;
    isDocumentArchived: boolean | undefined;
    addSaveFunction: (moduleId: string, save: () => Promise<void>) => void,
    setDocumentModuleTextHtmlString: (value: string) => void;
}

const DocumentModulesContext = createContext<IDocumentModulesProvider>({} as IDocumentModulesProvider);
export const useDocumentModulesContext = () => useContext<IDocumentModulesProvider>(DocumentModulesContext);


interface IDocumentModules {
    checklistView: (moduleId: string) => ChecklistView;
    setChecklistView: (moduleId: string, value: ChecklistView) => void;
    isDocumentPreview: boolean;
    modules: IDocumentModule[];
    isFetchingModules: boolean;
    refetchModules: () => void;
    isEditMode: boolean;
    previewDocumentId?: string;
    setIsActiveModule: (val: boolean) => void;
    setDisableQueries: (val: boolean) => void;
    isDocumentArchived: boolean | undefined;
    document?: IDocument;
}

const DocumentModules = (
        {
            checklistView,
            setChecklistView,
            isDocumentPreview,
            modules,
            isFetchingModules,
            refetchModules,
            isEditMode,
            previewDocumentId,
            setIsActiveModule,
            setDisableQueries,
            isDocumentArchived,
            document,
        }: IDocumentModules) => {
        const {documentId} = useParams();
        const docId = (documentId ?? previewDocumentId ?? "");
        const renderRef = useRef<any>(null);

        const [isSaveDisabled, setIsSaveDisabled] = useState(false);
        const [activeModuleIndex, setActiveModuleIndex] = useState<number | undefined>(undefined);

        const [saveFunctions, setSaveFunctions] = useState<Map<string, () => Promise<void>>>(new Map());
        const prompt = usePrompt();
        useChangesModal(async () => {
            await clearActiveModule();
        }, () => !isNoChanges());

        // Active module
        const [originalActiveModule, setOriginalActiveModule] = useState<IDocumentModule | null>(null);
        const [activeModule, setActiveModule] = useState<IDocumentModule | null>(null);

        const [htmlString, setHtmlString] = useState<string>("");

        // Clear active
        const clearActiveModule = async () => {
            await refetchModules();
            setOriginalActiveModule(null);
            setActiveModule(null);
            setIsActiveModule(false);
            if ((activeModuleIndex !== undefined) && (ModuleType.TEXT === activeModule?.documentModuleType)) {
                renderRef.current.triggerInit(activeModuleIndex);
            }
            setActiveModuleIndex(undefined);
        }

        // Set active module
        const handleSetActiveModule = (module: IDocumentModule | null, moduleIndex: number | undefined) => {
            // FIXME nedan funkar, men måste jag köra cloneDeep?
            const cloneOriginal = _.cloneDeep(module);
            const cloneModule = _.cloneDeep(module);
            setOriginalActiveModule(cloneOriginal);
            setActiveModule(cloneModule);
            setIsActiveModule(true);
            setActiveModuleIndex(moduleIndex);
        }

        const [showAnswerChecklistModal, setShowAnswerChecklistModal] = useState(false);
        const [checklistToAnswer, setChecklistToAnswer] = useState<IDocumentModule | undefined>(undefined);
        const [isAnswerMode, setAnswerMode] = useState<boolean>(false);

        const handleSetChecklistToAnswer = (module: IDocumentModule) => {
            setDisableQueries(true);
            setChecklistToAnswer(module);
            setShowAnswerChecklistModal(true);
            setAnswerMode(true);
            setActiveModule(module);
        }

        const handleCloseAnswerChecklist = async () => {
            setDisableQueries(false);
            setShowAnswerChecklistModal(false);
            setChecklistToAnswer(undefined);
            setAnswerMode(false);
            await clearActiveModule()
        }

        // Iterate all forms in the active module and compare to the same form in the originalActiveModule to find if there's been any changes.
        const checkEditedForms = (forms: IDocumentModuleChecklistForm[] | undefined, originalForms: IDocumentModuleChecklistForm[] | undefined): void => {
            if (!forms || !originalForms) return;
            forms.forEach(form => {
                form.isEdited = false;
                originalForms.forEach(originalForm => {
                    if (form.id === originalForm.id) {
                        form.isEdited = JSON.stringify(form) !== JSON.stringify(originalForm);
                    }
                });
            });
        };

        // Update module
        const [updateModule] = useUpdateModule(docId);
        const handleUpdateModule = async () => {
            if (!activeModule) return;

            const active = _.cloneDeep(activeModule);

            if (active.documentModuleType === ModuleType.TEXT) {
                active.documentModuleText!.content = htmlString;
            }

            if (active.documentModuleType === ModuleType.CHECKLIST) {
                const forms = active?.documentModuleChecklist?.documentModuleChecklistForms.map(v => {
                    const vForm = v as IDocumentModuleChecklistResponse;
                    if (vForm.isNew) {
                        delete vForm.id;
                    }
                    vForm.documentModuleChecklistFormDataRows.forEach(r => {
                        if (r.isNew) delete r.id;
                    })
                    return vForm;
                }) ?? [];

                if (active?.documentModuleChecklist == null) return;
                active.documentModuleChecklist.documentModuleChecklistForms = forms as any as IDocumentModuleChecklistForm[];
            }
            checkEditedForms(active.documentModuleChecklist?.documentModuleChecklistForms, originalActiveModule?.documentModuleChecklist?.documentModuleChecklistForms);
            await updateModule(active!).then(async (response) => {
                if (response.success) {
                    await clearActiveModule();
                }
            });
        }

        // Delete module
        const [deleteModule] = useDeleteModule(docId);
        const handleDeleteModule = async (moduleId: string) => {
            await deleteModule(moduleId).then(async (response) => {
                if (response.success) {
                    await clearActiveModule();
                }
            });
        }

        // Validate if changes
        const isNoChanges = () => {
            return JSON.stringify(activeModule) === JSON.stringify(originalActiveModule);
        }

        const handleCancelEditModule = async () => {
            prompt(async () => await clearActiveModule())
        }

        // Update active module with changes
        const updateActiveModule = (module: IDocumentModule) => {
            if (!activeModule) return;
            setActiveModule(module);
        }

        return <>
            <OverlayComponent
                showOverlay={isFetchingModules}
                showSpinner={isFetchingModules}
            >
                <DocumentModulesContext.Provider value={{
                    document: document,
                    isDocumentPreview: isDocumentPreview,
                    previewDocumentId: previewDocumentId,
                    modules: modules,
                    originalActiveModule: originalActiveModule,
                    activeModule: activeModule,
                    setActiveModule: handleSetActiveModule,
                    isNoChangesActiveModule: isNoChanges(),
                    updateActiveModule: updateActiveModule,
                    setActiveModuleIndex: setActiveModuleIndex,
                    cancelEditModule: handleCancelEditModule,
                    deleteModule: handleDeleteModule,
                    saveChanges: handleUpdateModule,
                    isEditMode: isEditMode,
                    refetchModules: refetchModules,
                    handleSetChecklistToAnswer: handleSetChecklistToAnswer,
                    handleCloseAnswerChecklist: handleCloseAnswerChecklist,
                    isAnswerMode: isAnswerMode,
                    getChecklistView: checklistView,
                    updateChecklistView: setChecklistView,
                    isDocumentArchived: isDocumentArchived,
                    isSaveDisabled,
                    setSaveState: setIsSaveDisabled,
                    isDocumentClosed: document?.publishStatus === PublishStatusType.CLOSED,
                    addSaveFunction: (moduleId, sFunc) => {
                        setSaveFunctions(saveFunctions.set(moduleId, sFunc));
                    },
                    setDocumentModuleTextHtmlString: setHtmlString,

                }}>
                    <RenderDocumentModules ref={renderRef}/>

                    <When condition={isEditMode && !activeModule}>
                        <AddDocumentModulesContainer documentId={docId}/>
                    </When>

                    <When condition={isAnswerMode}>
                        <AnswerChecklistContainer
                            showAnswerChecklistModal={showAnswerChecklistModal}
                            closeAnswerChecklist={handleCloseAnswerChecklist}
                        >
                            <RenderDocumentModules ref={renderRef}/>
                        </AnswerChecklistContainer>

                    </When>
                </DocumentModulesContext.Provider>
            </OverlayComponent>
        </>
    }
;

export default DocumentModules;
