import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { api } from "../api/api";
import { HTTP_STATUS } from "../core/enums/enums";
import { toastError } from "../core/helpers/toastHelper";
import { ICustomQuery, ICustomQueryKeys, IResponse } from "../core/interfaces/common";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";


export const handleQueryKeys = (queryKeys: ICustomQueryKeys) => {
    let result = [queryKeys.root];
    if (queryKeys.cId) {
        result.push(queryKeys.cId);
    }
    if (queryKeys.extraKeys) {
        result = result.concat(queryKeys.extraKeys);
    }
    return result;
}

export const useGet = <T>(query: ICustomQuery) => {
    const {t} = useTranslation();
    const defaultStaleTime = useQueryClient().getDefaultOptions().queries?.staleTime;
    let queryKeys = [query.queryKeys?.root];
    if (query.queryKeys?.cId) {
        queryKeys.push(query.queryKeys.cId);
    }
    if (query.queryKeys?.extraKeys) {
        queryKeys = queryKeys.concat(query.queryKeys.extraKeys);
    }

    const staleTime = query.options?.staleTime ?? defaultStaleTime ?? 60000;
    const config = query.axiosRequestConfig;

    return useQuery(queryKeys, () => api.get<IResponse<T>>(query.url, config ?? undefined), {
            enabled: query.options?.enabled ?? true,
            staleTime: staleTime,
            refetchInterval: query.options?.refetchInterval
        }
    );
};

const handlePost = <T>(url: string) => {
    return (data: T) => api.post<IResponse<T>>(url, data).then((response) => {
        if (response.status !== HTTP_STATUS.CREATED_201) {
            return {
                data: {
                    success: false,
                    status: response.status,
                    errorCodes: response.data.errorCodes
                }
            } as IResponse<T>;
        } else {
            return {
                data: response.data,
                success: true,
                status: response.status
            } as IResponse<T>;
        }
    });
}

const handleDelete = <T>(url: string) => {
    return (id?: T) => api.delete<IResponse<T>>(url + (id ? `/${id}` : '')).then((response) => {
        if (response.status !== HTTP_STATUS.ACCEPTED_202) {
            return {
                data: {
                    success: false,
                    status: response.status,
                    errorCodes: response.data.errorCodes
                }
            } as IResponse<T>;
        } else {
            return {
                data: response.data,
                success: true,
                status: response.status
            } as IResponse<T>;
        }
    });
}

const handlePut = <T>(url: string) => {
    return (data: T) => api.put<IResponse<T>>(url, data).then((response) => {
        if (response.status !== HTTP_STATUS.OK_200 && response.status !== HTTP_STATUS.NO_CONTENT_204) {
            return {
                data: {
                    success: false,
                    status: response.status,
                    errorCodes: response.data.errorCodes,
                    errorResponse: response.data,
                }
            } as IResponse<T>;
        } else {
            return {
                data: response.data,
                success: true,
                status: response.status
            } as IResponse<T>;
        }
    });
}

export const usePost = <T>(
    url: string,
    queryKeys: ICustomQueryKeys,
    showGenericErrorToast?: boolean
) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const keys = handleQueryKeys(queryKeys);

    return useMutation<IResponse<T>, AxiosError<T>, T>(handlePost<T>(url), {
        onMutate: async () => {
            await queryClient.cancelQueries(keys);
        },
        onError: async (err, _, context) => {
            if (showGenericErrorToast) {
                dispatch(addToast(toastError(t('useCustomQuery.ERROR_OCCURRED_1'), t('useCustomQuery.UNEXPECTED_ERROR_1'))));
            }
            return await queryClient.setQueryData(keys, context);
        },
        onSettled: async (response) => {
            if (!response) return {data: {} as T, success: false};

            if (!response.success && showGenericErrorToast) {
                dispatch(addToast(toastError(t('useCustomQuery.ERROR_OCCURRED_2'), t('useCustomQuery.UNEXPECTED_ERROR_OCCURRED_1'))));
            }
            await queryClient.invalidateQueries(keys);
            return response;
        }
    });
};

export const usePut = <T>(
    url: string,
    queryKeys: ICustomQueryKeys,
    showGenericErrorToast?: boolean
) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const keys = handleQueryKeys(queryKeys);

    return useMutation<IResponse<T>, AxiosError<T>, T>(handlePut<T>(url), {
        onMutate: async () => {
            await queryClient.cancelQueries(keys);
        },
        onError: async (err, _, context) => {
            if (showGenericErrorToast) {
                dispatch(addToast(toastError(t('useCustomQuery.ERROR_OCCURRED_3'), t('useCustomQuery.UNEXPECTED_ERROR_OCCURRED_2'))));
            }
            return await queryClient.setQueryData(keys, context);
        },
        onSettled: async (response) => {
            if (!response) return {data: {} as T, success: false};

            if (!response.success && showGenericErrorToast) {
                dispatch(addToast(toastError(t('useCustomQuery.ERROR_OCCURRED_4'), t('useCustomQuery.UNEXPECTED_ERROR_2'))));
            }
            await queryClient.invalidateQueries(keys);
            return response;
        }
    });
};

export const useDelete = <T>(
    url: string,
    queryKeys: ICustomQueryKeys,
    showGenericErrorToast?: boolean
) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const keys = handleQueryKeys(queryKeys);

    return useMutation<IResponse<T>, AxiosError<T>, T>(handleDelete<T>(url), {
        onMutate: async () => {
            await queryClient.cancelQueries(keys);
        },
        onError: async (err, _, context) => {
            if (showGenericErrorToast) {
                dispatch(addToast(toastError(t('useCustomQuery.ERROR_OCCURRED_5'), t('useCustomQuery.UNEXPECTED_ERROR_OCCURRED_3'))));
            }
            return await queryClient.setQueryData(keys, context);
        },
        onSettled: async (response) => {
            if (!response) return {data: {} as T, success: false};

            if (!response.success && showGenericErrorToast) {
                dispatch(addToast(toastError(t('useCustomQuery.ERROR_OCCURRED_6'), t('useCustomQuery.UNEXPECTED_ERROR_3'))));
            }
            await queryClient.invalidateQueries(keys);
            return response;
        }
    });
}
