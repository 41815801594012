import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import LoadingSpinner from "../../components/LoadingSpinner";
import When from "../../components/When";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { login } from "../../store/slices/authSlice";
import { STATUS_IDLE, STATUS_UNAUTHORIZED } from "../../store/types";
import LoginForm from "./LoginForm";
import styles from './login.module.scss';

const Login: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const from = (location.state?.from?.pathname || "/");
    const lState = location.state;
    const status = useAppSelector((state) => state.user.status);
    const isAuthenticated = useAppSelector((state) => state.user.authenticated);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated && location.pathname === "/login") {
            navigate(location.state?.from ?? "/");
        }
    }, [isAuthenticated, location, navigate]);

    const submit = async (formData: IUserLoginRequest) => {
        await dispatch(login(formData));
        if (status === STATUS_IDLE) {
            navigate(from);
        }
    };

    useEffect(() => {
        queryClient.clear();
    }, [queryClient])

    if (localStorage.token) return <MainContainer><LoadingSpinner/></MainContainer>

    return <MainContainer className="login">
        <ContentContainer className={styles.contentWidth}>
            <Row className="pt-2 pb-3">
                <Col>
                    <h4 className="text-center">CC ICON</h4>
                </Col>
            </Row>
            <When condition={status === STATUS_UNAUTHORIZED || lState?.fromForgot || lState?.fromConfirmed}>
                <Row>
                    <Col lg={{offset: 3, span: 8}} xxl={{offset: 4, span: 6}}>
                        <div style={{minHeight: "60px"}}>
                            {lState?.fromExpiredToken &&
                                <Alert data-test-id="signed_out" variant="danger">{t('Login.MESSAGE_1')}</Alert>}
                            {status === STATUS_UNAUTHORIZED &&
                                <Alert data-test-id="bad_credentials" variant="danger">{t('Login.MESSAGE_2')}</Alert>}
                            <When condition={!!lState?.fromForgot}>
                                <Alert data-test-id="forgot_password" variant="primary">{t('Login.MESSAGE_3')}</Alert>
                            </When>
                            <When condition={!!lState?.fromConfirmed}>
                                <Alert data-test-id="forgot_password" variant="success">{t('Login.MESSAGE_4')}</Alert>
                            </When>
                        </div>
                    </Col>
                </Row>
            </When>
            <Row>
                <LoginForm onSubmit={submit}/>
            </Row>
        </ContentContainer>
    </MainContainer>
}

export default Login;