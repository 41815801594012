import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from '@lexical/selection';
import { $getSelection, $isRangeSelection } from "lexical";
import styles from "../ToolbarDropdown.module.scss";

interface IFontSizeButton {
    fontSize: string;
    text: string;
    onSelect: () => void;
}

const FontSizeButton = (
    {
        fontSize,
        text,
        onSelect,
    }: IFontSizeButton) => {
    const [editor] = useLexicalComposerContext();

    const setSelected = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $patchStyleText(selection, {
                    'font-size': fontSize,
                });
            }
        });
        onSelect();
    }

    return <button
        className={styles.toolbarDropdownItem}
        onClick={setSelected}
        aria-label={text}
    >
        <span className={"ps-2"}>{text}</span>
    </button>
};

export default FontSizeButton;