import _ from 'lodash';
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/esm/Form";
import { useTranslation } from "react-i18next";
import { useCreateActionPlanFromIncident, useCreateActionPlanFromStandalone } from "../../../../api/ActionPlanApi";
import FormContainer from "../../../../components/FormContainer/FormContainer";
import SpinnerButton from "../../../../components/SpinnerButton";
import { ActionPlanType, DataRowSubType, IncidentExternalPersonExists } from "../../../../core/enums/enums";
import {
    DataRowType,
    DeviationDataRows,
    IncidentDataRows,
    Internal_Ids,
    NewTextField,
    StandaloneActionDataRows
} from "../../../../core/helpers/dataRowHelper";
import { IDataRow, IDataRowMultiChoiceField, IDataRowTextField, } from "../../../../core/interfaces/document";
import ActionPlanDescription from "../../../ActionPlan/Components/ActionPlanDescription";
import GeneralizedDataRow from "../../../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedDataRow";
import GeneralizedFormHeader from "../../../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedFormHeader";

interface INewIncidentModal {
    initialActionPlanType: ActionPlanType;
    show: boolean;
    setShow: () => void;
}

const NewIncidentModal = (props: INewIncidentModal) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState("");
    const [validated, setValidate] = useState(false);
    const [type, setType] = useState<ActionPlanType>(props.initialActionPlanType);
    const [rows, setRows] = useState(_.cloneDeep(IncidentDataRows));
    const [createFromIncident, isCreatingFromIncident] = useCreateActionPlanFromIncident();
    const [createFromStandalone, isCreatingFromStandalone] = useCreateActionPlanFromStandalone();
    const [description, setDescription] = useState<IDataRow>(NewTextField(DataRowSubType.INCIDENT_HEAD_DESCRIPTION));

    const getInitialConfig = (actionPlanType: ActionPlanType) => {
        switch (actionPlanType) {
            case ActionPlanType.INCIDENT:
                setTitle(t('NewIncidentModal.REPORT_INCIDENTS_1'));
                setRows(_.cloneDeep(IncidentDataRows));
                break;
            case ActionPlanType.ACCIDENT:
                setTitle(t('NewIncidentModal.REPORT_ACCIDENT_1'));
                setRows(_.cloneDeep(IncidentDataRows));
                break;
            case ActionPlanType.DEVIATION:
                setTitle(t('NewIncidentModal.REPORT_DEVIATION_1'));
                setRows(_.cloneDeep(DeviationDataRows));
                break;
            case ActionPlanType.STANDALONE:
                setTitle(t('NewIncidentModal.ACTION_1'));
                setRows(_.cloneDeep(StandaloneActionDataRows));
                break;
            default:
                setTitle(t('NewIncidentModal.REPORT_INCIDENTS_2'));
                setRows(_.cloneDeep(IncidentDataRows));
                break;
        }
    }

    const getRows = (actionPlanType: ActionPlanType) => {
        switch (actionPlanType) {
            case ActionPlanType.INCIDENT:
                return _.cloneDeep(IncidentDataRows);
            case ActionPlanType.ACCIDENT:
                return _.cloneDeep(IncidentDataRows);
            case ActionPlanType.DEVIATION:
                return _.cloneDeep(DeviationDataRows);
            case ActionPlanType.STANDALONE:
                return _.cloneDeep(StandaloneActionDataRows);
            default:
                return _.cloneDeep(IncidentDataRows);
        }
    }

    useEffect(() => {
        setValidate(false);
        getInitialConfig(props.initialActionPlanType);
        setDescription(NewTextField(DataRowSubType.INCIDENT_HEAD_DESCRIPTION));
    }, [props.show]);

    const handleUpdateDataRow = (dataRow: IDataRow) => {
        if (dataRow.checklistFormDataRowTypeSymbol === DataRowType.MULTI_CHOICE_FIELD && (dataRow as IDataRowMultiChoiceField).multiChoiceValue == null)
            return;
        let rowRef = rows;
        if (dataRow.subType === DataRowSubType.INCIDENT_TYPE) {
            const multiChoiceValue = ((dataRow) as IDataRowMultiChoiceField).multiChoiceValue;
            if (multiChoiceValue == null)
                return;
            const actionPlanType = multiChoiceValue as ActionPlanType;
            getInitialConfig(actionPlanType);
            rowRef = getRows(actionPlanType);
            setType(actionPlanType);
            setValidate(false);
        }

        if (dataRow.id === Internal_Ids.EXTERNAL_USER_TOGGLE) {
            rowRef = rowRef.map(v => {
                if (v.id === Internal_Ids.EXTERNAL_USER_TEXT) {
                    v.isClosed = (dataRow as IDataRowMultiChoiceField).multiChoiceValue !== IncidentExternalPersonExists.YES;
                    (v as IDataRowTextField).textValue = "";
                }
                return v;
            })
        }

        setRows(rowRef.map((row) => (row.subType === dataRow.subType) ? dataRow : row));
    }

    const handleSubmit = async (e: any) => {
        setValidate(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        e.preventDefault();
        e.stopPropagation();

        const newRows = [...rows, description];

        if (ActionPlanType.STANDALONE === type) {
            createFromStandalone(newRows.map(v => {
                delete v.id
                return v;
            })).then((isCreated) => {
                if (isCreated) props.setShow();
            });
        } else {
            createFromIncident(newRows.map(v => {
                delete v.id;
                return v;
            }), type).then((isCreated) => {
                if (isCreated) props.setShow();
            });
        }
    }

    return <Modal show={props.show} size={"xl"} centered onHide={() => props.setShow()}>
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Modal.Body>

                <FormContainer>
                    <GeneralizedFormHeader
                        isExpanded={true}
                        setExpanded={() => NaN}
                        title={title}
                        description={" "}
                        isHeaderLocked={true}
                        showExpand={true} />
                    <ActionPlanDescription
                        key={description.id}
                        isNew={true}
                        isEditMode={true}
                        dataRow={description}
                        setDataRow={setDescription}
                    />
                    <GeneralizedDataRow
                        dataRows={rows}
                        handleUpdateDataRow={handleUpdateDataRow}
                        isActiveMode={!isCreatingFromIncident && !isCreatingFromStandalone && true}
                        isAnswerMode={!isCreatingFromIncident && !isCreatingFromStandalone && true}
                        isEditMode={false}
                        handleRemoveDataRow={() => NaN}
                        handleCopyDataRow={() => NaN}
                        requiredSubType={Object.values(DataRowSubType)}
                    />

                </FormContainer>


            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton onClick={() => props.setShow()} text={t('NewIncidentModal.CANCEL_1')} variant={"danger"}
                    isLoading={isCreatingFromIncident || isCreatingFromStandalone} />
                <SpinnerButton type={"submit"}
                    text={t('NewIncidentModal.SAVE_1')}
                    isLoading={isCreatingFromIncident || isCreatingFromStandalone}
                />
            </Modal.Footer>
        </Form>
    </Modal>
}

export default NewIncidentModal;
