import { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useCreateFolder } from "../../../api/DocumentDirectory";
import CustomTextInput from "../../../components/FormComponents/CustomTextInput";
import SpinnerButton from "../../../components/SpinnerButton";
import When from "../../../components/When";
import { handleOnEnter } from "../../../core/helpers/utils";
import { IFolderCreateRequest } from "../../../core/interfaces/document";

interface INewFolderModal {
    companyId: string;
    parentFolderId: string | null;
    closeModal: () => void;
    parentFolderName?: string;
}

const NewFolderModal = ({companyId, parentFolderId, closeModal, parentFolderName}: INewFolderModal) => {
    const {t} = useTranslation();
    const initialFolder: IFolderCreateRequest = {
        name: "",
        parentFolderId: parentFolderId
    };
    const [newFolder, setNewFolder] = useState<IFolderCreateRequest>(initialFolder);

    const [createFolder, isCreatingFolder] = useCreateFolder(companyId);
    const handleCreateFolder = async () => {
        if(disableCondition) return;
        await createFolder(newFolder).then((response) => {
            if (response.success) {
                closeModal();
            }
        });
    }

    const changeFolderName = (e: any) => {
        setNewFolder({
            ...newFolder,
            name: e.target.value,
        });
    }

    const disableCondition = (newFolder.name.length === 0 || isCreatingFolder)

    return (<>
        <Modal.Header closeButton>
            <Modal.Title>{t('NewFolderModal.NEW_FOLDER_1')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label className={'pb-2'}>{t('NewFolderModal.NAME_FOLDER_1')}</label>
            <CustomTextInput
                name="name"
                value={newFolder.name}
                onChange={changeFolderName}
                disableAutocomplete
                onKeyDown={(event) => handleOnEnter(event, handleCreateFolder)}
                autofocus={true}
            />
            <When condition={!!parentFolderName}>
                <p><i>{t('NewFolderModal.CREATED_IN_PARENT_FOLDER', {parentFolder: parentFolderName})} {(parentFolderName ?? t('NewFolderModal.IN_FOLDER', {folder: parentFolderName}))+'\\'}</i></p>                
            </When>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>{t('NewFolderModal.CANCEL_1')}</Button>
            <SpinnerButton
                text={t('NewFolderModal.OK_1')}
                isLoading={isCreatingFolder}
                disabled={disableCondition}
                onClick={handleCreateFolder}
            />
        </Modal.Footer>
    </>)
};

export default NewFolderModal;
