import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useUpdateUserSkill } from '../../../api/CompanyApi';
import FormContainer from '../../../components/FormContainer/FormContainer';
import SpinnerButton from '../../../components/SpinnerButton';
import { IUserSkill } from '../../../core/interfaces/company';
import { IDataRow } from '../../../core/interfaces/document';
import GeneralizedDataRow from '../../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedDataRow';
import GeneralizedFormHeader from '../../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedFormHeader';


interface IEditUserSkill {
    show: boolean;
    setShow: () => void;
    userSkill: IUserSkill;
}

const EditUserSkillModal = (props: IEditUserSkill) => {
    const { t } = useTranslation();

    const [validated, setValidate] = useState(false);
    const [formDataRows, setFormDataRows] = useState<IDataRow[]>([]);;

    const [update, isUpdating] = useUpdateUserSkill(props.userSkill?.id, props.userSkill?.companyUserId);

    useEffect(() => {
        setValidate(false);
        setFormDataRows(props.userSkill?.form.documentModuleChecklistFormDataRows ?? []);
    }, [props.show]);

    const handleUpdateDataRow = (dataRow: IDataRow) => {
        let rowRef = formDataRows;
        setFormDataRows(rowRef.map((row) => (row.id === dataRow.id) ? dataRow : row));
    }

    const handleSubmit = async (e: any) => {
        setValidate(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        e.preventDefault();
        e.stopPropagation();

        update(formDataRows.map(v => {
            //delete v.id;
            return v;
        })).then((isCreated) => {
            if (isCreated)
                props.setShow();
        });
    }

    return (
        <Modal
            show={props.show}
            size={"xl"}
            centered
            onHide={() => props.setShow()}>

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>
                    <FormContainer>

                        <GeneralizedFormHeader
                            isExpanded={false}
                            setExpanded={() => NaN}
                            title={t('EditUserSkillModal.EDIT_1')}
                            description={" "}
                            isHeaderLocked={true}
                            showExpand={false} />

                        <GeneralizedDataRow
                            dataRows={formDataRows}
                            handleUpdateDataRow={handleUpdateDataRow}
                            isActiveMode={!isUpdating}
                            isAnswerMode={!isUpdating}
                            isEditMode={false}
                            userSkillId={props.userSkill?.id}
                            companyUserId={props.userSkill?.companyUserId}
                            companyId={props.userSkill?.companyId}
                            handleRemoveDataRow={() => NaN}
                            handleCopyDataRow={() => NaN}
                        />

                    </FormContainer>
                </Modal.Body>
                <Modal.Footer>
                    <SpinnerButton
                        onClick={() => props.setShow()}
                        text={t('EditUserSkillModal.CANCEL_1')}
                        variant={"danger"}
                        isLoading={isUpdating} />
                    <SpinnerButton
                        type={"submit"}
                        text={t('EditUserSkillModal.SAVE_1')}
                        isLoading={isUpdating}
                    />
                </Modal.Footer>
            </Form>

        </Modal>
    );
};

export default EditUserSkillModal;