import {IIncident} from "../../../core/interfaces/incident";
import Row from "react-bootstrap/Row";
import {TypeForm} from "../Forms/TypeForm";
import {ReportedDateForm} from "../Forms/ReportedDateForm";
import {CreatedByForm} from "../Forms/CreatedByForm";
import {IncidentDateForm} from "../Forms/IncidentDateForm";
import {IncidentTimeForm} from "../Forms/IncidentTimeForm";
import {LocationForm} from "../Forms/LocationForm";
import {DescriptionForm} from "../Forms/DescriptionForm";
import {ActionUserForm} from "../Forms/ActionUserForm";
import {IPerson} from "../../../core/interfaces/common";
import {CauseOfEventForm} from "../Forms/CauseOfEventForm";

export function FirstSection(props: { editMode: boolean, isNewIncident: boolean, companyUsers: IPerson[], currentUserName: string, newIncident: IIncident, onChangeActionUser: (e:any) => void, onChange: (e: any) => void, onChangeDate: (e: any) => void, onChangeDateTime: (e: any) => void }) {
    return<Row className="pt-3">
        <TypeForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>

        <ReportedDateForm isNewIncident={props.isNewIncident} newIncident={props.newIncident} />

        <CreatedByForm currentUserName={props.currentUserName} newIncident={props.newIncident} isNewIncident={props.isNewIncident}/>

        <IncidentDateForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChangeDate}/>

        <IncidentTimeForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChangeDateTime}/>

        <LocationForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>

        <DescriptionForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>

        <ActionUserForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChangeActionUser} companyUsers={props.companyUsers}/>

        <CauseOfEventForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>
    </Row>;
}