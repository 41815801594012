import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useGetPositionLevels } from "../../../api/PositionLevelApi";
import CustomMultiSelect from "../../../components/FormComponents/CustomMultiSelect";
import When from "../../../components/When";
import { IDocument } from "../../../core/interfaces/document";
import { IPositionLevel } from "../../../core/interfaces/positionLevel";

interface IDocumentPositionLevelForm {
    isEditMode: boolean;
    document: IDocument;
    onChange: (positionLevels: IPositionLevel[] | undefined) => void;
}

const DocumentPositionLevelForm = (
    {
        isEditMode,
        document,
        onChange,
    }: IDocumentPositionLevelForm) => {

    const { t } = useTranslation();
    const [positionLevels] = useGetPositionLevels();

    const handleChange = (e: { value: string, label: string }[]) => {
        if (!e) {
            return onChange(undefined);
        }

        const documentPositionLevels = e.map(pl => {
            return { id: pl.value, name: pl.label };
        });

        onChange(documentPositionLevels);
    };

    return <Form.Group as={Row}>
        <Row>
            <Form.Label column className={"pt-1 pb-0"}><strong>{t('DocumentPositionLevelForm.POSITION_LEVELS_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <When condition={isEditMode}
                    then={
                        <CustomMultiSelect
                            isEditMode={isEditMode}
                            name={"positionLevels"}
                            selected={(positionLevels ?? []).filter(p => document.positionLevels?.some(pl => pl.id === p.id)).map(p => ({ value: p.id, label: p.name })) ?? []}
                            options={(positionLevels ?? []).map(p => ({ value: p.id, label: p.name })) ?? []}
                            onChange={handleChange}
                        />
                    }
                    otherwise={<span>{document.positionLevels?.length ? document.positionLevels.map(i => i.name).join(', ') : "-"}</span>} />
            </Col>
        </Row>
    </Form.Group>
};

export default DocumentPositionLevelForm;