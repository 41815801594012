import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { usePrivateTemplate, usePublishTemplate } from "../../../../api/TemplateApi";
import { Icon } from "../../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import { PublishStatusType } from "../../../../core/enums/enums";
import { toastInfo } from "../../../../core/helpers/toastHelper";
import { IDocument } from "../../../../core/interfaces/document";
import { useAppDispatch } from "../../../../store/hooks";
import { addToast } from "../../../../store/slices/uiSlice";

export const TogglePublishTemplate = (
    {
        document,
        isEditMode,
    }: { document: IDocument, isEditMode: boolean }) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [publishTemplate, isPublishingTemplate] = usePublishTemplate(document.id, document.name);
    const [isPublished, setPublished] = useState(PublishStatusType.APPROVED === document.publishStatus);

    useEffect(() => {
        setPublished(PublishStatusType.APPROVED === document.publishStatus);
    }, [document.publishStatus]);

    const handlePublishStatus = async () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('PublishTemplateHandle.LEAVE_EDITING_MODE_1'), t('PublishTemplateHandle.LEAVE_EDITING_MODE_2'))));
            return;
        }
        const request = {publish: !isPublished};
        await publishTemplate(request);
    }

    return <CustomDropdownItem
        icon={isPublished ? Icon.EYE_SLASH : Icon.EYE}
        text={isPublished ? t('PublishTemplateHandle.UNPUBLISH_TEMPLATE_1') : t('PublishTemplateHandle.PUBLISH_TEMPLATE_1')}
        disabled={isPublishingTemplate}
        onClick={handlePublishStatus}
    />
};

export const TogglePrivateTemplate = (
    {
        document,
        isEditMode,
    }: { document: IDocument, isEditMode: boolean }) => {
    
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const { cId } = useParams();
    const [privateTemplate, isLoading] = usePrivateTemplate(document.template?.id ?? "", document.id ?? "");
    const [isPrivate, setPrivate] = useState<boolean>(document.template?.private ?? true);

    useEffect(() => {
        if (document.template) {
            setPrivate(document.template.private);
        }
    }, [document.template]);

    const handleSetPrivate = async () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('PublishTemplateHandle.LEAVE_EDITING_MODE_3'), t('PublishTemplateHandle.LEAVE_EDITING_MODE_4'))));
            return;
        }
        const request = {private: !isPrivate};
        await privateTemplate(request);
    }

    return <CustomDropdownItem
        icon={isPrivate ? Icon.EYE : Icon.EYE_SLASH}
        text={isPrivate
            ? (cId
                ? t('PublishTemplateHandle.MAKE_AVAILABLE_FOR_SUBSIDAIRIES_1')
                : t('PublishTemplateHandle.MAKE_AVAILABLE_FOR_BUSINESSES_1'))
            : (cId
                ? t('PublishTemplateHandle.MAKE_AVAILABLE_FOR_CURRENT_COMPANY_1')
                : t('PublishTemplateHandle.MAKE_AVAILABLE_ONLY_FOR_SAMG_1'))
            }
        disabled={isLoading}
        onClick={handleSetPrivate}
    />
}
