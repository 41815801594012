import { useState } from 'react';
import { TableColumn } from "react-data-table-component";
import { useTranslation } from 'react-i18next';
import { useGetSkills } from '../../api/SkillApi';
import { Icon } from "../../assets/Icons/IconClassNames";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import PaginatedDataTableClientSide from "../../components/DataTableComponents/PaginatedDataTable";
import { ISkill, ISkillResponse } from '../../core/interfaces/skill';
import styles from "../Users/Users.module.scss";
import EditSkillModal from './EditSkillModal';
import NewSkillModal from "./NewSkillModal";
import RemoveSkillModal from './RemoveSkillModal';

const SkillTable = () => {
    const { t } = useTranslation();
    const [openNewSkillModal, setOpenNewSkillModal] = useState(false);
    const [data] = useGetSkills();
    const [showEditSkillModal, setShowEditSkillModal] = useState(false);
    const [showRemoveSkillModal, setShowRemoveSkillModal] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState<ISkillResponse | null>(null);

    const columns: TableColumn<ISkillResponse>[] = [
        {
            name: t('SkillTable.SKILL_1'),
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: <span className={styles.clickable} onClick={() => setOpenNewSkillModal(true)}> + {t('SkillTable.ADD_SKILL_1')}</span>,
            right: true,
            cell: row => (
                <CustomDropdown toggleType={DropdownToggleType.GEAR}>
                    <CustomDropdownItem
                        icon={Icon.EDIT}
                        text={t('SkillTable.EDIT_1')}
                        onClick={() => {
                            setSelectedSkill(row)
                            setShowEditSkillModal(true)
                        }}
                    />
                    <CustomDropdownItem
                        icon={Icon.REMOVE}
                        text={t('SkillTable.DELETE_SKILL_1')}
                        onClick={() => {
                            setSelectedSkill(row)
                            setShowRemoveSkillModal(true)
                        }}
                    />
                </CustomDropdown>
            )
        }
    ];

    return (
        <ContentContainer>
            <PaginatedDataTableClientSide
                data={data ?? []}
                progressPending={false}
                columns={columns}
            />

            <NewSkillModal show={openNewSkillModal} hide={() => setOpenNewSkillModal(false)} />
            <EditSkillModal show={showEditSkillModal} hide={() => setShowEditSkillModal(false)} skill={selectedSkill as ISkill} />
            <RemoveSkillModal show={showRemoveSkillModal} hide={() => setShowRemoveSkillModal(false)} skill={selectedSkill} />
        </ContentContainer>
    );
};

export default SkillTable;