import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import CustomDate from "../../../../../../components/FormComponents/CustomDate";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { RegexValidateDate, UTCStringToLocaleString } from "../../../../../../core/helpers/dates/datesHelper";
import { IDataRowProps, IDateDataRow } from "../../../../../../core/interfaces/document";
import DataRowActionButtons from "./DataRowActionButtons";


const DateDataRow = (
    {
        dataRow,
        setDataRow,
        isFieldEnabled,
        isHeaderEnabled,
        removeDataRow,
        copyDataRow,
        isEditMode,
        isActive,
        isAnswerMode = false,
        checklistViewTable,
        requiredSubType
    }: IDataRowProps<IDateDataRow>) => {

    const {t} = useTranslation();
    const [internalDate, setInternalDate] = useState<string | null | undefined>(null);

    useEffect(() => {
        if (dataRow.date) {
            if (RegexValidateDate(dataRow.date)) {
                setInternalDate(UTCStringToLocaleString(dataRow.date))
            }
        }
    }, [dataRow.date]);

    const handleChangeDate = (e: any) => {

        const localInputDate = e.target.value;

        if (localInputDate === "") {
            setDataRow({
                ...dataRow,
                [e.target.name]: null
            });
            return;
        }

        if (!localInputDate || localInputDate.length < 10 || localInputDate.length > 15) return;

        const testDate = new Date(localInputDate);
        if (isNaN(testDate.getTime())) return;

        let time = "";
        if (internalDate && internalDate.length > 10) {
            time = internalDate.substring(10);
        } else {
            time = " 00:00:00";
        }

        const aggDate = localInputDate + time;

        const localDate = new Date(aggDate);
        setInternalDate(aggDate);

        setDataRow({
            ...dataRow,
            [e.target.name]: localDate
        });
    }

    const handleChangeDateTime = (e: any) => {
        const localInputTime: string = e.target.value;
        if (!localInputTime || localInputTime.length < 5) return;

        let date = "";
        if (internalDate && internalDate.length >= 10) {
            date = internalDate.substring(0, 10);
        } else {
            const now = new Date();
            const nowUtcString = now.toISOString();
            date = UTCStringToLocaleString(nowUtcString).slice(0, 10);
        }

        const aggDate = date + " " + localInputTime;

        const localDate = new Date(aggDate);
        setInternalDate(aggDate);

        setDataRow({
            ...dataRow,
            [e.target.name]: localDate
        });
    }


    return <>
        <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
            <When condition={!checklistViewTable}>
                <Col md={3}>
                    <CustomTextInput
                        isEditMode={isEditMode}
                        disabled={!isHeaderEnabled}
                        name={"header"}
                        placeholder={t('DateDataRow.EXAMPLE_DATE_1')}
                        value={getDataRowHeader(dataRow)}
                        onChange={(e) => setDataRow({
                            ...dataRow,
                            [e.target.name]: e.target.value
                        })}
                        disableAutocomplete
                    />
                </Col>
            </When>
            <Col md={checklistViewTable ? 12 : 7}>
                <Row>
                    <Col md={12}>
                        <CustomDate
                            checklistViewTable={checklistViewTable}
                            required={requiredSubType.includes(dataRow.subType!)}
                            disabled={!isFieldEnabled || !(isEditMode || isAnswerMode)}
                            isEditMode={true}
                            type={'date'}
                            name={'date'}
                            value={dataRow.date ?? ""}
                            onChange={handleChangeDate}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col md={12}>
                        <When condition={!dataRow.dateOnly}>
                            <CustomDate
                                checklistViewTable={checklistViewTable}
                                required={requiredSubType.includes(dataRow.subType!)}
                                disabled={!isFieldEnabled || !(isEditMode || isAnswerMode)}
                                isEditMode={true}
                                type={'time'}
                                name={'date'}
                                value={dataRow.date ?? ""}
                                onChange={handleChangeDateTime}
                            />
                        </When>
                    </Col>
                </Row>
            </Col>
            <When condition={isEditMode && isActive}>
                <DataRowActionButtons
                    dataRow={dataRow}
                    removeDataRow={removeDataRow}
                    copyDataRow={copyDataRow}
                />
            </When>
        </Row>
    </>
};


export default DateDataRow;
