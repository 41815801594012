import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { DataRowSubType, MultiChoiceAlternativeClassified, MultiChoiceDisplayType } from "../../../../../../core/enums/enums";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowMultiChoiceField, IDataRowProps } from "../../../../../../core/interfaces/document";
import DataRowActionButtons from "./DataRowActionButtons";
import styles from "./dataRowRadioButtonGroup.module.scss";

const MultiChoiceDataRow = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        isFieldEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isAnswerMode = false,
        isActive,
        requiredSubType,
    }: IDataRowProps<IDataRowMultiChoiceField>) => {

    const { t } = useTranslation();
    const [valueColor, setValueColor] = useState<string>("");

    const colorGreen = "rgba(0, 255, 0, 0.25)";
    const colorRed = "rgba(255, 0, 0, 0.25)";
    const colorNeutral = "rgba(255,255,0, 0.25)";
    const colorTransparent = "";
    const onChangeHeader = (e: any) => {
        setDataRow({
            ...dataRow,
            [e.target.name]: e.target.value
        });
    }

    const onSelect = (e: any) => {
        const newDataRow = { ...dataRow };
        if (e.target.value === "" || e.target.value === newDataRow.multiChoiceValue) {
            newDataRow.multiChoiceValue = null;
            newDataRow.multiChoiceValueClassified = null;
            setDataRow(newDataRow);
            return;
        }

        newDataRow.multiChoiceValue = e.target.value;
        const option = newDataRow.multiChoiceAlternatives?.find((option) => option.name === e.target.value);
        if (!option) {
            console.error(t('MultiChoiceDataRow.COULD_NOT_FIND_ALTERNATIVE_WITH_NAME_1'), e.target.value);
            return;
        }
        newDataRow.multiChoiceValueClassified = option.classifiedAs;
        setDataRow(newDataRow);
    }

    useEffect(() => {
        if (dataRow.multiChoiceValue == null && requiredSubType.includes(dataRow.subType!)) {
            const newDataRow = { ...dataRow };
            const firstOption = newDataRow.multiChoiceAlternatives?.at(0);
            if (firstOption == null) return;
            newDataRow.multiChoiceValue = firstOption.name;
            newDataRow.multiChoiceValueClassified = firstOption.classifiedAs;
            setDataRow(newDataRow)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredSubType])

    useEffect(() => {
        if (!dataRow.multiChoiceValueClassified) return setValueColor("");
        if (MultiChoiceAlternativeClassified.POSITIVE === dataRow.multiChoiceValueClassified) return setValueColor(colorGreen);
        if (MultiChoiceAlternativeClassified.NEGATIVE === dataRow.multiChoiceValueClassified) return setValueColor(colorRed);
        if (MultiChoiceAlternativeClassified.NEUTRAL === dataRow.multiChoiceValueClassified) return setValueColor(colorNeutral);
        if (MultiChoiceAlternativeClassified.TRANSPARENT == dataRow.multiChoiceValueClassified) return setValueColor(colorTransparent);
    }, [dataRow.multiChoiceValueClassified]);

    const getLabel = (label: string) => {
        if (dataRow.subType && dataRow.subType !== DataRowSubType.NONE)
            return t(`${dataRow.subType}.${label}`);
        else if (label.indexOf('.') > 0)
            return t(label);
        else
            return label;
    }

    const renderSelect = () => {
        return <Col md={
            isEditMode && isActive
                ? checklistViewTable ? 12 : 7
                : checklistViewTable ? 12 : 9
        }>
            <CustomSelect
                checklistViewTable={checklistViewTable}
                style={{ backgroundColor: valueColor }}
                disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                isEditMode={true}
                name={"multiChoiceValue"}
                value={dataRow.multiChoiceValue ?? undefined}
                onChange={onSelect}
                showInputFieldInReadOnly
                defaultValue={undefined}
                options={<>
                    <option value={""}>{t('MultiChoiceDataRow.SELECT_AN_OPTION_1')}</option>
                    {dataRow.multiChoiceAlternatives?.map((alternative) => (
                        <option key={dataRow.id + "_" + alternative.name} value={alternative.name}>{getLabel(alternative.name)}</option>
                    ))}
                </>}
            />
        </Col>
    };

    const renderRadio = () => {
        return <Col md={
            isEditMode && isActive
                ? checklistViewTable ? 12 : 7
                : checklistViewTable ? 12 : 9
        }>
            <div className={styles.radioButtonGroup} style={{ flexWrap: checklistViewTable ? "wrap" : undefined }}>
                {dataRow.multiChoiceAlternatives?.map((alternative) => (
                    <label
                        key={dataRow.id + "_" + alternative.name}
                        className={`${styles.radioButton} ${isFieldEnabled ? "" : styles.disabled}`}
                        style={{ backgroundColor: dataRow.multiChoiceValue === alternative.name ? valueColor : "" }}
                    >
                        <input
                            disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                            type="radio"
                            value={alternative.name}
                            checked={dataRow.multiChoiceValue === alternative.name}
                            onClick={onSelect}
                            onChange={() => NaN}
                        />

                        {getLabel(alternative.name)}
                    </label>
                ))}
            </div>
        </Col>
    }

    return <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
        <When condition={!checklistViewTable}>
            <Col md={3}>
                <CustomTextInput
                    disabled={!isHeaderEnabled}
                    isEditMode={isEditMode}
                    name={"header"}
                    placeholder={t('MultiChoiceDataRow.TITLE_1')}
                    value={getDataRowHeader(dataRow)}
                    onChange={onChangeHeader}
                    disableAutocomplete
                />
            </Col>
        </When>
        <When condition={MultiChoiceDisplayType.DROPDOWN === dataRow.multiChoiceDisplayType}
            then={renderSelect()}
            otherwise={renderRadio()} />

        <When condition={isEditMode && isActive}>
            <DataRowActionButtons
                dataRow={dataRow}
                removeDataRow={removeDataRow}
                copyDataRow={copyDataRow}
            />
        </When>
    </Row>
};

export default MultiChoiceDataRow;
