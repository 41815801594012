import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsersExtended, useUserSkills } from "../../../api/CompanyApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import CustomDropdown, { DropdownToggleType } from "../../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import PaginatedDataTableClientSide from "../../../components/DataTableComponents/PaginatedDataTable";
import When from "../../../components/When";
import { UserSkillCourseCompleted, UserSkillLevel } from "../../../core/enums/enums";
import { BetweenDates, DateToLocale, DateToYYYYMMDD } from "../../../core/helpers/dates/datesHelper";
import { IUserSkill } from "../../../core/interfaces/company";
import { skillCourseCompletedMap, skillLevelMap } from "../util";
import EditUserSkillModal from "./EditUserSkillModal";
import NewUserSkillModal from "./NewUserSkillModal";
import RemoveUserSkillModal from "./RemoveUserSkillModal";
import styles from './UserSkillTable.module.scss';

const UserSkillTable = ({ companyId, companyUserId }: { companyId: string, companyUserId?: string }) => {

    const { t } = useTranslation();
    const [userSkills, isFetching] = useUserSkills(companyId, companyUserId);
    const [filterText, setFilterText] = useState("");

    const [openNewUserSkillModal, setOpenNewUserSkillModal] = useState(false);
    const [showEditUserSkillModal, setShowEditUserSkillModal] = useState(false);
    const [showRemoveUserSkillModal, setShowRemoveUserSkillModal] = useState(false);
    const [selectedUserSkill, setSelectedUserSkill] = useState<IUserSkill | null>(null);
    const [users, loading] = useGetCompanyUsersExtended();

    const hide = () => {
        setSelectedUserSkill(null);
        setOpenNewUserSkillModal(false);
        setShowEditUserSkillModal(false);
        setShowRemoveUserSkillModal(false);
    }

    const renderCourseCompleted = (courseCompleted: string) => {
        return <span style={{ color: courseCompleted === 'SKILL_COURSE_COMPLETED.YES' ? 'green' : courseCompleted === 'SKILL_COURSE_COMPLETED.NO' ? 'red' : 'unset' }}>{t(courseCompleted)}</span>;
    }

    const renderSkillLevel = (skillLevel: string) => {
        return <span style={{ color: ['SKILL_LEVEL.LEVEL_1', 'SKILL_LEVEL.LEVEL_2'].includes(skillLevel) ? 'red' : ['SKILL_LEVEL.LEVEL_4', 'SKILL_LEVEL.LEVEL_5'].includes(skillLevel) ? 'green' : 'unset' }}>{t(skillLevel)}</span>;
    }

    const createColumns = (): TableColumn<IUserSkill>[] => {
        const columns: TableColumn<IUserSkill>[] = [
            {
                name: t('UserSkillTable.SKILL_1'),
                selector: row => `${row.skillName}`,
                sortable: true,
            },
            {
                name: t('UserSkillTable.COURSE_COMPLETED_1'),
                selector: row => renderCourseCompleted(skillCourseCompletedMap[row.courseCompleted as UserSkillCourseCompleted]),
                sortable: true,
            },
            {
                name: t('UserSkillTable.COURSE_COMPLETED_DATE_1'),
                selector: row => DateToYYYYMMDD(DateToLocale(row.courseCompletedDate)) ?? Date.now(),
                sortable: true,
            },
            {
                name: t('UserSkillTable.COURSE_VALID_DATE_1'),
                selector: row => DateToYYYYMMDD(DateToLocale(row.courseValidDate)) ?? Date.now(),
                sortable: true,
            },
            {
                name: t('UserSkillTable.SKILL_LEVEL_1'),
                selector: row => renderSkillLevel(skillLevelMap[row.skillLevel as UserSkillLevel]),
                sortable: true,
            },
            {
                name: t('UserSkillTable.COURSE_FILES_1'),
                selector: row => row.form?.documentModuleChecklistFormDataRows?.flatMap((r: any) => r.files)?.map((f: any) => f.name).join(', '),
                sortable: true,
            },
            {
                name: <span className={styles.clickable} onClick={() => setOpenNewUserSkillModal(true)}> + {t('UserSkillTable.ADD_SKILL_1')}</span>,
                right: true,
                cell: row => (
                    <CustomDropdown toggleType={DropdownToggleType.GEAR}>
                        <CustomDropdownItem
                            icon={Icon.EDIT}
                            text={t('UserSkillTable.EDIT_1')}
                            onClick={() => {
                                setSelectedUserSkill(row)
                                setShowEditUserSkillModal(true)
                            }}
                        />
                        <CustomDropdownItem
                            icon={Icon.REMOVE}
                            text={t('UserSkillTable.DELETE_1')}
                            onClick={() => {
                                setSelectedUserSkill(row)
                                setShowRemoveUserSkillModal(true)
                            }}
                        />
                    </CustomDropdown>
                )
            }
        ];

        if (!companyUserId) {
            columns.unshift({
                name: t('UserSkillTable.NAME_1'),
                selector: row => `${row.companyUserName}`,
                sortable: true,
            });
        }

        return columns;
    }

    const columns = createColumns();

    const filteredItems = userSkills.filter(
        item => (
            (item.companyUserName && item.companyUserName.trim().toLowerCase().includes(filterText.trim().toLowerCase())) ||
            (item.skillName && item.skillName.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.courseCompleted && t(skillCourseCompletedMap[item.courseCompleted as UserSkillCourseCompleted]).toLowerCase().includes(filterText.toLowerCase())) ||
            (item.skillLevel && t(skillLevelMap[item.skillLevel as UserSkillLevel]).toLowerCase().includes(filterText.toLowerCase())) ||
            (item.courseCompletedDate && item.courseValidDate && BetweenDates(filterText, item.courseCompletedDate, item.courseValidDate)) ||
            (item.form?.documentModuleChecklistFormDataRows?.flatMap((r: any) => r.files || []).map((f: any) => (f.name || '').trim().toLowerCase()).findIndex((e: any) => e.includes(filterText.toLowerCase())) >= 0)
        )
    );

    return (
        <>
            <When condition={!isFetching}>
                <PaginatedDataTableClientSide
                    data={filteredItems ?? []}
                    progressPending={false}
                    columns={columns}
                    withFilter
                    filterText={filterText}
                    setFilterText={(prev) => setFilterText(prev)}
                    onRowClick={v => { setSelectedUserSkill(v); setShowEditUserSkillModal(true) }}
                />
            </When>

            <NewUserSkillModal show={openNewUserSkillModal} setShow={() => { hide(); setOpenNewUserSkillModal(false) }} companyUserId={companyUserId} users={users} />
            <EditUserSkillModal show={showEditUserSkillModal} setShow={() => { hide(); setShowEditUserSkillModal(false) }} userSkill={selectedUserSkill as IUserSkill} />
            <RemoveUserSkillModal show={showRemoveUserSkillModal} hide={() => { hide(); setShowRemoveUserSkillModal(false) }} userSkill={selectedUserSkill} />
        </>
    );
}
export default UserSkillTable
