import React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteActionPlan } from "../../../api/ActionPlanApi";
import ModalForm from "../../../components/FormComponents/ModalForm";
import { ActionPlanType } from "../../../core/enums/enums";
import { IActionPlan } from "../../../core/interfaces/actionPlan";


const RemoveActionPlanModal: React.FC<{ show: boolean, hide: () => void, actionPlan: IActionPlan | null }> = ({
    show,
    hide,
    actionPlan
}) => {
    const { t } = useTranslation();

    const EnumToValue: { [key in keyof typeof ActionPlanType]: string } = {
        [ActionPlanType.ACCIDENT]: 'RemoveActionPlanModal.ACCIDENT_1',
        [ActionPlanType.CHECKLIST]: 'RemoveActionPlanModal.CHECKLIST_1',
        [ActionPlanType.DEVIATION]: 'RemoveActionPlanModal.DEVIATION_1',
        [ActionPlanType.INCIDENT]: 'RemoveActionPlanModal.INCIDENT_1',
        [ActionPlanType.STANDALONE]: 'RemoveActionPlanModal.STANDALONE_1'
    };

    const [remove, isRemoving] = useDeleteActionPlan(actionPlan?.id ?? '');
    if (actionPlan == null) return <></>

    return (
        <ModalForm show={show} hide={hide} title={t('RemoveActionPlanModal.DELETE_1') + t(EnumToValue[actionPlan.type])} isEdited={() => false} submit={() => remove().then(_ => hide())} submitText={t('RemoveActionPlanModal.SUBMIT_TEXT_1')}
            isLoading={isRemoving}>
            <p>{t('RemoveActionPlanModal.CONFIRM_DELETE_1') + actionPlan.headDescription}?</p>
        </ModalForm>
    )
}

export default RemoveActionPlanModal