import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import { Icon, IconSize, getIcon } from "../../../assets/Icons/IconClassNames";
import When from "../../../components/When";
import { PublishStatusType } from "../../../core/enums/enums";
import { IDocumentNodeModel, IFolderNodeModel } from "../../../core/interfaces/directory";
import { isDocument, isFolder, isTrashbin, renderNodeText } from "../Helpers/DirectoryHelper";
import DocumentType from "./DocumentType";

interface IDirectoryNode {
    node: NodeModel<IFolderNodeModel | IDocumentNodeModel>;
    depth: number;
    isOpen: boolean;
    isSelected: boolean;
    onToggle: (id: NodeModel["id"]) => void;
    onClickNode: (treeNode: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => void;
    handleExpandedFoldersToggle: (id: string) => void;
}

const DirectoryNode = (
    {
        node,
        depth,
        isOpen,
        isSelected,
        onToggle,
        onClickNode,
        handleExpandedFoldersToggle
    }: IDirectoryNode) => {
    const dragOverProps = useDragOver(node.id, isOpen, onToggle);

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleExpandedFoldersToggle("" + node.id);
        onToggle(node.id);
    };
    const isApprovedDocument = isDocument(node) && [PublishStatusType.APPROVED, PublishStatusType.CLOSED].includes((node.data! as IDocumentNodeModel).publishStatus as PublishStatusType);
    return (
        <div
            className={`tree-node root text-nowrap ${isDocument(node) && !isApprovedDocument ? 'unpublishedItemColor' : ''}`}
            style={{
                paddingInlineStart: depth * 12,
                fontWeight: isSelected ? "bold" : "normal"
            }}
            {...dragOverProps}
        >
            <div
                className={`expandIconWrapper ${isOpen ? 'isOpen' : ''}`}
                onClick={handleToggle}
            >
                <When condition={node.droppable === true}>
                    <div>
                        <i className={getIcon(Icon.ARROW_RIGHT, IconSize.XS)}/>
                    </div>
                </When>
            </div>
            <div>
                <When
                    condition={isFolder(node)}
                    then={<span className={getIcon(isTrashbin(node) ? Icon.REMOVE : Icon.FOLDER, IconSize.XS)}/>}
                    otherwise={<DocumentType data={node.data! as IDocumentNodeModel} iconSize={IconSize.XS}/>}>
                </When>
            </div>
            <div>
                <span className={`labelGridItem`}
                      onClick={() => onClickNode(node)}>
                    {renderNodeText(node)}
                </span>
            </div>
        </div>
    );
};

export default DirectoryNode;