import { useEffect, useRef, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { Icon, IconSize, getIcon } from "../../../../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import DropDownIconToggle from "../../../../../../components/DropDownIconToggle";
import CustomTextAreaInput from "../../../../../../components/FormComponents/CustomTextAreaInput";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import SimpleConfirmModal from "../../../../../../components/ModalComponents/SimpleConfirmModal";
import When from "../../../../../../components/When";
import { ChecklistView, DataRowType } from "../../../../../../core/enums/enums";
import { handleOnEnter } from "../../../../../../core/helpers/utils";
import { IDocumentModuleChecklistForm } from "../../../../../../core/interfaces/document";
import { useAcceptModalContext } from "../../../../../../providers/AcceptModalProvider";
import { useDocumentModulesContext } from "../../../DocumentModules";
import { useChecklistContext } from "../../ChecklistContext";
import { useChecklistFormContext } from "../ChecklistFormContext";
import GeneralizedDataRow from "../GeneralizedDataRow";
import { SendToActionPlan } from "../SendToActionPlan";
import styles from "../checklistTable.module.scss";
import { DataRowWidth } from "./TableView";


const CheckListRow = (
    {
        width,
        form
    }: { width: DataRowWidth, form: IDocumentModuleChecklistForm }) => {
    const {t} = useTranslation();
    const {
        removeForm,
        copyForm,
        copyMultipleForm,
        documentContext: {isEditMode},
        getModuleId,
        isHideActionPlan,
        isHideHeaderColumnTableView,
        addActionPlanDataRow,
        removeActionPlanDataRow,
    } = useChecklistContext();
    const {isSaveDisabled, setSaveState, isAnswerMode} = useDocumentModulesContext();
    const [copies, setCopies] = useState(1);
    const {
        updateDataRow,
        addDataRow,
        getForm: {documentModuleChecklistFormDataRows: dataRows},
        updateDescription,
        updateSendToActionPlan,
        removeDataRow,
    } = useChecklistFormContext();
    const modalContext = useAcceptModalContext();
    const actionListRow = dataRows.filter(row => row.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD)[0] ?? null;
    useEffect(() => {
        modalContext.setAcceptFunction(() => copyMultipleForm(form, copies))
    }, [copies]);

    const idleTimeoutRef = useRef<any>(null);
    const [internalDescription, setInternalDescription] = useState(form.description);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const delayedUpdateDescription = () => {
        clearTimeout(idleTimeoutRef.current);
        idleTimeoutRef.current = setTimeout(() => {
            return updateDescription(internalDescription);
        }, 500);
    };

    useEffect(() => {
        delayedUpdateDescription();
    }, [internalDescription]);

    const onEnterClick = (copies: number) => {
        copyMultipleForm(form, copies);
        modalContext.close();
    }

    return (<>
        <div className={"d-flex"} style={{position: 'relative', minHeight: 100}}>
            {isHideHeaderColumnTableView && form.documentModuleChecklistFormDataRows.length === 0 && (
                <div className={"d-flex justify-content-center align-items-center w-100"}>
                    {t('CheckListRow.ADD_COLUMN_1')} <span className={`${getIcon(Icon.SQUARE_PLUS, IconSize.X1)} ps-2 pe-2`} /> {t('CheckListRow.ADD_COLUMN_2')}
                </div>
            )}
            {!isHideHeaderColumnTableView && (
                <div className={[styles.tableRow, styles.extra].join(" ")} style={{width: 250}}>
                    <CustomTextAreaInput
                        checklistViewTable={true}
                        isEditMode={isEditMode}
                        rows={3}
                        name={"textValue"}
                        value={internalDescription}
                        onChange={(e: any) => setInternalDescription(e.target.value)}
                        autoComplete={false}
                        onFocusEdit={isEditMode}
                        defaultValueDash={false}
                        showInputFieldInReadOnly
                    />
                </div>
            )}
            <GeneralizedDataRow
                dataRows={dataRows}
                moduleId={getModuleId}
                formId={form.id}
                checklistViewTable={true}
                handleUpdateDataRow={(dataRow) => {
                    updateDataRow(dataRow)
                }}
                handleRemoveDataRow={() => NaN}
                handleCopyDataRow={() => NaN}
                dataRowWrapper={(dataRow, DataRow, index, props) => {
                    return <div key={dataRow.id}
                                className={styles.tableRow}
                                style={{width: width[index]}}>
                        <DataRow {...props}/>
                    </div>
                }}
                isEditMode={isEditMode} isAnswerMode={isAnswerMode}
                isActiveMode={isEditMode}
                isSaveDisabled={isSaveDisabled} setSaveDisabled={() => setSaveState(false)}
                delayedUpdate
            />
            <When condition={isEditMode}>
                <Dropdown style={{position: 'absolute', right: "-12px"}} drop={'start'}>
                    <DropDownIconToggle icon={Icon.THREE_DOTS_VERTICAL} iconSize={IconSize.XL}/>

                    <DropdownMenu>
                        <Container fluid>
                            <When condition={!isHideActionPlan}>
                                <When
                                    condition={actionListRow === null}
                                    then={<CustomDropdownItem
                                        icon={Icon.FILE_IMPORT}
                                        text={t('CheckListRow.ACTION_PLAN_1')}
                                        onClick={() => addActionPlanDataRow(form)}
                                    />}
                                    otherwise={<CustomDropdownItem
                                        icon={Icon.FILE_IMPORT}
                                        text={t('CheckListRow.REMOVE_SEND_ACTION_PLAN_1')}
                                        onClick={() => removeActionPlanDataRow(form)}
                                    />}
                                />
                                <hr/>
                            </When>
                            <CustomDropdownItem
                                icon={Icon.REMOVE}
                                text={t('CheckListRow.DELETE_2')}
                                onClick={() => setShowRemoveModal(true)}
                            />
                            <CustomDropdownItem
                                icon={Icon.COPY}
                                text={t('CheckListRow.COPY_1')}
                                onClick={() => copyForm(form)}
                            />
                            <CustomDropdownItem
                                icon={Icon.COPY}
                                text={t('CheckListRow.COPY_SEVERAL_1')}
                                onClick={() => modalContext.open({
                                    header: t('CheckListRow.COPY_SEVERAL_2'),
                                    declineButtonText: t('CheckListRow.CANCEL_1'),
                                    acceptButtonVariant: 'primary',
                                    acceptButtonText: t('CheckListRow.COPY_2'),
                                    disableAcceptButton: false,
                                    body: <CopyHandler set={setCopies}
                                                       onEnterClick={(copies: number) => onEnterClick(copies)}/>
                                })}/>
                            <CustomDropdownItem
                                icon={Icon.UPLOAD}
                                text={t('CheckListRow.ADD_EMPTY_ROW_1')}
                                onClick={() => copyForm(form, true)}
                            />
                        </Container>
                    </DropdownMenu>
                </Dropdown>

            </When>
        </div>
        <Row style={{width: 300, marginLeft: 3}}>
            <SendToActionPlan isActive={isAnswerMode} setSaveState={setSaveState}
                              hidden={!isAnswerMode || isHideActionPlan}
                              handleAddDataRow={addDataRow} handleUpdateDataRows={() => removeDataRow(actionListRow)}
                              setSendToActionPlan={updateSendToActionPlan}
                              form={form} viewType={ChecklistView.TABLE}/>
        </Row>

        <SimpleConfirmModal
            show={showRemoveModal}
            header={<>{t('CheckListRow.DELETE_ROW_1')}</>}
            text={<>{t('CheckListRow.DELETE_ROW_2')}</>}
            onClickOKText={t('CheckListRow.DELETE_1')}
            onClickOK={() => removeForm(form)}
            onClickCancelText={t('CheckListRow.CANCEL_2')}
            onClickCancel={() => setShowRemoveModal(false)}
        />
    </>)
}


const CopyHandler = ({set, onEnterClick}: { set: (v: number) => void, onEnterClick: (copies: number) => void }) => {
    const {t} = useTranslation();
    const [copies, setCopies] = useState(1);
    return (<>
            <CustomTextInput
                isEditMode={true}
                disabled={false}
                name={"header"}
                placeholder={t('CheckListRow.FOR_EXAMPLE_NUMBER_1')}
                value={copies}
                autofocus
                onChange={(e) => {
                    let v = e.target.value;
                    if (v > 15) v = 15;
                    set(v)
                    setCopies(v)
                }
                }
                onKeyDown={(event) => handleOnEnter(event, () => onEnterClick(copies))}
                disableAutocomplete
            />
            <span><i>{t('CheckListRow.MAXIMUM_LINES_1')}</i></span>
        </>
    )
}
export default CheckListRow
