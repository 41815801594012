import MainContainer from "../../../../components/ContainerComponents/MainContainer";
import styles from './incidentFormContainer.module.scss'
import React from "react";

interface IIncidentFormContainer<T extends React.ElementType> {
    as?: T;
    children?: React.ReactNode;
}

const IncidentFormContainer = <T extends React.ElementType> ({as, ...props}: IIncidentFormContainer<T>)=> {
    const Component = as || MainContainer;

    return <Component {...props} className={styles.container}/>
}


export default IncidentFormContainer