import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetDocumentVersions } from "../../../api/DocumentApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import LoadingSpinner from "../../../components/LoadingSpinner";
import When from "../../../components/When";
import { toastInfo } from "../../../core/helpers/toastHelper";
import { IDocument } from "../../../core/interfaces/document";
import { useAcceptModalContext } from "../../../providers/AcceptModalProvider";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";

const VersionModalBody = ({
                              document,
                              cId,
                              closeFunction
                          }: { document: IDocument, cId: string, closeFunction: () => void }) => {

    const {t} = useTranslation();
    const [data, isFetching] = useGetDocumentVersions(cId!, document.id, document.baseDocumentId ?? document.id);

    return (
        <When condition={!isFetching} otherwise={<LoadingSpinner/>}>
            <When condition={data.length > 0} otherwise={t('Versions.NO_READ_RECEIPTS_1')}>
                {data.map(v => {
                    return <Link state={{fromVersion: true}} to={`c/${cId}/documents/${v.id}`} key={v.id}
                                 onClick={() => closeFunction()}>
                        <Row>
                            <Col>{v.name}</Col>
                            <Col>Version {v.version}
                            </Col>
                        </Row>
                    </Link>
                })}
            </When>
        </When>
    )
}
export const Versions = ({document, isEditMode}: { document: IDocument, isEditMode: boolean }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const acceptModalContext = useAcceptModalContext();
    const {cId} = useParams();
    const location = useLocation();

    const handleVersions = () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('Versions.LEAVE_EDITING_MODE_1'), t('Versions.EDITING_MODE_1'))));
            return;
        }
        acceptModalContext.open({
            header: t('Versions.VERSIONS_1'),
            declineButtonText: t('Versions.CLOSE_1'),
            body: <VersionModalBody document={document} cId={cId!}
                                    closeFunction={acceptModalContext.close}/>,
            hideAcceptButton: true,
        })
    }

    return <CustomDropdownItem
        icon={Icon.CODE_COMPARE}
        text={t('Versions.VERSIONS_2')}
        disabled={!(!!document.baseDocumentId || location?.state?.fromVersion)}
        onClick={handleVersions}/>


}
