import Form from "react-bootstrap/esm/Form";
import {UTCStringToLocaleString} from "../../core/helpers/dates/datesHelper";
import {ITestAble} from "../../core/interfaces/common";
import {useTranslation} from "react-i18next";

interface ICustomDate {
    type: "date" | "time";
    isEditMode: boolean;
    name: string;
    value: string;
    onChange: (e: any) => void;
    required?: boolean;
    disabled?: boolean;
    checklistViewTable?: boolean;
}

const CustomDate = (
    {
        type,
        isEditMode,
        name,
        value,
        onChange,
        required,
        testId,
        disabled = false,
        checklistViewTable,
    }: ICustomDate & ITestAble) => {

    const {t} = useTranslation();
    
    const renderValue = () => {
        if (!isEditMode && value === '') return '-';
        if (type === "date") {
            return UTCStringToLocaleString(value, "date");
        }
        if (type === "time") {
            return UTCStringToLocaleString(value, "time");
        }
    }

    return <>
        {isEditMode ? (
            <Form.Control
                className={checklistViewTable ? "checklistTableFieldsSelect" : ""}
                type={type}
                max={type === 'date' ? t('CustomDate.DATE_1') : undefined}
                name={name}
                disabled={disabled}
                value={renderValue()}
                onChange={onChange}
                required={required}
            />
        ) : (
            <span data-test-id={testId}>{renderValue()}</span>
        )}
    </>
}

export default CustomDate;
