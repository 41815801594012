import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteAttachment, useDownloadAttachmentById } from "../../../api/DocumentApi";
import { Icon, IconSize, getIcon, getRegularIcon } from "../../../assets/Icons/IconClassNames";
import When from "../../../components/When";
import { IDocumentAttachment } from "../../../core/interfaces/document";
import { IAcceptModalUi, useAcceptModalContext } from "../../../providers/AcceptModalProvider";

export function Attachment({attachment, isEditMode, documentId}: { attachment: IDocumentAttachment, isEditMode: boolean, documentId?: string }) {

    const {t} = useTranslation();
    const acceptModalContext = useAcceptModalContext();
    const [request, isFetching] = useDeleteAttachment(attachment.id)
    const [downloadAttachment, isDownloading] = useDownloadAttachmentById(attachment.id, documentId)
    const [hide, setHide] = useState(false);

    const deleteFunction = () => {
        request().then(resp => setHide(resp))
    }
    const handleClick = () => {
        acceptModalContext.trigger(deleteFunction, {
            header: t('Attachment.TEMPLATE_1'),
            body: <>
                <p>{t('Attachment.DELETE_CONFIRMATION_1')}</p>
                <i className={attachment.contentType.includes("image") ? getRegularIcon(Icon.IMAGE) : getRegularIcon(Icon.PDF)}></i>
                <b> {attachment.name}</b>
                <br />
                {t('Attachment.DELETE_DISCLAIMER_1')}</>
        } as IAcceptModalUi)
    }
    return (
        <When condition={!hide}>
            <li className={!isEditMode ? "fileDownloadHover" : ""}>
                <i className={attachment.contentType.includes("image") ? getRegularIcon(Icon.IMAGE) : getRegularIcon(Icon.PDF)}></i>
                <span onClick={() => {
                    if (!isEditMode && !isDownloading) {
                        downloadAttachment();
                    }
                }}> {attachment.name}
                    <When condition={isEditMode && !isFetching}>
                    <i className={[getIcon(Icon.REMOVE, IconSize.XS), "fileDownloadHover", "px-1"].join(" ")}
                       onClick={handleClick}/>
                </When>
            </span>
            </li>
        </When>);
}
