import { FormEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import getIcon, { Icon } from "../../../../../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../../../../../components/ClickableIconButton/ClickableIconButton";
import When from "../../../../../../../components/When";
import { getBase64 } from "../../../../../../../core/helpers/fileHelper";
import { toastError } from "../../../../../../../core/helpers/toastHelper";
import { getBlockedFileTypes } from "../../../../../../../core/helpers/utils";
import { INewFile } from "../../../../../../../core/interfaces/document";
import { addToast } from "../../../../../../../store/slices/uiSlice";


interface IFileSelectProps {
    isEditMode: boolean;
    newFile: (file: INewFile) => void;
}

const FileSelect: React.FC<IFileSelectProps> = ({isEditMode, newFile}) => {
    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const handleNewFile = async (e: FormEvent<HTMLInputElement>) => {
        const file = e?.currentTarget?.files?.item(0);

        if (file) {
            if(getBlockedFileTypes.includes(file.type)) return dispatch(addToast(toastError(t('FileSelect.BLOCKED_FORMAT_1'), t('FileSelect.FILE_BLOCKED_FORMAT_1'))));

            newFile({
                file: await getBase64(file),
                contentType: file.type,
                name: file.name,
                }
            )
        }
    }


    return (
        <When condition={isEditMode}>
            <input onInputCapture={handleNewFile} ref={inputRef} type={"file"} id={"incident-file"} multiple hidden
                   accept=""
                   onClick={(e: any) => (e.target.value = null)} // Reset value to allow same file to be uploaded again
            />
            <ClickableIconButton
                disabled={!isEditMode}
                buttonType={"EDIT"}
                icon={getIcon(Icon.CIRCLE_PLUS)}
                onClick={() => inputRef?.current?.click()}
                title={t('FileSelect.ADD_FILE_1')}
            />
            {/*<SpinnerButton text={t('FileSelect.ADD_FILE_2')} onClick={() => inputRef?.current?.click()} isLoading={false}/>*/}
        </When>
    )
}

export default FileSelect
