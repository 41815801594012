import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetCompanyUsersExtended } from "../../api/CompanyApi";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import LoadingSpinner from "../../components/LoadingSpinner";
import { ICompanyUserExtended } from "../../core/interfaces/company";
import FileTable from "./components/FileTable";


const UserDocuments = () => {
    const { t } = useTranslation();
    const { cUId } = useParams();
    const [users, loading] = useGetCompanyUsersExtended();
    const [updatedObject, setUpdatedObject] = useState<ICompanyUserExtended>();

    useEffect(() => {
        if (users.length > 0) {
            const currentUser = users.find(s => s.id === cUId);
            if (currentUser == null)
                return;
            setUpdatedObject({ ...currentUser });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    if (loading || updatedObject == null) {
        return (
            <MainContainer>
                <HeaderContainer title={t('UserDocuments.DOCUMENTS_1')}></HeaderContainer>
                <LoadingSpinner />
            </MainContainer>
        )
    }

    return (
        <MainContainer>
            <HeaderContainer title={t('UserDocuments.DOCUMENTS_1')}></HeaderContainer>
            <ContentContainer>
                <FileTable files={updatedObject.attachments} companyUserId={cUId!} />
            </ContentContainer>
        </MainContainer>
    )
}
export default UserDocuments
