import { NavDropdown } from "react-bootstrap";

interface ICustomNavLink {
    icon: string;
    title: string;
    testId?: string;
    onClick: () => void;
}

const CustomNavLink = ({
    icon,
    title,
    testId,
    onClick }: ICustomNavLink) => {
    return (
        <NavDropdown.Item onClick={onClick}>
            <div data-test-id={testId}>
                <span className="circle bg-color-blue-primary" style={{ float: "left" }}>
                    <i className={icon} />
                </span>
                <span className="ps-2">{title}</span>
            </div>
        </NavDropdown.Item>
    )
}

export default CustomNavLink;