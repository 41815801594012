import { useQueryClient } from "@tanstack/react-query";
import i18n from "i18next";
import { useParams } from "react-router-dom";
import { queryStringBuilder } from "../core/helpers/queryStringBuilder";
import { toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, ICustomQueryKeys, IResponse } from "../core/interfaces/common";
import { IDirectoryNode } from "../core/interfaces/directory";
import {
    IDocumentNodeOrderRequest,
    IFolder,
    IFolderCreateRequest,
    IMoveDocumentRequest,
    IMoveFolderRequest,
    IRenameNodeRequest
} from "../core/interfaces/document";
import { AppQueryKey, useInvalidateQuery } from "../core/queryKeys";
import { useGet, usePost, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import {
    URL_DOCUMENT_MOVE,
    URL_DOCUMENT_NODES,
    URL_DOCUMENT_NODES_SORT,
    URL_FOLDERS,
    URL_FOLDERS_MOVE,
    URL_RENAME_DOCUMENT_NODE
} from "./endpoints/endpoints";

export const useGetFolders = (cId: string) => {
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId}
    const url = URL_FOLDERS(cId) + queryStringBuilder({include: ["subfolders", "documents"]});
    const query: ICustomQuery = {url, queryKeys}
    return useGet<IFolder[]>(query);
}

export const useGetNodes = (enabled?: boolean | true) => {
    let {cId} = useParams();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId}
    const query: ICustomQuery = {url: URL_DOCUMENT_NODES(cId ?? ""), queryKeys: queryKeys, options: {enabled: enabled}}
    return useGet<IDirectoryNode>(query);
}

// FIXME uncomment when BE is fixed
// export const useGetFavoriteDocumentNodes = (cId: string) => {
//     const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId, extraKeys: ["favorites"]};
//     const query: ICustomQuery = {url: URL_FAVORITE_DOCUMENT_NODES(cId), queryKeys: queryKeys};
//     return useGet<IDocumentNode[]>(query);
// }

export const useCreateFolder = (
    cId: string
): [((request: IFolderCreateRequest) => Promise<IResponse<IFolderCreateRequest>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId}

    const usePostMutation = usePost<IFolderCreateRequest>(
        URL_FOLDERS(cId),
        queryKeys,
        true
    );

    const request = (request: IFolderCreateRequest) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentDirectory.FOLDER_CREATED_1'), `${i18n.t('DocumentDirectory.FOLDER_CREATED_2')} ${(response.data as any).data.name} ${i18n.t('DocumentDirectory.FOLDER_CREATED_3')}`)));
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
}

export const useRenameNode = (
    cId: string,
): [((request: IRenameNodeRequest) => Promise<IResponse<IRenameNodeRequest>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId};
    const {cUId} = useParams();
    const usePutMutation = usePut<IRenameNodeRequest>(
        URL_RENAME_DOCUMENT_NODE(cId),
        queryKeys,
        true
    );
    const invalidateUserDocuments = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {includeCompanyId: false, extraKeys: [cUId ?? '']});

    const request = (req: IRenameNodeRequest) => usePutMutation.mutateAsync(req).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentDirectory.NAME_UPDATED_1'), "")));
            await queryClient.invalidateQueries(["folders", cId]);
            await invalidateUserDocuments();
        }
        return response;
    });
    return [request, usePutMutation.isLoading];

}

export const useUpdateNodeOrder = (
    cId: string,
): [((request: { nodesToUpdate: IDocumentNodeOrderRequest[] }) => Promise<IResponse<{ nodesToUpdate: IDocumentNodeOrderRequest[] }>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId};

    const usePostMutation = usePost<{ nodesToUpdate: IDocumentNodeOrderRequest[] }>(
        URL_DOCUMENT_NODES_SORT(cId),
        queryKeys,
        true
    );

    const request = (request: { nodesToUpdate: IDocumentNodeOrderRequest[] }) => usePostMutation.mutateAsync(request).then((response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentDirectory.SORTING_SAVED_1'), i18n.t('DocumentDirectory.SORTING_SAVED_1'))));
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
}

export const useMoveFolder = (): [(request: IMoveFolderRequest) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId};

    const usePostMutation = usePut<IMoveFolderRequest>(
        URL_FOLDERS_MOVE(cId!),
        queryKeys,
        true
    );

    const request = (request: IMoveFolderRequest) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentDirectory.SORTING_SAVED_3'), i18n.t('DocumentDirectory.SORTING_SAVED_3'))));
            await queryClient.invalidateQueries(["folders", cId]);
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useMoveDocument = (): [(request: IMoveDocumentRequest) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId};

    const usePostMutation = usePut<IMoveDocumentRequest>(
        URL_DOCUMENT_MOVE(cId!),
        queryKeys,
        true
    );

    const request = (request: IMoveDocumentRequest) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentDirectory.SORTING_SAVED_5'), i18n.t('DocumentDirectory.SORTING_SAVED_5'))));
            await queryClient.invalidateQueries(["folders", cId]);
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}
