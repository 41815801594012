import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import CustomMultiSelect from "../../../../../../components/FormComponents/CustomMultiSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { DataRowSubType } from "../../../../../../core/enums/enums";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowProps, IMultiPickDataRow } from "../../../../../../core/interfaces/document";


const MultiPickDataRow = (
    {
        dataRow,
        setDataRow,
        isFieldEnabled,
        isEditMode,
        isHeaderEnabled,
        checklistViewTable,
        isAnswerMode = false
    }: IDataRowProps<IMultiPickDataRow>) => {

    const { t } = useTranslation();

    const onChange = (input: { value: string, label: string }[]) => {
        const newValue = dataRow.multiPicks.map((v) => {
            v.chosen = !!input.find(inValue => inValue.value === v.name);
            return v;
        })
        setDataRow({
            ...dataRow,
            'multiPicks': newValue
        });
    }

    const getLabel = (label: string) => {
        if (dataRow.subType && dataRow.subType !== DataRowSubType.NONE)
            return t(`${dataRow.subType}.${label}`);
        else if (label.indexOf('.') > 0)
            return t(label);
        else
            return label;
    }

    return <>
        <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
            <When condition={!checklistViewTable}>
                <Col md={3}>
                    <CustomTextInput
                        isEditMode={isEditMode}
                        disabled={!isHeaderEnabled}
                        name={"header"}
                        placeholder={t('MultiPickDataRow.EXAMPLE_SELECT_SOME_1')}
                        value={getDataRowHeader(dataRow)}
                        onChange={(e) => setDataRow({
                            ...dataRow,
                            [e.target.name]: e.target.value
                        })}
                        disableAutocomplete
                    />
                </Col>
            </When>
            <Col md={9}>
                <Col md={12}>
                    <CustomMultiSelect
                        disabled={!isFieldEnabled}
                        isEditMode={isEditMode || isAnswerMode}
                        name={"multiUsers"}
                        selected={(dataRow?.multiPicks ?? []).filter(v => v.chosen).map(p => ({ value: p.name, label: getLabel(p.name) })) ?? []}
                        options={(dataRow?.multiPicks ?? []).map(p => ({ value: p.name, label: getLabel(p.name) })) ?? []}
                        onChange={onChange}
                    />
                </Col>
            </Col>
        </Row>

    </>
};



export default MultiPickDataRow;
