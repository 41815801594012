import { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useGetActionPlans } from "../../api/ActionPlanApi";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../components/ClickableIconButton/ClickableIconButton";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import PaginatedDataTableClientSide from "../../components/DataTableComponents/PaginatedDataTable";
import CustomMultiSelect from "../../components/FormComponents/CustomMultiSelect";
import When from "../../components/When";
import { ActionPlanStatus, ActionPlanType, CompanyRole } from "../../core/enums/enums";
import { DateToYYYYMMDD } from "../../core/helpers/dates/datesHelper";
import { IActionPlan } from "../../core/interfaces/actionPlan";
import useAccessCheck from "../../hooks/useAccessCheck";
import NewIncidentModal from "../Dashboard/Components/Incident/NewIncidentModal";
import OpenDownloadAsPDF from "../Pdf/OpenDownloadAsPDF";
import ActionPlanModal from "./ActionPlanModal";
import RemoveActionPlanModal from "./Components/RemoveActionPlanModal";
import Select from "react-select";

const EnumToValue: { [key in keyof typeof ActionPlanType]: string } = {
    [ActionPlanType.INCIDENT]: 'ActionPlanView.INCIDENT_1',
    [ActionPlanType.ACCIDENT]: 'ActionPlanView.ACCIDENT_1',
    [ActionPlanType.DEVIATION]: 'ActionPlanView.DEVIATION_1',
    [ActionPlanType.STANDALONE]: 'ActionPlanView.OWN_ACTION_1',
    [ActionPlanType.CHECKLIST]: 'ActionPlanView.CHECKLIST_1'
};

const StatusValue: { [key in keyof typeof ActionPlanStatus]: string } = {
    CREATED: 'ActionPlanView.NEWLY_ARRIVED_1',
    ONGOING: 'ActionPlanView.STARTED_1',
    COMPLETED: 'ActionPlanView.COMPLETED_1'
};

const ActionPlanView = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { cId } = useParams();
    const [data, isFetching, isFetched] = useGetActionPlans();
    const [chosen, setChosen] = useState<IActionPlan | null>(null);
    const [openActionPlanModal, setOpenActionPlanModal] = useState(false);
    const [openStandaloneActionModal, setOpenStandaloneActionModal] = useState(false);
    const [showRemoveActionPlanModal, setShowRemoveActionPlanModal] = useState(false);
    const [checkAccess] = useAccessCheck();
    const hide = () => {
        setChosen(null);
        setOpenActionPlanModal(false);
        setOpenStandaloneActionModal(false);
        setShowRemoveActionPlanModal(false);
    }

    const [filterText, setFilterText] = useState("");
    const [selectedStatuses, setSelectedStatuses] = useState<ActionPlanStatus[]>([ActionPlanStatus.CREATED, ActionPlanStatus.ONGOING]);

    const [showPdfModal, setShowPdfModal] = useState(false);
    const [pdfVariant, setPdfVariant] = useState<'DOWNLOAD' | 'OPEN'>('OPEN');

    const handleShowPdfModal = (variant: 'OPEN' | 'DOWNLOAD') => {
        setPdfVariant(variant);
        setShowPdfModal(true);
    }

    const columns: TableColumn<IActionPlan>[] = [
        {
            name: t('ActionPlanView.REPORTED_1'),
            selector: cell => DateToYYYYMMDD(cell.createdAt),
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.REPORTED_BY_1'),
            selector: row => row.reportedByUser?.firstName + " " + row.reportedByUser?.lastName,
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.ASSIGNED_1'),
            selector: row => row.actionUser?.firstName + " " + row.actionUser?.lastName,
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.FIXED_LAST_1'),
            selector: cell => DateToYYYYMMDD(cell.actionDate),
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.TYPE_1'),
            selector: c => t(EnumToValue[(c.type) as ActionPlanType]),
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.DESCRIPTION_1'),
            selector: cell => cell.headDescription ?? '',
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.PROJECT_NUMBER_1'),
            selector: cell => cell.projectNumber ?? '',
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.STATUS_1'),
            selector: row => t(StatusValue[(row.status) as ActionPlanStatus]),
            sortable: true,
            compact: true
        },
        {
            name: t('ActionPlanView.DOCUMENT_1'),
            cell: cell => <a href={`/c/${cId}/documents/${cell.relatedDocument?.id}`} target="_blank"
                rel="noreferrer">{cell.relatedDocument?.documentName}</a>,
            sortable: false,
            compact: true
        },
        {
            name: <></>,
            right: true,
            cell: row => (
                <CustomDropdown toggleType={DropdownToggleType.GEAR}>
                    <CustomDropdownItem
                        icon={Icon.EDIT}
                        text={t('ActionPlanView.EDIT_1')}
                        onClick={() => {
                            setChosen(row);
                            setOpenActionPlanModal(true);
                        }}
                    />
                    <When condition={checkAccess(CompanyRole.ADMIN)}>
                        <CustomDropdownItem
                            icon={Icon.REMOVE}
                            text={t('ActionPlanView.DELETE_1')}
                            onClick={() => {
                                setChosen(row);
                                setShowRemoveActionPlanModal(true);
                            }}
                        />
                    </When>
                    <CustomDropdownItem
                        icon={Icon.DOWNLOAD}
                        text={t('ActionPlanView.DOWNLOAD_THE_PDF_1')}
                        onClick={() => {
                            setChosen(row);
                            handleShowPdfModal('DOWNLOAD');
                        }}
                    />
                    <CustomDropdownItem
                        icon={Icon.PDF}
                        text={t('ActionPlanView.OPEN_PDF_1')}
                        onClick={() => {
                            setChosen(row);
                            handleShowPdfModal('OPEN');
                        }}
                    />
                </CustomDropdown>
            )
        }
    ]

    function ActionPlanTab(type: ActionPlanType[] | ActionPlanType) {
        const { t } = useTranslation();
        const isCheckList = Array.isArray(type) ? type.includes(ActionPlanType.CHECKLIST || ActionPlanType.STANDALONE) : type === (ActionPlanType.CHECKLIST || ActionPlanType.STANDALONE);

        const dataByType = data.filter(x => Array.isArray(type) ? type.includes(x.type) : x.type === type)

        const extraColumn: TableColumn<IActionPlan>[] = isCheckList ? [] : [{
            name: t('ActionPlanView.SERIAL_NUMBER_1'),
            cell: row => row.serialNumber ?? '-',
            selector: row => row.serialNumber ?? "",
            sortable: true,
            compact: true,
        }]
        const filteredItems = dataByType.filter(
            item => (
                (selectedStatuses.length === 0 || selectedStatuses.includes(item.status)) && (
                    (item.serialNumber?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.reportedByUser?.firstName && item.reportedByUser?.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.reportedByUser?.lastName && item.reportedByUser?.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.actionUser?.firstName && item.actionUser?.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.actionUser?.lastName && item.actionUser?.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.headDescription?.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.projectNumber?.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.status && t(StatusValue[(item.status) as ActionPlanStatus]).toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.actionDate && item.actionDate.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.createdAt && item.createdAt.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.type && t(EnumToValue[(item.type)]).toLowerCase().includes(filterText.toLowerCase())) ||
                    (filterText.includes(' ') &&
                        (item.reportedByUser && (item.reportedByUser.firstName.toLowerCase().includes(filterText.split(' ')[0].toLowerCase()) && item.reportedByUser.lastName.toLowerCase().includes(filterText.split(' ')[1].toLowerCase())))) ||
                    (filterText.includes(' ') &&
                        (item.actionUser && (item.actionUser.firstName.toLowerCase().includes(filterText.split(' ')[0].toLowerCase()) && item.actionUser.lastName.toLowerCase().includes(filterText.split(' ')[1].toLowerCase()))))
                )),
        );

        useEffect(() => {
            if (isFetched) {
                const stateId = location.state?.stateId;
                const chosenItem = stateId ? filteredItems.find(item => item.id === stateId) : null;

                if (chosenItem) {
                    setChosen(chosenItem);
                    setOpenActionPlanModal(true);
                }
            }
        }, [isFetched]);

        return (
            <>
                <ContentContainer>
                    <When condition={type.includes(ActionPlanType.CHECKLIST || ActionPlanType.STANDALONE)}>
                        <div className="position-absolute ps-3" style={{ zIndex: 10 }}>
                            <ClickableIconButton
                                disabled={false}
                                buttonType={"EDIT"}
                                icon={getIcon(Icon.CIRCLE_PLUS, IconSize.X2)}
                                onClick={() => setOpenStandaloneActionModal(true)}
                                title={t('ActionPlanView.ACTION_1')}
                            />
                        </div>
                    </When>
                    <div className="me-3 d-flex justify-content-end">
                        <Select
                            key={"status_" + type}
                            isMulti
                            name={"status"}
                            options={Object.values(ActionPlanStatus).map(p => ({ value: p, label: t(StatusValue[p]) })) ?? []}
                            value={selectedStatuses.map(s => ({ value: s, label: t(StatusValue[s]) }))}
                            noOptionsMessage={() => t('CustomMultiSelect.NO_OPTIONS_1')}
                            onChange={(selectedOptions: any) => setSelectedStatuses(selectedOptions.map((opt: any) => opt.value as ActionPlanStatus))}
                            placeholder={t('ActionPlanView.STATUS_PLACEHOLDER_1')}
                        />
                    </div>
                    <PaginatedDataTableClientSide
                        data={filteredItems ?? []}
                        progressPending={isFetching}
                        columns={[...extraColumn, ...columns]}
                        pointerOnHover
                        withFilter
                        filterText={filterText}
                        setFilterText={(prev) => setFilterText(prev)}
                        onRowClick={v => { setChosen(v); setOpenActionPlanModal(true) }}
                    />
                </ContentContainer>
            </>
        );
    }

    return (
        <MainContainer>
            <HeaderContainer title={t('ActionPlanView.ACTION_PLAN_1')} />
            <Tabs
                defaultActiveKey="INCIDENT"
                transition={false}
                className="ms-5 me-5 shadow"
                justify
            >
                <Tab eventKey="INCIDENT" title={t('ActionPlanView.ACCIDENTS_AND_INCIDENTS_1')}>
                    {ActionPlanTab([ActionPlanType.INCIDENT, ActionPlanType.ACCIDENT])}
                </Tab>
                <Tab eventKey="DEVIATION" title={t('ActionPlanView.DEVIATION_2')}>
                    {ActionPlanTab(ActionPlanType.DEVIATION)}
                </Tab>
                <Tab eventKey="CHECKLIST" title={t('ActionPlanView.ACTION_PLAN_2')}>
                    {ActionPlanTab([ActionPlanType.CHECKLIST, ActionPlanType.STANDALONE])}
                </Tab>
            </Tabs>

            <ActionPlanModal
                actionPlan={chosen}
                show={openActionPlanModal}
                setShow={() => setOpenActionPlanModal(false)}
            />

            <NewIncidentModal
                initialActionPlanType={ActionPlanType.STANDALONE}
                show={openStandaloneActionModal}
                setShow={() => setOpenStandaloneActionModal(false)}
            />

            <RemoveActionPlanModal
                actionPlan={chosen}
                hide={hide}
                show={showRemoveActionPlanModal} />

            <When condition={showPdfModal}>
                <OpenDownloadAsPDF
                    actionPlan={chosen!}
                    showPdfModal={showPdfModal}
                    closePdfModal={() => setShowPdfModal(false)}
                    pdfVariant={pdfVariant}
                />
            </When>
        </MainContainer>
    )
}

export default ActionPlanView

