import { useTranslation } from "react-i18next";
import When from "../../../components/When";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function CreatedByForm(props: { currentUserName: string, newIncident: IIncident, isNewIncident: boolean }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'formReportedBy'} title={t('CreatedByForm.REPORTED_BY_1')}>
            <span data-test-id='formReportedBy' className={'col-form-label'}>
                <When condition={props.isNewIncident}
                      otherwise={props.newIncident?.createdBy?.name ?? t('CreatedByForm.UNKNOWN_USER_1')}>
                    {props.currentUserName ?? t('CreatedByForm.UNKNOWN_USER_2')}
                </When>
            </span>
    </FormProvider>;
}
