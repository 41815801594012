import { useTranslation } from "react-i18next";
import CustomTextAreaInput from "../../../components/FormComponents/CustomTextAreaInput";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function ExternalUsersDescriptionForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return (<FormProvider controlId="formExternalUsersDescription"
                          title={t('ExternalUsersDescriptionForm.ADDITIONAL_INFORMATION_REGARDING_EXTERNAL_PERSONS_1')} colAs={'large'}>
            <CustomTextAreaInput
                disabled={!props.newIncident.hasExternalUsers}
                isEditMode={props.editMode}
                rows={5}
                name={"externalUsersDescription"}
                testId={'formExternalUsersDescription'}
                value={props.newIncident.externalUsersDescription ?? ''}
                onChange={props.onChange}
            />
        </FormProvider>
    );
}
