import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import { DataRowType } from "../../../../../../core/enums/enums";
import { handleOnEnter } from "../../../../../../core/helpers/utils";
import { IDataRowNumericField } from "../../../../../../core/interfaces/document";

const newDataRowNumeric: IDataRowNumericField = {
    checklistFormDataRowTypeSymbol: DataRowType.NUMERIC_FIELD,
    isNew: true,
    header: "",
    isMinMaxChecked: false,
    isClosed: false,
}

interface IAddNumericDataRow {
    onAdd: (dataRow: IDataRowNumericField) => void;
    onCancel: () => void;
}

const AddNumericDataRow = ({onAdd, onCancel}: IAddNumericDataRow) => {
    const {t} = useTranslation();
    const [dataRow, setDataRow] = useState(newDataRowNumeric);

    const [isInvalidMinMaxValues, setIsInvalidMinMaxValues] = useState(false);
    const [isMissingMinMaxValues, setIsMissingMinMaxValue] = useState(false);

    useEffect(() => {
        if (!dataRow.isMinMaxChecked) {
            setIsInvalidMinMaxValues(false);
            setIsMissingMinMaxValue(false);
            return;
        }

        if (isNaN(dataRow.numMinValue ?? NaN) || isNaN(dataRow.numMaxValue ?? NaN)) {
            setIsMissingMinMaxValue(true);
        } else {
            setIsMissingMinMaxValue(false);
        }
        if (dataRow.numMinValue! > dataRow.numMaxValue!) {
            setIsInvalidMinMaxValues(true);
        } else {
            setIsInvalidMinMaxValues(false);
        }
    }, [dataRow.isMinMaxChecked, dataRow.numMaxValue, dataRow.numMinValue]);

    const onChangeHeader = (e: any) => {
        setDataRow({
            ...dataRow,
            header: e.target.value
        });
    }

    const handleChange = (e: any) => {
        if (!dataRow) return;
        const newVal = isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value);
        setDataRow({
            ...dataRow,
            [e.target.name]: newVal,
        });
    }

    const handleIntervalCheck = (e: any) => {
        setDataRow({
            ...dataRow,
            isMinMaxChecked: e.target.checked,
        });
    }

    const handleOnAdd = () => {
        if (!dataRow.header || isInvalidMinMaxValues || isMissingMinMaxValues) return;
        onAdd(dataRow);
    }

    return <>
        <Row>
            <Col lg={3} className={"pt-1"}>{t('AddNumericDataRow.TITLE_1')}</Col>
            <Col lg={6}>
                <CustomTextInput
                    name={"header"}
                    value={dataRow.header}
                    placeholder={t('AddNumericDataRow.EXAMPLE_NUMBER_1')}
                    onChange={onChangeHeader}
                    disableAutocomplete
                    required
                    autofocus={true}
                    onKeyDown={(event) => handleOnEnter(event, handleOnAdd)}
                />
            </Col>
        </Row>
        <Row className={"mt-3"}>
            <Col lg={3}></Col>
            <Col lg={9}>
                <Form.Switch
                    onClick={handleIntervalCheck}
                    label={t('AddNumericDataRow.SPECIFY_RANGE_FOR_ACCEPTED_VALUE_1')}
                />
            </Col>
        </Row>
        {dataRow.isMinMaxChecked && (<>
            <Row className={"mt-3"}>
                <Col lg={3}>{t('AddNumericDataRow.MINIMUM_VALUE_1')}</Col>
                <Col lg={6}>
                    <Form.Control
                        type={"number"}
                        name={"numMinValue"}
                        value={dataRow.numMinValue ?? ''}
                        onChange={handleChange}
                        min={-99999999}
                        max={99999999}
                        onKeyDown={(event) => handleOnEnter(event, handleOnAdd)}
                    />
                </Col>
            </Row>
            <Row className={"mt-3"}>
                <Col lg={3}>{t('AddNumericDataRow.MAX_VALUE_1')}</Col>
                <Col lg={6}>
                    <Form.Control
                        type={"number"}
                        name={"numMaxValue"}
                        value={dataRow.numMaxValue ?? ''}
                        onChange={handleChange}
                        min={-99999999}
                        max={99999999}
                        onKeyDown={(event) => handleOnEnter(event, handleOnAdd)}
                    />
                </Col>
            </Row>
        </>)}
        <Row className={"mt-3"}>
            <Col lg={3}></Col>
            <Col lg={6}>
                <Button disabled={!dataRow.header || isInvalidMinMaxValues || isMissingMinMaxValues}
                        onClick={handleOnAdd}>{t('AddNumericDataRow.INSERT_1')}</Button>
                <Button onClick={onCancel}>{t('AddNumericDataRow.CANCEL_1')}</Button>
            </Col>
        </Row>
        <Row className={"mt-3"}>
            <Col lg={3}></Col>
            <Col lg={9}>
                {isMissingMinMaxValues && <div style={{color: '#b71212'}}>{t('AddNumericDataRow.MIN_AND_MAX_VALUE_1')}</div>}
                {isInvalidMinMaxValues &&
                    <div style={{color: "red"}}>{t('AddNumericDataRow.MINIMUM_MAXIMUM_RELATIONSHIP_1')}</div>}
            </Col>
        </Row>
    </>
};

export default AddNumericDataRow;
