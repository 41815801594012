import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCreateNewDocumentTemplate } from "../../../../api/TemplateApi";
import CustomTextInput from "../../../../components/FormComponents/CustomTextInput";
import FormBase from "../../../../components/FormComponents/FormBase";
import ModalForm from "../../../../components/FormComponents/ModalForm";
import { NewTemplateRequest } from "../../../../core/interfaces/template";

interface ICreateNewTemplate {
    visible: boolean;
    setVisible: (val: boolean) => void;
    parentFolderId?: string;

}

const CreateNewTemplate = (
    {
        visible,
        setVisible,
        parentFolderId,
    }: ICreateNewTemplate) => {

    const initRequestObj: NewTemplateRequest = {
        name: "",
        parentFolderId: parentFolderId,
        allowCompanies: false,
    }

    const {t} = useTranslation();
    const [requestObj, setRequestObj] = useState(initRequestObj);
    const [createTemplate, isCreatingTemplate] = useCreateNewDocumentTemplate();


    const handleCreateTemplate = async () => {
        if (!requestObj.name || requestObj.name === "") return;
        await createTemplate(requestObj).then((isCreated) => {
            if (isCreated) {
                setVisible(false);
            }
        });
    }

    const renderBody = () => (<>
        <Row>
            <Col>
                <FormBase row={false} controlId={'name'} title={'Namn'}
                          className={'pb-2'}>
                    <CustomTextInput
                        name={'templateName'}
                        disableAutocomplete
                        isEditMode={true}
                        value={requestObj.name}
                        onChange={(e) => setRequestObj({
                            ...initRequestObj,
                            name: e.target.value
                        })}
                        autofocus
                    />
                </FormBase>
            </Col>
        </Row>
    </>);

    const isDisabled = () => {
        return !requestObj.name;
    }

    return <ModalForm
        show={visible}
        size={"sm"}
        hide={() => setVisible(false)}
        title={t('CreateNewTemplate.TEMPLATE_CREATION_1')}
        isEdited={() => requestObj.name !== ""}
        submit={handleCreateTemplate}
        isLoading={isCreatingTemplate}
        disableSubmit={isDisabled()}
    >
        {renderBody()}
    </ModalForm>
};

export default CreateNewTemplate;
