import React from "react";
import { useDeletePositionLevel } from "../../api/PositionLevelApi";
import ModalForm from "../../components/FormComponents/ModalForm";
import { IPositionLevel } from "../../core/interfaces/positionLevel";
import { useTranslation } from "react-i18next";

const RemovePositionLevelModal: React.FC<{
    show: boolean,
    hide: () => void,
    positionLevel: IPositionLevel | null
}> = ({
    show,
    hide,
    positionLevel
}) => {
    const { t } = useTranslation();
    const [remove, isRemoving] = useDeletePositionLevel(positionLevel?.id ?? '');
    if (positionLevel == null)
        return <></>

        return (
            <ModalForm
                show={show}
                hide={hide}
                title={t('RemovePositionLevelModal.EDIT_POSITION_LEVEL_1')}
                isEdited={() => false}
                submit={() => remove().then(_ => hide())}
                submitText={t('RemovePositionLevelModal.YES_1')}
                isLoading={isRemoving}>
                    <p>{t('RemovePositionLevelModal.CONFIRM_DELETE_1')} '{positionLevel.name}'?</p>
            </ModalForm>
        )
    }

export default RemovePositionLevelModal