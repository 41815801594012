import { NodeModel } from "@minoru/react-dnd-treeview";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    useCopyTemplate,
    useCreateNewChecklistFromTemplate,
    useCreateNewDocumentFromTemplate,
    useGetTemplatesByType
} from "../../../api/TemplateApi";
import DataTableFilter from "../../../components/DataTableComponents/DataTableFilter";
import FormBase from "../../../components/FormComponents/FormBase";
import GenericInput from "../../../components/FormComponents/GenericInput";
import ModalForm from "../../../components/FormComponents/ModalForm";
import When from "../../../components/When";
import { NodeType, TemplateActionType, TemplateType } from "../../../core/enums/enums";
import { ITemplateNodeData, ITemplateRequest } from "../../../core/interfaces/template";
import styles from "../../Document/Components/Templates/SaveAsTemplate.module.scss";
import TemplatesDirectoryTree from "../../Document/Components/Templates/TemplatesDirectoryTree";

interface ISelectTemplateModal {
    visible: boolean;
    setVisible: (state: boolean) => void;
    templateActionType: TemplateActionType;
    templateType: TemplateType;
    parentFolderId?: string;
    parentFolderName?: string;
    companyUserId?: string;
}

const SelectTemplateModal = (
    {
        visible,
        setVisible,
        templateActionType,
        templateType,
        parentFolderId,
        parentFolderName,
        companyUserId
    }: ISelectTemplateModal) => {

    const initObject: ITemplateRequest = {
        nodeType: null,
        id: '',
        name: '',
        parentFolderId
    }

    const { t } = useTranslation();

    const [templateRequest, setTemplateRequest] = useState<ITemplateRequest>(initObject);
    const [selectedNode, setSelectedNode] = useState<NodeModel<ITemplateNodeData> | null>(null);

    const [createNewDocumentTemplate, isCreatingNewDocumentTemplate] = useCreateNewDocumentFromTemplate(templateRequest.id);
    const [createNewChecklistTemplate, isCreatingNewChecklistTemplate] = useCreateNewChecklistFromTemplate(templateRequest.id);
    const [copyTemplate, isCopyingTemplate] = useCopyTemplate();

    const { data: templates, isFetching: isFetchingTemplates } = useGetTemplatesByType(templateType, true);
    const [data, setData] = useState<NodeModel<ITemplateNodeData>[]>([]);

    useEffect(() => {
        if (templates) {
            setData(templates.data.data as NodeModel<ITemplateNodeData>[]);
        }
    }, [templates]);

    const clearAll = () => {
        setTemplateRequest(initObject);
        setSelectedNode(null);
    }

    useEffect(() => {
        if (visible) {
            clearAll();
        }
        // eslint-disable-next-line
    }, [visible]);

    const handleSetSelectedNode = (node: NodeModel<ITemplateNodeData>) => {
        if (selectedNode === node) {
            setSelectedNode(null);
            setTemplateRequest(initObject);
        } else {
            setSelectedNode(node);
            const requestCopy = { ...templateRequest };
            requestCopy.id = node.data?.id ?? "";
            requestCopy.name = node.text;
            requestCopy.nodeType = node.data!.nodeType === NodeType.FOLDER ? NodeType.FOLDER : NodeType.DOCUMENT;
            setTemplateRequest(requestCopy);
        }
    }

    const isDisabled = (): boolean => {
        return templateRequest.name === ""
            || templateRequest.id === ""
            || templateRequest.nodeType === null
            || (templateActionType === TemplateActionType.COPY_TEMPLATE && selectedNode?.data?.nodeType !== NodeType.DOCUMENT);
    }

    const handleSelectTemplate = async () => {
        if (isDisabled())
            return;

        const tRequest = {
            ...templateRequest,
            copyFromOtherLibrary: true,
            companyUserId: companyUserId
        };

        switch (templateActionType) {
            case TemplateActionType.COPY_TEMPLATE:
                await copyTemplate(tRequest).then((isCreated) => {
                    if (isCreated) {
                        clearAll();
                    }
                });
                break;

            case TemplateActionType.CREATE_FROM_TEMPLATE:
                if (templateType === TemplateType.DOCUMENT) {
                    await createNewDocumentTemplate(tRequest).then((isCreated) => {
                        if (isCreated) {
                            clearAll();
                        }
                    });
                } else if (templateType === TemplateType.CHECKLIST) {
                    await createNewChecklistTemplate(tRequest).then((isCreated) => {
                        if (isCreated) {
                            clearAll();
                        }
                    });
                }
                break;

            default:
                break;
        }
    }
    const [filterText, setFilterText] = useState("");

    // Find all ancestors of a given node
    const getAncestors = (nodeId: string, nodes: NodeModel<ITemplateNodeData>[]): NodeModel<ITemplateNodeData>[] => {
        const nodeMap = new Map<string, NodeModel<ITemplateNodeData>>();
        nodes.forEach(node => nodeMap.set(node.id.toString(), node));

        const ancestors: NodeModel<ITemplateNodeData>[] = [];
        let currentNodeId = nodeId;

        while (currentNodeId) {
            const node = nodeMap.get(currentNodeId);
            if (node) {
                ancestors.push(node);
                currentNodeId = node.parent.toString() || '';
            } else {
                break;
            }
        }

        return ancestors;
    };

    // Find all descendants of a given node
    const getDescendants = (nodeId: string, nodes: NodeModel<ITemplateNodeData>[]): NodeModel<ITemplateNodeData>[] => {
        const nodeMap = new Map<string, NodeModel<ITemplateNodeData>>();
        nodes.forEach(node => nodeMap.set(node.id.toString(), node));

        const descendants: NodeModel<ITemplateNodeData>[] = [];
        const stack = [nodeId];

        while (stack.length > 0) {
            const currentId = stack.pop();
            if (currentId) {
                const children = nodes.filter(node => node.parent.toString() === currentId);
                descendants.push(...children);
                stack.push(...children.map(child => child.id.toString()));
            }
        }

        return descendants;
    };

    // Get all nodes required for the final tree display
    const getFilteredTree = (data: NodeModel<ITemplateNodeData>[], filterText: string): NodeModel<ITemplateNodeData>[] => {
        // Filter nodes
        const filteredNodes = data.filter(node => node.text.toLowerCase().includes(filterText.toLowerCase()));

        // Collect all ancestors and descendants of filtered nodes
        const relevantNodeSet = new Set<string>();
        filteredNodes.forEach(node => {
            const ancestors = getAncestors(node.id.toString(), data);
            const descendants = getDescendants(node.id.toString(), data);
            ancestors.forEach(ancestor => relevantNodeSet.add(ancestor.id.toString()));
            descendants.forEach(descendant => relevantNodeSet.add(descendant.id.toString()));
            relevantNodeSet.add(node.id.toString());
        });

        // Return nodes that are either filtered nodes, their ancestors, or their descendants
        const result = data.filter(node => relevantNodeSet.has(node.id.toString()));
        return result;
    };


    const renderBody = () => (<>
        <Row>
            <Col>
                <DataTableFilter
                    filterText={filterText!}
                    setFilterText={setFilterText!}
                    inputPlaceholder={t('SelectTemplateModal.FILTER_1')}
                />
            </Col>
        </Row>
        <Row className={"mb-2"}>
            <Col>{templateActionType === TemplateActionType.CREATE_FROM_TEMPLATE ? t('SelectTemplateModal.SELECT_FOLDER_TEMPLATE_1') : t('SelectTemplateModal.SELECT_TEMPLATE_1')}</Col>
        </Row>
        <Row>
            <Col className={styles.templatesDirectoryTreeBorder}>
                <TemplatesDirectoryTree
                    templatesData={getFilteredTree(data, filterText)}
                    onClickNode={handleSetSelectedNode}
                    selectedNode={selectedNode}
                />
            </Col>
        </Row>
        {selectedNode?.data?.nodeType === NodeType.DOCUMENT && (
            <Row>
                <Col>
                    <FormBase
                        row={false}
                        controlId={'formFirstName'}
                        title={templateType === TemplateType.DOCUMENT ? t('SelectTemplateModal.DOCUMENT_NAME_1') : t('SelectTemplateModal.CHECKLIST_NAME_1')}
                        className={'pb-2'}
                    >
                        <GenericInput<ITemplateRequest>
                            name={'name'}
                            isEditMode={true}
                            object={templateRequest}
                            setValue={(v) => setTemplateRequest(v)}
                            autofocus={true}
                        />
                    </FormBase>
                </Col>
            </Row>
        )}
    </>);

    return <ModalForm
        show={visible}
        size={"lg"}
        hide={() => setVisible(false)}
        title={
            templateActionType === TemplateActionType.CREATE_FROM_TEMPLATE
                ? (templateType === TemplateType.DOCUMENT ? t('SelectTemplateModal.TEMPLATE_CREATION_1') : t('SelectTemplateModal.TEMPLATE_CREATION_2')) 
                : t('SelectTemplateModal.COPY_TEMPLATE_1')
        }
        isEdited={() => templateRequest.name !== ""}
        submit={handleSelectTemplate}
        submitText={templateActionType === TemplateActionType.CREATE_FROM_TEMPLATE ? t('SelectTemplateModal.SAVE_1') : t('SelectTemplateModal.COPY_1')}
        isLoading={isCreatingNewDocumentTemplate || isCreatingNewChecklistTemplate || isCopyingTemplate || isFetchingTemplates}
        centered={false}
        disableSubmit={isDisabled()}
    >
        {renderBody()}
        <When condition={!!parentFolderName}>
            <p><i>{t('SelectTemplateModal.CREATED_IN_PARENT_FOLDER')} {parentFolderName}/</i></p>
        </When>
    </ModalForm>
}
export default SelectTemplateModal
