import { useTranslation } from "react-i18next";
import CustomTextAreaInput from "../../../components/FormComponents/CustomTextAreaInput";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function DescriptionForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'formDescription'} title={t('DescriptionForm.EVENT_DESCRIPTION_1')} colAs={props.editMode ? 'large' : 'sm'}>
        <CustomTextAreaInput
            isEditMode={props.editMode}
            rows={5}
            name={"description"}
            testId={'formDescription'}
            value={props.newIncident.description ?? ''}
            onChange={props.onChange}
            required />
    </FormProvider>;
}
