import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

export const useSearchParam = (tokenName: string, navigateOnFailureUrl?: string): string => {
    const [token, setToken] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        let t = searchParams.get(tokenName);
        if (t == null) {
            if (navigateOnFailureUrl) {
                navigate(navigateOnFailureUrl);
            }
        }
        setToken(t ?? '');
    }, [searchParams, navigate, navigateOnFailureUrl, tokenName])

    return token;
}
