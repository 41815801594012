import {useEffect} from "react";
import {Navigate, Outlet, useLocation, useParams} from "react-router-dom";
import {Role} from "../core/enums/enums";
import {useAppDispatch} from "../store/hooks";
import {setSamgSelectedCompanyThunk} from "../store/slices/authSlice";
import {useGetUser} from "../hooks/useAuth";

interface IProtectedRoutes {
    allowedRoles: Role[];
}

const ProtectedRoutes = ({allowedRoles}: IProtectedRoutes) => {
    const user = useGetUser();
    const {cId} = useParams();
    const location = useLocation();
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (user.role === Role.SUPERADMIN && (cId && cId !== user.companyId)) {
            dispatch(setSamgSelectedCompanyThunk(cId));
        }
    }, [dispatch, user.role, user.companyId, cId]);

    if (!user.authenticated) return <Navigate to="/login" state={{from: location}} replace/>

    // Restrictions doesn't affect Superadmins
    if (user.role !== Role.SUPERADMIN) {
        if (location.pathname === "/") return <Navigate to={`c/${user.companyId}`} replace/>
        if (cId && user.companyId !== cId) return <Navigate to="/restricted" replace/>
    }

    return user.role && (user.role === Role.SUPERADMIN || allowedRoles.includes(user.role))
        ? <Outlet/>
        : user?.authenticated
            ? <Navigate to="/restricted" state={{from: location}} replace/>
            : <Navigate to="/login" state={{from: location}} replace/>
}

export default ProtectedRoutes;