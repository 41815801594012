import Form from "react-bootstrap/esm/Form";
import {ITestAble} from "../../core/interfaces/common";
import When from "../When";
import {CSSProperties, useEffect, useRef, useState} from "react";

interface ICustomTextAreaInput {
    isEditMode: boolean;
    rows: number;
    name: string;
    value: string;
    onChange: (e: any) => void;
    required?: boolean;
    autoComplete?: boolean;
    disabled?: boolean;
    showInputFieldInReadOnly?: boolean;
    defaultValueDash?: boolean;
    onFocusEdit?: boolean;
    checklistViewTable?: boolean;
    style?: CSSProperties;
    title?: string;
    defaultHeight?: boolean;
    disableResize?: boolean;
}

const CustomTextAreaInput = (
    {
        isEditMode,
        rows,
        name,
        value,
        onChange,
        required,
        testId,
        disabled,
        autoComplete,
        showInputFieldInReadOnly,
        defaultValueDash = true,
        onFocusEdit = false,
        checklistViewTable,
        style,
        title,
        defaultHeight,
        disableResize,

    }: ICustomTextAreaInput & ITestAble) => {
    const ref = useRef<any>()
    const [forceEdit, setForceEdit] = useState(false);

    useEffect(() => {
        if (forceEdit) {
            ref.current.focus()
            if (value) {
                ref.current.setSelectionRange(value.length, value.length)
            }
        }
    }, [forceEdit]);

    const [initHeight, setInitHeight] = useState("50px");

    useEffect(() => {
        if (defaultHeight) return;
        const lineHeight = 25;
        const numberOfLines = rows || (value ? value.split('\n').length : 1);
        const result = numberOfLines * lineHeight;
        setInitHeight(result < 50 ? "50px" : `${result}px`);
    }, []);

    const handleInputChange = (e: any) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight < 50 ? "50px" : e.target.scrollHeight}px`;
        onChange(e);
    }

    const setStyles = () => {
        let styles = {};
        if (style) {
            styles = style
        }
        if (!defaultHeight) {
            styles = {
                ...style,
                height: initHeight
            }
        }
        return styles;
    }

    return <When
        condition={forceEdit || isEditMode}
        otherwise={
            showInputFieldInReadOnly ? (
                <Form.Control
                    ref={ref}
                    style={setStyles()}
                    className={checklistViewTable ? `checklistTableFields checklistTableTextArea ${disableResize ? 'disableResize' : ''}` : ""}
                    disabled
                    data-test-id={testId}
                    as="textarea"
                    rows={rows}
                    value={value ? value : defaultValueDash ? "-" : undefined}
                    readOnly
                    title={title}
                />
            ) : <span onClick={() => onFocusEdit && setForceEdit(true)} data-test-id={testId} title={title} style={{
                overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap'

            }}>{value ? value : defaultValueDash ? "-" : undefined}</span>
        }>
        <Form.Control
            style={setStyles()}
            className={checklistViewTable ? `checklistTableFields checklistTableTextArea ${disableResize ? 'disableResize' : ''}` : ""}
            ref={ref}
            disabled={disabled}
            as="textarea"
            rows={rows}
            name={name}
            onChange={handleInputChange}
            value={value}
            required={required}
            autoComplete={autoComplete ? "on" : "off"}
            onBlur={() => setForceEdit(false)}
            title={title}
            />
    </When>
};

export default CustomTextAreaInput;