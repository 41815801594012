import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/Row";

const Hr: React.FC<{ title: string, children: JSX.Element | JSX.Element[] }> = ({title, children}) => {
    return <><Row>
        <Col sm={1}>
            <b>{title}</b>
        </Col>
        <Col sm={11}>
            <hr/>
        </Col>
    </Row>
        <Row style={{marginLeft: 1}}>
            {children}
        </Row>
    </>

}
export default Hr