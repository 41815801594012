import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowAssessmentScopeSeverityField, IDataRowProps } from "../../../../../../core/interfaces/document";
import DataRowActionButtons from "./DataRowActionButtons";

const AssessmentScopeSeverity = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        isFieldEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isAnswerMode = false,
        isActive,
    }: IDataRowProps<IDataRowAssessmentScopeSeverityField>) => {

    const colorPositive = "rgba(0, 255, 0, 0.25)";
    const colorNegative = "rgba(255, 0, 0, 0.25)";
    const colorNeutral = "rgba(255, 193, 7, 0.5)";

    const {t} = useTranslation();
    const [result, setResult] = useState<string>("");

    const onChange = (e: any) => {
        setDataRow({
            ...dataRow,
            [e.target.name]: e.target.value
        });
    }

    const onChangeValue = (e: any) => {
        const value = !isNaN(e.target.value) ? parseInt(e.target.value) : undefined;
        setDataRow({
            ...dataRow,
            [e.target.name]: value
        });
    }


    const calculateScopeSeverity = (scope?: number, severity?: number) => {
        if ((!scope || isNaN(scope)) || (!severity || isNaN(severity))) return setResult("");
        if (scope < 1 || scope > 5 || severity < 1 || severity > 5) return setResult("");

        if (scope * severity <= 5) return setResult(colorPositive)
        if (scope * severity >= 6 && scope * severity <= 9) return setResult(colorNeutral)
        if (scope * severity >= 10) return setResult(colorNegative)

        return setResult("")
    }

    useEffect(() => {
        calculateScopeSeverity(dataRow.assessmentScope, dataRow.assessmentSeverity);
    }, [dataRow.assessmentScope, dataRow.assessmentSeverity]);

    return <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
        <When condition={!checklistViewTable}>
            <Col md={3}>
                <CustomTextInput
                    disabled={!isHeaderEnabled}
                    isEditMode={isEditMode}
                    name={"header"}
                    placeholder={t('AssessmentScopeSeverity.EXAMPLE_SCOPE_1')}
                    value={getDataRowHeader(dataRow)}
                    onChange={onChange}
                    disableAutocomplete
                />
            </Col>
        </When>
        <When condition={!!checklistViewTable}>
            <Col>
                <div>
                    <CustomSelect
                        checklistViewTable={checklistViewTable}
                        style={{backgroundColor: result}}
                        disabled={!isFieldEnabled}
                        isEditMode={isEditMode || isAnswerMode}
                        name={"assessmentScope"}
                        value={dataRow.assessmentScope}
                        onChange={onChangeValue}
                        showInputFieldInReadOnly
                        defaultValue={undefined}
                        options={<>
                            <option value={undefined}>{t('AssessmentScopeSeverity.SELECT_SCOPE_1')}</option>
                            <option value={1}>{t('AssessmentScopeSeverity.MARGINAL_1')}</option>
                            <option value={2}>{t('AssessmentScopeSeverity.CALL_1')}</option>
                            <option value={3}>{t('AssessmentScopeSeverity.MODERATE_1')}</option>
                            <option value={4}>{t('AssessmentScopeSeverity.BIG_1')}</option>
                            <option value={5}>{t('AssessmentScopeSeverity.VERY_LARGE_1')}</option>
                        </>}
                    />
                </div>
                <div>
                    <CustomSelect
                        checklistViewTable={checklistViewTable}
                        style={{backgroundColor: result}}
                        disabled={!isFieldEnabled}
                        isEditMode={isEditMode || isAnswerMode}
                        name={"assessmentSeverity"}
                        value={dataRow.assessmentSeverity}
                        onChange={onChangeValue}
                        showInputFieldInReadOnly
                        defaultValue={undefined}
                        options={<>
                            <option value={undefined}>{t('AssessmentScopeSeverity.SELECT_SEVERITY_1')}</option>
                            <option value={1}>{t('AssessmentScopeSeverity.MARGINAL_2')}</option>
                            <option value={2}>{t('AssessmentScopeSeverity.LOW_1')}</option>
                            <option value={3}>{t('AssessmentScopeSeverity.MODERATE_2')}</option>
                            <option value={4}>{t('AssessmentScopeSeverity.HIGH_1')}</option>
                            <option value={5}>{t('AssessmentScopeSeverity.VERY_HIGH_1')}</option>
                        </>}
                    />
                </div>
            </Col>
        </When>


        <When condition={!checklistViewTable}>
            <Col md={isEditMode && isActive ? 3 : 4}>
                <CustomSelect
                    style={{backgroundColor: result}}
                    disabled={!isFieldEnabled}
                    isEditMode={isEditMode || isAnswerMode}
                    name={"assessmentScope"}
                    value={dataRow.assessmentScope}
                    onChange={onChangeValue}
                    showInputFieldInReadOnly
                    defaultValue={undefined}
                    options={<>
                        <option value={undefined}>{t('AssessmentScopeSeverity.SCOPE_SELECTION_1')}</option>
                        <option value={1}>{t('AssessmentScopeSeverity.MARGINAL_2')}</option>
                        <option value={2}>{t('AssessmentScopeSeverity.CALL_2')}</option>
                        <option value={3}>{t('AssessmentScopeSeverity.MODERATE_3')}</option>
                        <option value={4}>{t('AssessmentScopeSeverity.LARGE_1')}</option>
                        <option value={5}>{t('AssessmentScopeSeverity.VERY_LARGE_2')}</option>
                    </>}
                />
            </Col>
            <Col md={isEditMode && isActive ? 4 : 5}>
                <CustomSelect
                    style={{backgroundColor: result}}
                    disabled={!isFieldEnabled}
                    isEditMode={isEditMode || isAnswerMode}
                    name={"assessmentSeverity"}
                    value={dataRow.assessmentSeverity}
                    onChange={onChangeValue}
                    showInputFieldInReadOnly
                    defaultValue={undefined}
                    options={<>
                        <option value={undefined}>{t('AssessmentScopeSeverity.SELECT_SEVERITY_2')}</option>
                        <option value={1}>{t('AssessmentScopeSeverity.MARGINAL_3')}</option>
                        <option value={2}>{t('AssessmentScopeSeverity.LOW_2')}</option>
                        <option value={3}>{t('AssessmentScopeSeverity.MODERATE_4')}</option>
                        <option value={4}>{t('AssessmentScopeSeverity.HIGH_2')}</option>
                        <option value={5}>{t('AssessmentScopeSeverity.VERY_HIGH_2')}</option>
                    </>}
                />
            </Col>
        </When>

        <When condition={isEditMode && isActive}>
            <DataRowActionButtons
                dataRow={dataRow}
                removeDataRow={removeDataRow}
                copyDataRow={copyDataRow}
            />
        </When>
    </Row>
};
export default AssessmentScopeSeverity;
