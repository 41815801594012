import styles from './formContainer.module.scss'

interface FormContainerProps {
    extraStyling?: string[];
    children: JSX.Element | JSX.Element[];
}
const FormContainer: React.FC<FormContainerProps> = ({extraStyling, children}) => {
    return (<div className={`${styles.formContainer} ${(extraStyling ?? []).join(" ")}`}>
        {children}
    </div>)
}

export default FormContainer