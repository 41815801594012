import { Button, ButtonProps, Spinner } from "react-bootstrap";
import getIcon, { Icon } from "../assets/Icons/IconClassNames";

interface ISpinnerButton extends ButtonProps {
    text: string;
    isLoading: boolean;
    icon?: Icon;
}

const SpinnerButton = ({
    text,
    isLoading,
    icon,
    ...props
}: ISpinnerButton) => {
    return <Button
        className="spinner-btn"
        {...props}
        disabled={props.disabled || isLoading}>
        {isLoading ?
            <Spinner
                as="span"
                size="sm"
                aria-hidden="true"
                animation="border" />
            :
            icon ? <span><i className={`${getIcon(icon)} pe-2`} />{text}</span> : <span>{text}</span>}
    </Button>
}

export default SpinnerButton;