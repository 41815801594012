import {Modal} from "react-bootstrap";

interface ISimpleModal {
    show: boolean;
    size: "xl" | "lg" | "sm" | undefined;
    header: JSX.Element[] | JSX.Element;
    body: JSX.Element[] | JSX.Element;
    footer: JSX.Element[] | JSX.Element;
    closeButton?: boolean;
    centered?: boolean;
    onHide?: () => void;
    fullscreen?: true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
}

const SimpleModal = (
    {
        show,
        size,
        header,
        body,
        footer,
        closeButton,
        centered,
        onHide,
        fullscreen,
    }: ISimpleModal) => {

    return <Modal fullscreen={fullscreen} show={show} size={size} centered={centered ?? true} onHide={onHide}>
        <Modal.Header closeButton={closeButton}>
            <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {body}
        </Modal.Body>
        <Modal.Footer>
            {footer}
        </Modal.Footer>
    </Modal>
};

export default SimpleModal;