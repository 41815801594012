import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {ModalState, modalTrigger} from "../store/slices/changesModalSlice";
import usePrevious from "./usePrevious";


export const usePrompt = (): (promptFunction: () => void) => void => {
    const [id] = useState(Math.floor(Math.random() * 99999).toString());
    const modalState = useAppSelector(state => state.changesModal.state)
    const modalHolder = useAppSelector(state => state.changesModal.modalHolder);
    const trigger = useAppSelector(state => state.changesModal.trigger)
    const previousModalState = usePrevious(modalState);
    const dispatch = useAppDispatch();

    const [toExecute, setToExecute] = useState<() => void>(() => NaN)

    useEffect(() => {
        if (trigger === id && modalState === ModalState.IDLE) {
            if (previousModalState === ModalState.PENDING || previousModalState === ModalState.APPROVED) {
                toExecute();
            }
        }
    }, [id, modalState, previousModalState, toExecute, trigger])

    return (promptFunction) => {
        if (modalHolder) {
            setToExecute(() => promptFunction)
            dispatch(modalTrigger(id));
        } else {
            promptFunction()
        }
    }
}