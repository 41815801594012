import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CreateUser } from "../../api/UserApi";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import { capitalizeEachWord } from "../../core/helpers/strings/stringHelper";
import { toastSuccess } from "../../core/helpers/toastHelper";
import { createNewSuperadmin, createNewUser, validateEmail } from "../../core/helpers/userHelper";
import { ISuperadmin, IUserResponse } from "../../core/interfaces/user";
import { AppQueryKey, useInvalidateQuery } from "../../core/queryKeys";
import useUpdatableObject from "../../hooks/useUpdatableObject";
import { addToast } from "../../store/slices/uiSlice";

const NewSuperadminModal: React.FC<{ show: boolean, hide: () => void }> = ({show, hide}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const invalidateUsers = useInvalidateQuery(AppQueryKey.USERS);
    const [updated, setUpdated, isEdited, submit, loading] = useUpdatableObject<ISuperadmin, IUserResponse>(createNewSuperadmin(), CreateUser, {
        getErrorToast: (status: number): { title: string, message: string } => {
            const info = {title: t('NewSuperadminModal.ERROR_OCCURRED_1'), message: t('NewSuperadminModal.PLEASE_TRY_AGAIN_SOON_1')}
            if (status === 409) {
                info.message = t('NewSuperadminModal.EMAIL_ALREADY_EXISTS_1')
            }
            return info;
        },

    });
    const [errors, setErrors] = useState({
        firstName: true,
        lastName: true,
        email: true
    });


    useEffect(() => {
        setErrors({
            firstName: true,
            lastName: true,
            email: true,
        });
        setUpdated({
            ...createNewUser()
        });
    }, [show, setUpdated])

    const validate = (user: ISuperadmin, name: string) => {

        switch (name) {
            case 'firstName':
            case 'lastName':
                user[name] = capitalizeEachWord(user[name]);
                errors[name] = !user[name].length;
                break;
            case 'email':
                errors.email = !validateEmail(user.email);
                break;
        }

        setErrors(errors)
        return user;
    }
    const commonProps = {
        isEditMode: true,
        object: updated,
        errorObject: errors,
        setValue: setUpdated,
        onChangeMiddleWare: validate,
    }

    const handleSubmit = () => {

        submit().then(async createdUser => {
            hide();
            dispatch(addToast(toastSuccess(t('NewSuperadminModal.SUCCESS_1'), `${t('NewSuperadminModal.USER_CREATED_1')} ${createdUser.person.firstName} ${createdUser.person.lastName} ${t('NewSuperadminModal.USER_CREATED_2')}`)));
            await invalidateUsers();
        }).catch(_ => NaN);
    }

    return (
        <ModalForm show={show} hide={hide} title={t('NewSuperadminModal.SUPERADMIN_1')} isEdited={isEdited} submit={handleSubmit} isLoading={loading} disableSubmit={!!Object.values(errors).filter(s => s).length}>
            <FormBase row={false} controlId={'firstName'} title={t('NewSuperadminModal.FIRSTNAME_1')} className={'pb-2'}>
                <GenericInput name={'firstName'} {...commonProps} />
            </FormBase>
            <FormBase row={false} controlId={'lastName'} title={t('NewSuperadminModal.SURNAME_1')} className={'pb-2'}>
                <GenericInput name={'lastName'} {...commonProps} />
            </FormBase>
            <FormBase row={false} controlId={'email'} title={t('NewSuperadminModal.EMAIL_1')} className={'pb-2'}>
                <GenericInput name={'email'} {...commonProps} />
            </FormBase>
        </ModalForm>
    );
};

export default NewSuperadminModal;
