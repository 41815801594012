import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return <>
        <h4 className="text-center">{t('AccessDenied.ACCESS_DENIED_1')}</h4>
        <p className="text-center">{t('AccessDenied.PERMISSION_DENIED_1')}</p>
        <div className="text-center"><Button variant="outline-primary" onClick={() => navigate("/")}>{t('AccessDenied.HOME_1')}</Button></div>
    </>
}

export default AccessDenied;
