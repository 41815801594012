import { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomTextInput from "../../components/FormComponents/CustomTextInput";
import SpinnerButton from "../../components/SpinnerButton";
import { useAppSelector } from "../../store/hooks";
import { STATUS_PENDING } from "../../store/types";

interface ILoginForm {
    onSubmit: (loginReq: IUserLoginRequest) => void;
}

const LoginForm = ({
                       onSubmit
                   }: ILoginForm) => {

    const {t} = useTranslation();
    const isLoading: boolean = useAppSelector((state) => state.user.status) === STATUS_PENDING;

    const [request, setRequest] = useState<IUserLoginRequest>({username: "", password: ""});

    const handleChange = (e: any) => {
        const newVal = e.target.value;
        setRequest({
            ...request,
            [e.target.name]: newVal
        });
    }


    const validateFormData = () => {
        return true;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        validateFormData();

        return onSubmit(request);
    }


    return <>
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Form.Group as={Row} className="pt-1 pb-2" controlId="formUsername">
                        <Form.Label column lg={{span: 2, offset: 1}} xxl={{span: 2, offset: 2}}>{t('LoginForm.E_MAIL_1')}</Form.Label>
                        <Col lg={{span: 8}} xxl={{span: 6}}>
                            <CustomTextInput
                                type={"email"}
                                isEditMode={true}
                                name={"username"}
                                value={request.username}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="pt-2 pb-3" controlId="formPassword">
                        <Form.Label column lg={{span: 2, offset: 1}} xxl={{span: 2, offset: 2}}>{t('LoginForm.PASSWORD_1')}</Form.Label>
                        <Col lg={{span: 8}} xxl={{span: 6}}>
                            <CustomTextInput
                                type="password"
                                isEditMode={true}
                                name={"password"}
                                value={request.password}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={{offset: 3, span: 3}} xxl={{offset: 4}}>
                    <SpinnerButton
                        id="loginBtn"
                        variant="primary"
                        type="submit"
                        text={t('LoginForm.LOGIN_1')}
                        isLoading={isLoading}
                    />
                </Col>
                <Col lg={{span: 5}} xxl={{span: 5}}>
                    <Link to={"/forgot-password"}>
                        <p className={"mt-2 mb-0"}><u>{t('LoginForm.FORGOT_PASSWORD_1')}</u></p>
                    </Link>
                </Col>
            </Row>
        </Form>
    </>
}

export default LoginForm;
