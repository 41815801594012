import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { DateToYYYYMMDD } from "../../../core/helpers/dates/datesHelper";
import { IDocument } from "../../../core/interfaces/document";


type DocumentModifiedAtFormProps = {
    document: IDocument;
}

export const DocumentModifiedAtForm: React.FC<DocumentModifiedAtFormProps> = (props) => {
    const {t} = useTranslation();
    return <Form.Group as={Row}>
        <Row>
            <Form.Label column className={"pt-1 pb-0"}><strong>{t('DocumentModifiedAtForm.UPDATED_DATE_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <span>{DateToYYYYMMDD(props.document.modifiedAt)}</span>
            </Col>
        </Row>
    </Form.Group>
}
