import i18n from "i18next";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../components/FormComponents/CustomSelect";
import When from "../../../components/When";
import { IAbsenceInterval, IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";

const formatValue = (value?: string) => {
    if (value === undefined) return value;
    return !value.includes("-") && value.length > 1 ? (value + i18n.t('SicknessAbsenceForm.OR_MORE_1')) : value;
}

export function SicknessAbsenceForm(props: { editMode: boolean, newIncident: IIncident, absenceIntervals: IAbsenceInterval[], onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'sicknessAbsenceIntervalId'} title={t('SicknessAbsenceForm.NUMBER_OF_SICK_DAYS_DUE_TO_THE_INCIDENT_1')}>
        <When condition={props.editMode} otherwise={
            <span>{formatValue(props.absenceIntervals.find(v => v.id === props.newIncident.sicknessAbsenceIntervalId)?.value) ?? t('SicknessAbsenceForm.NOT_SPECIFIED_1')}</span>}>
            <CustomSelect
                isEditMode={props.editMode}
                size="sm"
                name={"sicknessAbsenceIntervalId"}
                value={(props.newIncident.sicknessAbsenceIntervalId) + ''}
                options={<>
                    <option value="">{t('SicknessAbsenceForm.NOT_SPECIFIED_1')}</option>
                    {props.absenceIntervals?.map(interval =>
                        <option key={interval.id} value={interval.id}>{formatValue(interval.value)} {t('SicknessAbsenceForm.DAYS')}</option>
                    )}
                </>}
                onChange={(e) => props.onChange({
                    target: {
                        name: e.target.name,
                        value: e.target.value === "" ? null : e.target.value,
                    }
                })}
            />
        </When>
    </FormProvider>
}
