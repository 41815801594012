import _ from "lodash";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "../../../../../components/DragAndDropComponent/DragAndDropComponent";
import FormContainer from "../../../../../components/FormContainer/FormContainer";
import When from "../../../../../components/When";
import { DataRowType } from "../../../../../core/enums/enums";
import { IDocumentModuleChecklist, IDocumentModuleChecklistForm } from "../../../../../core/interfaces/document";
import { useDocumentModulesContext } from "../../DocumentModules";
import { useChecklistContext } from "../ChecklistContext";
import ChecklistFormActionButtons from "./ChecklistFormActionButtons";
import { useChecklistFormContext } from "./ChecklistFormContext";
import ChecklistFormDataRows from "./ChecklistFormDataRows";
import ChecklistFormHeader from "./ChecklistFormHeader";
import AddDataRowsForm from "./Forms/AddDataRowsForm";
import { SendToActionPlan } from "./SendToActionPlan";
import styles from "./checklistForm.module.scss";

interface IChecklistForm {
    index: number;
    renderHeaderInTableView?: boolean;
    form: IDocumentModuleChecklistForm;
}

const ChecklistForm = (
    {
        index,
        form
    }: IChecklistForm) => {

    const {
        activeModule,
        updateActiveModule,
        setSaveState,
        isAnswerMode
    } = useDocumentModulesContext();


    const {
        documentContext: {isEditMode},
        isHideActionPlan
    } = useChecklistContext()
    const {
        addDataRow: handleAddDataRow,
        updateDescription,
        updateTitle,
        updateSendToActionPlan: setSendToActionPlan,
        removeDataRow
    } = useChecklistFormContext();

    const [isExpanded, setExpanded] = useState(true);

    const dropItem = (currentIndex: number, newIndex: number) => {
        if (!activeModule || !activeModule.documentModuleChecklist) return;
        const updatedChecklist: IDocumentModuleChecklist = _.cloneDeep(activeModule.documentModuleChecklist);
        const formsCopy: IDocumentModuleChecklistForm[] = [...updatedChecklist.documentModuleChecklistForms];
        const movedForm = formsCopy[currentIndex];
        formsCopy.splice(currentIndex, 1);
        formsCopy.splice(newIndex, 0, movedForm);

        for (let i = 0; i < formsCopy.length; i++) {
            formsCopy[i].sequenceNo = i;
        }

        updatedChecklist.documentModuleChecklistForms = formsCopy;
        updateActiveModule({...activeModule, documentModuleChecklist: updatedChecklist});
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <DraggableItem
                allowedToDrag={isEditMode}
                allowedToDrop={isEditMode}
                type={"LIST_FORM"}
                id={form.id}
                index={index}
                key={index}
                onDrop={dropItem}
            >
                <FormContainer extraStyling={[false ? styles.edited : ""]}>
                    <ChecklistFormHeader
                        index={index}
                        isHeaderLocked={false}
                        isActiveModule={isEditMode}
                        isExpanded={isExpanded}
                        setExpanded={setExpanded}
                        title={form.title}
                        setTitle={updateTitle}
                        description={form.description}
                        setDescription={updateDescription}
                    />
                    <When
                        condition={isEditMode}
                        otherwise={<>
                            <ChecklistFormDataRows
                                moduleId={module.id}
                                formId={form.id}
                                dataRows={form.documentModuleChecklistFormDataRows}
                            />
                            <SendToActionPlan {...{
                                form,
                                hidden: isHideActionPlan,
                                setSendToActionPlan,
                                handleAddDataRow,
                                handleUpdateDataRows: () => removeDataRow(form.documentModuleChecklistFormDataRows.find(s => s.checklistFormDataRowTypeSymbol == DataRowType.ACTION_PLAN_FIELD)!),
                                setSaveState,
                                isActive: (isEditMode) || isAnswerMode
                            }}/>
                        </>}
                    >
                        <When condition={isExpanded}>
                            <ChecklistFormDataRows
                                moduleId={module.id}
                                formId={form.id}
                                dataRows={form.documentModuleChecklistFormDataRows}
                            />
                            <SendToActionPlan {...{
                                form,
                                handleAddDataRow,
                                handleUpdateDataRows: () => removeDataRow(form.documentModuleChecklistFormDataRows.find(s => s.checklistFormDataRowTypeSymbol == DataRowType.ACTION_PLAN_FIELD)!),
                                setSaveState,
                                setSendToActionPlan,
                                hidden: isHideActionPlan
                            }}/>
                            <AddDataRowsForm addDataRow={handleAddDataRow}/>
                            <ChecklistFormActionButtons index={index} form={form}/>
                        </When>
                    </When>
                </FormContainer>
            </DraggableItem>
        </DndProvider>
    );
};

export default ChecklistForm;