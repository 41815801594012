import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsers } from "../../../api/DocumentApi";
import CustomSelect from "../../../components/FormComponents/CustomSelect";
import When from "../../../components/When";
import { toastError } from "../../../core/helpers/toastHelper";
import { IPerson } from "../../../core/interfaces/common";
import { IDocument } from "../../../core/interfaces/document";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";


interface IResponsibleByForm {
    isEditMode: boolean;
    document: IDocument;
    onChange: (responsibleBy: IPerson | undefined) => void;
}

const ResponsibleByForm = (
    {
        isEditMode,
        document,
        onChange,
    }: IResponsibleByForm) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [companyUsers] = useGetCompanyUsers();

    const handleChange = (e: any) => {
        const value = e.target.value ?? "";
        if (!value) {
            return onChange(undefined);
        }
        const selectedPerson = companyUsers.find(person => person.userId === value);
        if (!selectedPerson) {
            dispatch(addToast(toastError(t('ResponsibleByForm.ERROR_OCCURRED_1'), t('ResponsibleByForm.ERROR_SELECTION_USER_1'))));
            return;
        }
        onChange({id: selectedPerson.userId, name: selectedPerson.name});
    }

    return <Form.Group as={Row}>
        <Row>
            <Form.Label column className={"pt-1 pb-0"}><strong>{t('ResponsibleByForm.RESPONSIBLE_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <When condition={isEditMode}
                      then={<CustomSelect
                          isEditMode={isEditMode}
                          name={'responsibleBy'}
                          onChange={handleChange}
                          value={document.responsibleBy?.id}
                          options={<>
                              <option key={''} value={''}>{t('ResponsibleByForm.SELECT_RESPONSIBLE_1')}</option>
                              {companyUsers.map(person => <option key={person.userId}
                                                                  value={person.userId}>{person.name}</option>)}
                          </>}
                      />}
                      otherwise={<span>{document.responsibleBy?.name ?? "-"}</span>}/>
            </Col>
        </Row>
    </Form.Group>
};

export default ResponsibleByForm;
