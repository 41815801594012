import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { IDocument } from "../../../core/interfaces/document";


type VersionFormProps = {
    document: IDocument;
}

export const VersionForm: React.FC<VersionFormProps> = (props) => {
    const {t} = useTranslation();
    return <Form.Group as={Row}>
        <Row>
            <Form.Label column className={"pt-0 pb-0"}><strong>{t('VersionForm.VERSION_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <span>{props.document.version ?? "-"}</span>
            </Col>
        </Row>
    </Form.Group>
}
