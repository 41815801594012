import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { Icon, IconSize, getRegularIcon } from "../../../assets/Icons/IconClassNames";
import When from "../../../components/When";
import { IIncident, IncidentFile } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";



type FileListProps = {
    files: IncidentFile[] | File[],
    editMode: boolean,
    downloadFile?: (incidentFile: IncidentFile) => void,
    removeFile: (index: number) => void
};

const FileList: React.FC<FileListProps> = (props) => (
    <ul style={{listStyle: 'none', overflow: 'hidden', marginTop: props.editMode ? 10 : 0, paddingLeft: 1, marginBottom: 1}}>
        {props.files?.map((v, i) =>
            <li key={i} className={!props.editMode ? 'fileDownloadHover' : ''}>
            <span onClick={() => {
                if (!props.editMode && props.downloadFile && 'id' in v) {
                    props.downloadFile(v);
                }
            }}>
            <i className={v.type.includes("image") ? getRegularIcon(Icon.IMAGE) : getRegularIcon(Icon.PDF)}></i> {v.name} {'size' in v ? `(${Math.floor(v.size / 1000)}kb)` : ''}
            </span>
                <When condition={props.editMode}>
                    <i className={[getRegularIcon(Icon.X, IconSize.XS), "fileDownloadHover"].join(" ")}
                       onClick={() => props.removeFile(i)}
                       style={{color: 'red'}}/>
                </When>
            </li>
        )}
    </ul>)


type FileUploadSectionProps = {
    editMode: boolean,
    newIncident: IIncident,
    files: File[],
    downloadFile: (id: IncidentFile) => void,
    setFiles: (files: File[]) => void,
    setIncidentFiles: (files: IncidentFile[]) => void,
};

export function FileUploadSection(props: FileUploadSectionProps) {
    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedIncidentFile, setSelectedIncidentFile] = useState<IncidentFile | undefined>({} as IncidentFile);

    const handleNewFile = () => {
        if (inputRef?.current?.files) {
            props.setFiles([...(props.files ?? []), ...Array.from(inputRef.current.files)])
        }
    }

    const handleDeleteNewFile = (index: number) => {
        let files = [...props.files];
        files.splice(index, 1);
        props.setFiles([...files]);
    }

    const handleDeleteOldFile = () => {
        const incidentFiles = [...props.newIncident.files];
        incidentFiles.splice(incidentFiles.findIndex(f => f.id === selectedIncidentFile?.id), 1);
        props.setIncidentFiles(incidentFiles);
    }


    return <FormProvider controlId={'file-upload'} title={'Ladda upp bilder/dokument'}>
        <input onInputCapture={handleNewFile} ref={inputRef} type={"file"} id={"incident-file"} multiple hidden
               accept="image/png, image/jpeg, application/pdf"/>
        <Button onClick={() => inputRef?.current?.click()} hidden={!props.editMode}>{t('FileUploadSection.SELECT_FILES_1')}</Button>
        <When condition={props.files?.length > 0 && props.editMode}>
            <FileList files={props.files} editMode={props.editMode} removeFile={handleDeleteNewFile}/>
        </When>
        <When condition={props.newIncident.files?.length > 0}
              otherwise={props.editMode ? <></> : <span>{t('FileUploadSection.NO_FILES_SELECTED_1')}</span>}>
            <When condition={props.editMode}>
                <p style={{marginTop: 10}}>{t('FileUploadSection.PREVIOUSLY_UPLOADED_FILES_1')}</p>
            </When>
            <FileList files={props.newIncident?.files} editMode={props.editMode} downloadFile={props.downloadFile}
                      removeFile={(index: number) => {
                          setSelectedIncidentFile(props.newIncident.files[index]);
                          setShowModal(true)
                      }}/>
        </When>
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('FileUploadSection.DELETE_FILE_1')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('FileUploadSection.DELETE_CONFIRMATION_1')}</p>
                <i className={selectedIncidentFile?.type?.includes(t('FileUploadSection.IMAGE_1')) ? getRegularIcon(Icon.IMAGE) : getRegularIcon(Icon.PDF)}></i>
                <b> {selectedIncidentFile?.name}</b>
                <br/>
                {t('FileUploadSection.DISCLAIMER_1')}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'secondary'} onClick={() => setShowModal(false)}>{t('FileUploadSection.CANCEL_1')}</Button>
                <Button variant={'danger'} onClick={() => {
                    setShowModal(false)
                    handleDeleteOldFile()
                }}>{t('FileUploadSection.DELETE_1')}</Button>
            </Modal.Footer>
        </Modal>
    </FormProvider>;
}
