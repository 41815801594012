import { FormEvent, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useDownloadActionPlanDataRowImageAttachment } from "../../../../../../api/ActionPlanApi";
import { useDownloadDataRowImageAttachment } from "../../../../../../api/DocumentModuleChecklistApi";
import { Icon, IconSize, getIcon } from "../../../../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../../../../components/ClickableIconButton/ClickableIconButton";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { getBase64 } from "../../../../../../core/helpers/fileHelper";
import { IDataRowImageField, IDataRowProps } from "../../../../../../core/interfaces/document";
import { useDocumentModulesContext } from "../../../DocumentModules";
import DataRowActionButtons from "./DataRowActionButtons";


const ImageDataRow = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        isFieldEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isAnswerMode = false,
        isActive,
        moduleId,
        formId,
        isFromReportForm,
        isFromActionPlan,
        actionPlanId,
    }: IDataRowProps<IDataRowImageField>) => {

    const {t} = useTranslation();
    
    const {
        isDocumentPreview,
        previewDocumentId,
    } = useDocumentModulesContext();

    const [downloadActionPlanImage] = useDownloadActionPlanDataRowImageAttachment(actionPlanId ?? "", isFromReportForm ?? false);
    const [downloadImage] = useDownloadDataRowImageAttachment(moduleId, formId, dataRow.id!, isDocumentPreview ? previewDocumentId : undefined);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [isUnsavedImage, setIsUnsavedImage] = useState(false);

    const onChange = (e: any) => {
        setIsUnsavedImage(prevState => true);
        setDataRow({
            ...dataRow,
            [e.target.name]: e.target.value
        });
    }

    const handleNewFile = async (e: FormEvent<HTMLInputElement>) => {
        const file = e?.currentTarget?.files?.item(0);
        if (file) {
            let fileAsBase64;
            await getBase64(file).then((response) => {
                fileAsBase64 = response;
            });

            const updatedDataRow = {...dataRow};
            updatedDataRow.imageFile = fileAsBase64;
            updatedDataRow.imageName = file.name
            updatedDataRow.imageContentType = file.type;
            setDataRow(updatedDataRow);
            setIsUnsavedImage(true);
        }
    }

    const renderUploadButton = () => (<>
        <When condition={dataRow.isClosed || (!isEditMode && !isAnswerMode)}
              then={<></>}
              otherwise={<ClickableIconButton
                  disabled={!isFieldEnabled}
                  buttonType={"EDIT"}
                  icon={getIcon(Icon.CIRCLE_PLUS)}
                  title={t('ImageDataRow.ADD_IMAGE_1')}
                  onClick={() => fileInputRef?.current?.click()}
              />}/>
    </>);

    const handleDeleteImage = () => {
        setDataRow({
            ...dataRow,
            imageName: undefined,
        });
    }

    const renderFilename = () => (<Col className={"pt-2"}>
            <div className={"d-flex"}>
                {dataRow.imageName && (
                    <div className={"pt-1"}>
                        <ClickableIconButton
                            disabled={isFromActionPlan ? isUnsavedImage : isActive}
                            buttonType={"EDIT"}
                            icon={getIcon(Icon.DOWNLOAD, IconSize.LARGE)}
                            onClick={isFromActionPlan ? downloadActionPlanImage : downloadImage}
                            title={t('ImageDataRow.DOWNLOAD_IMAGE_1')}
                        />
                    </div>
                )}
                <When
                    condition={!!checklistViewTable}
                    then={<span title={dataRow.imageName} style={{fontSize: ".8rem"}}>{dataRow.imageName}</span>}
                    otherwise={<span title={dataRow.imageName}>{dataRow.imageName}</span>}
                 />
                <When condition={(isEditMode && isActive) || isAnswerMode === true}>
                    <ClickableIconButton
                        disabled={false}
                        buttonType={"CANCEL"}
                        title={t('ImageDataRow.DELETE_IMAGE_1')}
                        icon={getIcon(Icon.REMOVE, checklistViewTable ? IconSize.XS : "")}
                        onClick={handleDeleteImage}
                    />
                </When>
            </div>
        </Col>
    );

    return <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
        <When condition={!checklistViewTable}>
            <Col md={3}>
                <CustomTextInput
                    isEditMode={isEditMode}
                    disabled={!isHeaderEnabled}
                    name={"header"}
                    placeholder={t('ImageDataRow.ATTACHED_IMAGE_1')}
                    value={getDataRowHeader(dataRow)}
                    onChange={onChange}
                    disableAutocomplete
                />
            </Col>
        </When>
        <Col md={
            isEditMode && isActive
                ? checklistViewTable ? 12 : 7
                : checklistViewTable ? 12 : 9
        }>
            <input
                type="file"
                onInputCapture={handleNewFile}
                ref={fileInputRef}
                id={"data-row-image-file"}
                accept={"image/jpeg, image/png"}
                hidden
            />
            <When
                condition={!dataRow.imageName}
                then={renderUploadButton()}
                otherwise={renderFilename()}
            />
        </Col>
        <When condition={isEditMode && isActive}>
            <DataRowActionButtons dataRow={dataRow} removeDataRow={removeDataRow} copyDataRow={copyDataRow}/>
        </When>
    </Row>
};

export default ImageDataRow;
