import Col from "react-bootstrap/esm/Col";
import Container, { ContainerProps } from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";


interface IHeaderContainer extends ContainerProps {
    title: string;
    friend?: JSX.Element;
}

const HeaderContainer: React.FC<IHeaderContainer> = ({
    title,
    friend,
    ...props }) => {

    return <Container
        fluid
        as={props.as}
        className="mb-2"
    >
        <Row>
            <Col>
                <h5>{title}</h5>
            </Col>
        </Row>
    </Container>
}

export default HeaderContainer;