import { AxiosError } from "axios";
import i18n from "i18next";
import { useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, IResponse } from "../core/interfaces/common";
import { ISkill, ISkillResponse } from "../core/interfaces/skill";
import { AppQueryKey, GetQueryKey, useInvalidateQuery } from "../core/queryKeys";
import { useDelete, useGet, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient from "./api";
import { URL_COMPANY_SKILLS, URL_CREATE_SKILL, URL_DELETE_SKILL, URL_UPDATE_SKILL } from "./endpoints/endpoints";


export const CreateSkill = async (skill: ISkill, companyId: string): Promise<IResponse<ISkillResponse>> => {
    return await AxiosClient.post<ISkillResponse>(URL_CREATE_SKILL(companyId), skill)
        .then(resp => ({data: resp.data, status: resp.status}))
        .catch((error: AxiosError) => ({ data: {} as ISkillResponse, status: error.response?.status ?? 500}));
}

export const useGetSkills = (): [ISkillResponse[], boolean] => {
    const { cId } = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.SKILLS, { includeCompanyId: true });
    const query: ICustomQuery = {
        url: URL_COMPANY_SKILLS(cId!),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<ISkillResponse[]>(query);


    return [data?.data?.data ?? [], isFetching]
}

export const useUpdateSkill = (skillId: string): [(skill: ISkill) => Promise<boolean>, boolean] => {
    const { cId } = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.SKILLS);

    const usePostMutation = usePut<ISkill>(
        URL_UPDATE_SKILL(cId!, skillId),
        queryKeys,
        false
    );

    const invalidateSkills = useInvalidateQuery(AppQueryKey.SKILLS);


    const request = (skill: ISkill) => usePostMutation.mutateAsync(skill).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('SkillApi.SKILL_UPDATED_1'), i18n.t('SkillApi.SKILL_UPDATED_2'))));
            await invalidateSkills();
        }
        return !!response.success;
    }).catch(_ => {
        dispatch(addToast(toastError(i18n.t('SkillApi.SKILL_UPDATE_FAILED_1'), i18n.t('SkillApi.SKILL_UPDATE_FAILED_2'))))
        return false;
    });

    return [request, usePostMutation.isLoading];
}


export const useDeleteSkill = (skillId: string): [() => Promise<boolean>, boolean] => {
    const { cId } = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.SKILLS);
    const useDeleteMutation = useDelete<void>(
        URL_DELETE_SKILL(cId!, skillId),
        queryKeys,
        true
    );

    const invalidateSkills = useInvalidateQuery(AppQueryKey.SKILLS);

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('SkillApi.SKILL_DELETED_1'), i18n.t('SkillApi.SKILL_DELETED_2'))));
            await invalidateSkills();
        }
        return !!response.success;
    });
    return [request, useDeleteMutation.isLoading];
}
