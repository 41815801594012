import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { DataRowMultiChoiceType, DataRowType } from "../../../../../../core/enums/enums";
import {
    IDataRow,
    IDataRowAssessmentScopeSeverityField,
    IDataRowImageField,
    IDataRowRiskAssessmentField,
    IDataRowSignatureField,
    IDataRowTextField,
    IDataRowUserAndDate,
    IDateDataRow,
    ISkillDataRow,
    IUserDataRow
} from "../../../../../../core/interfaces/document";
import ChecklistCanvas from "../Components/ChecklistCanvas";
import styles from "../checklistForm.module.scss";
import AddMultiChoice from "./AddMultiChoice";
import AddNumericDataRow from "./AddNumericDataRow";


const newDataRowTextField: IDataRowTextField = {
    checklistFormDataRowTypeSymbol: DataRowType.TEXT_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
    textValue: "",
}

const newDataRowSignatureField: IDataRowSignatureField = {
    checklistFormDataRowTypeSymbol: DataRowType.SIGNATURE_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
    signatureUserId: null,
    signatureDate: null,
}

const newDataRowRiskAssessmentField: IDataRowRiskAssessmentField = {
    checklistFormDataRowTypeSymbol: DataRowType.RISK_ASSESSMENT_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
}

const newDataRowImageField: IDataRowImageField = {
    checklistFormDataRowTypeSymbol: DataRowType.IMAGE_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
}

const newDataRowDateField: IDateDataRow = {
    checklistFormDataRowTypeSymbol: DataRowType.DATE_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
    date: null,
    dateOnly: true,
}

const newDataRowUserField: IUserDataRow = {
    checklistFormDataRowTypeSymbol: DataRowType.USER_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
    userId: null,
}

const newDataRowSkillField: ISkillDataRow = {
    checklistFormDataRowTypeSymbol: DataRowType.SKILL_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
    skillId: null,
}

const newDataRowAssessmentScopeSeverityField: IDataRowAssessmentScopeSeverityField = {
    checklistFormDataRowTypeSymbol: DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
}

const newDataRowUserAndDateField: IDataRowUserAndDate = {
    checklistFormDataRowTypeSymbol: DataRowType.USER_AND_DATE_FIELD,
    isNew: true,
    isClosed: false,
    header: "",
    userAndDateUserId: null,
    userAndDateDate: null,
}

interface IAddDataRowsForm {
    addDataRow: (dataRow: IDataRow) => void;
}

const AddDataRowsForm = (
    {
        addDataRow,
    }: IAddDataRowsForm) => {
    const {t} = useTranslation();
    const [showMultiChoiceCanvas, setShowMultiChoiceCanvas] = useState(false);
    const [showNumericDataRowCanvas, setShowNumericDataRowCanvas] = useState(false);


    const handleAddDataRow = (dataRow: IDataRow) => {
        switch (dataRow.checklistFormDataRowTypeSymbol) {
            case DataRowType.TEXT_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.NUMERIC_FIELD:
                addDataRow(dataRow);
                setShowNumericDataRowCanvas(false);
                break;
            case DataRowType.SIGNATURE_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.RISK_ASSESSMENT_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.MULTI_CHOICE_FIELD:
                addDataRow(dataRow);
                setShowMultiChoiceCanvas(false);
                break;
            case DataRowType.IMAGE_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.DATE_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.USER_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.SKILL_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD:
                addDataRow(dataRow);
                break;
            case DataRowType.USER_AND_DATE_FIELD:
                addDataRow(dataRow);
                break;
            default:
                break;
        }
    }

    const handleCancelCanvas = () => {
        setShowMultiChoiceCanvas(false);
        setShowNumericDataRowCanvas(false);
    }

    return <>
        <Row className={"ms-2 me-2"}>
            <Col md={3}><h5><strong>{t('AddDataRowsForm.ADD_1')}</strong></h5></Col>
            <Col>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => setShowMultiChoiceCanvas(true)}
                >
                    {t('AddDataRowsForm.MULTIPLE_CHOICE_OPTIONS_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowSignatureField)}
                >
                    {t('AddDataRowsForm.SIGNING_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowTextField)}
                >
                    {t('AddDataRowsForm.TEXT_FIELD_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => setShowNumericDataRowCanvas(true)}>
                    {t('AddDataRowsForm.NUMERICAL_FIELD_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowRiskAssessmentField)}>
                    {t('AddDataRowsForm.RISK_ANALYSIS_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowImageField)}>
                    {t('AddDataRowsForm.IMAGE_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowDateField)}>
                    {t('AddDataRowsForm.DATE_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowUserField)}>
                    {t('AddDataRowsForm.STAFF_LIST_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowSkillField)}>
                    {t('AddDataRowsForm.SKILL_LIST_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowAssessmentScopeSeverityField)}>
                    {t('AddDataRowsForm.SCOPE_SEVERITY_1')}
                </Button>
                <Button
                    className={styles.checklistFormActionButton}
                    onClick={() => handleAddDataRow(newDataRowUserAndDateField)}>
                    {t('AddDataRowsForm.USER_DATE_1')}
                </Button>
            </Col>
        </Row>

        <ChecklistCanvas showCanvas={showMultiChoiceCanvas} setShowCanvas={setShowMultiChoiceCanvas}
                         title={t('AddDataRowsForm.MULTIPLE_CHOICE_OPTIONS_1')}>
            <AddMultiChoice
                multiChoiceType={DataRowMultiChoiceType.CHECKLIST}
                onAdd={handleAddDataRow}
                onCancel={() => setShowMultiChoiceCanvas(false)}
            />
        </ChecklistCanvas>

        <ChecklistCanvas showCanvas={showNumericDataRowCanvas} setShowCanvas={setShowNumericDataRowCanvas}
                         title={t('AddDataRowsForm.NUMERICAL_FIELD_1')}>
            <AddNumericDataRow onAdd={handleAddDataRow} onCancel={handleCancelCanvas}/>
        </ChecklistCanvas>
    </>
};

export default AddDataRowsForm;
