import { FormEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useUploadDocumentAttachment } from "../../../api/DocumentApi";
import SpinnerButton from "../../../components/SpinnerButton";
import When from "../../../components/When";
import { IEntity } from "../../../core/interfaces/common";
import { IDocumentAttachment } from "../../../core/interfaces/document";

export const AttachmentUpload = ({newAttachment, isEditMode}: { newAttachment: (attachment: IDocumentAttachment) => void, isEditMode: boolean}) => {

    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [upload, isUploading] = useUploadDocumentAttachment();
    const handleNewFile = async (e: FormEvent<HTMLInputElement>) => {
        const file = e?.currentTarget?.files?.item(0);
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", file.name);
            const response = await upload(formData);
            if (response.success) {
                const id = (response.data as unknown as { data: IEntity }).data?.id;
                newAttachment({ id: id, name: file.name, contentType: file.type });
            }
        }
    }


    return (
        <When condition={isEditMode}>
            <input onInputCapture={handleNewFile} ref={inputRef} type={"file"} id={"incident-file"} multiple hidden
                   accept="image/png, image/jpeg, application/pdf"
                   onClick={(e: any) => (e.target.value = null)} // Reset value to allow same file to be uploaded again
                   />
            <SpinnerButton text={t('AttachmentUpload.ATTACH_FILE_1')} onClick={() => inputRef?.current?.click()} isLoading={isUploading}/>
        </When>
    )
}
