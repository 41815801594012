import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { UNDO_COMMAND } from "lexical";
import { getIcon, Icon } from "../../../../../../assets/Icons/IconClassNames";
import styles from "../ToolbarPlugin.module.scss";
import { ToolbarPluginButton } from "./ToolbarPlugin";

interface IUndoButton extends ToolbarPluginButton {
}

const UndoButton = ({disabled}: IUndoButton) => {
    const [editor] = useLexicalComposerContext();

    return <button
        title={"ctrl+z"}
        className={styles.toolbarItem}
        disabled={disabled}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}>
        <span className={getIcon(Icon.UNDO)}/>
    </button>
};

export default UndoButton;