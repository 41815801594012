import i18n from "i18next";

const data = [
    [0, 5, 'greetingHelper.GREETING_1'],
    [6, 8, 'greetingHelper.GOOD_MORNING_1'],
    [9, 10, 'greetingHelper.GOOD_MORNING_2'],
    [11, 14, 'greetingHelper.GOOD_AFTERNOON_1'],
    [15, 17, 'greetingHelper.GOOD_AFTERNOON_2'],
    [18, 23, 'greetingHelper.GOOD_EVENING_1'],
];

export const displayGreeting = (firstName: string): string => {
    const dateHourNow = new Date().getHours();
    let result;
    for (let i = 0; i < data.length; i++) {
        if (dateHourNow >= (data[i][0] as number) && dateHourNow <= (data[i][1] as number)) {
            result = i18n.t(data[i][2].toString());
        }
    }
    if (!result)
        result = i18n.t('greetingHelper.GREETING_2');
    return result + " " + firstName + "!";
}
