import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { ListItemNode, ListNode } from '@lexical/list';
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { $createParagraphNode, $getRoot, $insertNodes, EditorState, LexicalEditor } from 'lexical';
import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState } from "react";
import ApplyHtmlStylingOnDOM from "./Helpers/TextModuleHelper";
import TextModuleTheme from "./TextModuleTheme";
import ToolbarPlugin from "./ToolbarPlugin/ToolbarPlugin";

interface ITextModule {
    isEditable: boolean;
    updateData: (data: string) => void;
    updateJSONData: (JSONData: string) => void;
    content?: string;
    contentJSON?: string;
}

function AutoFocusPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // Focus the editor when the effect fires!
        editor.focus();
    }, [editor]);

    return null;
}

function onError(error: Error) {
    console.error("Error from Lexical: ", error);
}

const EditablePlugin = ({isEditable}: { isEditable: boolean }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        editor.setEditable(isEditable);
    }, [editor, isEditable]);

    return null;
}

const addBoldStyleToOrderedListItemNumber = () => {
    const listItems = document.querySelectorAll('ol.editor-ordered-list1 li.editor-list-item');
    listItems.forEach(listItem => {
        const childElements = Array.from(listItem.children);
        if (childElements.length === 1 && (childElements[0].nodeName === 'STRONG' || childElements[0].classList.contains('editor-text-bold'))) {
            listItem.setAttribute('style', 'font-weight: bold;');
        } else {
            listItem.removeAttribute('style');
        }
    });
}

const InitializeContent = ({isInitialized, content, contentJSON, forceConvertToJson}: {
    isInitialized: boolean,
    content?: string,
    contentJSON?: string
    forceConvertToJson: (editor: LexicalEditor) => void;
}) => {
    const [editor] = useLexicalComposerContext();

    useLayoutEffect(() => {
        if (!isInitialized && (contentJSON !== undefined && contentJSON !== "")) {
            editor.update(() => {
                const editorState = editor.parseEditorState(contentJSON);
                editor.setEditorState(editorState);
                addBoldStyleToOrderedListItemNumber();
            });
        } else if (!isInitialized && (content !== undefined && content !== "")) {
            editor.update(() => {
                const parser = new DOMParser();
                const dom = parser.parseFromString(content, "text/html");
                const nodes = $generateNodesFromDOM(editor, dom);
                const paragraph = $createParagraphNode();
                $getRoot().clear();
                $getRoot().append(paragraph);
                $getRoot().select();
                $insertNodes(nodes);
                forceConvertToJson(editor);
            });
        }
    }, [isInitialized]);
    return null;
}

const TextModule = forwardRef((
    {
        isEditable,
        updateData,
        updateJSONData,
        content,
        contentJSON
    }: ITextModule, ref) => {
    ApplyHtmlStylingOnDOM();
    const [isInitialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!isInitialized) {
            setInitialized(true);
        }
    }, [isInitialized]);

    useImperativeHandle(ref, () => ({
        initializeContent() {
            setInitialized(false);
        },
    }));

    function onChange(editorState: EditorState, editor: LexicalEditor) {
        const jsonString = JSON.stringify(editorState);
        updateJSONData(jsonString);
        editorState.read(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
            updateData(htmlString);
        });
    }

    const initialConfig: InitialConfigType = {
        namespace: 'TextModule',
        editable: true,
        theme: TextModuleTheme,
        onError,
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            TableNode,
            TableCellNode,
            TableRowNode,
        ]
    };

    const forceConvertToJson = (editor: LexicalEditor) => {
        const jsonString = JSON.stringify(editor.getEditorState());
        console.log("NEED TO FORCE UPDATE MODULE WITH JSON STRING!");
    }

    return (
        <LexicalComposer initialConfig={initialConfig}>
            {isEditable ? <ToolbarPlugin/> : <></>}
            <InitializeContent
                isInitialized={isInitialized}
                content={content}
                contentJSON={contentJSON}
                forceConvertToJson={forceConvertToJson}
            />
            <RichTextPlugin
                contentEditable={
                    <ContentEditable
                        className={isEditable ? 'contentEditable' : 'contentEditableReadOnly'}
                    />
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin ignoreSelectionChange onChange={onChange}/>
            <HistoryPlugin/>
            <ListPlugin/>
            <CheckListPlugin/>
            <AutoFocusPlugin/>
            <EditablePlugin isEditable={isEditable}/>
            <TablePlugin  />
        </LexicalComposer>
    );
});

export default TextModule;