import React, {forwardRef, ReactElement, useState} from "react";
import {Form} from "react-bootstrap";


type CompleteFormProps = {
    children?: JSX.Element | JSX.Element[];
    isEditMode?: boolean,
    row?: boolean,
    useValidated?: boolean,
    onSubmit: () => void,
}



const CustomForm = ({children, useValidated = true, isEditMode = true, row = true, onSubmit}: CompleteFormProps, ref: React.ForwardedRef<HTMLButtonElement>): ReactElement => {
    const [valid, setValidForm] = useState(false);
    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const valid = e.currentTarget.checkValidity();
            setValidForm(!valid)
            if(valid) {
                onSubmit()
            }
        }} validated={useValidated ? valid : undefined} noValidate>
            {children}
            <button hidden={true} ref={ref}></button>
        </Form>
    )
}

export default forwardRef(CustomForm)