import { useTranslation } from "react-i18next";
import { useMarkDocumentHandled } from "../../../api/DocumentApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import { toastInfo } from "../../../core/helpers/toastHelper";
import { IDocument } from "../../../core/interfaces/document";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";

interface IMarkAsImportantHandled {
    document: IDocument;
    disabled: boolean;
    isEditMode: boolean;
}

const MarkAsImportantHandled = (
    {
        document,
        disabled,
        isEditMode,
    }: IMarkAsImportantHandled) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [request, isLoading] = useMarkDocumentHandled(document.id);

    const handleRequest = async () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('MarkAsImportantHandled.LEAVE_EDITING_MODE_1'), t('MarkAsImportantHandled.EXIT_EDITING_MODE_1'))));
            return;
        }
        await request(document.importantHandledAt === undefined || document.importantHandledAt === null);
    }
    return <CustomDropdownItem
        disabled={disabled || isLoading}
        icon={Icon.CIRCLE}
        iconColor={"green"}
        text={`${document.importantHandledAt ? t('MarkAsImportantHandled.UNSELECT_1') : t('MarkAsImportantHandled.SELECT_1')} ${t('MarkAsImportantHandled.HANDLED_1')}`}
        onClick={handleRequest}
    />
};

export default MarkAsImportantHandled;
