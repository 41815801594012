import { FormEvent, forwardRef } from "react";
import { useUploadDocument } from "../../../api/DocumentApi";

interface IDocumentUploadProps {
    folderId: string | null;
}

export const DocumentUpload = forwardRef(({folderId}: IDocumentUploadProps, ref: any) => {
    const [upload] = useUploadDocument();

    const handleNewFile = async (e: FormEvent<HTMLInputElement>) => {
        const file = e?.currentTarget?.files?.item(0);

        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", file.name);
            formData.append("folderId", folderId ?? "");
            await upload(formData);
        }
    }

    return (
        <>
            <input onInputCapture={handleNewFile} ref={ref} type={"file"} id={"incident-file"} multiple hidden
                   accept="image/png, image/jpeg, application/pdf"/>
        </>
    )
})