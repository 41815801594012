import React from "react";

type WhenProps = {
    condition: boolean;
    then?: any;
    children?: any;
    otherwise?: any;
}
const When: React.FC<WhenProps> = ({ children, condition, then, otherwise}) => {
    if(condition) return <>{then ? then : children}</>

    if(otherwise!= null) return <>{otherwise}</>;

    return null;
}

export default When;