import {Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import styles from './formProvider.module.scss'
import Col from "react-bootstrap/esm/Col";
import React from "react";

interface IFormProvider {
    children: JSX.Element | JSX.Element[];
    controlId: string,
    title: string,
    colClassName?: string,
    colAs?: 'sm' | 'large',
}

const FormProvider: React.FC<IFormProvider> = ({children, controlId, title, colAs = "sm", colClassName = ""}) => {
    return (<Form.Group as={Row} controlId={controlId} className={styles.form}>
        <Form.Label column md={6} xs={colAs === 'sm' ? 6 : 12} style={{fontWeight: 500}}>{title}</Form.Label>
        <Col md={colAs === "sm" ? 4 : 6} lg={colAs === "sm" ? 3 : 6} xs={colAs === 'sm' ? 6 : 12} className={[colClassName, styles.children].join(" ")}>
            {children}
        </Col>
    </Form.Group>)
}

export default FormProvider