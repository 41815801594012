import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export enum ModalState {
    IDLE,
    PENDING,
    OPENED,
    APPROVED
}

interface IChangesModalState {
    state: ModalState,
    trigger: string,
    modalHolder: string,
    previousState: ModalState
}

const initialState: IChangesModalState = {
    modalHolder: "",
    state: ModalState.IDLE,
    previousState: ModalState.IDLE,
    trigger: ""
}
export const changeModalSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setModalHolder: (state, action: PayloadAction<string>) => {
            state.modalHolder = action.payload;
        },
        removeModalHolder: (state) => {
            state.modalHolder = "";
        },
        modalTrigger: (state, action: PayloadAction<string>) => {
            state.trigger = action.payload;
            state.state = ModalState.PENDING;
        },
        modalHolderResponse: (state, action: PayloadAction<boolean>) => {
            if(state.modalHolder && action.payload) {
                state.state = ModalState.OPENED;
            } else {
                state.state = ModalState.IDLE;
            }
        },
        modalResponse: (state, action: PayloadAction<boolean>) => {
            state.state = action.payload ? ModalState.APPROVED : ModalState.IDLE;
        },
        setAsIdle: (state) => {
            state.state = ModalState.IDLE;
        }
    }
});

export const {
    setModalHolder,
    removeModalHolder,
    modalTrigger,
    setAsIdle,
    modalHolderResponse,
    modalResponse
} = changeModalSlice.actions;

export default changeModalSlice.reducer;