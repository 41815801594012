import {NavigateOptions, useNavigate} from "react-router-dom";
import {usePrompt} from "./usePrompt";

export const useCNavigate = (): (destination: number | string, options?: NavigateOptions) => void => {

    const prompt = usePrompt();
    const navigate = useNavigate();

    return (destination: number | string, options?: NavigateOptions) => {
        prompt(() => {
            if (!isNaN(destination as number)) {
                navigate(destination as number);
            } else {
                navigate(destination as string, options);
            }
        })
    }
}