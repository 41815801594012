import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import CustomTextInput from "../../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../../core/helpers/dataRowHelper";
import { IDataRowFileField, IDataRowProps, INewFile } from "../../../../../../../core/interfaces/document";
import DataRowActionButtons from "../DataRowActionButtons";
import FileList from "./FileList";
import FileSelect from "./FileSelect";


const FileDataRow = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isAnswerMode = false,
        isFieldEnabled,
        isActive,
        isFromActionPlan,
        actionPlanId,
        userSkillId,
        companyUserId,
        companyId
    }: IDataRowProps<IDataRowFileField>) => {

    const { t } = useTranslation();
    const handleRemoveFile = (index: number, isNewFile: boolean) => {
        if (isNewFile) {
            const newFiles = [...dataRow.newFiles];
            newFiles.splice(index, 1);
            setDataRow({ ...dataRow, newFiles: [...newFiles] })
        } else {
            const files = [...dataRow.files];
            files.splice(index, 1);
            setDataRow({ ...dataRow, files: [...files] })
        }
    }

    const handleNewFile = (formData: INewFile) => setDataRow({ ...dataRow, newFiles: [...dataRow.newFiles, formData] });
    const onChange = (e: any) => {
        setDataRow({
            ...dataRow,
            [e.target.name]: e.target.value
        });
    }

    const renderFileList = () => (
        <FileList
            files={dataRow.files}
            newFiles={dataRow.newFiles}
            removeFile={handleRemoveFile}
            isEditMode={isEditMode || (!!isFromActionPlan && isActive)}
            isAnswerMode={isAnswerMode}
            isFromActionPlan={isFromActionPlan}
            actionPlanId={actionPlanId}
            userSkillId={userSkillId}
            companyUserId={companyUserId}
            companyId={companyId}
        />)

    const renderFileSelect = () => <FileSelect isEditMode={isEditMode || isAnswerMode} newFile={handleNewFile} />;

    const renderDataRowInTableView = () => (<div className={"d-flex"}>
        <When
            condition={(dataRow.files.length + dataRow.newFiles.length) > 0}
            then={<div className={"pt-1 ps-1"}>{renderFileList()}</div>}
            otherwise={<span className={"pt-1 ps-1"} style={{ fontSize: ".8rem" }}>{t('FileDataRow.NO_FILES_SELECTED_1')}</span>}
        />
        {/*<FileSelect isEditMode={isEditMode || isAnswerMode} newFile={handleNewFile}/>*/}
        {renderFileSelect()}
    </div>);
    const renderDataRowInListView = () => (<Row className={"mt-3 mb-3 ms-2 me-2"}>
        <Col md={3}>
            <CustomTextInput
                isEditMode={isEditMode}
                disabled={!isHeaderEnabled}
                name={"header"}
                placeholder={t('FileDataRow.ATTACHED_FILES_1')}
                value={getDataRowHeader(dataRow)}
                onChange={onChange}
                disableAutocomplete
            />
        </Col>
        <Col md={
            isEditMode && isActive
                ? checklistViewTable ? 12 : 7
                : checklistViewTable ? 12 : 9
        }>
            <Row>
                <Col md={6} lg={4} xl={3} className={"pt-1 ps-3"}>
                    <When
                        condition={(dataRow.files.length + dataRow.newFiles.length) > 0}
                        then={renderFileList()}
                        otherwise={<span>{t('FileDataRow.NO_FILES_SELECTED_2')}</span>}
                    />
                </Col>
                <Col md={6} lg={8} xl={9}>
                    {isFieldEnabled && renderFileSelect()}
                </Col>
            </Row>
        </Col>
        <When condition={isEditMode && isActive}>
            <DataRowActionButtons dataRow={dataRow} removeDataRow={removeDataRow} copyDataRow={copyDataRow} />
        </When>
    </Row>);

    return (<>
        <When
            condition={!!checklistViewTable}
            then={renderDataRowInTableView()}
            otherwise={renderDataRowInListView()}
        />
    </>);
};

export default FileDataRow;
