import {Toast, ToastContainer} from "react-bootstrap";
import getIcon, {Icon} from "../assets/Icons/IconClassNames";
import {ToastType} from "../core/enums/enums";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {removeToast} from "../store/slices/uiSlice";
import MainContainer from "./ContainerComponents/MainContainer";


const ToastComponent = () => {
    const toasts = useAppSelector((state) => state.ui.toasts);
    const dispatch = useAppDispatch();

    const handleRemoveToast = (uId?: number) => {
        if (!uId) return; // should not happend, but you'll never know!
        dispatch(removeToast(uId));
    }

    const getStyle = (toastType: ToastType) => {
        switch (toastType) {
            case ToastType.SUCCESS:
                return "toastSuccess";
            case ToastType.DANGER:
                return "toastDanger";
            case ToastType.INFO:
                return "toastInfo";
            default:
                return ""
        }
    }

    const getToastIcon = (toastType: ToastType) => {
        switch (toastType) {
            case ToastType.SUCCESS:
                return getIcon(Icon.CHECK_CIRCLE);
            case ToastType.DANGER:
                return getIcon(Icon.WARNING);
            case ToastType.INFO:
                return getIcon(Icon.EXCLAMATION);
            default:
                return ""
        }
    }

    return (
        <MainContainer>
            <ToastContainer
                containerPosition="fixed"
                position="top-end"
                className="toastContainer z-index9999"
            >
                <>
                    {toasts.map((toast) => {
                        return (
                            <Toast
                                key={toast.uId}
                                bg={toast.toastType}
                                onClose={() => handleRemoveToast(toast.uId)}
                                autohide={toast.autohide ?? true}
                                delay={4000}
                            >
                                <Toast.Header className={getStyle(toast.toastType)}>
                                    <strong className="me-auto"><i className={`${getToastIcon(toast.toastType)} pe-3`} />{toast.title}</strong>
                                </Toast.Header>
                                <Toast.Body className={getStyle(toast.toastType)}>
                                    {toast.message}
                                </Toast.Body>
                            </Toast>
                        )
                    })}
                </>
            </ToastContainer>
        </MainContainer >
    )

}

export default ToastComponent;