import { IDataRow } from "../../../../../core/interfaces/document";
import { useDocumentModulesContext } from "../../DocumentModules";
import { useChecklistContext } from "../ChecklistContext";
import { useChecklistFormContext } from "./ChecklistFormContext";
import GeneralizedDataRow from "./GeneralizedDataRow";

interface IChecklistFormDataRows {
    moduleId: string;
    formId: string;
    dataRows: IDataRow[];
}

interface IRenderDataRows {
    moduleId: string;
    formId: string;
    dataRows: IDataRow[];
    handleUpdateDataRow: (dataRow: IDataRow, index: number) => void;
    handleRemoveDataRow: (dataRow: IDataRow) => void;
    handleCopyDataRow: (dataRow: IDataRow) => void;
}

const RenderDataRows = (
    {
        moduleId,
        formId,
        dataRows,
        handleUpdateDataRow,
        handleRemoveDataRow,
        handleCopyDataRow,
    }: IRenderDataRows) => {

    const {
        isAnswerMode,
        isSaveDisabled,
        setSaveState
    } = useDocumentModulesContext();

    const {documentContext: {isEditMode}} = useChecklistContext()
    return <GeneralizedDataRow
        dataRows={dataRows}
        handleUpdateDataRow={handleUpdateDataRow}
        handleRemoveDataRow={handleRemoveDataRow}
        handleCopyDataRow={handleCopyDataRow}
        isEditMode={isEditMode}
        isAnswerMode={isAnswerMode}
        isSaveDisabled={isSaveDisabled}
        setSaveDisabled={setSaveState}
        formId={formId}
        moduleId={moduleId}
        isActiveMode={isEditMode}
        delayedUpdate
    />
}

const ChecklistFormDataRows = (
    {
        moduleId,
        formId,
        dataRows,
    }: IChecklistFormDataRows) => {

    const {
        updateDataRow: handleUpdateDataRow,
        removeDataRow: handleRemoveDataRow,
        copyDataRow: handleCopyDataRow
    } = useChecklistFormContext();

    return <>
        <RenderDataRows
            moduleId={moduleId}
            formId={formId}
            dataRows={dataRows}
            handleUpdateDataRow={(dataRow) => handleUpdateDataRow(dataRow)}
            handleRemoveDataRow={(dataRow) => handleRemoveDataRow(dataRow)}
            handleCopyDataRow={(dataRow) => handleCopyDataRow(dataRow)}
        />
    </>
};

export default ChecklistFormDataRows;