import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import getIcon, { Icon } from "../../../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../../../components/ClickableIconButton/ClickableIconButton";
import SimpleConfirmModal from "../../../../../components/ModalComponents/SimpleConfirmModal";
import { IDocumentModuleChecklistForm } from "../../../../../core/interfaces/document";
import { useChecklistContext } from "../ChecklistContext";
import styles from "./checklistForm.module.scss";


interface IChecklistFormActionButtons {
    index: number;
    form: IDocumentModuleChecklistForm;

}

const ChecklistFormActionButtons = ({index, form}: IChecklistFormActionButtons) => {
    const {t} = useTranslation();
    const { copyForm, removeForm } = useChecklistContext();
    const [showDeleteFormModal, setShowDeleteFormModal] = useState(false);


    return <>
        <Row>
            <Col>
                <div className={styles.checklistFormContainerActionButtons}>
                    <div>
                        <ClickableIconButton disabled={false} buttonType={"CANCEL"} icon={getIcon(Icon.REMOVE)}
                                             onClick={() => setShowDeleteFormModal(true)}/>
                    </div>
                    <div className={"me-2"}>
                        <ClickableIconButton disabled={false} buttonType={"EDIT"} icon={getIcon(Icon.COPY)}
                                             onClick={() => copyForm(form)}/>
                    </div>
                </div>
            </Col>
        </Row>

        <SimpleConfirmModal
            show={showDeleteFormModal}
            header={<>{t('ChecklistFormActionButtons.DELETE_FORM_1')}</>}
            text={<>{t('ChecklistFormActionButtons.DELETE_FORM_2')}{index + 1}?<br/>{t('ChecklistFormActionButtons.IRREVERSIBLE_CHOICE_1')}</>}
            onClickOKText={t('ChecklistFormActionButtons.DELETE_1')}
            onClickOK={() => {
                removeForm(form)
                setShowDeleteFormModal(false)
            }}
            onClickCancelText={t('ChecklistFormActionButtons.NO_1')}
            onClickCancel={() => setShowDeleteFormModal(false)}
        />
    </>
};

export default ChecklistFormActionButtons;
