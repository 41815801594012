import React from "react";
import {Button, Modal} from "react-bootstrap";
import {AcceptModalState, IAcceptModalUi} from "../providers/AcceptModalProvider";
import When from "./When";
import {useTranslation} from "react-i18next";

export const AcceptModal = ({
                                state,
                                setModalState,
                                modalUi,
                                disableAcceptButton,
                            }: { state: AcceptModalState, setModalState: (state: AcceptModalState) => void, modalUi?: IAcceptModalUi, disableAcceptButton: boolean }) => {

    const {t} = useTranslation();
    
    return (
        <Modal
            show={state === AcceptModalState.OPENED}
            onHide={() => setModalState(AcceptModalState.CANCELLED)}
            size={modalUi?.size ? modalUi.size : "sm"}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title> <When condition={!modalUi?.header} otherwise={modalUi?.header}>
                    {t('AcceptModal.CANCEL_1')}
                </When></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <When condition={!modalUi?.body} otherwise={modalUi?.body}>
                    <p>{t('AcceptModal.CANCEL_CONFIRMATION_1')}</p>
                    {t('AcceptModal.CANCEL_CONFIRMATION_2')}
                </When>
            </Modal.Body>
            <Modal.Footer>

                <Button variant={'secondary'} onClick={() => setModalState(AcceptModalState.CANCELLED)}>
                    <When condition={!modalUi?.declineButtonText} otherwise={modalUi?.declineButtonText}>
                        {t('AcceptModal.CANCEL_1')}
                    </When>
                </Button>
                <When condition={!modalUi?.hideAcceptButton}>
                    <Button variant={modalUi?.acceptButtonVariant ?? 'danger'} onClick={() => setModalState(AcceptModalState.ACCEPTED)} disabled={disableAcceptButton}>
                        <When condition={!modalUi?.acceptButtonText} otherwise={modalUi?.acceptButtonText}>
                            {t('AcceptModal.YES_1')}
                        </When>
                    </Button>
                </When>
            </Modal.Footer>
        </Modal>
    )
};
