import { useTranslation } from "react-i18next";
import CustomDate from "../../../components/FormComponents/CustomDate";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function ActionDateForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'formActionDate'} title={t('ActionDateForm.DATE_FIXED_1')}>
        <CustomDate
            isEditMode={props.editMode}
            type={"date"}
            name={"actionDate"}
            value={props.newIncident.actionDate ?? ""}
            onChange={props.onChange}
        />
    </FormProvider>
}
