import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import SuperadminTable from "./SuperadminTable";

const Superadmins = () => {
    const { t } = useTranslation();

    return (
        <MainContainer>
            <HeaderContainer title={t('Superadmins.SUPER_ADMINS_1')}/>
            <Row>
                <SuperadminTable/>
            </Row>
        </MainContainer>
    );
};

export default Superadmins;