import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateIncident } from "../../api/Incident";
import { getBase64 } from "../../core/helpers/fileHelper";
import { newIncident, transformDatesToUTC } from "../../core/helpers/incidentHelper";
import { IIncident, IIncidentToSend, IncidentFileToUpdate } from "../../core/interfaces/incident";
import { useCNavigate } from "../../hooks/useCNavigate";
import IncidentContainer from "./components/IncidentContainer/IncidentContainer";

const Incident = () => {
    const navigate = useNavigate();
    const cNavigate = useCNavigate();

    const {cId} = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [incident, _] = useState<IIncident>(newIncident());

    const createIncident = useCreateIncident(cId!);

    const onSubmit = async (incident: IIncident, files?: File[]) => {
        setIsSubmitting(true);

        const newFiles: IncidentFileToUpdate[] = await Promise.all((files ?? []).map(async (file) =>
            ({
                base64: await getBase64(file),
                name: file.name,
                type: file.type,

            } as IncidentFileToUpdate)
        ));

        const incidentToSend: IIncidentToSend = {
            ...transformDatesToUTC(incident),
            newFiles
        };

        await createIncident(incidentToSend).then((response) => {
            if (response.success) {
                navigate(`/c/${cId}/incidents/${response.data.id}`);
            } else {
                setIsSubmitting(false);
            }
        });
    }

    const onCancel = () => {
        cNavigate(`..`, {relative: 'path'});
    }

    return (
        <IncidentContainer
            isNewIncident
            incident={incident}
            isEditMode={true}
            isSubmitting={isSubmitting}
            onEdit={() => NaN}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    );
}

export default Incident;