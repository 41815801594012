import { useTranslation } from "react-i18next";
import CustomTextAreaInput from "../../../components/FormComponents/CustomTextAreaInput";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function InstantActionForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'formDirectAction'} title={t('InstantActionForm.DIRECT_ACTION_1')} colAs={props.editMode ? 'large' : 'sm'}>
        <CustomTextAreaInput
            isEditMode={props.editMode}
            rows={5}
            name={"instantAction"}
            value={props.newIncident.instantAction ?? ''}
            onChange={props.onChange}
        />
    </FormProvider>

}
