import CustomDate from "./CustomDate";
import CustomTextInput from "./CustomTextInput";
import {useState} from "react";
import CustomSelect from "./CustomSelect";
import CustomTextAreaInput from "./CustomTextAreaInput";

const handleDate = (e: any, object: any) => {
    const targetName = e.target.name;
    const newValue = e.target.value;
    const oldVal = Object.entries(object).find(([key]) => key === targetName)?.[1];

    const newDate = new Date(newValue);

    if (oldVal) {
        const oldDate = new Date(oldVal as string);
        const oldDateHours = oldDate.getHours();
        const oldDateMinutes = oldDate.getMinutes();

        newDate.setHours(oldDateHours);
        newDate.setMinutes(oldDateMinutes);
    }
    return {
        ...object,
        [e.target.name]: newDate.toLocaleString()
    };
}

const handleChange = (e: any, object: any) => {
    return {
        ...object,
        [e.target.name]: e.target.value
    };
}


const InputTypesChange: { [key: string]: (e: any, object: any) => object } = {
    'date': handleDate,
    'text': handleChange,
    'password': handleChange,
    'email': handleChange,
    'select': handleChange,
    'textarea': handleChange,
}


type GenericInputProps<T> = {
    type?: 'date' | 'time' | 'text' | 'password' | 'email' | 'select' | 'textarea';
    isEditMode: boolean,
    name: string,
    object: T,
    setValue: (e: T) => void,
    required?: boolean,
    onChangeMiddleWare?: (e: T, name: string) => T,
    errorObject?: any,
    children?: JSX.Element[];
    description?: string,
    disabled?: boolean,
    autofocus?: boolean
}
const GenericInput = <T, >({
                               name,
                               type = 'text',
                               object,
                               isEditMode,
                               setValue,
                               required,
                               errorObject,
                               onChangeMiddleWare,
                               description,
                               children,
                               disabled,
                               autofocus,
                           }: GenericInputProps<T>) => {

    const [edited, setEdited] = useState(false);

    const hasErrorObject = !!errorObject;
    const commonProps = {
        required,
        name,
        disabled,
        description,
        isEditMode,
        value: (object as any)[name] ?? '',
        onChange: (e: any) => {
            setEdited(true);
            let obj = InputTypesChange[type](e, object) as T;
            if (onChangeMiddleWare) {
                obj = onChangeMiddleWare(obj, name);
            }
            setValue(obj);
        },
        isValid: edited && hasErrorObject ? !errorObject[name] : undefined,
        isInvalid: edited && hasErrorObject ? errorObject[name] : undefined,
        autofocus
    }

    switch (type) {
        case "date":
        case "time":
            return <CustomDate type={type} {...commonProps}/>
        case "select":
            return <CustomSelect {...commonProps}>{children}</CustomSelect>
        case "textarea":
            return <CustomTextAreaInput rows={3} {...commonProps} />
        case "text":
        case "password":
        case "email":
        default:
            return <CustomTextInput type={type} {...commonProps} />

    }
}
export default GenericInput;