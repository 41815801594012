import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "i18next";
import AxiosClient from "../../api/api";
import { URL_INCIDENT_VIEW } from "../../api/endpoints/endpoints";
import { ToastType } from "../../core/enums/enums";
import { IResponse } from "../../core/interfaces/common";
import { IAbsenceInterval, IReportTo, IViewInfoResponse } from "../../core/interfaces/incident";
import { IToast } from "../../core/interfaces/toast";
import { GET_INCIDENT_VIEW_INFO, STATUS_IDLE, STATUS_PENDING, STATUS_REJECTED, STATUS_SUCCEEDED } from "../types";

interface IuiState {
    toasts: IToast[];
    incidentViewInfo: IIncidentViewInfo,
}

interface IIncidentViewInfo {
    absenceIntervals: IAbsenceInterval[],
    reportTos: IReportTo[],
    status: string,
}

const initialState: IuiState = {
    toasts: [],
    incidentViewInfo: {
        absenceIntervals: [],
        reportTos: [],
        status: STATUS_IDLE,
    }
}
export const getIncidentViewInfo = createAsyncThunk(GET_INCIDENT_VIEW_INFO, async () => await AxiosClient.get<IResponse<IViewInfoResponse>>(URL_INCIDENT_VIEW).then(response => {
        return {
            absenceIntervals: response.data.data.sicknessAbsenceIntervalResponses,
            reportTos: response.data.data.reportedToResponses
        } as IIncidentViewInfo;
    })
)

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<IToast>) => {
            const randomId = Math.floor(Math.random() * 10000);
            action.payload = {...action.payload, uId: randomId}
            state.toasts.push(action.payload);
        },
        removeToast: (state, action: PayloadAction<number>) => {
            const result = state.toasts.filter((toast) => toast.uId !== action.payload);
            return {
                ...state,
                toasts: result
            }
        },
        addErrorGeneralToast: (state) => {
            const randomId = Math.floor(Math.random() * 10000);
            const title = i18n.t('uiSlice.ERROR_OCCURRED_1');
            const message = i18n.t('uiSlice.UNEXPECTED_ERROR_1');
            const toast: IToast = {
                toastType: ToastType.DANGER,
                title: title,
                message: message,
                uId: randomId,
                autohide: false
            };
            state.toasts.push(toast);

        }
    },
    extraReducers(builder) {
        builder
            .addCase(getIncidentViewInfo.pending, (state) => {
                state.incidentViewInfo.status = STATUS_PENDING;
            })
            .addCase(getIncidentViewInfo.fulfilled, (state, action: PayloadAction<IIncidentViewInfo>) => {
                state.incidentViewInfo = action.payload;
                state.incidentViewInfo.status = STATUS_SUCCEEDED;
            })
            .addCase(getIncidentViewInfo.rejected, (state) => {
                //Hur hantera vi detta?
                state.incidentViewInfo.status = STATUS_REJECTED;
            })
    }
});

export const {
    addToast,
    removeToast,
    addErrorGeneralToast,
} = uiSlice.actions;

export default uiSlice.reducer;
