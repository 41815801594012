import { useState } from 'react';
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useGetSuperadmins, useUpdateSystemUserRole } from "../../api/UserApi";
import { Icon } from "../../assets/Icons/IconClassNames";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import PaginatedDataTableClientSide from "../../components/DataTableComponents/PaginatedDataTable";
import SimpleConfirmModal from "../../components/ModalComponents/SimpleConfirmModal";
import When from "../../components/When";
import { Role } from "../../core/enums/enums";
import { IUserResponse } from "../../core/interfaces/user";
import styles from "../Users/Users.module.scss";
import NewSuperadminModal from "./NewSuperadminModal";

const SuperadminTable = () => {
    const {t} = useTranslation();
    const [openNewSuperadminModal, setOpenNewSuperadminModal] = useState(false);
    const [data, loading] = useGetSuperadmins();
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IUserResponse | null>(null);
    const [update, isUpdating] = useUpdateSystemUserRole(selectedUser?.id ?? "");

    const columns: TableColumn<IUserResponse>[] = [
        {
            name: t('SuperadminTable.NAME_1'),
            selector: row => `${row.person.firstName} ${row.person.lastName}`,
            sortable: true,
        },
        {
            name: t('SuperadminTable.E_MAIL_1'),
            selector: row => row.email,
        },
        {
            name: <span className={styles.clickable} onClick={() => setOpenNewSuperadminModal(true)}>{t('SuperadminTable.ADD_SUPERADMIN_1')}</span>,
            right: true,
            cell: row => (
                <CustomDropdown toggleType={DropdownToggleType.GEAR}>
                    <CustomDropdownItem
                        icon={Icon.REMOVE}
                        text={t('SuperadminTable.REMOVE_AS_SUPERADMIN_1')}
                        onClick={() => {
                            setSelectedUser(row)
                            setShowRemoveUserModal(true)
                        }}
                    />
                </CustomDropdown>
            )
        }
    ];

    const handleUpdate = async () => {
        if (!selectedUser) return;
        //setting to USER for future alternatives
        await update(Role.USER);
        setSelectedUser(null);
        setShowRemoveUserModal(false);
    }


    return (
        <ContentContainer>

            {/*-Alternative for adding-*/}

            {/*<div className="ps-2 pb-2">*/}
            {/*    <ClickableIconButton*/}
            {/*        disabled={false}*/}
            {/*        buttonType={"EDIT"}*/}
            {/*        icon={getIcon(Icon.CIRCLE_PLUS, IconSize.X2)}*/}
            {/*        onClick={() => setOpenNewSuperadminModal(true)}*/}
            {/*        title={t('SuperadminTable.ADD_SUPERADMIN_2')}*/}
            {/*    />*/}
            {/*</div>*/}
            <PaginatedDataTableClientSide
                data={data ?? []}
                progressPending={false}
                columns={columns}
            />

            <NewSuperadminModal show={openNewSuperadminModal} hide={() => setOpenNewSuperadminModal(false)}/>
            <When condition={!!selectedUser && showRemoveUserModal}>
                <SimpleConfirmModal
                    show={showRemoveUserModal}
                    header={<>{t('SuperadminTable.DELETE_SUPER_ADMINISTRATOR_1')}</>}
                    text={<>{t('SuperadminTable.CONFIRM_1')}</>}
                    onClickOKText={t('SuperadminTable.YES_1')}
                    onClickOK={handleUpdate}
                    isLoadingClickOk={isUpdating}
                    onClickCancelText={t('SuperadminTable.CANCEL_1')}
                    onClickCancel={() => {
                        setShowRemoveUserModal(false);
                        setSelectedUser(null);
                    }}
                />
            </When>
        </ContentContainer>
    );
};

export default SuperadminTable;
