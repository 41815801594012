import i18n from "i18next";
import { DataRowSubType, DataRowType, DeviationType, IncidentExternalPersonExists, IncidentType, MultiChoiceAlternativeClassified, MultiChoiceDisplayType, StandaloneCategory, UserSkillCourseCompleted, UserSkillLevel } from "../enums/enums";
import { IDataRow, IDataRowMultiChoiceOptionAlternative } from "../interfaces/document";

const NewTextField = (subType: DataRowSubType, id = Math.random() + "", isClosed = false, rows?: number, maxLength?: number) => (
    {
        id,
        checklistFormDataRowTypeSymbol: DataRowType.TEXT_FIELD,
        header: "",
        textValue: "",
        subType,
        isNew: true,
        isClosed: isClosed,
        rows: rows,
        maxLength: maxLength
    } as IDataRow
)

const NewDescriptionField = (subType: DataRowSubType, isClosed = false) => (
    {
        checklistFormDataRowTypeSymbol: DataRowType.TEXT_FIELD,
        header: "",
        textValue: "",
        subType,
        isNew: true,
        isClosed: isClosed
    } as IDataRow
)

const NewMultiChoice = (subType: DataRowSubType, choices: string[], id = Math.random() + "", isDropdown = false) => (
    {
        id,
        checklistFormDataRowTypeSymbol: DataRowType.MULTI_CHOICE_FIELD,
        multiChoiceValue: choices[0],
        multiChoiceAlternatives: choices.map(v => ({ name: v })),
        multiChoiceDisplayType: isDropdown ? MultiChoiceDisplayType.DROPDOWN : MultiChoiceDisplayType.RADIO,
        header: "",
        subType,
        isClosed: false,
        isNew: true
    } as IDataRow
)

const NewMultiClassifiedChoice = (subType: DataRowSubType, choices: IDataRowMultiChoiceOptionAlternative[], id = Math.random() + "", isDropdown = false) => (
    {
        id,
        checklistFormDataRowTypeSymbol: DataRowType.MULTI_CHOICE_FIELD,
        multiChoiceValue: choices[0].name,
        multiChoiceAlternatives: choices,
        multiChoiceDisplayType: isDropdown ? MultiChoiceDisplayType.DROPDOWN : MultiChoiceDisplayType.RADIO,
        header: "",
        subType,
        isClosed: false,
        isNew: true
    } as IDataRow
)

const NewActionUser = () => (
    {
        id: Math.random() + "",
        actionUserId: '',
        actionDate: '',
        checklistFormDataRowTypeSymbol: DataRowType.ACTION_PLAN_FIELD,
        header: '',
        isNew: true,
        isClosed: false,
        subType: DataRowSubType.NONE,
    } as IDataRow
)

const NewDateField = (subType = DataRowSubType.NONE, dateOnly = false) => (
    {
        id: Math.random() + "",
        checklistFormDataRowTypeSymbol: DataRowType.DATE_FIELD,
        date: null,
        dateOnly,
        header: '',
        subType,
        isNew: true,
        isClosed: false
    } as IDataRow
)

const NewUserField = () => (
    {
        id: Math.random() + "",
        checklistFormDataRowTypeSymbol: DataRowType.USER_FIELD,
        header: '',
        subType: DataRowSubType.NONE,
        isNew: true,
        isClosed: false
    }
)

const NewSkillField = () => (
    {
        id: Math.random() + "",
        checklistFormDataRowTypeSymbol: DataRowType.SKILL_FIELD,
        header: '',
        subType: DataRowSubType.NONE,
        isNew: true,
        isClosed: false
    }
)

const NewMultiUserField = (subType: DataRowSubType) => (
    {
        multiUsers: [],
        checklistFormDataRowTypeSymbol: DataRowType.MULTI_USER_FIELD,
        header: '',
        subType,
        isNew: true,
        isClosed: false
    } as IDataRow
)

const NewMultiPickField = (subType: DataRowSubType, picks: string[]) => (
    {
        checklistFormDataRowTypeSymbol: DataRowType.MULTI_PICK_FIELD,
        header: "",
        isNew: true,
        subType,
        isClosed: false,
        multiPicks: picks.map(v => ({ value: v, chosen: false })),
    } as IDataRow
)

const NewImageField = (subType: DataRowSubType) => (
    {
        checklistFormDataRowTypeSymbol: DataRowType.IMAGE_FIELD,
        header: "",
        subType,
        isNew: true,
        isClosed: false,

    } as IDataRow
)

const NewFileField = (subType: DataRowSubType) => (
    {
        id: Math.random() + "",
        checklistFormDataRowTypeSymbol: DataRowType.FILES_FIELD,
        header: "",
        subType,
        isNew: true,
        isClosed: false,
        files: [],
        newFiles: []

    } as IDataRow
)


const enum Internal_Ids {
    EXTERNAL_USER_TOGGLE = "External_User_Toggle",
    EXTERNAL_USER_TEXT = "External_Users_Text"
}

const IncidentDataRows = [
    NewTextField(DataRowSubType.ACTIONPLAN_PROJECT_NUMBER, undefined, undefined, 1, 30),
    NewMultiChoice(DataRowSubType.INCIDENT_TYPE, [
        IncidentType.INCIDENT,
        IncidentType.ACCIDENT,
        IncidentType.DEVIATION
    ]),
    NewDateField(DataRowSubType.INCIDENT_DATE),
    NewTextField(DataRowSubType.INCIDENT_LOCATION),
    NewTextField(DataRowSubType.INCIDENT_DESCRIPTION),
    NewTextField(DataRowSubType.INCIDENT_INSTANT_ACTION),
    NewMultiUserField(DataRowSubType.INCIDENT_USERS),
    NewMultiChoice(DataRowSubType.INCIDENT_EXTERNAL_PERSONAL_EXISTS, [
        IncidentExternalPersonExists.NOT_SPECIFIED,
        IncidentExternalPersonExists.YES,
        IncidentExternalPersonExists.NO
    ], Internal_Ids.EXTERNAL_USER_TOGGLE),
    NewTextField(DataRowSubType.INCIDENT_EXTERNAL_PERSONAL, Internal_Ids.EXTERNAL_USER_TEXT, true),
    NewImageField(DataRowSubType.ACTIONPLAN_SUBTYPE_IMAGE),
    NewFileField(DataRowSubType.INCIDENT_FILES),
    NewActionUser(),
];

const DeviationDataRows = [
    NewTextField(DataRowSubType.ACTIONPLAN_PROJECT_NUMBER, undefined, undefined, 1, 30),
    NewMultiChoice(DataRowSubType.INCIDENT_TYPE, [
        IncidentType.INCIDENT,
        IncidentType.ACCIDENT,
        IncidentType.DEVIATION
    ]),
    NewMultiChoice(DataRowSubType.DEVIATION_TYPE, [
        DeviationType.NOT_SPECIFIED,
        DeviationType.WORK_ENVIRONMENT,
        DeviationType.QUALITY,
        DeviationType.ENVIRONMENT,
        DeviationType.IMPROVEMENT_SUGGESTION,
        DeviationType.REVISION,
        DeviationType.OPINION,
        DeviationType.CUSTOMER_COMPLAINT,
        DeviationType.DEFICIENT_SELF_INSPECTIONS_AT_WORKPLACE,
        DeviationType.INADEQUATE_SECURITY_WORKPLACE,
        DeviationType.DEFICIENT_CHEMICAL_MANAGEMENT,
        DeviationType.INADEQUATE_FINANCIAL_MONITORING,
        DeviationType.RESOURCE_SHORTAGE,
        DeviationType.INSUFFICIENT_KNOWLEDGE_SKILLS,
        DeviationType.OTHER
    ], undefined, true),
    NewTextField(DataRowSubType.DEVIATION_DESCRIPTION),
    NewTextField(DataRowSubType.DEVIATION_INSTANT_ACTION),
    NewMultiUserField(DataRowSubType.INCIDENT_USERS),
    NewImageField(DataRowSubType.ACTIONPLAN_SUBTYPE_IMAGE),
    NewFileField(DataRowSubType.INCIDENT_FILES),

    NewActionUser(),
];

const StandaloneActionDataRows = [
    NewTextField(DataRowSubType.ACTIONPLAN_PROJECT_NUMBER, undefined, undefined, 1, 30),
    NewMultiChoice(DataRowSubType.STANDALONE_CATEGORY, [
        StandaloneCategory.NOT_SPECIFIED,
        StandaloneCategory.RISK,
        StandaloneCategory.DEVIATION,
        StandaloneCategory.INCIDENT_ACCIDENT,
        StandaloneCategory.IMPROVEMENT_SUGGESTION
    ], undefined, true),
    NewTextField(DataRowSubType.STANDALONE_DESCRIPTION),
    NewTextField(DataRowSubType.STANDALONE_ACTION),
    NewTextField(DataRowSubType.STANDALONE_RESOURCES),
    NewActionUser(),
];

const UserSkillDataRows = [
    NewUserField(),
    NewSkillField(),
    NewMultiClassifiedChoice(DataRowSubType.SKILL_COURSE_COMPLETED, [
        {
            name: UserSkillCourseCompleted.NOT_SPECIFIED,
            classifiedAs: MultiChoiceAlternativeClassified.TRANSPARENT,
        },
        {
            name: UserSkillCourseCompleted.YES,
            classifiedAs: MultiChoiceAlternativeClassified.POSITIVE,
        },
        {
            name: UserSkillCourseCompleted.NO,
            classifiedAs: MultiChoiceAlternativeClassified.NEGATIVE,
        }
    ], undefined, false),
    NewDateField(DataRowSubType.SKILL_COURSE_COMPLETED_DATE, true),
    NewDateField(DataRowSubType.SKILL_COURSE_VALID_DATE, true),
    NewMultiClassifiedChoice(DataRowSubType.SKILL_LEVEL, [
        {
            name: UserSkillLevel.NOT_SPECIFIED,
            classifiedAs: MultiChoiceAlternativeClassified.TRANSPARENT,
        },
        {
            name: UserSkillLevel.LEVEL_1,
            classifiedAs: MultiChoiceAlternativeClassified.NEGATIVE,
        },
        {
            name: UserSkillLevel.LEVEL_2,
            classifiedAs: MultiChoiceAlternativeClassified.NEGATIVE,
        },
        {
            name: UserSkillLevel.LEVEL_3,
            classifiedAs: MultiChoiceAlternativeClassified.NEUTRAL,
        },
        {
            name: UserSkillLevel.LEVEL_4,
            classifiedAs: MultiChoiceAlternativeClassified.POSITIVE,
        },
        {
            name: UserSkillLevel.LEVEL_5,
            classifiedAs: MultiChoiceAlternativeClassified.POSITIVE,
        }
    ], undefined, false),
    NewTextField(DataRowSubType.SKILL_DESCRIPTION),
    NewFileField(DataRowSubType.SKILL_COURSE_FILES)
];

const getDataRowHeader = (dataRow: IDataRow): string => {
    const subType = DataRowSubType[dataRow.subType!];

    if (dataRow.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD)
        return i18n.t('dataRowHelper.RESPONSIBLE_FOR_ACTION');
    else if (dataRow.checklistFormDataRowTypeSymbol === DataRowType.USER_FIELD)
        return i18n.t('dataRowHelper.USER_FIELD');
    else if (dataRow.checklistFormDataRowTypeSymbol === DataRowType.SKILL_FIELD)
        return i18n.t('dataRowHelper.SKILL_FIELD');
    else if (subType && subType !== DataRowSubType.NONE)
        return i18n.t('DATAROW_SUBTYPE.' + dataRow.subType);
    else
        return dataRow.header;
}

export {
    DataRowType, DeviationDataRows, IncidentDataRows, Internal_Ids, MultiChoiceDisplayType, NewActionUser,
    NewDateField, NewDescriptionField, NewImageField, NewMultiChoice, NewMultiPickField, NewMultiUserField, NewTextField, StandaloneActionDataRows, UserSkillDataRows, getDataRowHeader
};

