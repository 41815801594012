import { useEffect, useRef, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { Icon, IconSize, getIcon } from "../../../../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../../../../components/ClickableIconButton/ClickableIconButton";
import { CustomDropdownItem } from "../../../../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import DragAndDropComponent from "../../../../../../components/DragAndDropComponent/DragAndDropComponent";
import DropDownIconToggle from "../../../../../../components/DropDownIconToggle";
import CustomTextAreaInput from "../../../../../../components/FormComponents/CustomTextAreaInput";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import ModalForm from "../../../../../../components/FormComponents/ModalForm";
import SimpleConfirmModal from "../../../../../../components/ModalComponents/SimpleConfirmModal";
import TooltipWrapper from "../../../../../../components/Tooltip/TooltipWrapper";
import When from "../../../../../../components/When";
import { DataRowType } from "../../../../../../core/enums/enums";
import { IDataRow } from "../../../../../../core/interfaces/document";
import { useChecklistContext } from "../../ChecklistContext";
import styles from "../checklistTable.module.scss";
import { DataRowWidth } from "./TableView";


const HeaderDivs = (dataRows: IDataRow[], width: DataRowWidth, ids: string[]) => {
    return dataRows
        .map((dataRow, index) => {
            return {...dataRow, index};
        })
        .sort((a, b) => {
            if (a.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD) return 1;
            if (b.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD) return -1;
            return 0;
        })
        .map(({index, ...dataRow}) => {
            return <HeaderDivsInternal key={dataRow.id} index={index} width={width} ids={ids} dataRow={dataRow}/>
        });
}

const HeaderDivsInternal = (
    {
        index,
        ids,
        dataRow,
        width
    }: { index: number, width: DataRowWidth, ids: string[], dataRow: IDataRow }) => {

    const {
        moveDataRow,
        changeHeaderOnFormsDataRow,
        changeHeaderTooltipOnFormsDataRow,
        documentContext: {isEditMode},
        removeDataRowsOnForms
    } = useChecklistContext();
    const {t} = useTranslation();
    const [showRemoveColumnModal, setShowRemoveColumnModal] = useState(false);
    const [showHeaderTooltipModal, setShowHeaderTooltipModal] = useState(false);

    const idleTimeoutRef = useRef<any>(null);
    const [internalHeader, setInternalHeader] = useState(dataRow.header);
    const [headerTooltipText, setHeaderTooltipText] = useState(dataRow.headerTooltipText);

    const delayedUpdateHeader = () => {
        clearTimeout(idleTimeoutRef.current);
        idleTimeoutRef.current = setTimeout(() => {
            return changeHeaderOnFormsDataRow(index, internalHeader, ids);
        }, 500);
    };
    useEffect(() => {
        delayedUpdateHeader();
    }, [internalHeader]);


    const handleMove = (oldIndex: number, newIndex: number) => moveDataRow(oldIndex, newIndex, ids);

    const handleRemoveColumn = () => {
        removeDataRowsOnForms(index, ids);
        setShowRemoveColumnModal(false);
    }

    const closeHeaderTooltipModal = () => {
        setShowHeaderTooltipModal(false);
    }

    const updateHeaderTooltipText = () => {
        changeHeaderTooltipOnFormsDataRow(index, ids, headerTooltipText);
        closeHeaderTooltipModal();
    }

    const renderDataRowActionFieldHeader = () => (<>
        <CustomTextInput
            style={{fontWeight: "bold", backgroundColor: "#e9f1ff"}}
            checklistTableView={true}
            name={"ACTION_FIELD_HEADER"}
            value={t('HeaderDivs.RESPONSIBLE_FOR_ACTION_1')}
            onChange={() => NaN}
            disabled
        />
    </>);

    const [headerRows, setHeaderRows] = useState(1);
    const parentDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setHeaderRows(dataRow.header.split('\n').length > 1 ? dataRow.header.split('\n').length : 1);
    }, [dataRow.header]);

    useEffect(() => {
        if (parentDivRef.current) {
            const lineHeight = 20;
            const calculatedHeight = lineHeight * headerRows + 16;
            const newHeight = calculatedHeight > 44 ? calculatedHeight : 44;
            parentDivRef.current.style.height = `${newHeight}px`;
        }
    }, [headerRows]);

    const renderHeader = () => (<>
        <CustomTextAreaInput
            checklistViewTable={true}
            style={{fontWeight: "bold", backgroundColor: "#e9f1ff"}}
            isEditMode={isEditMode}
            rows={headerRows}
            name={"textValue"}
            value={internalHeader}
            onChange={(e: any) => setInternalHeader(e.target.value)}
            onFocusEdit={isEditMode}
            defaultValueDash={false}
            showInputFieldInReadOnly
            title={headerTooltipText}
            defaultHeight
            disableResize
        />
    </>);

    return (<div ref={parentDivRef} className={styles.tableHeader}
                 style={{width: width[index]}}>
        <When condition={dataRow.checklistFormDataRowTypeSymbol !== DataRowType.ACTION_PLAN_FIELD}>
            <div className={"position-absolute ps-1"}>
                <When condition={isEditMode}>
                    <Dropdown drop={'down'} style={{paddingTop: "4px", zIndex: 8}}>
                        <DropDownIconToggle icon={Icon.THREE_DOTS_VERTICAL} iconSize={IconSize.XL}/>
                        <DropdownMenu>
                            <Container fluid style={{zIndex: 9899}}>
                                <CustomDropdownItem
                                    icon={Icon.EDIT}
                                    text={t('HeaderDivs.DESCRIPTIVE_TEXT_1')}
                                    onClick={() => setShowHeaderTooltipModal(true)}
                                />
                                <CustomDropdownItem
                                    icon={Icon.REMOVE}
                                    text={t('HeaderDivs.DELETE_1')}
                                    onClick={() => setShowRemoveColumnModal(true)}
                                />
                            </Container>
                        </DropdownMenu>
                    </Dropdown>
                </When>
            </div>
        </When>
        <DndProvider backend={HTML5Backend}>
            <DragAndDropComponent
                allowedToDrag={isEditMode && dataRow.checklistFormDataRowTypeSymbol !== DataRowType.ACTION_PLAN_FIELD}
                allowedToDrop={isEditMode && dataRow.checklistFormDataRowTypeSymbol !== DataRowType.ACTION_PLAN_FIELD}
                type={"HEADER"}
                id={dataRow.id!}
                index={index}
                onDrop={handleMove}
                style={{right: 0, top: 0, zIndex: 5}}
                dragIconLeftRight
                hideDragHandle={!isEditMode}
            >
                <div className={"d-flex"}>
                    <When
                        condition={dataRow.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD}
                        then={renderDataRowActionFieldHeader()}
                        otherwise={renderHeader()}
                    />
                    {headerTooltipText && (
                        <TooltipWrapper tooltipText={headerTooltipText}>
                            <div style={{position: "absolute", right: isEditMode ? 32 : 0, top: "-2px"}}>
                                <ClickableIconButton
                                    disabled={false}
                                    buttonType={"EDIT"}
                                    icon={getIcon(Icon.INFO_CIRCLE)}
                                    onClick={() => NaN}
                                />
                            </div>
                        </TooltipWrapper>
                    )}
                </div>
            </DragAndDropComponent>
        </DndProvider>

        <SimpleConfirmModal
            show={showRemoveColumnModal}
            header={<>{t('HeaderDivs.DELETE_COLUMN_1')}</>}
            text={<>{t('HeaderDivs.SURE_DELETE_COLUMN_1')}</>}
            onClickOKText={t('HeaderDivs.CONFIRM_DELETE_1')}
            onClickOK={handleRemoveColumn}
            onClickCancelText={t('HeaderDivs.CANCEL_1')}
            onClickCancel={() => setShowRemoveColumnModal(false)}
        />

        <ModalForm
            size={"sm"}
            show={showHeaderTooltipModal}
            hide={closeHeaderTooltipModal}
            title={t('HeaderDivs.DESCRIPTIVE_TEXT_2')}
            isEdited={() => dataRow.headerTooltipText !== headerTooltipText}
            submit={updateHeaderTooltipText}
            submitText={t('HeaderDivs.SAVE_1')}
            isLoading={false}
        >
            <Row>
                <Col>{t('HeaderDivs.HEADING')} {internalHeader}</Col>
                {/*<Col>t('HeaderDivs.HEADING')} {dataRow.header}</Col>*/}
            </Row>
            <Row className={"pt-2"}>
                <Col>
                    <CustomTextInput
                        name={"headerTooltipText"}
                        value={headerTooltipText ?? ""}
                        onChange={(e: any) => setHeaderTooltipText(e.target.value)}
                        disableAutocomplete
                        autofocus
                    />
                </Col>
            </Row>
        </ModalForm>
    </div>)
}

export default HeaderDivs
