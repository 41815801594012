import { useState } from "react";
import { IDocumentAttachment } from "../../../core/interfaces/document";
import { AttachmentList } from "../Components/AttachmentList";
import { AttachmentUpload } from "../Components/AttachmentUpload";

export function AttachmentForm(props: { attachments: IDocumentAttachment[], isEditMode: boolean, documentId?: string }) {
    const [newAttachments, setNewAttachments] = useState<IDocumentAttachment[]>([]);
    const appendAttachment = (attachment: IDocumentAttachment) => {
        setNewAttachments([...newAttachments, attachment]);
    }

    return <>
        <AttachmentUpload newAttachment={appendAttachment} isEditMode={props.isEditMode}/>
        <AttachmentList attachments={props.attachments.concat(newAttachments)} isEditMode={props.isEditMode} documentId={props.documentId}/>
    </>;
}