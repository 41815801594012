import {Locale, differenceInDays, formatDistanceStrict, parseISO} from "date-fns";
import sv from "date-fns/locale/sv";
import da from "date-fns/locale/da";
import en from "date-fns/locale/en-US";
import i18n from "i18next";

export const isUndefined = (value: any) => value === undefined || value === null;

export const handleOnEnter = (event: any, func: () => void) => {
    if (event.keyCode === 13) {
        func();
    }
}

export const getBlockedFileTypes = ["application/x-msdownload", "application/octet-stream", "application/bat", "application/cmd", "application/exe", "application/dll", "application/vbscript", "application/vbs", "application/scriptlet", "application/wsf", "application/pif"];


const locales: Record<string, Locale> = {
    sv: sv,
    da: da,
    en: en
};

export const formatRelativeDateFromToday = (date: string) => {
    const newDate = parseISO(date);
    return formatDistanceStrict(newDate, new Date(), {
        addSuffix: true,
        locale: locales[i18n.language]
    });
}

export const differenceInDaysDate = (date: string) => {
    const newDate = parseISO(date);
    const dateNow = new Date();
    newDate.setHours(0, 0, 0, 0);
    dateNow.setHours(0, 0, 0, 0);
    return differenceInDays(dateNow, newDate);
}