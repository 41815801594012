import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUserDocuments } from "../../../api/CompanyApi";
import { downloadDocument } from "../../../api/DocumentApi";
import { useRenameNode } from "../../../api/DocumentDirectory";
import PaginatedDataTable from "../../../components/DataTableComponents/PaginatedDataTable";
import FormBase from "../../../components/FormComponents/FormBase";
import GenericInput from "../../../components/FormComponents/GenericInput";
import ModalForm from "../../../components/FormComponents/ModalForm";
import { DocumentTypes, NodeType } from "../../../core/enums/enums";
import { DateToLocale } from "../../../core/helpers/dates/datesHelper";
import { IUserFile } from "../../../core/interfaces/company";
import { IDocumentNodeModel } from "../../../core/interfaces/directory";
import { IDocumentLightExtended, IRenameNodeRequest } from "../../../core/interfaces/document";
import DocumentType from "../../DocumentDirectory/Components/DocumentType";
import TableDropdown from "../../DocumentDirectory/Components/TableDropdown";
import { getBlockedFileFormats } from "../util";
import styles from './FileTable.module.scss';
import { FileUpload } from "./FileUpload";

const FileTable = ({companyUserId}: { files: IUserFile[], companyUserId: string }) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [docs, isFetching] = useUserDocuments(companyUserId);
    const {cId, cUId} = useParams();
    const [editNameDoc, setEditNameDoc] = useState('');
    const [renameNode, isRenamingNode] = useRenameNode(cId ?? "");
    const [changeNameObject, setChangeNameObject] = useState<IRenameNodeRequest>({
        id: '',
        name: '',
        type: NodeType.DOCUMENT
    });
    const [filterText, setFilterText] = useState("");
    const changeName = async () => {

        await renameNode({
            name: changeNameObject?.name ?? '',
            id: editNameDoc,
            type: NodeType.DOCUMENT
        }).then((response) => {
            if (response.success) {
                setEditNameDoc('');
            }
        });
    }

    useEffect(() => {
        setChangeNameObject({id: '', name: '', type: NodeType.DOCUMENT})
    }, [editNameDoc])
    const handleClick = async (isFile: boolean, documentId: string) => {
        if (isFile) {
            return downloadDocument(documentId).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: response.contentType}));
                window.open(url);
            });
        }
        navigate(`/c/${cId}/documents/${documentId}?user=${cUId}`, {state: {hash: location.hash}})
    }
    const columns: TableColumn<IDocumentLightExtended>[] = [
        {
            name: t('FileTable.NAME_1'),
            selector: row => row.name,
            sortable: true,
            cell: row => <span className={styles.clickable}
                               onClick={() => handleClick(row.documentType === DocumentTypes.FILE, row.id)}><DocumentType
                data={{...row} as unknown as IDocumentNodeModel}/> <span
                className={"ps-2"}>{row.name}</span></span>
        },
        {
            name: t('FileTable.TYPE_1'),
            selector: row => row.fileExtension ?? t('FileTable.DOCUMENT_1'),
            sortable: true,
        },
        {
            name: t('FileTable.TEMPLATE_1'),
            selector: row => DateToLocale(row.createdAt) ?? Date.now(),
            sortable: true,
        },
        {
            name: <FileUpload isEditMode={true} companyUserId={companyUserId}/>,
            right: true,
            allowOverflow: true,
            cell: row => <TableDropdown
                isUserDoc={true}
                isDeleted={false}
                isDocument={true}
                onClickEditName={() => setEditNameDoc(row.id)}
                documentId={row.id}
                documentType={row.documentType as DocumentTypes}
                documentName={row.name}
            />
        },
    ];

    const filteredItems = docs.filter(
        item => (
            (item.name && item.name.trim().toLowerCase().includes(filterText.trim().toLowerCase())) ||
            (item.fileExtension && item.fileExtension.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.createdAt && item.createdAt.toLowerCase().includes(filterText.toLowerCase()))
        )
    )

    return (
        <>
            <PaginatedDataTable 
                data={filteredItems ?? []} 
                progressPending={isFetching} 
                columns={columns}
                withFilter
                filterText={filterText}
                setFilterText={(prev) => setFilterText(prev)}
            />
            <p>{t('FileTable.DIRECT_SAVE_1')}</p>
            <p style={{fontSize: 10}}>{t('FileTable.NOT_ACCEPTED_FORMATS_1')} 
                ( {getBlockedFileFormats.map(v => `"${v}"`).join(', ')} )</p>


            <ModalForm
                show={!!editNameDoc}
                hide={() => setEditNameDoc('')}
                title={t('FileTable.CHANGE_NAME_1')}
                isEdited={() => false}
                submit={changeName}
                isLoading={isRenamingNode}>
                <FormBase
                    row={false}
                    controlId={'name'}
                    title={t('FileTable.NEW_NAME_1')}
                    className={'pb-2'}>
                    <GenericInput
                        name={'name'}
                        isEditMode={true}
                        object={changeNameObject}
                        setValue={setChangeNameObject}
                        autofocus
                    />
                </FormBase>
            </ModalForm>

        </>
    )
}
export default FileTable
