import React from "react";
import Col, { ColProps } from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/esm/Form";

type FormBaseProps = {
    controlId: string,
    title: string,
    row?: boolean
    children: JSX.Element | JSX.Element[],
    className?: string[] | string,
    labelProps?: ColProps,
    inputProps?: ColProps,
}
const FormBase: React.FC<FormBaseProps> = ({
    controlId,
    title,
    children,
    row = true,
    className = "",
    labelProps,
    inputProps
}) => {

    const classes = typeof className == 'string' ? [className] : className;

    return (<Form.Group as={row ? Row : undefined} controlId={controlId} className={classes ? classes.join(" ") : 'pb-2'}>
        <Form.Label column {...labelProps}>{title}</Form.Label>
        <Col {...inputProps}>
            {children}
        </Col>
    </Form.Group>)
}

export default FormBase;