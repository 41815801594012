import i18n from "i18next";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../components/FormComponents/CustomSelect";
import When from "../../../components/When";
import FormProvider from "./FormProvider/FormProvider";


const ValueToText = (value?: boolean) => {
    if (value === undefined) return i18n.t('BooleanForm.NOT_SPECIFIED_1');
    return value ? i18n.t('BooleanForm.YES_1') : i18n.t('BooleanForm.NO_1');
}
type BooleanFormProps = { editMode: boolean, header: string, propertyName: string, property?: boolean, onChange: (value: boolean | null, e: any) => void };

export function BooleanForm(props: BooleanFormProps) {
    const {t} = useTranslation();
    return <FormProvider controlId={"form" + props.propertyName} title={props.header}>
        <When condition={props.editMode}
              otherwise={<span data-test-id={"form" + props.propertyName}>{ValueToText(props.property)}</span>}>
            <CustomSelect
                isEditMode={props.editMode}
                name={props.propertyName}
                value={props.property === undefined ? '' : props.property ? '1' : '0'}
                options={<>
                    <option value="">{t('BooleanForm.NOT_SPECIFIED_2')}</option>
                    <option value="1">{t('BooleanForm.YES_2')}</option>
                    <option value="0">{t('BooleanForm.NO_2')}</option>
                </>}
                onChange={e => {
                    let value = null;
                    if (e.target.value) {
                        value = e.target.value === '1';
                    }
                    props.onChange(value, e);
                }}
            />
        </When>
    </FormProvider>
}
