import { useTranslation } from "react-i18next";
import { useUpdateDocumentVersion } from "../../../api/DocumentApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import { CompanyRole, PublishStatusType } from "../../../core/enums/enums";
import { toastInfo } from "../../../core/helpers/toastHelper";
import { IDocument } from "../../../core/interfaces/document";
import useAccessCheck from "../../../hooks/useAccessCheck";
import { useGetUser } from "../../../hooks/useAuth";
import { useAcceptModalContext } from "../../../providers/AcceptModalProvider";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";

export const NewVersion = (
    {
        document,
        disabled,
        isEditMode,
        setEditMode
    }: { document: IDocument, disabled: boolean, isEditMode: boolean, setEditMode: (v: boolean) => void }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [checkCompanyRole] = useAccessCheck();
    const user = useGetUser();
    const [request, isLoading] = useUpdateDocumentVersion(document.id);
    const acceptModalContext = useAcceptModalContext();
    const requestFunction = () => {
        request().then(success => {
            if (success) {
                setEditMode(false);
            }
        })
    }

    const handleCreateNewVersion = () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('NewVersion.EXIT_EDITING_MODE_1'), t('NewVersion.EDITING_MODE_1'))));
            return;
        }
        acceptModalContext.trigger(requestFunction, {
            header: t('NewVersion.VERSION_CREATION_1'),
            declineButtonText: t('NewVersion.CLOSE_1'),
            acceptButtonText: t('NewVersion.YES_1'),
            body: <p>{t('NewVersion.CONFIRM_VERSION_1')}</p>
        })
    }

    return <CustomDropdownItem
        disabled={disabled || isLoading || !((checkCompanyRole(CompanyRole.ADMIN) || document?.createdBy?.id === user.userId) && document.publishStatus === PublishStatusType.APPROVED)}
        onClick={handleCreateNewVersion}
        text={t('NewVersion.NEW_VERSION_1')}
        icon={Icon.CODE_COMPARE}
    />
}
