import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetCompanyLogo } from "../../api/CompanyApi";
import LoadingSpinner from "../LoadingSpinner";
import When from "../When";
import styles from "./companyLogo.module.scss";


interface ICompanyLogo {
    companyId: string;
    rounded?: boolean;
    thumbnail?: boolean;
    hideImageMissing?: boolean;
    onClick?: () => void;
}

const CompanyLogo = (
    {
        companyId,
        rounded,
        thumbnail,
        hideImageMissing,
        onClick,
    }: ICompanyLogo) => {
    const {t} = useTranslation();
    const {logoUrl, isFetching} = useGetCompanyLogo(companyId);
    const renderLogo = () => (<>
        <When condition={!logoUrl}>
            <When condition={!hideImageMissing}>
                <div className={`${styles.imageMissing} ${onClick ? "cursor-pointer" : ""}`} onClick={onClick}>
                    <span className={styles.imageMissingText}>{t('CompanyLogo.IMAGE_MISSING_1')}</span>
                </div>
            </When>
        </When>
        <When condition={!!logoUrl}>
            <Image
                className={onClick ? "cursor-pointer" : ""}
                fluid
                rounded={rounded}
                thumbnail={thumbnail}
                width={"120px"}
                style={{maxHeight: "56px"}}
                src={logoUrl}
                onClick={onClick}
            />
        </When>
    </>)

    return <>
        <When
            condition={isFetching}
            then={<div style={{width: "120px", height: "56px"}}>
                <LoadingSpinner removePadding/>
            </div>}
            otherwise={renderLogo()}
        />
    </>
};

export default CompanyLogo;
