import {
    DndProvider,
    getBackendOptions,
    MultiBackend,
    NodeModel,
    NodeRender,
    Tree,
    TreeMethods
} from "@minoru/react-dnd-treeview";
import { useRef } from "react";
import { NodeType } from "../../../../core/enums/enums";
import { ITemplateNodeData } from "../../../../core/interfaces/template";
import TemplatesDirectoryNode from "./TemplatesDirectoryNode";

interface ITemplatesDirectory {
    templatesData: NodeModel<ITemplateNodeData>[];
    onClickNode: (node: NodeModel<ITemplateNodeData>) => void;
    selectedNode: NodeModel<ITemplateNodeData> | null;
    nodeTypeAllowedToSelect?: NodeType;
}

const TemplatesDirectoryTree = (
    {
        templatesData,
        onClickNode,
        selectedNode,
        nodeTypeAllowedToSelect,

    }: ITemplatesDirectory) => {
    const ref = useRef<TreeMethods>(null);

    const nodeRender: NodeRender<ITemplateNodeData> = (
        node: NodeModel<ITemplateNodeData>,
        {depth, isOpen, onToggle}
    ) => (
        <TemplatesDirectoryNode
            node={node}
            depth={depth}
            isOpen={isOpen}
            nodeTypeAllowedToSelect={nodeTypeAllowedToSelect}
            isSelected={node === selectedNode}
            onToggle={onToggle}
            onClickNode={onClickNode}
        />
    )

    return <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree
            classes={{
                root: "treeRoot",
                draggingSource: "draggingSource",
                dropTarget: "dropTarget",
                placeholder: "placeholderContainer"
            }}
            tree={templatesData}
            ref={ref}
            rootId={''}
            sort={false}
            canDrag={() => false}
            canDrop={() => false}
            onDrop={() => NaN}
            render={nodeRender}
        />
    </DndProvider>
};

export default TemplatesDirectoryTree;