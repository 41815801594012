import { useParams } from "react-router-dom";
import { ICustomQuery, ICustomQueryKeys } from "../core/interfaces/common";
import { ITodo } from "../core/interfaces/todo";
import { AppQueryKey } from "../core/queryKeys";
import { useGet } from "../hooks/useCustomQuery";
import { URL_COMPANYUSER_TODO } from "./endpoints/endpoints";

export const useGetCompanyUserTodos = (): [ITodo[], boolean] => {
    const {cId} = useParams();
    const queryKeys: ICustomQueryKeys = {root: AppQueryKey.TODO, cId};

    const query: ICustomQuery = {
        url: URL_COMPANYUSER_TODO(cId!),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet(query);

    return [data?.data.data as any as ITodo[] ?? [], isFetching]
}
