import { ReactNode } from "react";
import Placeholder from "react-bootstrap/esm/Placeholder";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import LoadingSpinner from "../../components/LoadingSpinner";

interface IDashboardCard {
    fetching: boolean;
    theme: "lightblue" | "darkblue" | "green" | "grey";
    height?: string;
    onClick?: () => void;
    children?: ReactNode;
}

const DashboardCard = ({
    fetching,
    height,
    theme,
    onClick,
    children
}: IDashboardCard) => {

    const classNames = () => {
        let classNames = "";
        if (fetching) return "";
        if (onClick) {
            classNames += "dashboard-card-clickable"
        } else {
            classNames += " cursor-default";
        }
        return classNames;
    }

    const Loading = () => {
        return <Placeholder animation="glow">
            <Placeholder xs={6} size={"sm"} style={{ cursor: "default" }} />
            <Placeholder xs={9} size={"sm"} style={{ cursor: "default" }} />
            <Placeholder xs={12} size={"sm"} style={{ cursor: "default" }} />
            <Placeholder xs={9} size={"sm"} style={{ cursor: "default" }} />
            <Placeholder xs={12} size={"sm"} style={{ cursor: "default" }} />
        </Placeholder>
    }

    return (
        <ContentContainer
            className={classNames()}
            theme={theme}
            onClick={onClick}
            style={{ height: height ? height : "12rem" }}
        >
            {fetching ? <LoadingSpinner spinnerAnimation="border" /> : children}
        </ContentContainer>
    )
};

export default DashboardCard;