import { ICustomQuery, ICustomQueryKeys } from "../core/interfaces/common";
import { ICompany } from "../core/interfaces/company";
import { AppQueryKey } from "../core/queryKeys";
import { useGet } from "../hooks/useCustomQuery";
import { URL_COMPANIES } from "./endpoints/endpoints";


export const useGetCompanies = (cId?: string) => {
    const queryKeys: ICustomQueryKeys = { root: AppQueryKey.COMPANIES, cId: cId };
    let url = URL_COMPANIES + '?limit=1000';
    if (cId)
        url += '&parentCompanyId=' + cId;

    const query: ICustomQuery = {url, queryKeys};
    return useGet<ICompany[]>(query);
}