import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {modalResponse, ModalState} from "../store/slices/changesModalSlice";
import {useCNavigate} from "../hooks/useCNavigate";
import ChangesModal from "./ChangesModal";


const ReduxChangesModal = () => {
    const changesModalState = useAppSelector((state) => state.changesModal.state);
    const modalHolder = useAppSelector((state) => state.changesModal.modalHolder)
    const cNavigate = useCNavigate();
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (modalHolder) {
            window.history.pushState('fake-route', document.title, window.location.href);
        }
        const handler = () => {
            if (modalHolder && changesModalState === ModalState.IDLE) {
                cNavigate("..", {relative: 'path'});
            }
        }

        window.addEventListener('popstate', handler)

        return () => {
            window.removeEventListener('popstate', handler)
        }
        //This function won't work if we use all the dependencies.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalHolder])

    return (
        <ChangesModal show={changesModalState === ModalState.OPENED}
                      setShow={(status) => dispatch(modalResponse(status))}/>
    )
}

export default ReduxChangesModal