import React from "react";
import { useTranslation } from "react-i18next";
import HeaderContainer from "../../../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../../../components/ContainerComponents/MainContainer";
import IncidentForm, { IIncidentForm } from "../IncidentForm/IncidentForm";
import styles from './incidentContainer.module.scss';


interface IIncidentContainer extends IIncidentForm{}

const IncidentContainer: React.FC<IIncidentContainer> = (props) => {
    const {t} = useTranslation();
    return (
        <MainContainer className={styles.container}>
            <HeaderContainer title={props.isNewIncident ? t('IncidentContainer.NEW_OFFER_ACCIDENT_1') : t('IncidentContainer.INCIDENTS_ACCIDENTS_1')}/>
            <IncidentForm
                {...props}
            />
        </MainContainer>
    )
}

export default IncidentContainer
