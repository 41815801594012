import Form from "react-bootstrap/esm/Form";
import {CSSProperties} from "react";
import When from "../When";

interface ICustomSelect {
    isEditMode: boolean;
    name: string;
    value?: string | number | readonly string[] | undefined;
    options?: any;
    onChange: (e: any) => void;
    required?: boolean;
    children?: JSX.Element[];
    size?: "sm" | "lg" | undefined;
    disabled?: boolean | undefined;
    style?: CSSProperties | undefined;
    showInputFieldInReadOnly?: boolean;
    defaultValueDash?: boolean;
    defaultValue?: string | number | readonly string[] | undefined;
    checklistViewTable?: boolean;
}

const CustomSelect = (
    {
        isEditMode,
        name,
        value,
        options,
        onChange,
        children,
        required,
        size,
        disabled,
        style,
        showInputFieldInReadOnly,
        defaultValueDash,
        defaultValue,
        checklistViewTable,
    }: ICustomSelect) => {

    const renderSelectedValue = () => {
        if (!value) {
            return defaultValueDash ? "-" : "";
        }
        return value;
    }

    return <When
        condition={isEditMode}
        otherwise={
            showInputFieldInReadOnly ? (
                <Form.Select
                    className={checklistViewTable ? "checklistTableFieldsSelect" : ""}
                    style={style}
                    as="select"
                    disabled
                    size={size}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    aria-label={`select_${name}`}
                >{options}{children}</Form.Select>
            ) : <span>{renderSelectedValue()}</span>
        }>

        <Form.Select
            className={checklistViewTable ? "checklistTableFieldsSelect" : ""}
            style={style}
            as="select"
            disabled={disabled}
            size={size}
            name={name}
            value={value}
            aria-label={`select_${name}`}
            onChange={onChange}
            required={required}
            defaultValue={defaultValue}
        >
            {options}
            {children}
        </Form.Select>
    </When>
};

export default CustomSelect;