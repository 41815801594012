import { NodeModel } from "@minoru/react-dnd-treeview";
import { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetNodes } from "../../api/DocumentDirectory";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import OverlayComponent from "../../components/OverlayComponent";
import SearchBar from "../../components/SearchBar";
import { CustomFolders } from "../../core/enums/enums";
import { IDocumentNodeModel, IFolderNodeModel } from "../../core/interfaces/directory";
import Directory from "./Directory";
import DocumentPreview from "./DocumentPreview";
import FolderContent from "./FolderDetails";
import { convertDirectoryNodesToModels, getParentIds, goToDocument, isFolder } from "./Helpers/DirectoryHelper";


const DocumentDirectory = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const rootHash = "root";
    const [selectedNodeHash, setSelectedNodeHash] = useState<string | null>(rootHash);
    const [isDragAndDropEnabled, setDragAndDropEnabled] = useState(false);
    const [search, setSearch] = useState('');
    const [map, setMap] = useState(new Map<string, NodeModel<IFolderNodeModel>>());

    const [expandedFolders, setExpandedFolders] = useState(new Set<string>());

    // Fetch data
    const {
        isFetching: isFetchingDocumentDirectoryNodes,
        data: documentDirectoryNodes,
        refetch: refetchDirectoryNodes,
    } = useGetNodes(true);


    // Convert fetched data to NodeModels
    useEffect(() => {
        if (!isFetchingDocumentDirectoryNodes) {
            if (documentDirectoryNodes && documentDirectoryNodes.data && documentDirectoryNodes.data.data) {
                setTreeData(convertDirectoryNodesToModels(documentDirectoryNodes.data.data));
            }
        }
    }, [isFetchingDocumentDirectoryNodes, documentDirectoryNodes]);

    const [treeData, setTreeData] = useState<NodeModel<IFolderNodeModel | IDocumentNodeModel>[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<NodeModel<IFolderNodeModel> | null>(null);

    const [showPreview, setShowPreview] = useState(false);
    const [previewFileId, setPreviewFileId] = useState("");

    const handleExpandedFolders = (id: string) => {
        const idsToAdd: string[] = [];
        const item = treeData.find(item => item.id === id);
        if (!item) return;

        if (isFolder(item)) {
            idsToAdd.push("" + item.id);
        }
        if (item.parent) {
            idsToAdd.push(...getParentIds(treeData, "" + item.parent));
        }
        setExpandedFolders(prevState => new Set([...prevState, ...idsToAdd]));
    }

    const handleExpandedFoldersToggle = (id: string) => {
        if (!expandedFolders.has(id)) {
            setExpandedFolders(new Set([...expandedFolders, id]));
        } else {
            setExpandedFolders(prevStrings => {
                const newSet = new Set(prevStrings);
                newSet.delete(id);
                return newSet;
            });
        }
    }

    const handleCloseAll = () => {
        setExpandedFolders(new Set<string>());
    }

    const handleOnClickNode = async (node: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => {
        if (node.data?.deletedAt) return;
        setSearch('');
        if (isFolder(node)) {
            if (selectedFolder === node) return;
            handleExpandedFolders("" + node.id);
            navigate({hash: "" + node.id});
        } else {
            const doc = node.data as IDocumentNodeModel;
            if (doc.documentType === 'File') {
                await goToDocument(doc.id);
            } else {
                setPreviewFileId(doc.id);
                setShowPreview(true);
            }
        }
    }

    useEffect(() => {
        if (selectedFolder) {
            handleExpandedFolders("" + selectedFolder.id);
        }
    }, [selectedFolder]);

    useEffect(() => {
        if (location.hash === '' || location.hash.substring(1) === rootHash) {
            setSelectedNodeHash(null);
            setSelectedFolder(null);
        }
        setSelectedNodeHash(location.hash);
    }, [location.hash]);

    useEffect(() => {
        if (selectedNodeHash) {
            const found = treeData.find((data) => data.id === selectedNodeHash.substring(1));
            if (found !== undefined && isFolder(found)) {
                setSelectedFolder(found);
            }
        } else {
            setSelectedFolder(null);
        }
    }, [treeData, selectedNodeHash]);

    const handleSearchFilter = (model: NodeModel<IFolderNodeModel | IDocumentNodeModel>): boolean => {
        if (model.id === CustomFolders.TRASHBIN_PREFIXED || model.id === CustomFolders.COMPLETEDBIN_PREFIXED) return false;
        if (model.parent === CustomFolders.COMPLETEDBIN_PREFIXED || model.parent === CustomFolders.TRASHBIN_PREFIXED) return false;

        return model.text.toLowerCase().includes(search.toLowerCase());
    }

    const getContent = (): NodeModel<IFolderNodeModel | IDocumentNodeModel>[] => {
        return treeData.filter((model) => !!search.trim() ? handleSearchFilter(model) :
            selectedFolder ? model.parent === selectedFolder?.id : model.parent === 0 ?? []);
    }

    const handleStepBack = () => {
        if (!selectedFolder) return;
        if (selectedFolder.parent === 0) {
            setSelectedFolder(null);
            return navigate({hash: rootHash});
        }
        const parent = treeData.find((f) => f.id === selectedFolder.parent);
        if (!parent) return;
        setSelectedFolder(parent as NodeModel<IFolderNodeModel>);
        return navigate({hash: "" + parent.id});
    }

    useEffect(() => {
        const map = new Map<string, NodeModel<IFolderNodeModel>>();
        treeData.forEach(node => {
            if (isFolder(node)) {
                map.set(node.id.toString(), node);
            }
        })
        setMap(map);
    }, [treeData])


    const getParents = useCallback((): NodeModel<IFolderNodeModel>[] => {
        if (selectedFolder == null) return [];
        let first = selectedFolder;
        const r = [selectedFolder as NodeModel<IFolderNodeModel>]
        while (first?.parent !== 0) {
            const parent = map.get(first?.parent.toString()!);
            if (parent != null) {
                r.push(parent)
                first = parent;
            } else break;

        }
        return r.reverse();
    }, [selectedFolder])

    return <MainContainer>
        <Row>
            <Col lg={8}>
                <HeaderContainer title={t('DocumentsDirectory.DOCUMENT_1')}/>
            </Col>
            <Col lg={4} className="pb-3">
                <SearchBar
                    search={search}
                    setSearch={setSearch}
                    placeholder={t('DocumentsDirectory.GLOBAL_SEARCH_1')}
                    name={"searchBar"}
                />
            </Col>
        </Row>
        <Row className="mb-4">
            <Col lg={4} className="pb-3">
                <ContentContainer>
                    <Directory
                        data={treeData}
                        // data={selectedFolder ? treeData : treeData.filter(data => data.parent === 0)}
                        selectedFolder={selectedFolder}
                        onClickNode={handleOnClickNode}
                        isDragAndDropEnabled={isDragAndDropEnabled}
                        setDragAndDropEnabled={setDragAndDropEnabled}
                        expandedFolders={Array.from(expandedFolders)}
                        handleExpandedFolders={handleExpandedFolders}
                        handleExpandedFoldersToggle={handleExpandedFoldersToggle}
                        handleCloseAll={handleCloseAll}
                    />
                </ContentContainer>
            </Col>

            <Col>
                <Row>
                    <Col>
                        <OverlayComponent showOverlay={isDragAndDropEnabled} showSpinner={false}>
                            <ContentContainer style={{minHeight: "450px"}}>
                                <FolderContent
                                    selectedNodeModel={selectedFolder?.data as IFolderNodeModel}
                                    content={getContent()}
                                    onClickItem={handleOnClickNode}
                                    onStepBack={handleStepBack}
                                    parents={getParents()}
                                    search={search}
                                />
                                {showPreview && (
                                    <DocumentPreview
                                        documentId={previewFileId}
                                        onClose={setShowPreview}
                                    />
                                )}
                            </ContentContainer>
                        </OverlayComponent>
                    </Col>
                </Row>
            </Col>
        </Row>
    </MainContainer>
};

export default DocumentDirectory;
