import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useDownloadAsPdf, useOpenPdf } from "../../api/PdfApi";
import CustomSelect from "../../components/FormComponents/CustomSelect";
import SimpleModal from "../../components/ModalComponents/SimpleModal";
import SpinnerButton from "../../components/SpinnerButton";
import { IActionPlan } from "../../core/interfaces/actionPlan";

interface IOpenDownloadAsPDF {
    actionPlan?: IActionPlan;
    showPdfModal: boolean;
    closePdfModal: () => void;
    pdfVariant: 'OPEN' | 'DOWNLOAD';

}

const OpenDownloadAsPDF = (
    {
        actionPlan,
        showPdfModal,
        pdfVariant,
        closePdfModal,
    }: IOpenDownloadAsPDF) => {
    const {t} = useTranslation();
    const [orientation, setOrientation] = useState<'landscape' | 'portrait'>("landscape");
    const [download, isDownloadingPdf] = useDownloadAsPdf(orientation, actionPlan?.id);
    const [openPdf, isOpeningPdf] = useOpenPdf(orientation, actionPlan?.id);

    const handleOpenDownloadPdf = () => {
        pdfVariant === 'OPEN' ? openPdf(orientation) : download(orientation);
        closePdfModal();
    }

    const handleCloseModal = () => {
        closePdfModal();
    }

    const renderBody = () => (<>
        <Row>
            <Col>{t('OpenDownloadAsPDF.ORIENTATION_1')}</Col>
        </Row>
        <Row>
            <Col>
                <CustomSelect
                    isEditMode={true}
                    name={"orientation"}
                    value={orientation}
                    onChange={(e: any) => setOrientation(e.target.value ?? '')}
                    options={<>
                        <option key={'landscape'} value={'landscape'}>{t('OpenDownloadAsPDF.LANDSCAPE_1')}</option>
                        <option key={'portrait'} value={'portrait'}>{t('OpenDownloadAsPDF.STANDING_1')}</option>
                    </>}
                >
                </CustomSelect>
            </Col>
        </Row>
    </>)

    const renderFooter = () => (<>
        <Button variant="secondary" onClick={handleCloseModal}>{t('OpenDownloadAsPDF.CANCEL_1')}</Button>
        <SpinnerButton
            text={pdfVariant === 'OPEN' ? t('OpenDownloadAsPDF.OPEN_1') : t('OpenDownloadAsPDF.DOWNLOAD_1')}
            isLoading={isDownloadingPdf || isOpeningPdf}
            disabled={false}
            onClick={handleOpenDownloadPdf}
        />
    </>)

    return <SimpleModal
        show={showPdfModal}
        size={"sm"}
        header={pdfVariant === 'OPEN' ? <span>{t('OpenDownloadAsPDF.OPEN_PDF_1')}</span> : <span>{t('OpenDownloadAsPDF.DOWNLOAD_PDF_1')}</span>}
        body={renderBody()}
        footer={renderFooter()}
    />
};

export default OpenDownloadAsPDF;
