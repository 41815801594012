import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
    ModalState,
    modalHolderResponse,
    removeModalHolder,
    setAsIdle,
    setModalHolder
} from "../store/slices/changesModalSlice";

export const useChangesModal = (cancel: () => void, editedInfo: object | (() => boolean)) => {

    const [id] = useState(Math.floor(Math.random() * 99999).toString());
    const [initialObject] = useState({...editedInfo});

    const modalState = useAppSelector(state => state.changesModal.state)
    const dispatch = useAppDispatch();

    const isEdited = useCallback((): boolean => {
        if (typeof editedInfo == 'function') return editedInfo() as boolean;
        return JSON.stringify(initialObject) !== JSON.stringify(editedInfo)
    }, [editedInfo, initialObject])

    useEffect(() => {
        dispatch(setModalHolder(id));
        return () => {
            dispatch(removeModalHolder());
        }
    }, [id, dispatch])

    useEffect(() => {
        const handleUnload = (e: BeforeUnloadEvent) => {
            if (isEdited()) {
                e.cancelBubble = true;
                e.returnValue = ''; //This is displayed on the dialog
                //e.stopPropagation works in Firefox.
                if (e.stopPropagation) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        }
        window.addEventListener('beforeunload', handleUnload)


        return () => {
            window.removeEventListener('beforeunload', handleUnload)
        }
    }, [isEdited])


    useEffect(() => {
        switch (modalState) {
            case ModalState.PENDING:
                dispatch(modalHolderResponse(isEdited()));
                break;
            case ModalState.APPROVED:
                cancel();
                dispatch(setAsIdle())
                break;
            default:
                break;
        }
    }, [dispatch, modalState, cancel, isEdited])
}