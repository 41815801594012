import { AxiosError } from "axios";
import i18n from "i18next";
import { Role } from "../core/enums/enums";
import { toastError, toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, IResponse } from "../core/interfaces/common";
import {
    IConfirmResponse,
    IConfirmUserToken,
    IForgotPassword,
    ISuperadmin,
    IUserDetails,
    IUserResponse
} from "../core/interfaces/user";
import { AppQueryKey, GetQueryKey } from "../core/queryKeys";
import { useGet, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient from "./api";
import { URL_USER_CHANGE_SYSTEMROLE } from "./endpoints/endpoints";
import { CONFIRM_PASSWORD, CONFIRM_USER, FORGOT_PASSWORD, GET_USER_BY_ID, USERS, } from "./endpoints/user";

export const GetUserById = async (id: string): Promise<IResponse<IUserDetails>> => {
    return await AxiosClient.get<IResponse<IUserResponse>>(GET_USER_BY_ID(id)).then(resp => {
        const data = resp.data.data;
        const company = data.companies[0];
        return {
            data: {
                companyName: company?.name ?? '',
                companyRole: company?.role ?? '',
                companyUserId: company?.companyUserId ?? '',
                companyId: company?.id ?? '',
                createdAt: data.createdAt,
                createdById: data.createdById,
                firstName: data.person.firstName,
                id: data.id,
                userId: data.id,
                lastName: data.person.lastName,
                role: null,
                parentCompanyId: data.parentCompanyId,
                hasMultipleCompanies: false
            },
            status: resp.status
        } as IResponse<IUserDetails>
    }).catch((error: AxiosError) => {
        console.error(error)
        return ({data: {} as IUserDetails, status: error.response?.status ?? 500})
    });
}

export const ConfirmToken = async (confirmUserToken: IConfirmUserToken, type?: string): Promise<IResponse<IConfirmResponse>> => {
    return await AxiosClient.post<IResponse<IConfirmResponse>>(type === 'password' ? CONFIRM_PASSWORD : CONFIRM_USER, confirmUserToken).then(resp => {
        return {
            data: {newConfirmationEmail: resp.data.data?.newConfirmationEmail ?? false},
            status: resp.status
        }
    }).catch(error => {
        return {
            data: {
                newConfirmationEmail: false,
                failedValidation: error.response?.data?.data?.failedValidation ?? false
            }, status: error.response?.status ?? 500
        }
    });

}

export const ForgotPassword = async (forgotPassword: IForgotPassword): Promise<IResponse<boolean>> => {
    return await AxiosClient.post<IResponse<boolean>>(FORGOT_PASSWORD, forgotPassword).then(resp => {
        return {
            data: true,
            status: resp.status
        }
    }).catch(error => ({data: false, status: error.response?.status ?? 500}))
}

export const CreateUser = async (user: ISuperadmin): Promise<IResponse<IUserResponse>> => {
    return await AxiosClient.post<IResponse<IUserResponse>>(USERS, user)
        .then(resp => ({data: resp.data.data, status: resp.status}))
        .catch((error: AxiosError) => ({data: {} as IUserResponse, status: error.response?.status ?? 500}));

}

export const useGetSuperadmins = (): [IUserResponse[], boolean] => {
    const queryKeys = GetQueryKey(AppQueryKey.USERS);
    const query: ICustomQuery = {
        url: USERS,
        queryKeys: queryKeys
    };
    const {
        isFetching,
        data
    } = useGet<IUserResponse[]>(query);


    return [data?.data?.data ?? [], isFetching]
}

export const useUpdateSystemUserRole = (userId: string): [(role: Role) => Promise<boolean>, boolean] => {

    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.USERS);

    const usePostMutation = usePut<{ role: Role }>(
        URL_USER_CHANGE_SYSTEMROLE(userId),
        queryKeys,
        false
    );
    const request = (role: Role) => usePostMutation.mutateAsync({role}).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('UserApi.UPDATE_SUCCESSFUL_1'), i18n.t('UserApi.ROLE_UPDATED_1'))));
        }
        return !!response.success;
    }).catch(_ => {
        dispatch(addToast(toastError(i18n.t('UserApi.UPDATE_FAILED_1'), i18n.t('UserApi.UPDATE_FAILURE_1'))))
        return false;
    });

    return [request, usePostMutation.isLoading];
}
