import { NodeModel } from "@minoru/react-dnd-treeview";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useCreateNewTemplateFolder } from "../../../../api/TemplateApi";
import ContentContainer from "../../../../components/ContainerComponents/ContentContainer";
import CustomTextInput from "../../../../components/FormComponents/CustomTextInput";
import FormBase from "../../../../components/FormComponents/FormBase";
import SimpleModal from "../../../../components/ModalComponents/SimpleModal";
import When from "../../../../components/When";
import { TemplateType } from "../../../../core/enums/enums";
import { handleOnEnter } from "../../../../core/helpers/utils";
import { ITemplateNodeData, NewTemplateFolderRequest } from "../../../../core/interfaces/template";

interface ICreateNewTemplateFolder {
    templateType: TemplateType;
    parentFolder: NodeModel<ITemplateNodeData> | null;
    closeCreateNewFolderSection: () => void;
    isFromTemplateDirectory?: boolean;
}

const CreateNewTemplateFolder = (
    {
        templateType,
        parentFolder,
        closeCreateNewFolderSection,
        isFromTemplateDirectory,
    }: ICreateNewTemplateFolder) => {

    const {t} = useTranslation();
    const [newFolderName, setNewFolderName] = useState("");

    const handleCloseCreatNewFolderSection = () => {
        setNewFolderName("");
        closeCreateNewFolderSection();
    }

    const [createNewTemplateFolder, isCreatingNewTemplateFolder] = useCreateNewTemplateFolder(templateType);

    const handleCreateNewFolder = async () => {
        if (!newFolderName) return;

        const request: NewTemplateFolderRequest = {
            templateType: templateType,
            parentFolderId: parentFolder?.data?.id ?? null,
            name: newFolderName,
        }
        await createNewTemplateFolder(request).then((response) => {
            if (response.success) {
                handleCloseCreatNewFolderSection();
            }
        });
    }

    const renderForm = () => (<>
        <Row>
            <Col>
                <FormBase row={false} controlId={'folderName'} title={t('CreateNewTemplateFolder.FOLDER_NAME_1')}
                          className={'pb-2'}>
                    <CustomTextInput
                        name={'newFolderName'}
                        disableAutocomplete
                        isEditMode={true} value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        autofocus
                        onKeyDown={(event) => handleOnEnter(event, handleCreateNewFolder)}
                    />
                </FormBase>
            </Col>
        </Row>
    </>);

    const renderButtons = () => (<Row>
        <Col className={"d-flex justify-content-end"}>
            <Button
                className={"me-2"}
                variant="secondary"
                onClick={handleCloseCreatNewFolderSection}>{t('CreateNewTemplateFolder.CANCEL_1')}</Button>
            <Button
                variant="primary"
                disabled={!newFolderName}
                onClick={handleCreateNewFolder}>{t('CreateNewTemplateFolder.SAVE_1')}</Button>
        </Col>
    </Row>);

    return <>
        <When condition={!isFromTemplateDirectory}
              otherwise={
                  <SimpleModal
                      show={true}
                      size={"sm"}
                      header={<>{t('CreateNewTemplateFolder.FOLDER_CREATION_1')}</>}
                      body={renderForm()}
                      footer={renderButtons()}/>}
        >
            <ContentContainer className={"mt-3 mb-3"}
                              style={{border: !isFromTemplateDirectory ? "1px solid black" : ""}}>
                <Row className={"mt-2 mb-1"}>
                    <Col>
                        <i>{t('CreateNewTemplateFolder.SELECT_DESIRED_FOLDER_TO_CREATE_AS_SUBFOLDER_1')}</i>
                    </Col>
                </Row>
                {renderForm()}
                {renderButtons()}
            </ContentContainer>
        </When>
    </>
};

export default CreateNewTemplateFolder;
