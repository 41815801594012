import {Link, RelativeRoutingType, To} from "react-router-dom";
import {useCNavigate} from "../hooks/useCNavigate";
import React from "react";

export interface IClinkProps {
    to: To,
    relative?: RelativeRoutingType,
    children: JSX.Element
}

export const CLink: React.FC<IClinkProps> = ({to, relative, children}) => {

    const cNavigate = useCNavigate();

    return (

        <Link to={to} onClick={(e) => {
            cNavigate(to.toString(), {
                relative
            });
            e.preventDefault()
            e.stopPropagation()
        }}>
            {children}
        </Link>
    )
}