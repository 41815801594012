import i18n from "i18next";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { downloadIncidentFile } from "../../../../api/IncidentApi";
import { URL_COMPANY_USERS } from "../../../../api/endpoints/endpoints";
import { CLink } from "../../../../components/CLink";
import ActionsContainer from "../../../../components/ContainerComponents/ActionsContainer";
import ContentContainer from "../../../../components/ContainerComponents/ContentContainer";
import SpinnerButton from "../../../../components/SpinnerButton";
import When from "../../../../components/When";
import { INCIDENT_STATUS_CODES, newIncident as createIncident } from "../../../../core/helpers/incidentHelper";
import { IPerson } from "../../../../core/interfaces/common";
import { IIncident, IncidentFile } from "../../../../core/interfaces/incident";
import { useChangesModal } from "../../../../hooks/useChangesModal";
import { usePaginated } from "../../../../hooks/usePaginated";
import { useAppSelector } from "../../../../store/hooks";
import { ExternalUsersDescriptionForm } from "../../Forms/ExternalUsersDescriptionForm";
import { FileUploadSection } from "../../Forms/FileUploadSection";
import { HasExternalUsersForm } from "../../Forms/HasExternalUsersForm";
import { MedicalAssistanceForm } from "../../Forms/MedicalAssistanceForm";
import { PersonsInvolvedForm } from "../../Forms/PersonsInvolvedForm";
import { ReportedToForm } from "../../Forms/ReportedToForm";
import { SicknessAbsenceForm } from "../../Forms/SicknessAbsenceForm";
import { StatusForm } from "../../Forms/StatusForm";
import { FirstSection } from "../../Sections/FirstSection";
import { HeaderSection } from "../../Sections/HeaderSection/HeaderSection";
import { LastSection } from "../../Sections/LastSection";
import IncidentFormContainer from "../IncidentFormContainer/IncidentFormContainer";
import styles from './incidentForm.module.scss';


export interface IIncidentForm {
    isNewIncident: boolean;
    incident: IIncident;
    isEditMode: boolean;
    isSubmitting: boolean;
    onEdit: () => void;
    onSubmit: (incident: IIncident, files?: File[]) => void;
    onCancel: () => void;
}

const SectionRow: React.FC<any> = (props) => (
    <>
        <hr/>
        <Row className="pb-2">
            {props.children}
        </Row>
    </>
)

const IncidentForm = ({
                          isNewIncident,
                          incident,
                          isEditMode,
                          isSubmitting,
                          onEdit,
                          onSubmit,
                          onCancel
                      }: IIncidentForm) => {

    const {t} = useTranslation();
    const {cId} = useParams();
    const {user, viewInfo} = useAppSelector((state) => ({
        user: state.user,
        viewInfo: state.ui.incidentViewInfo
    }));

    const [newIncident, setNewIncident] = useState<IIncident>(createIncident());
    const [validatedForm, setValidatedForm] = useState(false);

    const [companyUsers] = usePaginated<IPerson>(URL_COMPANY_USERS(cId ?? ''));
    const [newFiles, setNewFiles] = useState<File[]>([]);

    useChangesModal(() => {
        setNewIncident(incident);
        setValidatedForm(false);
    }, () => isValuesChanged());

    useEffect(() => {
        setNewIncident({...incident})
    }, [incident])

    useEffect(() => {
        setNewFiles([]);
    }, [isEditMode])

    const handleChangeDate = (e: any) => {
        const targetName = e.target.name;
        const newValue = e.target.value;
        const oldVal: string = Object.entries(newIncident).find(([key]) => key === targetName)?.[1];

        const newDate = new Date(newValue);

        if (oldVal) {
            const oldDate = new Date(oldVal);
            const oldDateHours = oldDate.getHours();
            const oldDateMinutes = oldDate.getMinutes();

            newDate.setHours(oldDateHours);
            newDate.setMinutes(oldDateMinutes);
        }

        setNewIncident({
            ...newIncident,
            [e.target.name]: newDate.toLocaleString()
        });
    }
    //todo checkbox?
    const handleChangeDateTime = (e: any) => {
        const newValue: string[] = e.target.value.split(':');

        let date = new Date();
        if (newIncident.date) {
            date = new Date(newIncident.date)
        }

        date.setHours(+newValue[0]);
        date.setMinutes(+newValue[1]);


        setNewIncident({
            ...newIncident,
            timeIncluded: true,
            [e.target.name]: date.toLocaleString()
        })

    }

    const isValuesChanged = (): boolean => {
        return (JSON.stringify(incident) !== JSON.stringify(newIncident)) || newFiles.length > 0;
    }

    const handleChange = (e: any) => {
        const newVal = e.target.value;
        setNewIncident({
            ...newIncident,
            [e.target.name]: !isNaN(newVal) ? Number(newVal) : newVal,
        });
    }

    const handleBooleanChange = (value: boolean | null, e: any) => {
        setNewIncident({
            ...newIncident,
            [e.target.name]: value
        });
    }
    const handleStatusChange = (e: any) => {
        const checked = e.target.checked;

        setNewIncident({
            ...newIncident,
            status: checked ? INCIDENT_STATUS_CODES.COMPLETED : incident.status
        });
    }


    const handleMultiSelectChange = (selectedOptions: { value: string, label: string }[], name: string) => {
        setNewIncident({
            ...newIncident,
            [name]: selectedOptions.map(s => ({id: s.value, name: s.label}))
        });
    }

    const handleActionUserChange = (e: any) => {
        setNewIncident({
            ...newIncident,
            actionUser: {id: e.target.value, name: e.target.selectedOptions[0].innerText}
        })
    }


    const renderActionButtons = () => {
        if (isEditMode) {
            return (
                <>
                    <Col className="pe-0">
                        <Button onClick={() => onCancel()}>{t('IncidentForm.CANCEL_1')}</Button>
                    </Col>
                    <Col>
                        <SpinnerButton
                            variant="primary"
                            type="submit"
                            text={isNewIncident ? t('IncidentForm.TEMPLATE_1') : t('IncidentForm.SAVE_1')}
                            isLoading={isSubmitting}
                            disabled={!isValuesChanged()}
                        />
                    </Col>
                    <Col>
                        <CLink to={".."} relative="path"><Button>{t('IncidentForm.OFFERS_1')}</Button></CLink>
                    </Col>
                </>
            )
        } else {
            return (
                <>
                    <When condition={!isNewIncident}>
                        <Col className="pe-0">
                            <Button onClick={() => {
                                onEdit();
                            }}>{t('IncidentForm.EDIT_1')}</Button>
                        </Col>
                    </When>
                    <Col>
                        <Button disabled>{t('IncidentForm.PRINT_OUT_1')}</Button>
                    </Col>
                    <Col>
                        <CLink to={".."} relative="path"><Button>{t('IncidentForm.INCIDENTS_1')}</Button></CLink>
                    </Col>
                </>
            )
        }
    }

    const handleSubmit = (e: any) => {
        if (!isValuesChanged()) return;
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidatedForm(true);
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        console.info('Validera formul�r innan?');
        onSubmit(newIncident, newFiles);
    }

    if (!newIncident) {
        return <></>
    }

    return (<>
        <Form noValidate validated={validatedForm} onSubmit={handleSubmit}>
            <ActionsContainer placement="top">
                {renderActionButtons()}
            </ActionsContainer>
            <IncidentFormContainer as={ContentContainer}>
                <When condition={isNewIncident}>
                    <HeaderSection/>
                </When>
                <IncidentFormContainer>
                    <Row className={styles.containerPadding}>
                        <FirstSection
                            isNewIncident={isNewIncident}
                            editMode={isEditMode}
                            newIncident={newIncident}
                            onChange={handleChange}
                            companyUsers={companyUsers.data}
                            onChangeDate={handleChangeDate}
                            onChangeDateTime={handleChangeDateTime}
                            onChangeActionUser={handleActionUserChange}
                            currentUserName={user.firstName + " " + user.lastName}
                        />

                        <SectionRow>
                            <FileUploadSection editMode={isEditMode} newIncident={newIncident} files={newFiles}
                                               setFiles={(files: File[]) => setNewFiles(files)}
                                               downloadFile={(incidentFile: IncidentFile) => downloadIncidentFile(cId ?? '', incident.id, incidentFile)}
                                               setIncidentFiles={(files: IncidentFile[]) => {
                                                   setNewIncident({
                                                       ...newIncident,
                                                       files
                                                   });
                                               }}/>
                        </SectionRow>

                        <SectionRow>
                            <PersonsInvolvedForm editMode={isEditMode}
                                                 companyUsers={companyUsers.data}
                                                 newIncident={newIncident}
                                                 onChange={(e) => handleMultiSelectChange(e, "involvedUsers")}/>
                        </SectionRow>
                        <SectionRow>
                            <HasExternalUsersForm editMode={isEditMode} newIncident={newIncident}
                                                  onChange={handleBooleanChange}/>
                            <ExternalUsersDescriptionForm editMode={isEditMode}
                                                          newIncident={newIncident}
                                                          onChange={handleChange}/>
                        </SectionRow>
                        <When condition={!isNewIncident}>
                            <SectionRow>
                                <SicknessAbsenceForm editMode={isEditMode} newIncident={newIncident}
                                                     absenceIntervals={viewInfo.absenceIntervals}
                                                     onChange={handleChange}/>
                            </SectionRow>

                            <SectionRow>
                                <MedicalAssistanceForm editMode={isEditMode} newIncident={newIncident}
                                                       onChange={handleBooleanChange}/>
                            </SectionRow>

                            <SectionRow>
                                <ReportedToForm editMode={isEditMode}
                                                onChange={(e) => handleMultiSelectChange(e, 'reportedTos')}
                                                newIncident={newIncident} reportTos={viewInfo.reportTos}
                                />
                            </SectionRow>

                            <SectionRow>
                                <StatusForm newIncident={newIncident} onChange={handleChange}/>
                            </SectionRow>

                            <LastSection editMode={isEditMode}
                                         newIncident={newIncident}
                                         onChange={handleChange}
                                         onChangeStatus={handleStatusChange}
                                         onChangeDate={handleChangeDate}
                            />
                        </When>
                    </Row>
                </IncidentFormContainer>
            </IncidentFormContainer>
            <ActionsContainer placement="bottom">
                {renderActionButtons()}
            </ActionsContainer>
        </Form>
    </>)
}

export default IncidentForm;
