import { Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainContainer from "../components/ContainerComponents/MainContainer";
import {useTranslation} from "react-i18next";

const Missing = () => {
    const {t} = useTranslation();
    return <MainContainer>
        <Row>
            {t('Missing.MISSING_PAGE_1')}
        </Row>
        <Row>
            <Link to="/"><Button>{t('Missing.GO_HOME_1')}</Button></Link>
        </Row>
    </MainContainer>
}

export default Missing;
