import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import CustomDate from "../../../components/FormComponents/CustomDate";
import { Role } from "../../../core/enums/enums";
import { IDocument } from "../../../core/interfaces/document";
import useAccessCheck from "../../../hooks/useAccessCheck";


type DocumentCreatedAtFormProps = {
    document: IDocument;
    isEditMode: boolean;
    onChange: (e: any) => void

}

export const DocumentCreatedAtForm: React.FC<DocumentCreatedAtFormProps> = (props) => {
    const {t} = useTranslation();
    const [, checkRole] = useAccessCheck();

    return <Form.Group as={Row}>
        <Row>
            <Form.Label column className={"pt-1 pb-0"}><strong>{t('DocumentCreatedAtForm.CREATED_DATE_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <CustomDate type={'date'} isEditMode={checkRole(Role.SUPERADMIN) && props.isEditMode} name={'createdAt'} value={props.document.createdAt!} onChange={props.onChange} />
            </Col>
        </Row>
    </Form.Group>
}
