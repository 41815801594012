import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import getIcon, { Icon } from "../../../../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../../../../components/ClickableIconButton/ClickableIconButton";
import When from "../../../../../../components/When";
import { IDataRow } from "../../../../../../core/interfaces/document";
import { useChecklistContext } from "../../ChecklistContext";


interface IDataRowActionButtons {
    dataRow: IDataRow;
    removeDataRow: () => void;
    copyDataRow: () => void;
}

const DataRowActionButtons = (
    {
        dataRow,
        removeDataRow,
        copyDataRow,
    }: IDataRowActionButtons) => {

    const {t} = useTranslation();
    
    const {
        // documentContext: {isEditMode},
        isTableView
    } = useChecklistContext();

    const isEditMode = useChecklistContext()?.documentContext?.isEditMode;

    return <When condition={isEditMode && !isTableView} otherwise={<></>}>
        <Col md={2} style={{display: "flex", justifyContent: "flex-end"}}>
            <div className={"d-flex justify-content-center align-items-center"}>
                <ClickableIconButton
                    disabled={dataRow.isClosed}
                    buttonType={"CANCEL"}
                    icon={getIcon(Icon.REMOVE)}
                    onClick={removeDataRow}
                    title={t('DataRowActionButtons.REMOVE_1')}
                />
                <ClickableIconButton
                    disabled={dataRow.isClosed}
                    buttonType={"EDIT"}
                    icon={getIcon(Icon.COPY)}
                    onClick={copyDataRow}
                    title={t('DataRowActionButtons.COPY_1')}
                />
            </div>
        </Col>
    </When>
};

export default DataRowActionButtons;
