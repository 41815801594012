import * as _ from 'lodash';
import { createContext, useContext } from "react";
import { IDataRow, IDocumentModuleChecklistForm } from "../../../../../core/interfaces/document";
import { useChecklistContext } from "../ChecklistContext";

function generateRandomId(length: number): string {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

type ChecklistFormContextType = {
    addDataRow: (dataRow: IDataRow) => void;
    removeDataRow: (dataRow: IDataRow) => void;

    updateDataRow: (dataRow: IDataRow) => void;
    copyDataRow: (dataRow: IDataRow) => void;

    getForm: IDocumentModuleChecklistForm,
    updateTitle: (title: string) => void,
    updateDescription: (description: string) => void,
    updateSendToActionPlan: (value: boolean) => void,
}


const ChecklistFormContext = createContext<ChecklistFormContextType>({} as ChecklistFormContextType)


const ChecklistFormContextProvider = ({
                                      children, form
                                  }: { children: JSX.Element | JSX.Element[], form: IDocumentModuleChecklistForm }) => {
    
    const {updateForm} = useChecklistContext();

    return (
        <ChecklistFormContext.Provider value={{
            addDataRow: (dataRow) => {
                if(!form.documentModuleChecklistFormDataRows) form.documentModuleChecklistFormDataRows = [];
                dataRow.isNew = true;
                form.documentModuleChecklistFormDataRows.push(dataRow);
                updateForm(form);
            },
            removeDataRow: (dataRow) => {
                const index = form.documentModuleChecklistFormDataRows.findIndex(o => o.id === dataRow.id);
                form.documentModuleChecklistFormDataRows.splice(index, 1);
                updateForm(form);
            },
            updateDataRow: (dataRow) => {
                const index = form.documentModuleChecklistFormDataRows.findIndex(o => o.id === dataRow.id);
                form.documentModuleChecklistFormDataRows.splice(index, 1, dataRow);
                updateForm(form);
            },
            copyDataRow: (dataRow) => {
                const index = form.documentModuleChecklistFormDataRows.findIndex(d => d.id === dataRow.id);
                const clonedRow = _.cloneDeep(dataRow);
                clonedRow.id = generateRandomId(25);
                clonedRow.isNew = true;

                form.documentModuleChecklistFormDataRows.splice(index + 1, 0, clonedRow)
                updateForm(form)
            },
            getForm: form,
            updateTitle: (title) => {
              form.title = title;
              updateForm(form);
            },
            updateDescription: (description) => {
                form.description = description;
                updateForm(form)
            },
            updateSendToActionPlan: (value) => {
                form.sendToActionPlan = value;
                updateForm(form);
            }
        }}>
            {children}
        </ChecklistFormContext.Provider>
    )
}

const useChecklistFormContext = () => useContext(ChecklistFormContext);


export { ChecklistFormContextProvider, useChecklistFormContext };
