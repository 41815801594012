import { NodeModel } from "@minoru/react-dnd-treeview";
import { createRef, useEffect, useState } from "react";
import { Breadcrumb, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApproveAll } from "../../api/DocumentApi";
import { useRenameNode } from "../../api/DocumentDirectory";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../components/ClickableIconButton/ClickableIconButton";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import When from "../../components/When";
import { CustomFolders, DocumentTypes, NodeType, PublishStatusType, Role, TemplateActionType, TemplateType } from "../../core/enums/enums";
import { DateToLocale, DateToYYYYMMDDHHMM } from "../../core/helpers/dates/datesHelper";
import { IDocumentNodeModel, IFolderNodeModel } from "../../core/interfaces/directory";
import { IRenameNodeRequest } from "../../core/interfaces/document";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useAcceptModalContext } from "../../providers/AcceptModalProvider";
import { DocumentUpload } from "../Document/Components/DocumentUpload";
import SelectTemplateModal from "./Components/SelectTemplateModal";
import DocumentType from "./Components/DocumentType";
import TableDropdown from "./Components/TableDropdown";
import CreateNewModal from "./CreateNewModal/CreateNewModal";
import NewDocumentModal from "./CreateNewModal/NewDocumentModal";
import NewFolderModal from "./CreateNewModal/NewFolderModal";
import { isDeleted, isDocument, isFolder, isPublished, renderNodeText } from "./Helpers/DirectoryHelper";

interface IFolderContent {
    selectedNodeModel: IFolderNodeModel;
    content: NodeModel<IFolderNodeModel | IDocumentNodeModel>[];
    onClickItem: (item: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => void;
    onStepBack: () => void;
    parents?: NodeModel<IFolderNodeModel>[];
    search: string;
}

const TableRow = (
    {
        item,
        onClickItem,
        onClickEditName,
    }: {
        item: NodeModel<IDocumentNodeModel | IFolderNodeModel>,
        onClickItem: (item: any) => void,
        onClickEditName: () => void,
    }) => {
    const isDoc = isDocument(item);
    const asDocument = (item.data as IDocumentNodeModel);
    const asFolder = (item.data as IFolderNodeModel);
    const handleClick = (item: any) => isDeleted(item) ? NaN : onClickItem(item);
    const {t} = useTranslation();

    return (
        <tr key={item.id}>
            <td>
                <When condition={asDocument.important}>
            <span
                className={[getIcon(Icon.CIRCLE, IconSize.XS, "red"), "pe-2"].join(' ')}
                style={{paddingRight: 4}}
                title={t('FolderDetails.IMPORTANT_1')}
            />
                </When>
                <When condition={!!asDocument.importantHandledAt}>
            <span
                className={[getIcon(Icon.CIRCLE, IconSize.XS, "green"), "pe-2"].join(' ')}
                style={{paddingRight: 4}}
                title={t('FolderDetails.MANAGED_1')}
            />
                </When>
                <When condition={asDocument.publishStatus === PublishStatusType.CLOSED}>
            <span
                className={[getIcon(Icon.LOCK, IconSize.X1, 'lightblue'), 'pe-2'].join(' ')}
                title={t('FolderDetails.CLEARLY_MARKED_1')}
            />
                </When>
            </td>
            <td style={isDeleted(item) ? {} : {cursor: "pointer"}} onClick={() => handleClick(item)}>
                <div className={!isDoc || isPublished(item) ? '' : 'unpublishedItemColor'}>
                    <When
                        condition={isFolder(item)}
                        then={<span className={getIcon(Icon.FOLDER)}/>}
                        otherwise={<DocumentType data={asDocument} hideDocumentIcons/>}
                    />
                    <span className={"ps-2"}>{renderNodeText(item)}</span>
                </div>
            </td>
            <td>
                <div className={!isDoc || isPublished(item) ? '' : 'unpublishedItemColor'}>
                    <When condition={isDoc}>
                        {asDocument.version}
                    </When>
                </div>
            </td>
            <td className={!isDoc || isPublished(item) ? '' : 'unpublishedItemColor'}>
                <When condition={isDeleted(item)}>
                    {DateToYYYYMMDDHHMM(DateToLocale(asDocument.deletedAt ?? ""))}
                </When>
            </td>
            <td style={{textAlign: "right"}} className={!isDoc || isPublished(item) ? '' : 'unpublishedItemColor'}>
                <TableDropdown
                    isDeleted={isDeleted(item)}
                    folderId={asFolder.id}
                    onClickEditName={onClickEditName}
                    documentType={asDocument.documentType as unknown as DocumentTypes}
                    isDocument={isDoc}
                    documentId={asDocument.id}
                    documentName={item.text}
                />

            </td>
        </tr>
    )
}


const FolderContent = (
    {
        selectedNodeModel,
        content,
        onClickItem,
        onStepBack,
        parents,
        search,
    }: IFolderContent) => {
    let {cId} = useParams();
    const {t} = useTranslation();
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showCreateDocumentModal, setShowCreateDocumentModal] = useState(false);
    const [showCreateDocumentModalTemplate, setShowCreateDocumentModalTemplate] = useState(false);
    const [renameNode, isRenamingNode] = useRenameNode(cId ?? "");
    const initChangeNameObject: IRenameNodeRequest = {id: '', name: '', type: NodeType.DOCUMENT};
    const [changeNameObject, setChangeNameObject] = useState<IRenameNodeRequest>(initChangeNameObject);
    const [showEditNameModal, setShowEditNameModal] = useState(false);
    const [, checkRole] = useAccessCheck();

    const [update] = useApproveAll(selectedNodeModel?.id ?? '');
    const acceptModalContext = useAcceptModalContext();

    const documentUploadRef = createRef<HTMLInputElement>();

    const changeName = async () => {
        await renameNode(changeNameObject).then((response) => {
            if (response.success) {
                setShowEditNameModal(false);
            }
        });
    }

    useEffect(() => {
        acceptModalContext.setAcceptFunction(() => update())
    }, [selectedNodeModel])
    const handleChangeName = (item: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => {
        const obj: IRenameNodeRequest = {
            id: item.data?.id ?? '',
            name: item.text,
            type: isDocument(item) ? NodeType.DOCUMENT : NodeType.FOLDER
        };
        setChangeNameObject(obj);
        setShowEditNameModal(true);
    }

    return <>
        <When
            condition={selectedNodeModel?.id !== CustomFolders.TRASHBIN && selectedNodeModel?.id !== CustomFolders.COMPLETEDBIN}>
            <div className="d-flex justify-content-between" style={{position: 'relative'}}>
                <When condition={parents != null && parents?.length > 0} otherwise={<div></div>}>
                    <Breadcrumb style={{left: 0}}>
                        {parents?.map(v => <Breadcrumb.Item key={v.id} href={`#${v.id}`}>{v.text}</Breadcrumb.Item>)}
                    </Breadcrumb>
                </When>
                <div>
                    <CustomDropdown toggleType={DropdownToggleType.DOTS} iconSize={"30px"}>
                        <CustomDropdownItem
                            icon={Icon.FOLDER_CREATE}
                            text={t('FolderDetails.NEW_FOLDER_1')}
                            onClick={() => setShowCreateFolderModal(true)}
                        />

                        <CustomDropdownItem
                            icon={Icon.COPY}
                            text={t('FolderDetails.TEMPLATE_CREATION_1')}
                            onClick={() => setShowCreateDocumentModalTemplate(true)}
                        />

                        <When condition={typeof (selectedNodeModel?.id) != 'undefined'}>
                            <CustomDropdownItem
                                icon={Icon.PLUS}
                                text={t('FolderDetails.TEMPLATE_CREATION_2')}
                                onClick={() => setShowCreateDocumentModal(true)}
                            />

                            <CustomDropdownItem
                                icon={Icon.UPLOAD}
                                text={t('FolderDetails.UPLOAD_FILE_1')}
                                onClick={() => documentUploadRef.current?.click()}
                            />

                            <When condition={checkRole(Role.SUPERADMIN)}>
                                <CustomDropdownItem
                                    icon={Icon.CHECK_CIRCLE}
                                    text={t('FolderDetails.ACCEPT_ALL_DOCUMENTS_1')}
                                    onClick={() => acceptModalContext.open({
                                        header: t('FolderDetails.ACCEPT_1'),
                                        declineButtonText: t('FolderDetails.CLOSE_1'),
                                        body: <p>{t('FolderDetails.UPON_APPROVAL_1')}<b>{t('FolderDetails.ALL_FOLDERS_AND_DOCUMENTS_1')}</b> {t('FolderDetails.SURE_IN_FOLDER_VISIBLE_TO_ALL')}</p>,
                                        acceptButtonVariant: 'primary',
                                        acceptButtonText: t('FolderDetails.YES_1'),
                                    })}
                                />
                            </When>

                        </When>
                    </CustomDropdown>
                </div>
            </div>
        </When>
        <Table className={"striped-table"} striped bordered hover>
            <thead>
            <tr>
                <th style={{width: "80px"}}></th>
                <th>{t('FolderDetails.NAME_1')}</th>
                <th style={{width: "60px"}}>{t('FolderDetails.VERSION_1')}</th>
                <th>{selectedNodeModel?.id === CustomFolders.TRASHBIN ? t('FolderDetails.DELETED') : ''}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <When condition={!!content.length}>
                <tr>
                    <td colSpan={5} style={{cursor: !!search ? "default" : "pointer"}} onClick={onStepBack}>
                        <ClickableIconButton
                            disabled={!selectedNodeModel || !!search}
                            buttonType={"EDIT"}
                            icon={getIcon(Icon.THREE_DOTS_HORIZONTAL, IconSize.X1)}
                            onClick={onStepBack}
                        />
                    </td>
                </tr>
            </When>
            <When condition={!!content.length} otherwise={<tr>
                <td colSpan={5}>{t('FolderDetails.NO_DOCUMENTS_FOUND_1')}</td>
            </tr>}>
                {content.map((item) => <TableRow
                    key={item.id}
                    item={item}
                    onClickItem={onClickItem}
                    onClickEditName={() => handleChangeName(item)}
                />)}
            </When>
            </tbody>
        </Table>
        {showCreateFolderModal &&
            <CreateNewModal showModal={showCreateFolderModal} closeModal={() => setShowCreateFolderModal(false)}>
                <NewFolderModal
                    parentFolderName={parents?.map(v => v.text).join("/")}
                    companyId={cId ?? ""}
                    parentFolderId={selectedNodeModel?.id ?? null}
                    closeModal={() => setShowCreateFolderModal(false)}
                />
            </CreateNewModal>
        }

        {showCreateDocumentModal &&
            <CreateNewModal showModal={showCreateDocumentModal} closeModal={() => setShowCreateDocumentModal(false)}>
                <NewDocumentModal
                    parentFolderName={parents?.map(v => v.text).join("/")}
                    folderId={selectedNodeModel?.id ?? null}
                    closeModal={() => setShowCreateDocumentModal(false)}
                />
            </CreateNewModal>
        }

        {showCreateDocumentModalTemplate &&
            <SelectTemplateModal
                templateActionType={TemplateActionType.CREATE_FROM_TEMPLATE}
                parentFolderName={parents?.map(v => v.text).join("/")}
                templateType={TemplateType.DOCUMENT}
                parentFolderId={selectedNodeModel?.id ?? null}
                visible={showCreateDocumentModalTemplate}
                setVisible={(value) => setShowCreateDocumentModalTemplate(value)}
            />
        }

        {showEditNameModal &&
            <ModalForm
                show={showEditNameModal}
                hide={() => setShowEditNameModal(false)}
                title={t('FolderDetails.CHANGE_NAME_1')}
                isEdited={() => false}
                disableSubmit={changeNameObject.name === ''}
                submit={changeName}
                isLoading={isRenamingNode}>
                <FormBase
                    row={false}
                    controlId={'name'}
                    title={t('FolderDetails.NEW_NAME_1')}
                    className={'pb-2'}>
                    <GenericInput
                        name={'name'}
                        isEditMode={true}
                        object={changeNameObject}
                        setValue={setChangeNameObject}
                        autofocus
                    />
                </FormBase>
            </ModalForm>
        }

        <DocumentUpload
            ref={documentUploadRef}
            folderId={selectedNodeModel?.id ?? ''}
        />
    </>
};

export default FolderContent;
