import {Spinner} from "react-bootstrap";

interface ILoadingSpinner {
    spinnerAnimation?: "grow" | "border";
    removePadding?: boolean;
}

const LoadingSpinner = (
    {
        spinnerAnimation = "grow",
        removePadding,
    }: ILoadingSpinner) => {
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: removePadding ? '' : '2rem',
        paddingBottom: removePadding ? '' : '2rem'
    }}>
        <Spinner animation={spinnerAnimation} variant="secondary"/>
    </div>
};

export default LoadingSpinner;