import { Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsers } from "../../../../../../api/DocumentApi";
import CustomDate from "../../../../../../components/FormComponents/CustomDate";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowActionPlan, IDataRowProps } from "../../../../../../core/interfaces/document";


const ActionPlanDataRow = (
    {
        dataRow,
        setDataRow,
        isFieldEnabled,
        isEditMode,
        isAnswerMode = false,
        checklistViewTable,
        requiredSubType,
    }: IDataRowProps<IDataRowActionPlan>) => {

        const {t} = useTranslation();
    const [data, isFetching] = useGetCompanyUsers()
    const onChangeValue = (e: any) => {
        const value = (e.target.value ?? "");
        setDataRow({
            ...dataRow,
            [e.target.name]: value.length > 0 ? value : undefined
        });
    }
    if (checklistViewTable) {
        return <>
            <Row>
                <Col md={12}>
                    <When condition={!isFetching} otherwise={<Spinner animation={"border"}/>}>
                        <CustomSelect
                            checklistViewTable={checklistViewTable}
                            required={requiredSubType.includes(dataRow.subType!)}
                            disabled={!isFieldEnabled || !(isEditMode || isAnswerMode)}
                            isEditMode={true}
                            name={"actionUserId"}
                            // value={isEnabled ? dataRow.actionUserId : data.find(p => p.userId === dataRow.actionUserId)?.name}
                            value={dataRow.actionUserId ?? '-'}
                            onChange={onChangeValue}
                            options={<>
                                <option key={''} value={''}>{t('ActionPlanDataRow.SELECT_RESPONSIBLE_1')}</option>
                                {data.map(person => <option key={person.userId}
                                                            value={person.userId}>{person.name}</option>)}
                            </>}
                        />
                    </When>
                </Col>
                <Col md={12} className={'mt-1'}>
                    <CustomDate
                        checklistViewTable={checklistViewTable}
                        required={requiredSubType.includes(dataRow.subType!)}
                        disabled={!isFieldEnabled || !(isEditMode || isAnswerMode)}
                        isEditMode={true}
                        type={'date'}
                        name={'actionDate'}
                        value={dataRow.actionDate ?? ""}
                        onChange={onChangeValue}
                    />
                </Col>
            </Row>
        </>
    }
    return <>
        <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
            <When condition={!checklistViewTable}>
                <Col md={3}>
                    <CustomTextInput
                        disabled={true}
                        isEditMode={false}
                        name={"header"}
                        placeholder=""
                        value={getDataRowHeader(dataRow)}
                        onChange={_ => NaN}
                        disableAutocomplete
                    />
                </Col>
            </When>
            <Col md={4}>
                <When condition={!isFetching} otherwise={<Spinner animation={"border"}/>}>
                    <CustomSelect
                        required={requiredSubType.includes(dataRow.subType!)}
                        disabled={!isFieldEnabled || !(isEditMode || isAnswerMode)}
                        isEditMode={true}
                        name={"actionUserId"}
                        value={dataRow.actionUserId ?? ''}
                        onChange={onChangeValue}
                        options={<>
                            <option value={''}>{t('ActionPlanDataRow.SELECT_RESPONSIBLE_2')}</option>
                            {data.map(person => <option key={person.userId}
                                                        value={person.userId}>{person.name}</option>)}
                        </>}
                    />
                </When>
            </Col>
            <Col md={5}>
                <Col md={7}>
                    <CustomDate
                        required={requiredSubType.includes(dataRow.subType!)}
                        type={'date'}
                        disabled={!isFieldEnabled || !(isEditMode || isAnswerMode)}
                        isEditMode={true}
                        name={'actionDate'}
                        value={dataRow.actionDate ?? ""}
                        onChange={onChangeValue}
                    />
                </Col>
            </Col>
        </Row>
    </>
};

export default ActionPlanDataRow;
