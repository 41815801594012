import React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteUserSkill } from "../../../api/CompanyApi";
import ModalForm from "../../../components/FormComponents/ModalForm";
import { IUserSkill } from "../../../core/interfaces/company";

const RemoveUserSkillModal: React.FC<{
    show: boolean,
    hide: () => void,
    userSkill: IUserSkill | null
}> = ({
    show,
    hide,
    userSkill
}) => {
        const { t } = useTranslation();
        const [remove, isRemoving] = useDeleteUserSkill(userSkill?.id ?? '', userSkill?.companyUserId ?? '');
        if (userSkill == null)
            return <></>

        return (
            <ModalForm
                show={show}
                hide={hide}
                title={t('RemoveUserSkillModal.DELETE_SKILL_1')}
                isEdited={() => false}
                submit={() => remove().then(_ => hide())}
                submitText={t('RemoveUserSkillModal.SUBMIT_TEXT_1')}
                isLoading={isRemoving}>
                <p>{t('RemoveUserSkillModal.CONFIRM_DELETE_1')} '{userSkill.skillName}'?</p>
            </ModalForm>
        )
    }

export default RemoveUserSkillModal