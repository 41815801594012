import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { $createParagraphNode, $getSelection, $isRangeSelection } from "lexical";
import getIcon, { Icon } from "../../../../../../../../assets/Icons/IconClassNames";
import styles from "../../../ToolbarPlugin.module.scss";
import { BlockTypes } from "../../../enums";

interface IBlockTypeButton {
    blockType: BlockTypes;
    text: string;
    onSelect: () => void;
    icon?: Icon;
}

const BlockTypeButton = (
    {
        blockType,
        text,
        onSelect,
        icon,
    }: IBlockTypeButton) => {
    const [editor] = useLexicalComposerContext();

    const setSelected = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                switch (blockType) {
                    case BlockTypes.HEADER_H1:
                        $wrapNodes(selection, () => $createHeadingNode('h1'));
                        break;
                    case BlockTypes.HEADER_H2:
                        $wrapNodes(selection, () => $createHeadingNode('h2'));
                        break;
                    case BlockTypes.HEADER_H3:
                        $wrapNodes(selection, () => $createHeadingNode('h3'));
                        break;
                    case BlockTypes.PARAGRAPH:
                        $wrapNodes(selection, () => $createParagraphNode());
                        break;
                    case BlockTypes.LIST_UNORDERED:
                        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
                        break;
                    case BlockTypes.LIST_ORDERED:
                        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
                        break;
                    default:
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                        break;
                }
            }
        });
        onSelect();
    }

    return <button
        className={styles.toolbarDropdownItem}
        onClick={setSelected}
        aria-label={text}
    >
        {icon && <i className={getIcon(icon)}/>}
        <span className={"ps-2"}>{text}</span>
    </button>
};

export default BlockTypeButton;