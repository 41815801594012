import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import PaginatedDataTableClientSide from "../../../../components/DataTableComponents/PaginatedDataTable";
import { DateToYYYYMMDDHHMM } from "../../../../core/helpers/dates/datesHelper";
import { IExtendedDocumentsReadReceipt } from "../../../../core/interfaces/document";


const ReadReceiptsTable = ({readReceipts}: { readReceipts: IExtendedDocumentsReadReceipt[] }) => {
    const {t} = useTranslation();
    const [filterText, setFilterText] = useState("");

    const columns: TableColumn<IExtendedDocumentsReadReceipt>[] = [
        {
            name: t('ReadReceiptTable.PERSON_1'),
            selector: row => row.user.name,
            sortable: true,
        },
        {
            name: t('ReadReceiptTable.DOCUMENT_1'),
            selector: row => row.document.name,
            sortable: true,
        },
        {
            name: t('ReadReceiptTable.SENT_1'),
            selector: row => DateToYYYYMMDDHHMM(row.createdAt!),
            sortable: true,
        },
        {
            name: t('ReadReceiptTable.REQUESTED_BY_1'),
            selector: row => row.requestedBy.name,
            sortable: true,
        }
    ];

    const filteredItems = readReceipts.filter(
        item => (
            (item.user && item.user.name.trim().toLowerCase().includes(filterText.trim().toLowerCase())) ||
            (item.document && item.document.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.createdAt && item.createdAt.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.requestedBy && item.requestedBy.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (filterText.includes(' ') &&
                (item.user.name.split(' ')[0].toLowerCase().includes(filterText.split(' ')[0].toLowerCase())) &&
                (item.user.name.split(' ')[1].toLowerCase().includes(filterText.split(' ')[1].toLowerCase()))
            ) ||
            (filterText.includes(' ') &&
                (item.requestedBy.name.split(' ')[0].toLowerCase().includes(filterText.split(' ')[0].toLowerCase())) &&
                (item.requestedBy.name.split(' ')[1].toLowerCase().includes(filterText.split(' ')[1].toLowerCase()))
            )
        )
    )

    return (
        <PaginatedDataTableClientSide<IExtendedDocumentsReadReceipt>
            data={filteredItems ?? []}
            columns={columns}
            progressPending={false}
            withFilter
            filterText={filterText}
            setFilterText={(prev) => setFilterText(prev)}
        />
    )
}

export default ReadReceiptsTable
