import SimpleModal from "./SimpleModal";
import Button from "react-bootstrap/Button";
import SpinnerButton from "../SpinnerButton";

interface ISimpleConfirmModal {
    show: boolean;
    header: JSX.Element[] | JSX.Element;
    text: JSX.Element[] | JSX.Element;
    onClickOKText: string;
    onClickOK: () => void;
    onClickCancelText: string;
    onClickCancel: () => void;
    isLoadingClickOk?: boolean;
    isOkDisabled?: boolean;
    size?: "xl" | "lg" | "sm";
    centered?: boolean;
}

const SimpleConfirmModal = (
    {
        show,
        header,
        text,
        onClickOKText,
        onClickOK,
        onClickCancelText,
        onClickCancel,
        isLoadingClickOk,
        isOkDisabled,
        size,
        centered = true,
    }: ISimpleConfirmModal) => {

    return <SimpleModal
        show={show}
        centered={centered}
        size={size}
        header={header}
        body={<div>{text}</div>}
        footer={<>
            <>
                <Button variant="secondary" onClick={onClickCancel}>{onClickCancelText}</Button>
                <SpinnerButton
                    text={onClickOKText}
                    isLoading={isLoadingClickOk ?? false}
                    disabled={isOkDisabled}
                    onClick={onClickOK}
                />
            </>
        </>}
    />
};

export default SimpleConfirmModal;