import {IResponse} from "../core/interfaces/common";
import {useApiBase} from "./useApiBase";
import {useEffect, useState} from "react";

export const useSingle = <T>(url: string, format?: (data: T) => T):  [T, boolean, () => void] => {
    const [data, loading, reFetch] = useApiBase<IResponse<T>>(url);
    const [fetch, setFetched] = useState<T>({} as T);

    useEffect(() => {
        if(data.data) {
            setFetched(format ? format(data.data) : data.data)
        }
    }, [data])
    return [fetch, loading, reFetch]
}
