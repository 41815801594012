import { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../../components/ClickableIconButton/ClickableIconButton";
import SimpleConfirmModal from "../../../components/ModalComponents/SimpleConfirmModal";
import When from "../../../components/When";
import { ChecklistView, ModuleType } from "../../../core/enums/enums";
import { IDocumentModule } from "../../../core/interfaces/document";
import useAccessCheck from "../../../hooks/useAccessCheck";
import { useDocumentModulesContext } from "./DocumentModules";

interface IDocumentModulesActionButtons {
    index: number;
    isActiveModule: boolean;
    module: IDocumentModule;
    children: any;
}

const DocumentModulesActionButtons = (
    {
        index,
        isActiveModule,
        module,
        children,
    }: IDocumentModulesActionButtons) => {

    const {
        activeModule,
        isEditMode,
        setActiveModule,
        cancelEditModule,
        saveChanges,
        deleteModule,
        isNoChangesActiveModule,
        isSaveDisabled,
        updateChecklistView,
    } = useDocumentModulesContext();

    const {t} = useTranslation();
    const [, checkRole] = useAccessCheck();
    const [showDeleteChecklistModal, setShowDeleteChecklistModal] = useState(false);

    const saveCancelButtons = () => (
        <div className="d-flex align-content-end flex-row-reverse">
            <ClickableIconButton
                disabled={false}
                buttonType={"CANCEL"}
                icon={getIcon(Icon.X_MARK, IconSize.XL)}
                onClick={cancelEditModule}
                title={t('DocumentModulesActionButtons.CANCEL_EDITING_1')}
            />
            <ClickableIconButton
                disabled={isNoChangesActiveModule || isSaveDisabled}
                buttonType={"SAVE"}
                icon={getIcon(Icon.SAVE, IconSize.XL)}
                title={t('DocumentModulesActionButtons.SAVE_CHANGES_1')}
                onClick={saveChanges}
            />
        </div>
    );

    const editDeleteButtons = () => (
        <div className="d-flex align-content-end flex-row-reverse">
            <ClickableIconButton
                disabled={false}
                buttonType={"CANCEL"}
                icon={getIcon(Icon.REMOVE, IconSize.XL)}
                onClick={() => setShowDeleteChecklistModal(true)}
                title={t('DocumentModulesActionButtons.DELETE_MODULE_1')}
            />
            <ClickableIconButton
                disabled={false}
                buttonType={"EDIT"}
                icon={getIcon(Icon.EDIT, IconSize.XL)}
                onClick={() => setActiveModule(module, index)}
                title={t('DocumentModulesActionButtons.EDIT_MODULE_1')}
            />
        </div>
    );

    const handleDeleteModule = () => {
        deleteModule(module.id);
        setShowDeleteChecklistModal(false);
    }

    const moduleType = (type: string) => {
        switch (type) {
            case ModuleType.TEXT:
                return { if: t('DocumentModulesActionButtons.TEXT_MODULE_1'), df: t('DocumentModulesActionButtons.THE_TEXT_MODULE_1') }

            case ModuleType.CHECKLIST:
                return { if: t('DocumentModulesActionButtons.CHECK_LIST_1'), df: t('DocumentModulesActionButtons.THE_CHECK_LIST_1') }
        }
    }

    return <>
        <Row className={"pb-3"}>
            <When condition={!!module.documentModuleChecklist}>
                <Col className="d-flex">
                    <ClickableIconButton
                        disabled={false}
                        buttonType={"EDIT"}
                        title={t('DocumentModulesActionButtons.TABLE_VIEW_1')}
                        icon={getIcon(Icon.TABLE, IconSize.XL)}
                        onClick={() => updateChecklistView(module.id, ChecklistView.TABLE)}
                    />
                    <ClickableIconButton
                        disabled={false}
                        buttonType={"EDIT"}
                        title={t('DocumentModulesActionButtons.LIST_VIEW_1')}
                        icon={getIcon(Icon.BARS, IconSize.XL)}
                        onClick={() => updateChecklistView(module.id, ChecklistView.LIST)}
                    />
                </Col>
            </When>
            <Col>
                <When condition={isEditMode}>
                    <When
                        condition={activeModule !== null}
                        otherwise={editDeleteButtons()}>
                        <When condition={isActiveModule} then={saveCancelButtons()}/>
                    </When>
                </When>
            </Col>
        </Row>
        <Row>
            <Col>
                {children}
            </Col>
        </Row>

        <SimpleConfirmModal
            show={showDeleteChecklistModal}
            header={<>{t('DocumentModulesActionButtons.DELETE')} {moduleType(module?.documentModuleType)?.if}</>}
            text={<>{t('DocumentModulesActionButtons.SURE_DELETE')} {moduleType(module?.documentModuleType)?.df}
                <i>{module?.documentModuleChecklist?.header}</i>?<br/>{t('DocumentModulesActionButtons.UNDOABLE_CHOICE_1')}</>}
            onClickOKText={t('DocumentModulesActionButtons.DELETE_1')}
            onClickOK={handleDeleteModule}
            onClickCancelText={t('DocumentModulesActionButtons.NEGATION_1')}
            onClickCancel={() => setShowDeleteChecklistModal(false)}
        />
    </>
};

export default DocumentModulesActionButtons;
