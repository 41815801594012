import Row from "react-bootstrap/esm/Row";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import CompanyTable from "./CompanyTable";


const Companies = ({ title, parentCompanyId }: any) => {
    return <MainContainer>
        <HeaderContainer title={title} />
        <Row>
            <CompanyTable parentCompanyId={parentCompanyId} searchable navigateOnName showSettings />
        </Row>
    </MainContainer>
}

export default Companies;