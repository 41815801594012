import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsers } from "../../../../../../api/DocumentApi";
import CustomDate from "../../../../../../components/FormComponents/CustomDate";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { RegexValidateDate, UTCStringToLocaleString } from "../../../../../../core/helpers/dates/datesHelper";
import { IDataRowProps, IDataRowUserAndDate } from "../../../../../../core/interfaces/document";
import DataRowActionButtons from "./DataRowActionButtons";

const UserAndDateDataRow = (
    {
        dataRow,
        setDataRow,
        isFieldEnabled,
        checklistViewTable,
        requiredSubType,
        removeDataRow,
        copyDataRow,
        isEditMode,
        isAnswerMode = false,
        isActive,
        isHeaderEnabled,
    }: IDataRowProps<IDataRowUserAndDate>) => {

    const [data, isFetching] = useGetCompanyUsers()


    const onChangeValue = (e: any) => {
        const value = (e.target.value ?? "");
        setDataRow({
            ...dataRow,
            [e.target.name]: value.length > 0 ? value : undefined
        });
    }

    const {t} = useTranslation();
    const [internalDate, setInternalDate] = useState<string | null | undefined>(null);

    useEffect(() => {
        if (dataRow.userAndDateDate) {
            if (RegexValidateDate(dataRow.userAndDateDate)) {
                setInternalDate(UTCStringToLocaleString(dataRow.userAndDateDate))
            }
        }
    }, [dataRow.userAndDateDate]);


    const handleChangeDate = (e: any) => {
        const localInputDate = e.target.value;

        if (localInputDate === "") {
            setDataRow({
                ...dataRow,
                [e.target.name]: null
            });
            return;
        }

        if (!localInputDate || localInputDate.length < 10 || localInputDate.length > 15) return;

        const testDate = new Date(localInputDate);
        if (isNaN(testDate.getTime())) return;

        let time = "";
        if (internalDate && internalDate.length > 10) {
            time = internalDate.substring(10);
        } else {
            time = " 00:00:00";
        }

        const aggDate = localInputDate + time;

        const localDate = new Date(aggDate);
        setInternalDate(aggDate);

        setDataRow({
            ...dataRow,
            [e.target.name]: localDate
        });
    }


    if (checklistViewTable) {
        return <>
            <Row>
                <Col md={12}>
                    <When condition={!isFetching} otherwise={<Spinner animation={"border"}/>}>
                        <CustomSelect
                            checklistViewTable={checklistViewTable}
                            required={requiredSubType.includes(dataRow.subType!)}
                            disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                            isEditMode={true}
                            name={"userAndDateUserId"}
                            value={dataRow.userAndDateUserId ?? '-'}
                            onChange={onChangeValue}
                            options={<>
                                <option key={''} value={''}>{t('UserAndDateDataRow.SELECT_EMPLOYEE_1')}</option>
                                {data.map(person => <option key={person.userId}
                                                            value={person.userId}>{person.name}</option>)}
                            </>}
                        />
                    </When>
                </Col>
                <Col md={12} className={'mt-1'}>
                    <CustomDate
                        checklistViewTable={checklistViewTable}
                        required={requiredSubType.includes(dataRow.subType!)}
                        disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                        isEditMode={true}
                        type={'date'}
                        name={'userAndDateDate'}
                        value={dataRow.userAndDateDate ?? ""}
                        onChange={handleChangeDate}
                    />
                </Col>
            </Row>

        </>
    }
    return <>
        <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
            <When condition={!checklistViewTable}>
                <Col md={3}>
                    <CustomTextInput
                        disabled={!isHeaderEnabled}
                        isEditMode={isEditMode}
                        name={"header"}
                        placeholder={t('UserAndDateDataRow.DISCOVERY_OF_1')}
                        value={getDataRowHeader(dataRow)}
                        onChange={onChangeValue}
                        disableAutocomplete
                    />
                </Col>
            </When>
            <Col md={isEditMode && isActive ? 4 : 5}>
                <When condition={!isFetching} otherwise={<Spinner animation={"border"}/>}>
                    <CustomSelect
                        required={requiredSubType.includes(dataRow.subType!)}
                        disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                        isEditMode={true}
                        name={"userAndDateUserId"}
                        value={dataRow.userAndDateUserId ?? '-'}
                        onChange={onChangeValue}
                        options={<>
                            <option value={''}>{t('UserAndDateDataRow.SELECT_USER_1')}</option>
                            {data.map(person => <option key={person.userId}
                                                        value={person.userId}>{person.name}</option>)}
                        </>}
                    />
                </When>
            </Col>
            <Col md={isEditMode && isActive ? 3 : 4}>
                <Col>
                    <CustomDate
                        required={requiredSubType.includes(dataRow.subType!)}
                        disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                        isEditMode={true}
                        type={'date'}
                        name={'userAndDateDate'}
                        value={dataRow.userAndDateDate ?? ""}
                        onChange={handleChangeDate}
                    />
                </Col>
            </Col>
            <When condition={isEditMode && isActive}>
                <DataRowActionButtons
                    dataRow={dataRow}
                    removeDataRow={removeDataRow}
                    copyDataRow={copyDataRow}
                />
            </When>
        </Row>
    </>

}

export default UserAndDateDataRow
