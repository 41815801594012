import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toastError } from "../core/helpers/toastHelper";
import { IResponse } from "../core/interfaces/common";
import { addToast } from "../store/slices/uiSlice";


type UpdatableOptions<T> = {
    getErrorToast?: (status: number, data: T) => { title: string, message: string },
    skipErrorToast?: (status: number, data: T) => boolean,

}
const useUpdatableObject = <T, C>(object: T, submitFunction: (object: T) => Promise<IResponse<C>>, options?: UpdatableOptions<C>) => {
    const {t} = useTranslation();
    const [updated, setUpdated] = useState<T>(object);
    const [obj] = useState<T>(object);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const isEdited = () => {
        return JSON.stringify(updated) !== JSON.stringify(obj);
    }

    const submit = async (): Promise<C> => {
        return new Promise<C>((resolve, reject) => {
            if (loading) {
                dispatch(addToast(toastError(t('useUpdatableObject.ERROR_OCCURRED_1'), t('useUpdatableObject.RESPONSE_WAIT_1'))));
                reject();
            }
            setLoading(true)
            submitFunction(updated).then(resp => {
                if (resp.status >= 400) {
                    let toastInfo = {
                        title: t('useUpdatableObject.ERROR_OCCURRED_2'),
                        message: t('useUpdatableObject.UNEXPECTED_ERROR_OCCURRED_1')
                    }
                    if (options && options.getErrorToast) {
                        toastInfo = options.getErrorToast(resp.status, resp.data);
                    }
                    const skip = options && options.skipErrorToast && options.skipErrorToast(resp.status, resp.data);
                    if (!skip) {
                        dispatch(addToast(toastError(toastInfo.title, toastInfo.message)));
                    }
                    reject(resp.data);
                } else {
                    resolve(resp.data);
                }
            }).finally(() => setLoading(false))
        })
    }

    return [updated, setUpdated, isEdited, submit, loading] as const;
}

export default useUpdatableObject
