import {IIncident} from "../../../core/interfaces/incident";
import Row from "react-bootstrap/Row";
import {InstantActionForm} from "../Forms/InstantActionForm";
import {LongTermActionForm} from "../Forms/LongTermActionForm";
import {ActionDateForm} from "../Forms/ActionDateForm";
import {FollowUpForm} from "../Forms/FollowUpForm";
import {RootCauseForm} from "../Forms/RootCauseForm";
import {IsCompletedForm} from "../Forms/IsCompletedForm";

export function LastSection(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void, onChangeStatus: (e: any) => void, onChangeDate: (e: any) => void }) {
    return <>
        <hr/>
        <Row className="pb-2">
            <RootCauseForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>

            <InstantActionForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>

            <LongTermActionForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>

            <ActionDateForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChangeDate}/>

            <FollowUpForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChange}/>

            <IsCompletedForm editMode={props.editMode} newIncident={props.newIncident} onChange={props.onChangeStatus}/>

        </Row>
    </>;
}