import { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import getIcon, { Icon } from "../../../../../../../assets/Icons/IconClassNames";
import styles from "./ToolbarDropdown.module.scss";

interface IDropdownToggle {
    onClick: (e: any) => void;
    children: JSX.Element[];
    icon?: string;
}

const DropdownToggle = forwardRef((
    {
        label,
        onClick,
        children,
        icon,
    }: IDropdownToggle & { label: string }, ref: any) => (

    <button
        className={[styles.toolbarItem].join(" ")}
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {icon && <i className={icon} />}
        <span className={"ps-2 pe-2"}>{label}</span>
        <i className={getIcon(Icon.CHEVRON_DOWN)}/>
        {children}
    </button>
));

interface IToolbarDropdown {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    label: string;
    icon?: string;
    children: any;
}

const ToolbarDropdown = (
    {
        isOpen,
        setIsOpen,
        label,
        icon,
        children
    }: IToolbarDropdown) => {

    return <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
        <Dropdown.Toggle
        as={DropdownToggle}
        className={"lexicalDropdown"}
        size={"sm"}
        label={label}
        icon={icon}
        />
        <Dropdown.Menu>
            {children}
        </Dropdown.Menu>
    </Dropdown>
};

export default ToolbarDropdown;