import {useDocumentModulesContext} from "../../../DocumentModules";

const StickyDiv = ({children, index = 0}: { children: JSX.Element | JSX.Element[], index?: number }) => {
    const {
        isAnswerMode,
        isDocumentPreview,
    } = useDocumentModulesContext();
    const calculateTop = () => {
        if (isDocumentPreview) return -24;
        if (isAnswerMode) return -24;
        return 92;
    }
    return (
        <div style={{
                zIndex: 999 - index,
                position: 'sticky',
                backgroundColor: '#eeeeee',
                marginTop: 24,
                paddingTop: 8,
                top: calculateTop(),

            }}>
            {children}
        </div>
    )
}
export default StickyDiv;