import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GetCompaniesCount, GetUsersCount } from "../../api/endpoints/statistics";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import { numberWithSpacesAsString } from "../../core/helpers/numbers/numberHelper";
import { IStatistics } from "../../core/interfaces/statistics";
import { useGet } from "../../hooks/useCustomQuery";
import DashboardCard from "./DashboardCard";
import { DashboardCardContentImage, DashboardCardContentStatistics } from "./DashboardCardContent";

const DashboardSuper = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {
        isFetching: isFetchingUsersCount,
        data: userCount,
    } = useGet<IStatistics>(GetUsersCount());

    const {
        isFetching: isFetchingCompaniesCount,
        data: companiesCount,
    } = useGet<IStatistics>(GetCompaniesCount(30));

    const {
        isFetching: isFetchingCompaniesCountTotal,
        data: companiesCountTotal,
    } = useGet<IStatistics>(GetCompaniesCount());

    const validateValue = (value: number | undefined) => {
        return value ? numberWithSpacesAsString(value) : '?';
    }

    const dashboardContent = () => (
        <>
            <Col lg={3} className="pb-3">
                <Row>
                    <Col className="mt-3">
                        <DashboardCard fetching={false} theme={"grey"} height={"25rem"}>
                            {/* <DashboardCardContentDetails /> */}
                        </DashboardCard>
                    </Col>
                </Row>
            </Col>

            <Col lg={9} className="pb-3">
                <Row>
                    <Col md={4}>
                        <Row className="mb-3 mt-3">
                            <Col data-test-id="dashboard_number_of_users">
                                <DashboardCard fetching={isFetchingUsersCount} theme={"lightblue"} height="12rem">
                                    <DashboardCardContentStatistics
                                        valueAsString={validateValue(userCount?.data.data.value)}
                                        title={t('DashboardSuper.NUMBER_OF_USERS_1')}/>
                                </DashboardCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DashboardCard fetching={isFetchingCompaniesCount} theme={"lightblue"} height="12rem">
                                    <DashboardCardContentStatistics
                                        valueAsString={validateValue(companiesCount?.data.data.value)}
                                        title={t('DashboardSuper.NEW_CUSTOMERS_LAST_30_DAYS_1')}/>
                                </DashboardCard>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4}>
                        <Row className="mb-3 mt-3">
                            <Col>
                                <DashboardCard fetching={false} theme={"green"} height="12rem">
                                    {/*<DashboardCardContentStatistics value={numOppnaPunkter} title={t('DashboardSuper.OPEN_ITEMS_1')} />*/}
                                </DashboardCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DashboardCard fetching={isFetchingCompaniesCountTotal} theme={"green"} height="12rem">
                                    <DashboardCardContentStatistics
                                        valueAsString={validateValue(companiesCountTotal?.data.data.value)}
                                        title={t('DashboardSuper.NUMBER_OF_CUSTOMERS_1')}/>
                                </DashboardCard>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4}>
                        <Row className="mb-3 mt-3">
                            <Col>
                                <DashboardCard fetching={false} theme={"darkblue"} height="12rem">
                                </DashboardCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DashboardCard fetching={false} theme={"darkblue"} height="12rem"
                                               onClick={() => navigate("companies")}>
                                    <DashboardCardContentImage
                                        image={<i className={getIcon(Icon.CUSTOMERS, IconSize.X5)}
                                                  title={t('DashboardSuper.CUSTOMERS_SUPPLIERS_1')}></i>}
                                        title={t('DashboardSuper.CUSTOMERS_SUPPLIERS_2')}
                                    />
                                </DashboardCard>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </>
    );

    // const columns: TableColumn<ICompany>[] = [
    //     {
    //         name: t('DashboardSuper.COMPANY_1'),
    //         cell: cell => <Link to={"c/" + cell.id}>{cell.name}</Link>,
    //         sortable: true,
    //     },
    //     {
    //         name: t('DashboardSuper.LOCATION_1'),
    //         selector: row => row.city,
    //         sortable: true,
    //     },
    // ];

    // const Table = () => (
    //     <>
    //         <Col>
    //             <DataTable
    //                 columns={columns}
    //                 highlightOnHover
    //                 data={[]}
    //                 noDataComponent={<DataTableNoData/>}
    //             />
    //         </Col>
    //     </>
    // );

    return <MainContainer>
        <HeaderContainer title={"Dashboard"}/>
        <Row className="mb-4">
            {dashboardContent()}
        </Row>
        {/*<HeaderContainer title={t('DashboardSuper.MY_LATEST_CLIENTS_1')}/>*/}
        {/*<Row>*/}
        {/*    {Table()}*/}
        {/*</Row>*/}
    </MainContainer>
}

export default DashboardSuper;
