import { NodeModel } from "@minoru/react-dnd-treeview";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "i18next";
import { useParams } from "react-router-dom";
import { NodeType, TemplateType } from "../core/enums/enums";
import { toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, ICustomQueryKeys, IResponse } from "../core/interfaces/common";
import {
    ICopyTemplateRequest,
    IMoveTemplateRequest,
    IRenameTemplateNodeRequest,
    ITemplateNodeData,
    ITemplateRequest,
    NewTemplateFolderRequest,
    NewTemplateRequest
} from "../core/interfaces/template";
import { AppQueryKey, GetQueryKey, useInvalidateQuery } from "../core/queryKeys";
import { useDelete, useGet, usePost, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import {
    URL_CREATE_CHECKLIST_FROM_TEMPLATE,
    URL_CREATE_CHECKLIST_TEMPLATE,
    URL_CREATE_DOCUMENT_FROM_TEMPLATE,
    URL_CREATE_DOCUMENT_TEMPLATE,
    URL_CREATE_TEMPLATE_FOLDER,
    URL_GET_TEMPLATES_BY_TYPE,
    URL_MOVE_TEMPLATES,
    URL_PUBLISH_ALL_TEMPLATE_DOCUMENT,
    URL_PUBLISH_TEMPLATE_DOCUMENT,
    URL_RENAME_TEMPLATE,
    URL_TEMPLATE_COPY,
    URL_TEMPLATE_DELETE,
    URL_TEMPLATE_SET_PRIVATE
} from "./endpoints/endpoints";


export const useCreateNewDocumentFromTemplate = (templateId: string): [((request: ITemplateRequest) => Promise<boolean | undefined>), boolean] => {
    const dispatch = useAppDispatch();
    const {cId, cUId} = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENTS);

    const usePostMutation = usePost<ITemplateRequest>(
        URL_CREATE_DOCUMENT_FROM_TEMPLATE(cId!, templateId),
        queryKeys,
        true
    );

    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);
    const invalidateUserDocuments = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {includeCompanyId: false, extraKeys: [cUId ?? '']});
    const request = (request: ITemplateRequest) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            const title = request.nodeType === NodeType.FOLDER ? i18n.t('TemplateApi.FOLDER_CREATED_1') : i18n.t('TemplateApi.DOCUMENT_CREATED_1');
            const message = request.nodeType === NodeType.FOLDER
                ? `${i18n.t('TemplateApi.FOLDER_CREATED_4')} ${request.name} ${i18n.t('TemplateApi.FOLDER_CREATED_5')}`
                : `${i18n.t('TemplateApi.DOCUMENT_CREATED_2')} ${request.name} ${i18n.t('TemplateApi.DOCUMENT_CREATED_3')}`;

            dispatch(addToast(toastSuccess(title, message)));
            await invalidateFolders();
            await invalidateUserDocuments();
            // await queryClient.invalidateQueries(["folders", cId]);
        }
        return response.success;
    });
    return [request, usePostMutation.isLoading];
}


export const useCreateNewChecklistFromTemplate = (templateId: string): [((request: ITemplateRequest) => Promise<boolean | undefined>), boolean] => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const {cId, documentId} = useParams();
    const queryKeys: ICustomQueryKeys = {root: "modules", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePost<ITemplateRequest>(
        URL_CREATE_CHECKLIST_FROM_TEMPLATE(cId!, documentId!, templateId),
        queryKeys,
        true
    );

    const request = (request: ITemplateRequest) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            const title = request.nodeType === NodeType.FOLDER ? i18n.t('TemplateApi.CHECKLISTS_CREATED_1') : i18n.t('TemplateApi.CHECKLIST_CREATED_1');
            const message = request.nodeType === NodeType.FOLDER
                ? `${i18n.t('TemplateApi.CHECKLISTS_CREATED_2')} ${request.name} ${i18n.t('TemplateApi.CHECKLISTS_CREATED_3')}`
                : `${i18n.t('TemplateApi.CHECKLIST_CREATED_2')} ${request.name} ${i18n.t('TemplateApi.CHECKLIST_CREATED_3')}`;
            dispatch(addToast(toastSuccess(title, message)));
            await queryClient.fetchQuery(["modules", cId, documentId]);
        }
        return response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useCreateNewDocumentTemplateByCompany = (companyId?: string): [((request: NewTemplateRequest) => Promise<boolean | undefined>), boolean] => {
    return _useNewTemplateRequest(URL_CREATE_DOCUMENT_TEMPLATE(companyId), AppQueryKey.DOCUMENT_TEMPLATES);
}

export const useCreateNewDocumentTemplate = (): [((request: NewTemplateRequest) => Promise<boolean | undefined>), boolean] => {
    const { cId } = useParams();
    return _useNewTemplateRequest(URL_CREATE_DOCUMENT_TEMPLATE(cId), AppQueryKey.DOCUMENT_TEMPLATES);
}

export const useCreateNewChecklistTemplate = (moduleId: string): [((request: NewTemplateRequest) => Promise<boolean | undefined>), boolean] => {
    const {cId} = useParams();
    return _useNewTemplateRequest(URL_CREATE_CHECKLIST_TEMPLATE(cId!, moduleId!), AppQueryKey.CHECKLIST_TEMPLATES)
}

const _useNewTemplateRequest = (url: string, queryRootKey: AppQueryKey): [((request: NewTemplateRequest) => Promise<boolean | undefined>), boolean] => {
    const dispatch = useAppDispatch();
    const usePostMutation = usePost<NewTemplateRequest>(
        url,
        GetQueryKey(queryRootKey),
        true
    );

    const invalidateTemplates = useInvalidateQuery(AppQueryKey.DOCUMENT_TEMPLATES);

    const request = (request: NewTemplateRequest) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.TEMPLATE_CREATED_1'), i18n.t('TemplateApi.TEMPLATE_1') + request.name + i18n.t('TemplateApi.CREATED_1'))));
            await invalidateTemplates();
        }
        return response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useGetTemplatesByCompany = (templateType: TemplateType, onlyApproved: boolean, companyId?: string) => {
    const queryRootKey: AppQueryKey = TemplateType.DOCUMENT === templateType ? AppQueryKey.DOCUMENT_TEMPLATES : AppQueryKey.CHECKLIST_TEMPLATES;
    const queryKeys: ICustomQueryKeys = {
        root: queryRootKey, cId: companyId ?? "", extraKeys: [onlyApproved.toString()]
    };

    const query: ICustomQuery = {
        url: URL_GET_TEMPLATES_BY_TYPE(companyId ?? "", templateType, onlyApproved),
        queryKeys: queryKeys,
    };

    return useGet<NodeModel<ITemplateNodeData>[]>(query);
}

export const useGetTemplatesByType = (templateType: TemplateType, onlyApproved: boolean) => {
    const { cId } = useParams();
    const queryKey: AppQueryKey = TemplateType.DOCUMENT === templateType ? AppQueryKey.DOCUMENT_TEMPLATES : AppQueryKey.CHECKLIST_TEMPLATES;
    const query: ICustomQuery = {
        url: URL_GET_TEMPLATES_BY_TYPE(cId, templateType, onlyApproved),
        queryKeys: GetQueryKey(queryKey, { extraKeys: [onlyApproved.toString()] }),
    };

    return useGet<NodeModel<ITemplateNodeData>[]>(query);
}

export const useCreateNewTemplateFolder = (templateType: TemplateType): [((request: NewTemplateFolderRequest) => Promise<IResponse<NewTemplateFolderRequest>>), boolean] => {
    const dispatch = useAppDispatch();
    const { cId } = useParams();
    const queryClient = useQueryClient();
    const queryRootKey = TemplateType.DOCUMENT === templateType ? "documentTemplates" : "checklistTemplates";
    const queryKeys: ICustomQueryKeys = { root: queryRootKey, cId: cId };
    const usePostMutation = usePost<NewTemplateFolderRequest>(
        URL_CREATE_TEMPLATE_FOLDER(cId),
        queryKeys,
        true,
    );

    const request = (request: NewTemplateFolderRequest) => usePostMutation.mutateAsync(request).then((response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.FOLDER_CREATED_2'), i18n.t('TemplateApi.FOLDER_CREATED_3'))));
            queryClient.invalidateQueries([queryRootKey]);
        }
        return response;
    });

    return [request, usePostMutation.isLoading];
}

export const useMoveTemplate = (): [(request: IMoveTemplateRequest) => Promise<boolean>, boolean] => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "documentTemplates"};

    const usePutMutation = usePut<IMoveTemplateRequest>(
        URL_MOVE_TEMPLATES(),
        queryKeys,
        true
    );

    const request = (request: IMoveTemplateRequest) => usePutMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.SORT_SAVED_1'), i18n.t('TemplateApi.SORT_SAVED_2'))));
            await queryClient.invalidateQueries(["documentTemplates"]);
        }
        return !!response.success;
    });
    return [request, usePutMutation.isLoading];
}

export const useDeleteTemplate = (
    templateId: string,
    nodeType: string
): [() => Promise<boolean>, boolean] => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "documentTemplates"}

    const useDeleteMutation = useDelete<void>(
        URL_TEMPLATE_DELETE(templateId, nodeType),
        queryKeys,
        true,
    );

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.TEMPLATE_DELETED_1'), i18n.t('TemplateApi.TEMPLATE_DELETED_2'))));
            await queryClient.invalidateQueries(["documentTemplates"]);
        }
        return !!response.success;
    });
    return [request, useDeleteMutation.isLoading];
}

export const useRenameTemplateNode = (): [((request: IRenameTemplateNodeRequest) => Promise<IResponse<IRenameTemplateNodeRequest>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENT_TEMPLATES);

    const usePutMutation = usePut<IRenameTemplateNodeRequest>(
        URL_RENAME_TEMPLATE(),
        queryKeys,
        true,
    );

    const request = (req: IRenameTemplateNodeRequest) => usePutMutation.mutateAsync(req).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.NAME_UPDATED_1'), "")));
        }
        return response;
    });
    return [request, usePutMutation.isLoading];
}

export const usePublishTemplate = (
    documentId: string,
    documentName: string
): [(request: { publish: boolean }) => Promise<IResponse<({ publish: boolean })>>, boolean] => {
    const dispatch = useAppDispatch();

    const usePostMutation = usePost<{ publish: boolean }>(
        URL_PUBLISH_TEMPLATE_DOCUMENT(documentId),
        GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId]}),
        true,
    );

    const invalidateTemplates = useInvalidateQuery(AppQueryKey.DOCUMENT_TEMPLATES);

    const request = (request: { publish: boolean }) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.TEMPLATE_PUBLISHED_1'), i18n.t('TemplateApi.TEMPLATE_2') + documentName + i18n.t('TemplateApi.PUBLISHED_1'))));
            await invalidateTemplates();
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
}

export const usePublishAllTemplatesByFolderId = (
    folderId: string,
    folderName: string
): [() => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENT_TEMPLATES);

    const usePutMutation = usePut<void>(
        URL_PUBLISH_ALL_TEMPLATE_DOCUMENT(folderId),
        queryKeys,
        true,
    );

    const request = () => usePutMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.PUBLISHED_TEMPLATES_1'), i18n.t('TemplateApi.UNDER_FOLDER_1') + folderName + i18n.t('TemplateApi.PUBLISHED_2'))));
        }
        return !!response.success;
    });
    return [request, usePutMutation.isLoading];
}

export const usePrivateTemplate = (
    templateId: string,
    documentId: string,
): [(request: { private: boolean }) => Promise<IResponse<{ private: boolean }>>, boolean] => {
    const dispatch = useAppDispatch();
    const { cId } = useParams();
    const usePutMutation = usePut<{ private: boolean }>(
        URL_TEMPLATE_SET_PRIVATE(templateId),
        GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId]}),
        true,
    );

    const invalidateTemplates = useInvalidateQuery(AppQueryKey.DOCUMENT_TEMPLATES);

    const request = (request: { private: boolean }) => usePutMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            if (request.private) {
                dispatch(addToast(toastSuccess(i18n.t('TemplateApi.TEMPLATE_PRIVATE_1'), i18n.t('TemplateApi.TEMPLATE_ONLY_FOR_SAMG_1'))));
            } else {
                dispatch(addToast(toastSuccess(i18n.t('TemplateApi.TEMPLATE_PUBLIC_1'), i18n.t('TemplateApi.TEMPLATE_AVAILABLE_FOR_ALL_COMPANIES_1'))));
            }
            await invalidateTemplates();
        }
        return response;
    });
    return [request, usePutMutation.isLoading];
}

export const useCopyTemplate = (): [(request: ICopyTemplateRequest) => Promise<IResponse<any>>, boolean] => {
    const dispatch = useAppDispatch();
    const { cId } = useParams();

    const usePostMutation = usePost<{ copied: boolean }>(
        URL_TEMPLATE_COPY(cId),
        GetQueryKey(AppQueryKey.DOCUMENT_TEMPLATES),
        true,
    );

    const invalidateTemplates = useInvalidateQuery(AppQueryKey.DOCUMENT_TEMPLATES);

    const request = (request: any) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('TemplateApi.TEMPLATE_COPIED_1'), i18n.t('TemplateApi.TEMPLATE_COPIED_2'))));
            await invalidateTemplates();
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
}