import {Offcanvas, OffcanvasBody, OffcanvasHeader} from "react-bootstrap";

interface IChecklistCanvas {
    showCanvas: boolean;
    setShowCanvas: (value: boolean) => void;
    title: string;
    children: any;
}

const ChecklistCanvas = ({showCanvas, setShowCanvas, title, children}: IChecklistCanvas) => {

    return <>
        <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)} placement={"end"}>
            <OffcanvasHeader closeButton>
                <Offcanvas.Title>{title}</Offcanvas.Title>
            </OffcanvasHeader>
            <OffcanvasBody>
                {children}
            </OffcanvasBody>
        </Offcanvas>
    </>
};

export default ChecklistCanvas;