import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../components/LoadingSpinner";
import When from "../../components/When";

export const DashboardCardContentStatistics = ({
                                                   valueAsString,
                                                   title,
                                                   loading = false,
                                               }: { valueAsString: string, title: string, loading?: boolean }) => (
    <>
        <Row style={{height: "70%"}}>
            <Col className="dashboard-card-content-wrapper">
                <span className="dashboard-card-content-text">
                    <When condition={!loading} otherwise={<LoadingSpinner/>}>
                        {valueAsString}
                    </When>
                 </span>
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <span className="dashboard-card-title">
                    {title}
                </span>
            </Col>
        </Row>
    </>
);


export const DashboardCardContentImage = ({
                                              image,
                                              title
                                          }: { image: any, title: string }) => (
    <>
        <Row style={{height: "70%"}}>
            <Col className="dashboard-card-content-wrapper">
                {image}
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <span className="dashboard-card-title">
                    {title}
                </span>
            </Col>
        </Row>
    </>
);

export const DashboardCardContentDetails = () => {
    const {t} = useTranslation();
    return <>
        <Row>
            <Col>
                <h4 className="text-center">{t('DashboardCardContent.TASKS_1')}</h4>
            </Col>
        </Row>
        <Row>
            <Col>
                <ul>
                    <li>{t('DashboardCardContent.DO_NOT_KNOW_HOW_1')}</li>
                    <li>{t('DashboardCardContent.THIS_SHOULD_WORK_1')}</li>
                </ul>
            </Col>
        </Row>
    </>
}
