import { useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosRequestConfig } from "axios";
import i18n from "i18next";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentTypes, HTTP_STATUS } from "../core/enums/enums";
import { ValidationErrorCodes } from "../core/enums/validationErrorCodes";
import { downloadFileResponse, fileResponse } from "../core/helpers/fileHelper";
import { toastError, toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, ICustomQueryKeys, IEntity, IResponse, ISystemPerson } from "../core/interfaces/common";
import {
    IDocument,
    IDocumentCloseRequest,
    IDocumentCopyRequest,
    IDocumentCreateRequest,
    IDocumentLight,
    IDocumentModule,
    IDocumentModuleCreateReq,
    IDocumentReadReceipt,
    IDocumentUploadRequest,
    IDocumentVersionLight,
    IExtendedDocumentsReadReceipt,
    IUpdateDocument
} from "../core/interfaces/document";
import { ISkillResponse } from "../core/interfaces/skill";
import { AppQueryKey, GetQueryKey, useForceFetchQuery, useInvalidateQuery, useRemoveQuery } from "../core/queryKeys";
import { useDelete, useGet, usePost, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient from "./api";
import {
    URL_COMPANY_SKILLS,
    URL_COMPANY_USERS,
    URL_COMPANY_USER_VIEW_DOCUMENT,
    URL_DOCUMENT,
    URL_DOCUMENT_ATTACHMENT_DELETE,
    URL_DOCUMENT_ATTACHMENT_DOWNLOAD,
    URL_DOCUMENT_ATTACHMENT_UPLOAD,
    URL_DOCUMENT_CLOSE,
    URL_DOCUMENT_COMPANY_READ_RECEIPT,
    URL_DOCUMENT_COPY,
    URL_DOCUMENT_CREATE,
    URL_DOCUMENT_CREATE_READ_RECEIPT,
    URL_DOCUMENT_DELETE,
    URL_DOCUMENT_DOWNLOAD,
    URL_DOCUMENT_GET_READ_RECEIPT,
    URL_DOCUMENT_GET_VERSIONS,
    URL_DOCUMENT_IMPORTANT,
    URL_DOCUMENT_IMPORTANT_HANDLED,
    URL_DOCUMENT_MARK_READ_RECEIPT,
    URL_DOCUMENT_MODULES,
    URL_DOCUMENT_PUBLISH_APPROVE,
    URL_DOCUMENT_PUBLISH_REJECT,
    URL_DOCUMENT_PUBLISH_REQUEST_APPROVAL,
    URL_DOCUMENT_PUBLISH_USER,
    URL_DOCUMENT_RESTORE,
    URL_DOCUMENT_UPDATE_VERSION,
    URL_DOCUMENT_UPLOAD,
    URL_FOLDERS_DELETE,
    URL_FOLDER_APPROVE_ALL_DOCUMENTS,
    URL_FOLDER_RESTORE,
    URL_USER_COMPANY_READ_RECEIPT
} from "./endpoints/endpoints";

export const downloadDocument = async (documentId: string) => {
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    return await AxiosClient.get(URL_DOCUMENT_DOWNLOAD(documentId), config).then((response) => {
        return fileResponse(response) as any;
    }).catch((error: AxiosError) => {
        console.error("AxiosError: ", error.message); // TODO handle error?
    });
}

export const useGetDocumentById = (
    documentId: string,
    enabled?: boolean | true
) => {
    const documentQueryKey = GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId]});
    const query: ICustomQuery = {
        url: URL_DOCUMENT(documentId),
        queryKeys: documentQueryKey,
        options: {enabled: enabled}
    };
    return useGet<IDocument>(query);
}

export const useCreateNewDocument = (): [((request: IDocumentCreateRequest) => Promise<IResponse<IDocumentCreateRequest>>), boolean] => {
    const {cId, cUId} = useParams();
    const dispatch = useAppDispatch();

    const usePostMutation = usePost<IDocumentCreateRequest>(
        URL_DOCUMENT_CREATE(cId ?? ""),
        GetQueryKey(AppQueryKey.DOCUMENTS),
        true
    );

    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);
    const invalidateUserDocuments = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: [cUId ?? '']
    });

    const request = (request: IDocumentCreateRequest) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.DOCUMENT_CREATED_1'), i18n.t('DocumentApi.DOCUMENT_1') + request.name + i18n.t('DocumentApi.CREATED_1'))));
            await invalidateFolders();
            await invalidateUserDocuments();
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
}

export const useUpdateDocument = (): [((request: IUpdateDocument) => Promise<IResponse<IDocument>>), boolean] => {
    const {documentId} = useParams();
    const dispatch = useAppDispatch();
    const documentQueryKeys: ICustomQueryKeys = GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId ?? ""]});

    const usePutMutation = usePut<IUpdateDocument>(
        URL_DOCUMENT(documentId ?? ""),
        documentQueryKeys,
        true
    );

    const forceRefetchDocument = useForceFetchQuery(AppQueryKey.DOCUMENTS, {extraKeys: [documentId ?? ""]});
    const invalidateTemplates = useInvalidateQuery(AppQueryKey.DOCUMENT_TEMPLATES);
    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);
    const invalidateUserDocuments = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: undefined
    });

    const request = (request: IUpdateDocument) => usePutMutation.mutateAsync(request).then(async (response) => {
        const isTemplate = DocumentTypes.TEMPLATE === request.documentType;
        if (response.success) {
            dispatch(addToast(toastSuccess((isTemplate ? i18n.t('DocumentApi.TEMPLATE_1') : i18n.t('DocumentApi.DOCUMENT_2')) + i18n.t('DocumentApi.UPDATED_1'), (isTemplate ? i18n.t('DocumentApi.TEMPLATE_2') : i18n.t('DocumentApi.DOCUMENT_3')) + request.name + i18n.t('DocumentApi.UPDATED_2'))));
            await forceRefetchDocument();
            await invalidateUserDocuments();
            isTemplate ? await invalidateTemplates() : await invalidateFolders();
        }
        return response;
    });
    return [request, usePutMutation.isLoading];
}

export const useGetDocumentModulesById = (
    documentId: string,
    enabled?: boolean | true,
) => {
    const documentQueryKey = GetQueryKey(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId]});
    const query: ICustomQuery = {
        url: URL_DOCUMENT_MODULES(documentId),
        queryKeys: documentQueryKey,
        options: {enabled: enabled}
    };

    const {
        isFetching,
        data,
        refetch
    } = useGet<IDocumentModule[]>(query);

    const removeDocumentModuleQueryKey = useRemoveQuery(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId]});

    return {
        isFetching,
        data,
        refetch: async (invalidate: boolean = false) => {
            if (invalidate) {
                await removeDocumentModuleQueryKey();
            }
            return refetch();
        }
    }
}

export const useCreateNewModule = (
    documentId: string,
): [((request: IDocumentModuleCreateReq) => Promise<IResponse<IDocumentModuleCreateReq>>), boolean] => {
    const dispatch = useAppDispatch();
    const documentQueryKey = GetQueryKey(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId]});

    const usePostMutation = usePost<IDocumentModuleCreateReq>(
        URL_DOCUMENT_MODULES(documentId),
        documentQueryKey,
        true
    );

    const refetchDocumentModules = useForceFetchQuery(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId]});

    const request = (request: IDocumentModuleCreateReq) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            await refetchDocumentModules();
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.MODULE_ADDED_1'), i18n.t('DocumentApi.MODULE_ADDED_2'))));
        }
        return response;
    });
    return [request, usePostMutation.isLoading];
};

export const useUpdateModule = (
    d?: string
): [((request: IDocumentModule) => Promise<IResponse<IDocumentModule>>), boolean] => {
    const {documentId} = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId!]});

    const usePutMutation = usePut<IDocumentModule>(
        URL_DOCUMENT_MODULES(documentId!),
        queryKeys,
        true
    );

    const forceRefetchModule = useForceFetchQuery(AppQueryKey.DOCUMENT_MODULES, {extraKeys: [documentId!]});

    const request = (request: IDocumentModule) => usePutMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.MODULE_SAVED_1'), i18n.t('DocumentApi.MODULE_HAS_BEEN_SAVED_1'))));

            await forceRefetchModule();
        }
        return response;
    });
    return [request, usePutMutation.isLoading];
};

export const useDeleteModule = (
    documentId: string,
): [((request: string) => Promise<IResponse<string>>), boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "modules", cId: cId, extraKeys: [documentId]};

    const useDeleteMutation = useDelete<string>(
        URL_DOCUMENT_MODULES(documentId),
        queryKeys,
        true
    );

    const request = (request: string) => useDeleteMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.MODULE_DELETED_1'), i18n.t('DocumentApi.MODULE_DELETED_2'))));
        }
        return response;
    });
    return [request, useDeleteMutation.isLoading];
};

export const useUploadDocumentAttachment = (): [((request: FormData) => Promise<IResponse<IEntity>>), boolean] => {
    const {documentId} = useParams();
    const dispatch = useAppDispatch();

    const usePostMutation = usePost<FormData>(
        URL_DOCUMENT_ATTACHMENT_UPLOAD(documentId ?? ""),
        GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId!]}),
        false
    );

    const invalidateDocument = useInvalidateQuery(AppQueryKey.DOCUMENTS, {extraKeys: [documentId!]});
    const request = (request: FormData) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.UPLOAD_SUCCESS_1'), i18n.t('DocumentApi.UPLOAD_TO_DOCUMENT_1'))));
            await invalidateDocument();
        } else {
            if ((response.data as any).status === 422) {
                dispatch(addToast(toastError(i18n.t('DocumentApi.FILE_TOO_LARGE_1'), i18n.t('DocumentApi.FILE_TOO_LARGE_2'))));
            }
        }
        return response as unknown as IResponse<IEntity>;
    }).catch(_ => {
        dispatch(addToast(toastError(i18n.t('DocumentApi.ERROR_OCCURRED_1'), i18n.t('DocumentApi.UNEXPECTED_ERROR_OCCURRED_1'))));
        return {} as any;
    });
    return [request, usePostMutation.isLoading];
}


export const useDeleteAttachment = (attachmentId: string
): [() => Promise<boolean>, boolean] => {
    const {cId, documentId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "documents", cId: cId, extraKeys: [documentId!]};

    const useDeleteMutation = useDelete<void>(
        URL_DOCUMENT_ATTACHMENT_DELETE(documentId!, attachmentId),
        queryKeys,
        true
    );

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.REMOVAL_SUCCESSFUL_1'), i18n.t('DocumentApi.FILE_REMOVED_1'))));
            await queryClient.invalidateQueries(["documents", cId, documentId]);
        }
        return !!response.success
    });
    return [request, useDeleteMutation.isLoading];
}
export const useDownloadAttachmentById = (attachmentId: string, docId?: string): [() => void, boolean] => {
    const [isDownloading, setIsDownloading] = useState(false);
    const dispatch = useAppDispatch();
    const {documentId} = useParams();
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    const download = () => {
        setIsDownloading(true);
        AxiosClient.get(URL_DOCUMENT_ATTACHMENT_DOWNLOAD((docId ?? documentId!), attachmentId), config).then((response) => {
            downloadFileResponse(fileResponse(response))
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('DocumentApi.DOWNLOAD_FAILED_1'), i18n.t('DocumentApi.ERROR_TRY_AGAIN_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}

export const useGetReadReceipt = (
    documentId: string,
    enabled?: boolean | true): [IDocumentReadReceipt[], boolean] => {
    const {cId} = useParams();
    const queryKeys: ICustomQueryKeys = {root: "readreceipt", cId: cId, extraKeys: [documentId!]};
    const query: ICustomQuery = {
        url: URL_DOCUMENT_GET_READ_RECEIPT(cId ?? "", documentId),
        queryKeys: queryKeys,
        options: {enabled: enabled}
    };

    const {
        isFetching,
        data
    } = useGet<{ readReceipts: IDocumentReadReceipt[] }>(query);


    return [data?.data.data.readReceipts ?? [], isFetching]
}

export const useCreateReadReceipt = (documentId: string): [((request: string[]) => Promise<IResponse<IEntity>>), boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "readreceipt", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePost<{
        companyUserIds: string[]
    }>(
        URL_DOCUMENT_CREATE_READ_RECEIPT(cId ?? "", documentId),
        queryKeys,
        true
    );

    const request = (request: string[]) => {
        return usePostMutation.mutateAsync({
            companyUserIds: request
        }).then(async (response) => {
            if (response.success) {
                dispatch(addToast(toastSuccess(i18n.t('DocumentApi.READ_RECEIPTS_CREATED_1'), i18n.t('DocumentApi.READ_RECEIPTS_CREATED_EMAIL_SENT_1'))));
                await queryClient.invalidateQueries(["readreceipt", cId, documentId]);
                await queryClient.invalidateQueries(["company_read_receipt", cId]);
                await queryClient.invalidateQueries(["user_read_receipt", cId]);
            }
            return response as unknown as IResponse<IEntity>;
        });
    }
    return [request, usePostMutation.isLoading];
}

export const useMarkReadReceipt = (documentId: string): [() => Promise<IResponse<IEntity>>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "readreceipt", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePut<void>(
        URL_DOCUMENT_MARK_READ_RECEIPT(cId ?? "", documentId),
        queryKeys,
        true
    );

    const invalidateTodos = useInvalidateQuery(AppQueryKey.TODO);

    const request = () => {
        return usePostMutation.mutateAsync().then(async (response) => {
            if (response.success) {
                dispatch(addToast(toastSuccess(i18n.t('DocumentApi.READ_STATUS_UPDATED_1'), i18n.t('DocumentApi.MARKED_AS_READ_1'))));
                await queryClient.invalidateQueries(["readreceipt", cId, documentId]);
                await queryClient.invalidateQueries(["company_read_receipt", cId]);
                await queryClient.invalidateQueries(["user_read_receipt", cId]);
                await invalidateTodos();

            }
            return response as unknown as IResponse<IEntity>;
        });
    }
    return [request, usePostMutation.isLoading];
}

export const useGetCompanyReadReceipts = (skipRead = false) => _useReadReceipts(URL_DOCUMENT_COMPANY_READ_RECEIPT, skipRead, 'company_read_receipt');


export const useGetUserReadReceipts = (skipRead = false) => _useReadReceipts(URL_USER_COMPANY_READ_RECEIPT, skipRead, 'user_read_receipt');

const _useReadReceipts = (url: (cId: string) => string, skipRead: boolean, root: string): [IExtendedDocumentsReadReceipt[], boolean] => {
    const {cId} = useParams();
    const queryKeys: ICustomQueryKeys = {root, cId};

    const query: ICustomQuery = {
        url: url(cId!) + (skipRead ? '?q=skipRead' : ''),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<{ readReceipts: IExtendedDocumentsReadReceipt[] }>(query);


    return [data?.data.data.readReceipts ?? [], isFetching]
}

export const usePublishRequestApproval = (documentId: string): [(request: string) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "documents", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePut<{
        companyUserId: string
    }>(
        URL_DOCUMENT_PUBLISH_REQUEST_APPROVAL(documentId),
        queryKeys,
        true
    );

    const request = (request: string) => usePostMutation.mutateAsync({companyUserId: request}).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.REQUEST_SUCCEEDED_1'), i18n.t('DocumentApi.DOCUMENT_FLAGGED_FOR_APPROVAL_1'))));
            await queryClient.invalidateQueries(["documents", cId, documentId]);
        }
        return !!response.success
    });
    return [request, usePostMutation.isLoading];
}

export const usePublishApprove = (documentId: string): [() => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId!]});

    const usePostMutation = usePut<{}>(
        URL_DOCUMENT_PUBLISH_APPROVE(documentId),
        queryKeys,
        true
    );

    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);
    const invalidateUserPublish = useInvalidateQuery(AppQueryKey.USERS_PUBLISH);

    const request = () => usePostMutation.mutateAsync({}).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.APPROVAL_SUCCESSFUL_1'), i18n.t('DocumentApi.APPROVED_AND_PUBLISHED_1'))));
            await invalidateFolders();
            await invalidateUserPublish();
        }
        return !!response.success
    });
    return [request, usePostMutation.isLoading];
}

export const usePublishReject = (documentId: string): [(comment: string) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "documents", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePut<{ comment: string }>(
        URL_DOCUMENT_PUBLISH_REJECT(documentId),
        queryKeys,
        true
    );

    const request = (comment: string) => usePostMutation.mutateAsync({comment}).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.REJECTION_SUCCESSFUL_1'), i18n.t('DocumentApi.REJECTION_1'))));
            await queryClient.invalidateQueries(["documents", cId, documentId]);
            await queryClient.invalidateQueries(["user_publish", cId])
        }
        return !!response.success
    });
    return [request, usePostMutation.isLoading];
}

export const useUserPublishes = (): [IDocumentLight[], boolean] => {
    const {cId} = useParams();
    const queryKeys: ICustomQueryKeys = {root: 'user_publish', cId: cId};

    const query: ICustomQuery = {
        url: URL_DOCUMENT_PUBLISH_USER(cId ?? ""),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<IDocumentLight[]>(query);
    return [(data?.data ?? []) as any as IDocumentLight[], isFetching]
}

export const useUpdateDocumentVersion = (documentId: string): [() => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "documents", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePut<IResponse<IDocumentLight>>(
        URL_DOCUMENT_UPDATE_VERSION(documentId),
        queryKeys,
        true
    );

    const request = () => usePostMutation.mutateAsync({} as any).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.UPDATE_SUCCESSFUL_1'), i18n.t('DocumentApi.UPDATE_DOCUMENT_VERSION_1'))));
            await queryClient.invalidateQueries(["documents", cId]);
            await queryClient.invalidateQueries(["folders", cId]);
            await queryClient.invalidateQueries(["modules", cId]);
            navigate(`/c/${cId}/documents/${response.data.data.id}`, {
                replace: true,
            });
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useGetDocumentVersions = (companyId: string, documentId: string, documentBaseId: string): [IDocumentVersionLight[], boolean] => {
    const queryKeys: ICustomQueryKeys = {root: "documentVersions", cId: companyId, extraKeys: [documentBaseId]};
    const query: ICustomQuery = {
        url: URL_DOCUMENT_GET_VERSIONS(documentId),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data,
    } = useGet<IDocumentVersionLight[]>(query);


    return [data?.data?.data ?? [], isFetching]
}

export const useRestoreDocument = (documentId: string): [() => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "documents", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePut<{}>(
        URL_DOCUMENT_RESTORE(cId ?? "", documentId),
        queryKeys,
        true
    );

    const request = () => usePostMutation.mutateAsync({} as any).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.RESET_SUCCESSFUL_1'), i18n.t('DocumentApi.DOCUMENT_RESTORED_1'))));
            await queryClient.invalidateQueries(["documents", cId]);
            await queryClient.invalidateQueries(["folders", cId]);
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useDeleteDocument = (documentId: string): [() => Promise<boolean>, boolean] => {
    const {cId, cUId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "documents", cId: cId, extraKeys: [documentId!]};

    const useDeleteMutation = useDelete<void>(
        URL_DOCUMENT_DELETE(documentId),
        queryKeys,
        true
    );
    const invalidateUserFiles = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: [cUId ?? '']
    });
    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.DELETION_SUCCESSFUL_1'), i18n.t('DocumentApi.DELETED_DOCUMENT_1'))));
            await queryClient.invalidateQueries(["documents", cId, documentId]);
            await queryClient.invalidateQueries(["folders", cId]);
            await invalidateUserFiles();
        }
        return !!response.success
    });
    return [request, useDeleteMutation.isLoading];
}

export const useDeleteFolder = (folderId: string): [() => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "folder", cId: cId};

    const useDeleteMutation = useDelete<void>(
        URL_FOLDERS_DELETE(cId!, folderId),
        queryKeys,
        true
    );

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.DELETION_SUCCESS_1'), i18n.t('DocumentApi.DELETED_FOLDER_1'))));
            await queryClient.invalidateQueries(["folders", cId]);
        }
        return !!response.success
    });
    return [request, useDeleteMutation.isLoading];
}

export const useRestoreFolder = (folderId: string): [() => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId};

    const usePostMutation = usePut<{}>(
        URL_FOLDER_RESTORE(cId ?? "", folderId),
        queryKeys,
        true
    );

    const request = () => usePostMutation.mutateAsync({} as any).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.RESET_SUCCESSFUL_2'), i18n.t('DocumentApi.FOLDER_RESET_1'))));
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useGetCompanySkills = (): [ISkillResponse[], boolean] => {
    const { cId } = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.SKILLS);
    const query: ICustomQuery = {
        url: URL_COMPANY_SKILLS(cId!) + "?limit=1000",
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<ISkillResponse[]>(query);


    return [data?.data?.data ?? [], isFetching]
}

export const useGetCompanyUsers = (): [ISystemPerson[], boolean] => {
    const {cId} = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANY_USERS);
    const query: ICustomQuery = {
        url: URL_COMPANY_USERS(cId!) + "?limit=1000",
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<ISystemPerson[]>(query);


    return [data?.data?.data ?? [], isFetching]
}


export const useCloseDocument = (
    documentId: string
): [(request: IDocumentCloseRequest) => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId!]});

    const usePostMutation = usePut<IResponse<any>>(
        URL_DOCUMENT_CLOSE(documentId),
        queryKeys
    );

    const refetchDocument = useForceFetchQuery(AppQueryKey.DOCUMENTS, {extraKeys: [documentId!]});
    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);

    const refetchDocuments = useForceFetchQuery(AppQueryKey.DOCUMENTS);
    const refetchFolders = useForceFetchQuery(AppQueryKey.FOLDERS);
    const invalidateActionPlans = useInvalidateQuery(AppQueryKey.ACTION_PLANS);
    const invalidateNotifications = useInvalidateQuery(AppQueryKey.NOTIFICATIONS);
    const invalidateNotificationsCount = useInvalidateQuery(AppQueryKey.NOTIFICATIONS_COUNT);
    const invalidateUserDocuments = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: undefined
    });


    const request = (request: IDocumentCloseRequest) => usePostMutation.mutateAsync(request as any).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.DOCUMENT_READY_1'), i18n.t('DocumentApi.MARKED_AS_COMPLETE_1'))));
            await refetchDocument();
            await invalidateFolders();
            await invalidateUserDocuments();
        } else {
            if (response.data.status === HTTP_STATUS.UNPROCESSABLE_ENTITY_422 && response.data.errorResponse?.errors[ValidationErrorCodes.CHECKLIST.FORM_ACTION_USER]) {
                const forms: string[] = [];
                for (const error of response.data.errorResponse.errors[ValidationErrorCodes.CHECKLIST.FORM_ACTION_USER]) {
                    forms.push(error);
                }
                dispatch(addToast(toastError(i18n.t('DocumentApi.RESPONSIBLE_MISSING_IN_FORM_1'), `${i18n.t('DocumentApi.RESPONSIBLE_MISSING_IN_FORM_2')} ${forms.map(form => `[${form}]`).join(", ")} ${i18n.t('DocumentApi.RESPONSIBLE_MISSING_IN_FORM_3')}`)));
            } else {
                dispatch(addToast(toastError(i18n.t('DocumentApi.ERROR_OCCURRED_2'), i18n.t('DocumentApi.UNEXPECTED_ERROR_1'))));
            }
            await refetchDocument();
            await refetchDocuments();
            await refetchFolders();
            await invalidateActionPlans();
            await invalidateNotifications();
            await invalidateNotificationsCount();
            await invalidateUserDocuments();
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}


export const useMarkDocument = (docId?: string): [(important: boolean) => Promise<boolean>, boolean] => {
    const {cId, documentId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const documentIdToUpdate = docId ?? documentId;

    const queryKeys: ICustomQueryKeys = {root: "documents", cId: cId, extraKeys: [documentIdToUpdate!]};

    const usePostMutation = usePut<{ important: boolean }>(
        URL_DOCUMENT_IMPORTANT(cId!, documentIdToUpdate!),
        queryKeys,
        true
    );

    const invalidateUserDocuments = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: undefined
    });

    const request = (important: boolean) => usePostMutation.mutateAsync({important} as any).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.SELECTION_SUCCESSFUL_1'), i18n.t(important ? 'DocumentApi.DOCUMENT_WAS_MARKED' : 'DocumentApi.DOCUMENT_WAS_UNMARKED'))));
            await queryClient.refetchQueries(["documents", cId]);
            await queryClient.refetchQueries(["folders", cId]);
            await invalidateUserDocuments();
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useMarkDocumentHandled = (docId?: string): [(importantHandled: boolean) => Promise<boolean>, boolean] => {
    const {documentId} = useParams();
    const dispatch = useAppDispatch();

    const documentIdToUpdate = docId ?? documentId;

    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentIdToUpdate!]});

    const usePutMutation = usePut<{}>(
        URL_DOCUMENT_IMPORTANT_HANDLED(documentIdToUpdate ?? ""),
        queryKeys,
        true,
    );

    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);
    const invalidateUserDocuments = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: undefined
    });

    const request = (importantHandled: boolean) => usePutMutation.mutateAsync({importantHandled} as any).then(async (response) => {
        if (response.success) {
            const message = i18n.t(importantHandled ? 'DocumentApi.DOCUMENT_WAS_MARKED_AS_HANDLED' : 'DocumentApi.DOCUMENT_WAS_UNMARKED_AS_HANDLED');
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.DOCUMENT_MANAGED_1'), message)));
            await invalidateFolders();
            await invalidateUserDocuments();
        }
        return !!response.success;
    });
    return [request, usePutMutation.isLoading];
}

export const useUploadDocument = (): [((request: FormData) => Promise<IResponse<IDocumentUploadRequest>>), boolean] => {
    const {cId, cUId} = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENTS);

    const usePostMutation = usePost<FormData>(
        URL_DOCUMENT_UPLOAD(cId ?? ""),
        queryKeys,
        false
    );

    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);
    const invalidateUserFiles = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: [cUId ?? '']
    });
    const request = (request: FormData) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.UPLOAD_SUCCESS_2'), i18n.t('DocumentApi.FILE_UPLOADED_TO_FOLDER_1'))));
            await invalidateFolders();
            await invalidateUserFiles();
        } else {
            if ((response.data as any).status === 422) {
                dispatch(addToast(toastError(i18n.t('DocumentApi.TOO_LARGE_1'), i18n.t('DocumentApi.FILE_SIZE_LIMIT_1'))));
            }
        }
        return response;// as unknown as IResponse<IEntity>;
    }).catch(_ => {
        dispatch(addToast(toastError(i18n.t('DocumentApi.ERROR_OCCURRED_3'), i18n.t('DocumentApi.UNEXPECTED_ERROR_2'))));
        return {} as any;
    });
    return [request, usePostMutation.isLoading];
}

export const useViewDocument = (): [() => Promise<boolean>, boolean] => {
    const {cId, documentId} = useParams();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "viewedDocument", cId: cId, extraKeys: [documentId!]};

    const usePostMutation = usePut<void>(
        URL_COMPANY_USER_VIEW_DOCUMENT(cId!, documentId!),
        queryKeys,
        false
    );

    const request = () => usePostMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            await queryClient.refetchQueries(["lastDocuments", cId]);
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useCopyDocument = (
    documentId: string
): [((copyRequest: IDocumentCopyRequest) => Promise<IResponse<IDocumentCopyRequest>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.DOCUMENTS, {extraKeys: [documentId!]});
    const {cUId} = useParams();
    const useCopyMutation = usePost<IDocumentCopyRequest>(
        URL_DOCUMENT_COPY(documentId!),
        queryKeys,
        true
    );

    const invalidateFolders = useInvalidateQuery(AppQueryKey.FOLDERS);
    const invalidateUserFiles = useInvalidateQuery(AppQueryKey.USER_DOCUMENTS, {
        includeCompanyId: false,
        extraKeys: [cUId ?? '']
    });
    const request = (request: IDocumentCopyRequest) => useCopyMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('DocumentApi.COPY_CREATED_1'), i18n.t('DocumentApi.COPY_CREATED_2'))));
            await invalidateFolders();
            await invalidateUserFiles();
        }
        return response;
    });

    return [request, useCopyMutation.isLoading];
}

export const useApproveAll = (folderId: string): [() => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "folders", cId: cId};

    const usePostMutation = usePut<void>(
        URL_FOLDER_APPROVE_ALL_DOCUMENTS(folderId),
        queryKeys,
        false
    );

    const request = () => usePostMutation.mutateAsync().then(async (response) => {
        await queryClient.invalidateQueries(["documents", cId]);
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}
