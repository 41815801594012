import jwtDecode from "jwt-decode";

export const getDecodedTokenData = (token: string): IJWT | null => {
    try {
        return jwtDecode(token);
    } catch (error: any) {
        return null;
    }
}

export const isTokenValid = (decodedToken: IJWT) => {
    if (!decodedToken || !decodedToken.sub || !decodedToken.exp) return false;
    return decodedToken.exp * 1000 >= Date.now();
}

export const getTokens = (): ITokenResponse => {
    const token: string = localStorage.token;
    const refreshToken: string = localStorage.getItem("refreshToken") ?? "";
    return {token: token, refreshToken: refreshToken, expiresAt: ""};
}

export const setTokens = (token: string, refreshToken: string) => {
    if (!token.startsWith("Bearer ")) {
        token = "Bearer " + token;
    }
    setToken(token);
    setRefreshToken(refreshToken);
}

export const setToken = (token: string) => {
    localStorage.setItem("token", token);
}

export const setRefreshToken = (refreshToken: string) => {
    localStorage.setItem("refreshToken", refreshToken);
}

export const clearTokens = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("refreshToken", "");
};