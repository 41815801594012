import Container, { ContainerProps } from "react-bootstrap/esm/Container";

interface IContentContainer extends ContainerProps {
    theme?: "lightblue" | "darkblue" | "green" | "grey";
}

const ContentContainer: React.FC<IContentContainer> = ({
    theme,
    ...props }: IContentContainer) => {

    const classNames = () => {
        let className = "content";
        if (theme) {
            className += ` content-theme-${theme}`;
        }

        if (props.className) {
            className += ` ${props.className}`;
        }

        return className;
    }

    return <Container
        fluid
        as={props.as}
        className={classNames()}
        style={props.style}
        onClick={props.onClick}
    >
        {props.children}
    </Container>
}

export default ContentContainer;