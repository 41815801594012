import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CreateCompanyUser, useGetEmploymentTypes } from "../../api/CompanyApi";
import { useGetPositionLevels } from "../../api/PositionLevelApi";
import CustomMultiSelect from "../../components/FormComponents/CustomMultiSelect";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import { CompanyRole, EmploymentType } from "../../core/enums/enums";
import { capitalizeEachWord } from "../../core/helpers/strings/stringHelper";
import { toastSuccess } from "../../core/helpers/toastHelper";
import { createNewUser, validateEmail } from "../../core/helpers/userHelper";
import { IPerson } from "../../core/interfaces/common";
import { ICompanyInfo } from "../../core/interfaces/company";
import { ICreateUser } from "../../core/interfaces/user";
import { AppQueryKey, useInvalidateQuery } from "../../core/queryKeys";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useGetUser } from "../../hooks/useAuth";
import useUpdatableObject from "../../hooks/useUpdatableObject";
import { addToast } from "../../store/slices/uiSlice";

const Hr: React.FC<{ title: string, children: JSX.Element | JSX.Element[] }> = ({title, children}) => {
    return <><Row>
        <Col sm={1}>
            <b>{title}</b>
        </Col>
        <Col sm={11}>
            <hr/>
        </Col>
    </Row>
        <Row style={{marginLeft: 1}}>
            {children}
        </Row>
    </>

}

const employmentTypeMap: Record<EmploymentType, string> = {
    [EmploymentType.NOT_SPECIFIED]: 'NewCompanyUserModal.NOT_SPECIFIED_1',
    [EmploymentType.NOT_EMPLOYED]: 'NewCompanyUserModal.NOT_EMPLOYED_1',
    [EmploymentType.HIRED]: 'NewCompanyUserModal.HIRED_1',
    [EmploymentType.CONSULTANT]: 'NewCompanyUserModal.CONSULTANT_1',
    [EmploymentType.APPRENTICE]: 'NewCompanyUserModal.APPRENTICE_1',
    [EmploymentType.INTERNSHIP]: 'NewCompanyUserModal.WORK_EXPERIENCE_1',
    [EmploymentType.PROJECT_EMPLOYMENT]: 'NewCompanyUserModal.PROJECT_EMPLOYMENT_1',
    [EmploymentType.PROBATIONARY_EMPLOYMENT]: 'NewCompanyUserModal.TRIAL_EMPLOYMENT_1',
    [EmploymentType.SEASONAL_WORKER]: 'NewCompanyUserModal.SEASONAL_EMPLOYMENT_1',
    [EmploymentType.PERMANENT]: 'NewCompanyUserModal.UNTIL_FURTHER_NOTICE_1',
    [EmploymentType.HOURLY_EMPLOYEE]: 'NewCompanyUserModal.PART_TIME_EMPLOYEE_1',
    [EmploymentType.SUBSTITUTE]: 'NewCompanyUserModal.SUBSTITUTE_1',
    [EmploymentType.FIXED_TERM_EMPLOYMENT]: 'NewCompanyUserModal.FIXED_TERM_EMPLOYMENT_1'
};

const NewCompanyUserModal: React.FC<{ show: boolean, hide: () => void }> = ({show, hide}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const invalidateCompanyUsersExtended = useInvalidateQuery(AppQueryKey.COMPANY_USERS_EXTENDED);
    const user = useGetUser();
    const [checkCompanyRole] = useAccessCheck();
    const [employmentTypes] = useGetEmploymentTypes();
    const [updated, setUpdated, isEdited, submit, loading] = useUpdatableObject<ICreateUser, IPerson>(createNewUser(), (createUser) => {
        const cUser = _.cloneDeep(createUser) as ICompanyInfo;
        cUser.employmentType = employmentTypes.find(f => f.symbol === cUser.employmentType)?.id ?? employmentTypes.find(f => f.symbol === EmploymentType.NOT_SPECIFIED)?.id;
        // return Promise.reject();
        return CreateCompanyUser(cUser as any, user.companyId)
    });
    const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
    const [errors, setErrors] = useState({
        firstName: true,
        lastName: true,
        email: true
    });

    const [positionLevels] = useGetPositionLevels();

    const onChange = (e: { value: string, label: string }[]) => {
        setUpdated({
            ...updated,
            positionLevels: e.map(v => {
                return { id: v.value, name: v.label };
            })
        })
    };

    useEffect(() => {
        setIsExternalUser(false);
    }, [show]);


    useEffect(() => {
        setErrors({
            firstName: true,
            lastName: true,
            email: !isExternalUser,
        });
        setUpdated({
            ...createNewUser(),
            isExternal: isExternalUser
        });
    }, [show, setUpdated, isExternalUser])

    const validate = (user: ICreateUser, name: string) => {

        switch (name) {
            case 'firstName':
            case 'lastName':
                user[name] = capitalizeEachWord(user[name]);
                errors[name] = !user[name].length;
                break;
            case 'email':
                errors.email = !validateEmail(user.email);
                break;
        }

        setErrors(errors)
        return user;
    }

    const handleSubmit = () => {

        submit().then(async createdUser => {
            hide();
            dispatch(addToast(toastSuccess(t('NewCompanyUserModal.SUCCESS_1'), `${t('NewCompanyUserModal.USER_CREATED_1') + createdUser.name + t('NewCompanyUserModal.USER_CREATED_2')} '${user.companyName}'.`)));
            await invalidateCompanyUsersExtended();
        }).catch(_ => NaN);
    }

    const commonProps = {
        isEditMode: true,
        object: updated,
        setValue: setUpdated,
        onChangeMiddleWare: validate,
    }

    const commonPropsErros = {
        ...commonProps,
        errorObject: errors,
    }

    return (
        <ModalForm size={'lg'} show={show} hide={hide} title={t('NewCompanyUserModal.NEW_USER_1')} isEdited={isEdited}
                   submit={handleSubmit}
                   isLoading={loading} disableSubmit={!!Object.values(errors).filter(s => s).length}>
            <Row>
                <Col>
                    <Form.Check
                        checked={isExternalUser}
                        type="switch"
                        id="switchCreateUserWithoutEmail"
                        label={t('NewCompanyUserModal.EMAIL_REQUIRED_1')}
                        onChange={e => { }}
                        onClick={(e: any) => {
                            setIsExternalUser(e.target.checked);
                            setUpdated({...updated, isExternal: e.target.checked})
                        }}
                    />
                </Col>
            </Row>
            <p>{t('NewCompanyUserModal.MANDATORY_FIELDS_1')}</p>

            {isExternalUser ?
                <p className={"text-danger"}>{t('NewCompanyUserModal.DISCLAIMER_1')}</p> : <></>
            }

            <Hr title={t('NewCompanyUserModal.PERSONAL_INFORMATION_1')}>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formFirstName'} title={t('NewCompanyUserModal.REQUIRED_FIELD_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'firstName'} {...commonPropsErros}/>
                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formLastName'} title={t('NewCompanyUserModal.SURNAME_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'lastName'} {...commonPropsErros}/>
                        </FormBase>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        {!isExternalUser ?
                            <FormBase row={false} controlId={'formEmail'} title={t('NewCompanyUserModal.EMAIL_REQUIRED_2')}
                                      className={'pb-2'}>
                                <GenericInput type={'email'} name={'email'} {...commonPropsErros}/>
                            </FormBase> : <></>}
                    </Col>
                    <Col><span></span></Col>
                </Row>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formPhone'} title={t('NewCompanyUserModal.PHONE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'phone'} {...commonProps}  />
                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formPhone'} title={t('NewCompanyUserModal.MOBILE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'mobile'} {...commonProps}  />
                        </FormBase>
                    </Col>
                </Row>
            </Hr>
            <Hr title={t('NewCompanyUserModal.ADDRESS_1')}>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formStreet'} title={t('NewCompanyUserModal.HOME_ADDRESS_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'street'} {...commonProps}  />
                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formZipCode'} title={t('NewCompanyUserModal.ZIP_CODE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'zipcode'} {...commonProps}  />
                        </FormBase>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formAddress'} title={t('NewCompanyUserModal.LOCATION_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'city'} {...commonProps}  />

                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formPhone'} title={t('NewCompanyUserModal.PHONE_PRIVATE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'privatePhone'} {...commonProps}  />
                        </FormBase>
                    </Col>
                </Row>
            </Hr>

            <Hr title={t('NewCompanyUserModal.EMPLOYMENT_INFORMATION_1')}>
                <>
                    <Row>
                        <Col>
                            <FormBase row={false} controlId={'formPosition'} title={t('NewCompanyUserModal.POSITION_1')}
                                      className={'pb-2'}>
                                <GenericInput name={'position'} {...commonProps}  />
                            </FormBase>
                        </Col>
                        <Col>
                            <FormBase row={false} controlId={'formEmploymentNumber'} title={t('NewCompanyUserModal.EMPLOYMENT_NUMBER_1')}
                                      className={'pb-2'}>
                                <GenericInput name={'employmentNumber'} {...commonProps}  />
                            </FormBase>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <FormBase row={false} controlId={'formEmploymentType'} title={t('NewCompanyUserModal.TYPE_OF_EMPLOYMENT_1')}
                                      className={'pb-2'}>
                                <GenericInput type={'select'} name={'employmentType'} {...commonProps}>
                                    {employmentTypes.map((v, index) => <option key={index} defaultChecked={index === 0}
                                                                               value={v.symbol}>{t(employmentTypeMap[v.symbol])}</option>)}
                                </GenericInput>

                            </FormBase>
                        </Col>
                        <Col>
                            <FormBase row={false} controlId={'formPositionLevels'} title={t('NewCompanyUserModal.POSITION_LEVELS_1')}
                                className={'pb-2'}>
                                <CustomMultiSelect
                                    isEditMode={commonProps.isEditMode}
                                    name={"positionLevels"}
                                    selected={(positionLevels ?? []).filter(p => updated.positionLevels.some(pl => pl.id === p.id)).map(p => ({ value: p.id, label: p.name })) ?? []}
                                    options={(positionLevels ?? []).map(p => ({ value: p.id, label: p.name })) ?? []}
                                    onChange={onChange}
                                />
                            </FormBase>
                        </Col>
                    </Row>
                </>
            </Hr>
            <Hr title={t('NewCompanyUserModal.OTHER_1')}>
                <>
                    <FormBase row={false} controlId={'formRelatives'} title={t('NewCompanyUserModal.RELATIVES_1')}
                              className={'pb-2'}>
                        <GenericInput name={'relatives'} {...commonProps} type={'textarea'}/>
                    </FormBase>
                    <FormBase row={false} controlId={'formAdditionalInformation'} title={t('NewCompanyUserModal.ADDITIONAL_INFORMATION_1')}
                              className={'pb-2'}>
                        <GenericInput name={'additionalInformation'} {...commonProps} type={'textarea'}/>
                    </FormBase>
                </>
            </Hr>
            {!isExternalUser ?
                <Hr title={t('NewCompanyUserModal.ROLE_1')}>
                    <>
                        <FormBase row={false} controlId={'formRole'} title={t('NewCompanyUserModal.ROLE_2')}
                                  className={'pb-2'}>
                            <GenericInput type={'select'} name={'companyRole'} {...commonProps}>
                                <option value={CompanyRole.READER}>{t('NewCompanyUserModal.READER_1')}</option>
                                <option value={CompanyRole.EDITOR} disabled={!checkCompanyRole(CompanyRole.EDITOR)}>{t('NewCompanyUserModal.EDITOR_1')}</option>
                                <option value={CompanyRole.ADMIN} disabled={!checkCompanyRole(CompanyRole.ADMIN)}>{t('NewCompanyUserModal.ADMIN_1')}</option>
                            </GenericInput>
                        </FormBase>
                    </>
                </Hr>
                : <></>}
        </ModalForm>
    )
}

export default NewCompanyUserModal
