import { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TaskActionType, TaskEntityType } from "../../../../core/enums/enums";
import { differenceInDaysDate, formatRelativeDateFromToday } from "../../../../core/helpers/utils";
import { ITodo } from "../../../../core/interfaces/todo";
import styles from "./Todo.module.scss";

const Todo = ({todo}: { todo: ITodo; }) => {

    const {t} = useTranslation();
    const [todoExt, setTodoExt] = useState<ITodo>(todo);
    const [color, setColor] = useState<string | undefined>(undefined);

    const setInitTodo = (todo: ITodo) => {

        let newTodo = {...todo}

        switch (todo.type.toUpperCase()) {
            case TaskActionType.APPROVE_DOCUMENT :
                newTodo.typeText = t('Todo.APPROVE_DOCUMENT_1')
                newTodo.link = `documents/${newTodo.entityId}`
                break;
            case TaskActionType.READ_RECEIPT :
                newTodo.typeText = t('Todo.REQUEST_TO_READ_DOCUMENT_1')
                if (todo.entityType?.toUpperCase() === TaskEntityType.FILE) {
                    const folderKey = newTodo.referencedKeys.find(item => item.key === "folderId");
                    newTodo.link = `documents${folderKey?.value ? "#F_" + folderKey.value : ""}`

                } else {
                    newTodo.link = `documents/${newTodo.entityId}`
                }
                break;
            case TaskActionType.ACTIONPLAN_ACCIDENT :
                newTodo.typeText = t('Todo.ASSIGNED_RESPONSIBLE_FOR_ACCIDENT_1')
                newTodo.link = `action-plan`
                newTodo.stateId = todo.entityId
                break;
            case TaskActionType.ACTIONPLAN_DEVIATION :
                newTodo.typeText = t('Todo.ASSIGNED_RESPONSIBLE_FOR_DEVIATION_1')
                newTodo.link = `action-plan`
                newTodo.stateId = todo.entityId
                break;
            case TaskActionType.ACTIONPLAN_INCIDENT :
                newTodo.typeText = t('Todo.INCIDENT_RESPONSIBLE_1')
                newTodo.link = `action-plan`
                newTodo.stateId = todo.entityId
                break;
            case TaskActionType.ACTIONPLAN_STANDALONE :
                newTodo.typeText = t('Todo.ASSIGNED_RESPONSIBLE_FOR_ACTION_PLAN_1')
                newTodo.link = `action-plan`
                newTodo.stateId = todo.entityId
                break;
            default:
                break;
        }

        if (newTodo.createdAt) {
            setColor(colorByDays(newTodo.createdAt));
            newTodo.createdAt = formatRelativeDateFromToday(newTodo.createdAt)
        }

        setTodoExt(newTodo)
    }

    const colorByDays = (date: string) => {
        const diffIndays = differenceInDaysDate(date);

        if (diffIndays > 7) {
            return styles.todoColorRed;
        }
        if (diffIndays <= 7 && diffIndays > 3) {
            return styles.todoColorYellow;
        }
    }

    useEffect(() => {
        setInitTodo(todo)
    }, [todo]);


    return (
        <>
            <Link to={todoExt.link ?? ""}
                  state={todoExt.stateId ? {fromTodo: true, stateId: todoExt.stateId} : undefined}>
                <div className={"mb-2 dashboard-card-clickable"}>
                    <Card className={styles.card2}>
                        <Card.Header className={`${styles.header} ${color}`}>
                            <Card.Title className={"mb-0"}>{todoExt.typeText}</Card.Title>
                        </Card.Header>
                        <Card.Body className={styles.body}>
                            <Card.Text className={"mb-0"}>{todoExt.titleText}</Card.Text>
                            <Card.Text className={"fst-italic fw-light"}>{todoExt.createdAt}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Link>
        </>
    );
};

export default Todo;
