import { useEffect, useState } from "react";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCreateReadReceipt, useGetReadReceipt, useMarkReadReceipt } from "../../../api/DocumentApi";
import { URL_COMPANY_USERS } from "../../../api/endpoints/endpoints";
import { Icon } from "../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import CustomMultiSelect from "../../../components/FormComponents/CustomMultiSelect";
import When from "../../../components/When";
import { PublishStatusType } from "../../../core/enums/enums";
import { DateToYYYYMMDD } from "../../../core/helpers/dates/datesHelper";
import { toastInfo } from "../../../core/helpers/toastHelper";
import { IPerson } from "../../../core/interfaces/common";
import { IDocument, IDocumentReadReceipt } from "../../../core/interfaces/document";
import { useGetUser } from "../../../hooks/useAuth";
import { usePaginated } from "../../../hooks/usePaginated";
import { useAcceptModalContext } from "../../../providers/AcceptModalProvider";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";

const ReadReceiptModalBody = ({readReceipts}: { readReceipts: IDocumentReadReceipt[] }) => {
    const {t} = useTranslation();
    return (<>
            <When condition={readReceipts.length > 0} otherwise={t('ReadReceipt.NO_READ_RECEIPTS_1')}>
                {readReceipts.map(v => {
                    return <Row key={v.id}>
                        <Col>{v.user.name}</Col>
                        <Col>
                            <When condition={!!v.readAt} otherwise={t('ReadReceipt.NOT_READ_1')}>
                                {DateToYYYYMMDD(v.readAt)}
                            </When>
                        </Col>
                    </Row>
                })}
            </When>
        </>
    )
}

const CreateReadReceiptModalBody = ({currentReadReceipts, handleSelected}: {
    currentReadReceipts: IDocumentReadReceipt[],
    handleSelected: (user: { value: string, label: string }[]) => void
}) => {
    const {t} = useTranslation();
    const user = useGetUser();
    const [sendToAll, setSendToAll] = useState(false);
    const [companyUsers, isFetching] = usePaginated<IPerson>(URL_COMPANY_USERS(user.companyId));

    const getFilteredMapped = () => {
        return companyUsers.data.filter(v => !currentReadReceipts.find(s => !s.readAt && s.user.id === v.id)).filter(v => v.id !== user.companyUserId).map(p => ({
            value: p.id,
            label: p.name
        })) ?? [];
    }
    const handleIt = (value: boolean) => {
        handleSelected(value ? getFilteredMapped() : [])
        setSendToAll(value)
    }
    const Multi = () => (<CustomMultiSelect
        isEditMode={true}
        disabled={sendToAll}
        name={"involvedUsers"}
        options={getFilteredMapped()}
        selected={[]}
        onChange={handleSelected}
    />)

    if (isFetching) return null

    return (
        <>
            <When condition={sendToAll} otherwise={<Multi/>}>
                <Multi/>
            </When>

            <Row className={"p-1"}>
                <Col sm={2}>
                    <Form.Switch disabled={getFilteredMapped().length == 0}
                                 onChange={({target}) => handleIt(target.checked)}/>
                </Col>
                <Col>
                    <FormLabel>{t('ReadReceipt.SEND_TO_ALL_1')}</FormLabel>
                </Col>
            </Row>
        </>
    )
}

export const CreateReadReceipt = (
    {
        currentReadReceipts,
        disabled,
        documentId,
        isEditMode,
    }: {
        currentReadReceipts: IDocumentReadReceipt[],
        disabled: boolean,
        documentId: string,
        isEditMode: boolean,
    }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const acceptModalContext = useAcceptModalContext();

    const user = useGetUser();
    const [, isFetching] = usePaginated<IPerson>(URL_COMPANY_USERS(user.companyId));

    const [chosenUsers, setChosenUsers] = useState<string[]>([]);

    const [request, isLoading] = useCreateReadReceipt(documentId);
    const handleSelected = (user: { value: string, label: string }[]) => {
        setChosenUsers(user.map(v => v.value));
    }

    useEffect(() => {
        acceptModalContext.disableAcceptButton(chosenUsers.length === 0);
        acceptModalContext.setAcceptFunction(() => request(chosenUsers));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenUsers]);

    const handleRequestReadReceipt = () => {
        if (isEditMode) {
            dispatch(addToast(toastInfo(t('ReadReceipt.LEAVE_EDITING_MODE_1'), t('ReadReceipt.EXIT_EDITING_MODE_1'))));
            return;
        }
        acceptModalContext.open({
            header: t('ReadReceipt.REQUEST_READ_RECEIPT_1'),
            declineButtonText: t('ReadReceipt.CANCEL_1'),
            acceptButtonVariant: 'primary',
            acceptButtonText: t('ReadReceipt.SAVE_1'),
            disableAcceptButton: true,
            body: <CreateReadReceiptModalBody currentReadReceipts={currentReadReceipts}
                                              handleSelected={handleSelected}/>
        })
    }

    return <CustomDropdownItem
        icon={Icon.FILE_LINES}
        text={t('ReadReceipt.REQUEST_READ_RECEIPT_2')}
        disabled={disabled || isFetching || isLoading}
        onClick={handleRequestReadReceipt}
    />
}

export const MarkReadReceipt = (
    {
        hide,
        document,
        disableQuery
    }: { hide: boolean, document: IDocument, disableQuery?: boolean }) => {
    const {t} = useTranslation();
    const user = useGetUser();

    const [readReceipt, isFetchingReadReceipt] = useGetReadReceipt(document.id, !disableQuery);

    const [request, isFetching] = useMarkReadReceipt(document.id);
    return (
        <When
            condition={!hide && (document.publishStatus === PublishStatusType.APPROVED) && !!readReceipt.find(s => s.user.id === user.companyUserId && s.readAt == null)}>
            <CustomDropdownItem icon={Icon.EYE} text={t('ReadReceipt.MARK_AS_READ_1')}
                                disabled={isFetching || isFetchingReadReceipt}
                                onClick={() => request()}/>
        </When>
    )
}

export function ReadReceipt({document, isEditMode, disableQuery}: {
    document: IDocument,
    isEditMode: boolean,
    disableQuery?: boolean
}) {
    const {t} = useTranslation();
    const [data, isFetching] = useGetReadReceipt(document.id, !disableQuery);
    const acceptModalContext = useAcceptModalContext();
    //only admins check later kan requesta och visa
    const commonDisabled = isFetching || (document.publishStatus !== PublishStatusType.APPROVED);
    const invalidStatusReadReceipts = [PublishStatusType.CREATED, PublishStatusType.AWAITING_APPROVAL];
    return (<>
            <CustomDropdownItem
                icon={Icon.FILE_LINES}
                text={t('ReadReceipt.READ_RECEIPTS_1')}
                disabled={isFetching || invalidStatusReadReceipts.includes(document.publishStatus)}
                onClick={() => acceptModalContext.open({
                    header: t('ReadReceipt.READ_RECEIPTS_2'),
                    declineButtonText: t('ReadReceipt.CLOSE_1'),
                    size: "lg",
                    body: <ReadReceiptModalBody readReceipts={data}/>,
                    hideAcceptButton: true,
                })}
            />
            <CreateReadReceipt
                currentReadReceipts={data}
                documentId={document.id}
                disabled={document.archived || commonDisabled}
                isEditMode={isEditMode}
            />
        </>
    )
        ;
}
