import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import ContentContainer from "../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../components/ContainerComponents/HeaderContainer";
import MainContainer from "../components/ContainerComponents/MainContainer";
import When from "../components/When";
import { useAppSelector } from "../store/hooks";

const Support = () => {
    const {t} = useTranslation();
    const user = useAppSelector((state) => state.user);
    return <MainContainer>
        <HeaderContainer title={t('Support.SUPPORT_1')}/>
        <ContentContainer style={{minHeight: "400px"}}>
            <Row className="mb-2">
                <Col>
                    <strong>{t('Support.CONTACT_SUPPORT_1')}</strong>
                </Col>
            </Row>
            <When condition={user && user.authenticated} otherwise={
                <Row className="mb-2">
                    <Col><a href="mailto:Support@ccicon.se">Support@ccicon.se</a></Col>
                </Row>
            }>
                <Row className="mb-3">
                    <Col>
                        <strong>{t('Support.OPEN_WEEKDAYS_1')}</strong>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>{t('Support.PHONE_1')}</Col>
                </Row>
                <Row>
                    <Col><a href="mailto:Support@ccicon.se">Support@ccicon.se</a></Col>
                </Row>
            </When>
        </ContentContainer>
    </MainContainer>
}

export default Support;
