import CustomTextInput from "./FormComponents/CustomTextInput";
import getIcon, {Icon} from "../assets/Icons/IconClassNames";
import React from "react";
import ClickableIconButton from "./ClickableIconButton/ClickableIconButton";

interface ISearchBar {
    search: string;
    setSearch: (val: string) => void;
    placeholder: string;
    name?: string;
}

const SearchBar = (
    {
        search,
        setSearch,
        placeholder,
        name,
    }: ISearchBar) => {

    return <div style={{position: "relative", width: "100%"}}>
        <CustomTextInput
            name={name ?? "searchBar"}
            value={search}
            placeholder={placeholder}
            onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
            <div style={{position: "absolute", right: "5px", top: "3px"}}>
                <ClickableIconButton
                    disabled={false}
                    buttonType={"CANCEL"}
                    icon={getIcon(Icon.X_MARK)}
                    onClick={() => setSearch('')}
                />
            </div>
        )}
    </div>
};

export default SearchBar;