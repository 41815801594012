import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdatePositionLevel } from "../../api/PositionLevelApi";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import { IPositionLevel } from "../../core/interfaces/positionLevel";


const EditPositionLevelModal: React.FC<{
    show: boolean,
    hide: () => void,
    positionLevel: IPositionLevel
}> = ({
    show,
    hide,
    positionLevel
}) => {
    const {t} = useTranslation();

    const [update, isUpdating] = useUpdatePositionLevel(positionLevel?.id);
    const [updatedObject, setUpdatedObject] = useState({ ...positionLevel });

    const [errors, setErrors] = useState({
        name: true
    });

    const validate = (positionLevel: IPositionLevel, name: string) => {
        switch (name) {
            case 'name':
                errors.name = !positionLevel.name.length;
                break;
        }

        setErrors(errors);
        return positionLevel;
    }

    const commonProps = {
        isEditMode: true,
        object: updatedObject,
        errorObject: errors,
        setValue: setUpdatedObject,
        onChangeMiddleWare: validate,
    }

    useEffect(() => {
        setErrors({
            name: true
        });
        setUpdatedObject({
            ...positionLevel
        });
    }, [positionLevel])


    const handleSubmit = () => {
            const updated = _.cloneDeep(updatedObject) as IPositionLevel;
            update(updated).then(_ => hide());
        }

        return (
            <ModalForm
                show={show}
                hide={hide}
                title={t('EditPositionLevelModal.EDIT_POSITION_LEVELS_1')}
                isEdited={() => false}
                submit={handleSubmit}
                isLoading={isUpdating}
                disableSubmit={!!Object.values(errors).filter(s => s).length}>
                <FormBase row={false} controlId={'name'} title={t('EditPositionLevelModal.POSITION_LEVEL_1')}
                    className={'pb-2'}>
                    <GenericInput name={'name'} {...commonProps} />
                </FormBase>
            </ModalForm>
        );
    };

export default EditPositionLevelModal;