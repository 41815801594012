import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import React from "react";
import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../../assets/Icons/IconClassNames";
import When from "../../../../components/When";
import { NodeType } from "../../../../core/enums/enums";
import { ITemplateNodeData } from "../../../../core/interfaces/template";

interface ITemplatesDirectoryNode {
    node: NodeModel<ITemplateNodeData>;
    depth: number;
    isOpen: boolean;
    isSelected: boolean;
    onToggle: (id: NodeModel["id"]) => void;
    onClickNode: (treeNode: NodeModel<ITemplateNodeData>) => void;
    nodeTypeAllowedToSelect?: NodeType;
}

const TemplatesDirectoryNode = (
    {
        node,
        depth,
        isOpen,
        isSelected,
        onToggle,
        onClickNode,
        nodeTypeAllowedToSelect,
    }: ITemplatesDirectoryNode) => {

    const {t} = useTranslation();
    const dragOverProps = useDragOver(node.id, isOpen, onToggle);

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        onToggle(node.id);
    };

    const isFoldersAllowedToSelect = !nodeTypeAllowedToSelect ? true : NodeType.FOLDER === nodeTypeAllowedToSelect;
    const isDocumentsAllowedToSelect = !nodeTypeAllowedToSelect ? true : NodeType.DOCUMENT === nodeTypeAllowedToSelect;
    const voidFunction = () => NaN;

    return <div
        className={"tree-node root"}
        style={{
            paddingInlineStart: depth * 12,
            fontWeight: isSelected ? "bold" : "normal",
            border: isSelected ? "1px solid black" : "",
        }}
        {...dragOverProps}
    >
        <div
            className={`expandIconWrapper ${isOpen ? 'isOpen' : ''}`}
            onClick={handleToggle}
        >
            <When condition={NodeType.FOLDER === node.data?.nodeType}>
                <div>
                    <i className={getIcon(Icon.ARROW_RIGHT, IconSize.XS)}/>
                </div>
            </When>
        </div>
        <When condition={NodeType.FOLDER === node.data?.nodeType}>
            <div
                style={{opacity: isFoldersAllowedToSelect ? undefined : 0.7}}
                onClick={isFoldersAllowedToSelect ? () => onClickNode(node) : voidFunction}
            >
                <span className={getIcon(Icon.FOLDER, IconSize.XS)}/>
                <span> {node.text}</span>
                {isSelected && <span style={{position: "absolute", right: 0, marginRight: 40}}>{t('TemplatesDirectoryNode.SELECTED_FOLDER_1')}</span>}
            </div>
        </When>
        <When condition={NodeType.DOCUMENT === node.data?.nodeType}>
            <div
                style={{opacity: isDocumentsAllowedToSelect ? undefined : 0.7}}
                onClick={isDocumentsAllowedToSelect ? () => onClickNode(node) : voidFunction}
            >
                <span className={getIcon(Icon.FILE_LINES, IconSize.XS)}/>
                <span> {node.text}</span>
                {isSelected && <span style={{position: "absolute", right: 0, marginRight: 40}}>{t('TemplatesDirectoryNode.SELECTED_TEMPLATE_1')}</span>}
            </div>
        </When>
    </div>
};

export default TemplatesDirectoryNode;
