import { EditorThemeClasses } from "lexical";

const TextModuleTheme: EditorThemeClasses = {
    paragraph: 'editor-paragraph',
    heading: {
        h1: 'editor-heading-h1',
    },
    list: {
        listitem: "editor-list-item",
        nested: {
            listitem: "editor-nested-list-item"
        },
        olDepth: [
            "editor-ordered-list1",
        ],
        ul: "editor-unordered-list1",
    },
    text: {
        bold: "editor-text-bold",
        italic: "editor-text-italic",
        underline: "editor-text-underline",
        strikethrough: "editor-text-strikethrough",
    },
    table: "editor-table",
    tableCell: "editor-table-cell",
};

export default TextModuleTheme;