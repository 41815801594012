import { useTranslation } from "react-i18next";
import CustomTextAreaInput from "../../../components/FormComponents/CustomTextAreaInput";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";

export function RootCauseForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();

    return <FormProvider controlId={'formRootCause'} title={t('RootCauseForm.ROOT_CAUSE_1')} colAs={props.editMode ? 'large' : 'sm'}>
        <CustomTextAreaInput
            isEditMode={props.editMode}
            rows={3}
            name={"rootCause"}
            value={props.newIncident.rootCause ?? ''}
            onChange={props.onChange}
        />
    </FormProvider>
}
