import { useTranslation } from "react-i18next";
import CustomTextInput from "../../../components/FormComponents/CustomTextInput";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function LocationForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId="formLocation" title={t('LocationForm.LOCATION_FOR_EVENT_1')} >
            <CustomTextInput
                isEditMode={props.editMode}
                name={"location"}
                testId={'formLocation'}
                value={props.newIncident.location}
                onChange={props.onChange}
                required
                maxLength={50}
                disableAutocomplete
            />
    </FormProvider>
}
