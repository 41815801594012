import { useTranslation } from "react-i18next";
import CustomDate from "../../../components/FormComponents/CustomDate";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function IncidentDateForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId="formIncidentDate" title={t('IncidentDateForm.EVENT_DATE_1')}>

        <CustomDate
            isEditMode={props.editMode}
            type={"date"}
            name={"date"}
            testId={'formIncidentDate'}
            value={props.newIncident.date ?? Date.now()}
            onChange={props.onChange}
            required
        />
    </FormProvider>;
}
