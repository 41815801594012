import styles from './clickableIconButton.module.scss';
import {Spinner} from "react-bootstrap";

interface IClickableIconButton {
    disabled: boolean;
    buttonType: 'EDIT' | 'SAVE' | 'CANCEL';
    icon: string;
    onClick: () => void;
    isLoading?: boolean;
    title?: string;
}

const ClickableIconButton = (
    {
        disabled,
        buttonType,
        icon,
        onClick,
        isLoading,
        title,
    }: IClickableIconButton) => {

    const getStyles = () => {
        let additionalSyles;
        switch (buttonType) {
            case "EDIT":
                additionalSyles = !disabled ? styles.clickableIconEdit : "";
                break;
            case "SAVE":
                additionalSyles = !disabled ? styles.clickableIconSave : "";
                break;
            case "CANCEL":
                additionalSyles = !disabled ? styles.clickableIconCancel : "";
                break;
            default:
                additionalSyles = "";
                break;
        }
        return `${styles.clickableIcon} ${additionalSyles}`;
    };

    const renderLoadingSpinner = () => <div className={styles.loadingSpinner}>
        <Spinner
            as="span"
            size="sm"
            aria-hidden="true"
            animation="border"/>
    </div>


    const renderButton = () => (
        <div>
            <button disabled={disabled} type={"button"} title={title} className={`${getStyles()} pt-2`} onClick={onClick}>
                <span className={icon}/>
            </button>
        </div>
    )

    return isLoading ? (renderLoadingSpinner()) : (renderButton());
};

export default ClickableIconButton;