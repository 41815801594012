import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDownloadActionPlanDataRowFile } from "../../../../../../../api/ActionPlanApi";
import { useDownloadDataRowFile } from "../../../../../../../api/DocumentModuleChecklistApi";
import { Icon, IconSize, getIcon, getRegularIcon } from "../../../../../../../assets/Icons/IconClassNames";
import Map from "../../../../../../../components/Map";
import When from "../../../../../../../components/When";
import { IFile, INewFile } from "../../../../../../../core/interfaces/document";
import { AcceptModalState, useAcceptModalContext } from "../../../../../../../providers/AcceptModalProvider";
import { useDownloadUserSkillDataRowFile } from "../../../../../../../api/CompanyApi";


interface IFileListProps {
    files: IFile[];
    newFiles: INewFile[];
    removeFile: (index: number, isNewFile: boolean) => void;
    isEditMode: boolean;
    isAnswerMode?: boolean;
    isFromActionPlan?: boolean;
    actionPlanId?: string;
    userSkillId?: string;
    companyUserId?: string;
    companyId?: string;
}

const FileList: React.FC<IFileListProps> = (
    {
        files,
        newFiles,
        removeFile,
        isEditMode,
        isAnswerMode = false,
        isFromActionPlan,
        actionPlanId,
        userSkillId,
        companyUserId,
        companyId
    }) => {
    const {t} = useTranslation();
    const acceptModalContext = useAcceptModalContext();
    const {documentId} = useParams();
    const handleClick = () => {
        acceptModalContext.open({
            header: t('FileList.ATTACHED_FILES_1'),
            declineButtonText: t('FileList.CANCEL_1'),
            hideAcceptButton: true,
            body: <Files
                files={files}
                newFiles={newFiles}
                removeFile={removeFile}
                documentId={documentId!}
                isEditMode={isEditMode}
                isAnswerMode={isAnswerMode}
                isFromActionPlan={isFromActionPlan}
                actionPlanId={actionPlanId}
                userSkillId={userSkillId}
                companyUserId={companyUserId}
                companyId={companyId}
            />
        })
    }

    useEffect(() => {
        if (acceptModalContext.state === AcceptModalState.OPENED) {
            handleClick()
        }

        return () => {
            acceptModalContext.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, newFiles])
    return (
        <When condition={true}>
            <span className={"fileDownloadHover"} onClick={handleClick}>
                <i className={getIcon(Icon.FILE_LINES, IconSize.LARGE)}></i> ({files.length + newFiles.length})
            </span>
        </When>

    )
}

const NewFile = ({ file, removeFile, isEditMode, isAnswerMode }: { file: INewFile, removeFile: () => void, isEditMode: boolean, isAnswerMode: boolean }) => {
    return (
        <li>
            <span className={"fileDownloadHover"}>
                <i className={getRegularIcon(Icon.FILE_LINES)}></i>
                <span onClick={() => {
                    const base64String = file.file; // replace with your own Base64-encoded string
                    const binaryString = atob(base64String);
                    const bytes = new Uint8Array(binaryString.length);
                    for (let i = 0; i < binaryString.length; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes.buffer], {type: file.contentType});

                    const url = URL.createObjectURL(blob);
                    const newWindow = window.open(url, '_blank');
                    if (newWindow == null) return;
                    newWindow.addEventListener('beforeunload', () => {
                        URL.revokeObjectURL(url);
                    });
                }}> {file.name + ""}
            </span>
            </span>
            <i className={[getIcon(Icon.REMOVE, IconSize.XS), "fileDownloadHover", "px-1"].join(" ")}
                hidden={!isEditMode && !isAnswerMode}
               onClick={(e) => {
                   e.preventDefault();
                   e.stopPropagation();
                   removeFile();
               }}/>
        </li>
    )
}

const File = ({ file, documentId, removeFile, isEditMode, isAnswerMode, isFromActionPlan, actionPlanId, userSkillId, companyUserId, companyId }: {
    file: IFile,
    documentId: string,
    removeFile: () => void,
    isEditMode: boolean,
    isAnswerMode: boolean;
    isFromActionPlan?: boolean;
    actionPlanId?: string;
    userSkillId?: string;
    companyUserId?: string;
    companyId?: string;
}) => {

    const [actionPlanDownload, isActionPlanDownloading] = useDownloadActionPlanDataRowFile(file.id, actionPlanId ?? "");
    const [userSkillDownload, isUserSkillDownloading] = useDownloadUserSkillDataRowFile(file.id, userSkillId ?? '', companyUserId ?? '', companyId ?? '');
    const [download, isDownloading] = useDownloadDataRowFile(file.id, documentId);

    const handleDownload = () => {
        if (isFromActionPlan) {
            if (!isActionPlanDownloading) {
                actionPlanDownload();
            }
        } else if (userSkillId) {
            if (!isUserSkillDownloading)
                userSkillDownload();
        } else {
            if (!isDownloading) {
                download();
            }
        }
    };

    return (
        <li>
            <span className={"fileDownloadHover"}>
                <i className={getRegularIcon(Icon.FILE_LINES)}></i>
                <span onClick={handleDownload}> {file.name}</span>
            </span>
            <i className={[getIcon(Icon.REMOVE, IconSize.XS), "fileDownloadHover", "px-1"].join(" ")}
                hidden={!isEditMode && !isAnswerMode}
               onClick={(e) => {
                   e.preventDefault();
                   e.stopPropagation();
                   removeFile();
               }}/>
        </li>
    )
}
const Files: React.FC<IFileListProps & { documentId: string }> = (
    {
        files,
        newFiles,
        removeFile,
        documentId,
        isEditMode,
        isAnswerMode,
        isFromActionPlan,
        actionPlanId,
        userSkillId,
        companyUserId,
        companyId
    }) => {

    return (<>
        <ul style={{
                listStyle: 'none',
                overflow: 'hidden',
                marginTop: 10,
                paddingLeft: 1,
                marginBottom: 1
            }}>
                <Map
                    list={files}
                    outElement={(file, index) =>
                        <File
                            file={file}
                            documentId={documentId}
                            removeFile={() => removeFile(index, false)}
                            isEditMode={isEditMode}
                            isAnswerMode={isAnswerMode!}
                            isFromActionPlan={isFromActionPlan}
                            actionPlanId={actionPlanId}
                            userSkillId={userSkillId}
                            companyUserId={companyUserId}
                            companyId={companyId}
                        />}
                />

                <Map
                    list={newFiles}
                    outElement={(file, index) =>
                        <NewFile
                            file={file}
                            removeFile={() => removeFile(index, true)}
                            isEditMode={isEditMode}
                            isAnswerMode={isAnswerMode!}
                        />}
                />
            </ul>
        </>
    )
}


export default FileList
