import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { CreatePositionLevel } from "../../api/PositionLevelApi";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import { createNewPositionLevel } from "../../core/helpers/positionLevelHelper";
import { toastSuccess } from "../../core/helpers/toastHelper";
import { IPositionLevel, IPositionLevelResponse } from "../../core/interfaces/positionLevel";
import { AppQueryKey } from "../../core/queryKeys";
import { useGetUser } from '../../hooks/useAuth';
import useUpdatableObject from "../../hooks/useUpdatableObject";
import { addToast } from "../../store/slices/uiSlice";

const NewPositionLevelModal: React.FC<{ show: boolean, hide: () => void }> = ({ show, hide }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const useQuery = useQueryClient();
    const user = useGetUser();

    const [updated, setUpdated, isEdited, submit, loading] = useUpdatableObject<IPositionLevel, IPositionLevelResponse>(createNewPositionLevel(), (createPositionLevel) => {
        const positionLevel = _.cloneDeep(createPositionLevel) as IPositionLevel;
        return CreatePositionLevel(positionLevel, user.companyId);
    });

    const [errors, setErrors] = useState({
        name: true
    });


    useEffect(() => {
        setErrors({
            name: true
        });
        setUpdated({
            id: '',
            name: '',
        });
    }, [show, setUpdated])

    const validate = (positionLevel: IPositionLevel, name: string) => {

        switch (name) {
            case 'name':
                errors.name = !positionLevel.name.length;
                break;
        }

        setErrors(errors);
        return positionLevel;
    }

    const commonProps = {
        isEditMode: true,
        object: updated,
        errorObject: errors,
        setValue: setUpdated,
        onChangeMiddleWare: validate,
    }

    const handleSubmit = () => {
        submit().then(createdPositionLevel => {
            console.info('createdPositionLevel', createdPositionLevel);
            hide();
            useQuery.invalidateQueries([AppQueryKey.POSITION_LEVELS]);
            dispatch(addToast(toastSuccess(t('NewPositionLevelModal.SUCCESS_1'), `${t('NewPositionLevelModal.POSITION_LEVEL_1')} ${createdPositionLevel.name} ${t('NewPositionLevelModal.CREATED_1')}`)));
        });
    }

    return (
        <ModalForm show={show} hide={hide} title={t('NewPositionLevelModal.CREATE_POSITION_LEVEL_1')} isEdited={isEdited} submit={handleSubmit}
            isLoading={loading} disableSubmit={!!Object.values(errors).filter(s => s).length}>
            <FormBase row={false} controlId={'name'} title={t('NewPositionLevelModal.POSITION_LEVEL_2')}
                className={'pb-2'}>
                <GenericInput name={'name'} {...commonProps} />
            </FormBase>
        </ModalForm>
    );
};

export default NewPositionLevelModal;