import { useTranslation } from "react-i18next";
import CustomSelect from "../../../components/FormComponents/CustomSelect";
import { StatusCodeToName } from "../../../core/helpers/incidentHelper";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";

export function StatusForm(props: {newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={"formStatus"} title={t('StatusForm.STATUS_1')} colAs={'sm'}>
            <CustomSelect
                isEditMode={false}
                name={"status"}
                value={StatusCodeToName(props.newIncident.status)}
                options={[]}
                 onChange={props.onChange}
                required
            />
    </FormProvider>
}
