import { Spinner } from "react-bootstrap";

const DataTableLoadingSpinner = () => {
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '2rem',
        paddingBottom: '2rem'
    }}>
        <Spinner animation="grow" variant="secondary" />
    </div>
}

export default DataTableLoadingSpinner;