export enum Icon {
    CLIPBOARD = "fa-clipboard",
    INCIDENT = "fa-truck-medical fa-flip-horizontal",
    DEVIATION = "fa-code-branch",
    CUSTOMERS = "fa-users",
    CALENDAR = "fa-calendar-days",
    CHECK_CIRCLE = "fa-circle-check",
    WARNING = "fa-triangle-exclamation",
    LOG_OUT = "fa-right-from-bracket",
    SHUFFLE = "fa-shuffle",
    ADMIN_DASHBOARD = "fa-house-medical",
    BELL = "fa-bell",
    BARS = "fa-bars",
    X_MARK = "fa-xmark",
    PDF = "fa-file-pdf",
    EXCEL = "fa-file-excel",
    IMAGE = "fa-file-image",
    X = "fa-x",
    EYE = "fa-eye",
    EYE_SLASH = "fa-eye-slash",
    SAVE = "fa-floppy-disk",
    EDIT = "fa-pen-to-square",
    USER_PEN = "fa-user-pen",
    USER_SHIELD = "fa-user-shield",
    ARCHIVE = "fa-box-archive",
    REMOVE = "fa-trash",
    DOWNLOAD = "fa-file-arrow-down",
    UPLOAD = "fa-file-arrow-up",
    PLUS = "fa-plus",
    ARROW_RIGHT = "fa-caret-right",
    FOLDER = "fa-folder",
    FOLDER_CREATE = "fa-folder-plus",
    FOLDER_OPEN = "fa-folder-open",
    FILE_LINES = "fa-file-lines",
    GRIP_LINES = "fa-grip-lines",
    UNDO = "fa-arrow-rotate-left",
    REDO = "fa-arrow-rotate-right",
    LIST_BULLET = "fa-list-ul",
    LIST_NUMBER = "fa-list-ol",
    TEXT_FORMAT_BOLD = "fa-bold",
    TEXT_FORMAT_ITALIC = "fa-italic",
    TEXT_FORMAT_UNDERLINE = "fa-underline",
    TEXT_FORMAT_STRIKETHROUGH = "fa-strikethrough",
    FONT = "fa-font",
    CUBES = "fa-cubes",
    SORT = "fa-sort",
    CHEVRON_DOWN = "fa-chevron-down",
    CIRCLE_CHEVRON_DOWN = "fa-circle-chevron-down",
    CIRCLE_CHEVRON_UP = "fa-circle-chevron-up",
    COPY = "fa-copy",
    THREE_DOTS_HORIZONTAL = "fa-ellipsis",
    THREE_DOTS_VERTICAL = "fa-ellipsis-vertical",
    CIRCLE_PLUS = "fa-circle-plus",
    SQUARE_PLUS = "fa-square-plus",
    TABLE = "fa-table",
    EXCLAMATION = "fa-exclamation",
    INFO_CIRCLE = "fa-circle-info",
    LOCK = "fa-lock",
    CIRCLE = "fa-circle",
    SITEMAP = 'fa-sitemap',
    STAR = 'fa-star',
    RANKING_STAR = 'fa-ranking-star',

    DOCUMENT_EDIT = "fa-file-pen",
    CODE_COMPARE = "fa-code-compare",
    GEAR = "fa-gear",
    FILE_IMPORT = "fa-file-import",
    USER_CLOCK = "fa-user-clock"
}

export enum IconSize {
    LARGE = "fa-lg",
    XS = "fa-xs",
    SMALL = "fa-sm",
    XL = "fa-xl",
    DoubleXL = "fa-2xl",
    X1 = "fa-1x",
    X2 = "fa-2x",
    X4 = "fa-4x",
    X5 = "fa-5x",
}

/**
 * Render icon from Fontawesome
 * If color is missing, add the css class .color-XXX in _colors.scss
 *
 * @param icon Fontawesome icons
 * @param size Fontawesome icon-sizes
 * @param iconColor color-classes from _color.scss (color-green, color-red, etc)
 */
export const getIcon = (icon: Icon, size?: IconSize | string, iconColor?: string) => {
    let iconClassName: string;
    icon.startsWith("fab ") ? iconClassName = icon : iconClassName = "fas " + icon;
    iconClassName += size ? " " + size : "";
    iconClassName += iconColor ? " " + `color-${iconColor}` : "";
    return iconClassName;
}

export const getRegularIcon = (icon: Icon, size?: IconSize | string) => {
    let iconClassName = "far " + icon;
    iconClassName += size ? " " + size : "";
    return iconClassName;
}

export default getIcon;