import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { CreateSkill } from '../../api/SkillApi';
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import { createNewSkill } from "../../core/helpers/skillHelper";
import { toastSuccess } from "../../core/helpers/toastHelper";
import { ISkill, ISkillResponse } from '../../core/interfaces/skill';
import { AppQueryKey } from "../../core/queryKeys";
import { useGetUser } from '../../hooks/useAuth';
import useUpdatableObject from "../../hooks/useUpdatableObject";
import { addToast } from "../../store/slices/uiSlice";

const NewSkillModal: React.FC<{ show: boolean, hide: () => void }> = ({ show, hide }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const useQuery = useQueryClient();
    const user = useGetUser();

    const [updated, setUpdated, isEdited, submit, loading] = useUpdatableObject<ISkill, ISkillResponse>(createNewSkill(), (createSkill) => {
        const skill = _.cloneDeep(createSkill) as ISkill;
        return CreateSkill(skill, user.companyId);
    });

    const [errors, setErrors] = useState({
        name: true
    });


    useEffect(() => {
        setErrors({
            name: true
        });
        setUpdated({
            id: '',
            name: '',
        });
    }, [show, setUpdated])

    const validate = (skill: ISkill, name: string) => {

        switch (name) {
            case 'name':
                errors.name = !skill.name.length;
                break;
        }

        setErrors(errors);
        return skill;
    }

    const commonProps = {
        isEditMode: true,
        object: updated,
        errorObject: errors,
        setValue: setUpdated,
        onChangeMiddleWare: validate,
    }

    const handleSubmit = () => {
        submit().then(createdSkill => {
            console.info('createdSkill', createdSkill);
            hide();
            useQuery.invalidateQueries([AppQueryKey.SKILLS]);
            dispatch(addToast(toastSuccess(t('NewSkillModal.SUCCESS_1'), `${t('NewSkillModal.SKILL_1')} ${createdSkill.name} ${t('NewSkillModal.CREATED_1')}`)));
        });
    }

    return (
        <ModalForm show={show} hide={hide} title={t('NewSkillModal.CREATE_SKILL_1')} isEdited={isEdited} submit={handleSubmit}
            isLoading={loading} disableSubmit={!!Object.values(errors).filter(s => s).length}>
            <FormBase row={false} controlId={'name'} title={t('NewSkillModal.SKILL_2')}
                className={'pb-2'}>
                <GenericInput name={'name'} {...commonProps} />
            </FormBase>
        </ModalForm>
    );
};

export default NewSkillModal;