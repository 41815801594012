import jwtDecode from "jwt-decode";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ToastComponent from "./components/ToastComponent";
import AppRoutes from './core/AppRoutes';
import {useAppDispatch} from "./store/hooks";
import './styles/App.scss';
import BottomMenu from './views/Navbars/BottomMenu/BottomMenu';
import TopMenu from "./views/Navbars/TopMenu";
import {getUserDetails} from "./store/slices/authSlice";
import {getTokens} from "./core/auth/AuthToken";
import {getIncidentViewInfo} from "./store/slices/uiSlice";
import ReduxChangesModal from "./components/ReduxChangesModal";
import {AcceptModalProvider} from "./providers/AcceptModalProvider";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const App: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const CheckAuthentication = async () => {
        const authToken = localStorage.token;
        if (!authToken) {
            return;
        }

        try {
            const decodedToken: IJWT = jwtDecode(authToken);
            if (!decodedToken.exp) {
                localStorage.removeItem("token");
                return;
            }

            if (decodedToken.exp * 1000 < Date.now()) {
                // token expired
                localStorage.removeItem("token");
                const navigationState = {
                    state: {
                        fromExpiredToken: true,
                        from: {
                            pathname: location.pathname
                        },
                    }
                };
                navigate("/login", navigationState);
                return;
            }
            await dispatch(getIncidentViewInfo());
            // refresh state here...
            await dispatch(getUserDetails(getTokens()));
            navigate(location.pathname);

        } catch (error: any) {
            console.error(error);
            navigate("/");
            return;
        }
    };

    useEffect(() => {
        CheckAuthentication();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
            <AcceptModalProvider>
                <AppRoutes/>
                <ToastComponent/>
                <TopMenu/>
                <BottomMenu/>
                <ReduxChangesModal />
            </AcceptModalProvider>
        </>;
};

export default App;
