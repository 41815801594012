import { HTTP_STATUS } from "../core/enums/enums";
import { IResponse } from "../core/interfaces/common";
import AxiosClient from "./api";
import { URL_AUTHENTICATE } from "./endpoints/endpoints";

export const authenticate = async (loginRequest: IUserLoginRequest) => {
    return await AxiosClient.post<IResponse<ITokenResponse>>(URL_AUTHENTICATE, loginRequest)
        .then((response) => {
            return {data: response.data.data, status: response.status};
        }).catch((error: any) => {
            if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
                return {data: null, status: HTTP_STATUS.INTERNAL_SERVER_ERROR_500};
            }
            return {data: error.response?.data, status: error.response?.status};
        });
};