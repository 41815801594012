import { FormEvent, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import { useCreateUpdateCompanyLogo } from "../../api/CompanyApi";
import { getBase64 } from "../../core/helpers/fileHelper";
import { ICompany } from "../../core/interfaces/company";


interface IUploadCompanyLogo {
    setNewLogoPreview: (data: any) => void;
    company: ICompany;
}

const UploadCompanyLogo = (
    {
        company,
        setNewLogoPreview,
    }: IUploadCompanyLogo) => {
    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [newLogo, setNewLogo] = useState<FormData | null>(null);
    const [uploadLogo, isUploadingLogo] = useCreateUpdateCompanyLogo(company.id);

    const clearAll = () => {
        setNewLogo(null);
        setNewLogoPreview(null);
    }

    const setNewLogoFormData = (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("filename", file.name);
        setNewLogo(formData);
    }

    const handleNewLogo = async (e: FormEvent<HTMLInputElement>) => {
        const file = e?.currentTarget?.files?.item(0);
        if (file) {
            setNewLogoFormData(file);
            getBase64(file).then((data: any) => {
                setNewLogoPreview(data);
            });
        }
    }

    const handleUploadLogo = async () => {
        if (!newLogo) return;
        await uploadLogo(newLogo);
        clearAll();
    }

    return <div className={"d-flex justify-content-between"}>
        <input
            ref={inputRef}
            onInputCapture={handleNewLogo}
            type={"file"}
            hidden
            accept={"image/jpeg, image/png, image/gif"}
            onClick={(e: any) => (e.target.value = null)}
        />
        <Button onClick={() => inputRef?.current?.click()}>{t('UploadCompanyLogo.SELECT_NEW_LOGO')}</Button>
        <Button disabled={!newLogo} onClick={handleUploadLogo}>{t('UploadCompanyLogo.UPLOAD_1')}</Button>
    </div>
};

export default UploadCompanyLogo;
