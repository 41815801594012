import _ from "lodash";
import { createRef, forwardRef, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextModule from "../../../components/Modules/LexicalPlugins/TextModule/TextModule";
import { ModuleType } from "../../../core/enums/enums";
import { IDocumentModule, } from "../../../core/interfaces/document";
import ChecklistModule from "./ChecklistModule/ChecklistModule";
import { useDocumentModulesContext } from "./DocumentModules";
import DocumentModulesActionButtons from "./DocumentModulesActionButtons";


interface IRenderDocumentModules {
}

const RenderDocumentModules = forwardRef(({}: IRenderDocumentModules, ref) => {
    const {
        modules,
        activeModule,
        updateActiveModule,
        isAnswerMode,
        isEditMode,
        setDocumentModuleTextHtmlString,
    } = useDocumentModulesContext();

    const {t} = useTranslation();
    const refs = useMemo(() => Array.from({length: modules.length}).map(() => createRef<any>()), [modules.length]);
    useImperativeHandle(ref, () => ({
        triggerInit(index: number) {
            if (!refs[index].current) return;
            refs[index].current.initializeContent();
        }
    }));

    const handleUpdateTextModule = (content: string) => {
        if (activeModule == null) return;
        setDocumentModuleTextHtmlString(content);
    }

    const handleUpdateTextModuleJSONData = (contentJSON: string) => {
        if (activeModule == null) return;
        const newModule: IDocumentModule = _.cloneDeep(activeModule);
        if (!newModule.documentModuleText) return;
        newModule.documentModuleText.contentJSON = contentJSON;
        updateActiveModule(newModule);
    }

    const renderModule = (module: IDocumentModule, index: number) => {
        switch (module.documentModuleType) {
            case ModuleType.TEXT:
                return <div className={"mb-4"}>
                    <TextModule
                        key={index}
                        ref={refs[index]}
                        isEditable={isEditMode && activeModule !== null && activeModule.id === module.id}
                        content={module.documentModuleText?.content}
                        contentJSON={module.documentModuleText?.contentJSON}
                        updateData={handleUpdateTextModule}
                        updateJSONData={handleUpdateTextModuleJSONData}/>
                </div>
            case ModuleType.CHECKLIST:
                return <div className={"mb-4"}>
                    <ChecklistModule
                        key={index}
                        isActiveModule={module.id === activeModule?.id}
                        module={module.id === activeModule?.id ? activeModule : module}
                    />
                </div>
            default:
                return <>{t('RenderDocumentModules.MODULE_NOT_HANDLED_1')}</>
        }
    }

    return <>
        {(modules && modules).filter(module => isAnswerMode ? module.id === activeModule?.id : true).map((module, index) => (
            <DocumentModulesActionButtons
                key={index}
                index={index}
                isActiveModule={module.id === activeModule?.id}
                module={module}
            >
                {renderModule(module, index)}
            </DocumentModulesActionButtons>
        ))}</>
});

export default RenderDocumentModules;
