import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../../assets/Icons/IconClassNames";
import { useChecklistContext } from "./ChecklistContext";
import styles from "./checklistModule.module.scss";


interface IChecklistModuleActionButtons {
    moduleId: string;
}

const ChecklistModuleActionButtons = ({moduleId}: IChecklistModuleActionButtons) => {

    const { t } = useTranslation();
    const { addForm } = useChecklistContext();

    return <>
        <div className={styles.checklistModuleActionButtons}>
            <i className={`cursor-pointer pe-2 ${getIcon(Icon.CIRCLE_PLUS, IconSize.X2)}`}
               onClick={() => addForm({
                   title: "",
                   description: "",
                   isFulfilled: false,
                   sequenceNo: 0,
                   id: "",
                   isEdited: false,
                   documentModuleChecklistFormDataRows: [],
                   sendToActionPlan: false,
               })}
               title={t('ChecklistModuleActionButtons.ADD_FORM_1')}
            />
        </div>
    </>
};

export default ChecklistModuleActionButtons;
