import { useTranslation } from "react-i18next";
import When from "../../../components/When";
import { DateToYYYYMMDD } from "../../../core/helpers/dates/datesHelper";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";

export function ReportedDateForm(props: { newIncident: IIncident, isNewIncident: boolean }) {
    const {t} = useTranslation();
    return <FormProvider controlId="formReportedDate" title={t('ReportedDateForm.REPORTED_DATE_1')}>
            <span className={'col-form-label'}>
                <When condition={props.isNewIncident} otherwise={DateToYYYYMMDD(props.newIncident.createdAt)}>
                    {DateToYYYYMMDD(new Date())}
                </When>
            </span>
    </FormProvider>
}
