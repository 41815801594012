import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import SkillTable from "./SkillTable";

const Skills = () => {
    const { t } = useTranslation();
    return (
        <MainContainer>
            <HeaderContainer title={t('Skills.SKILLS_1')}/>
            <Row>
                <SkillTable/>
            </Row>
        </MainContainer>
    );
};

export default Skills;