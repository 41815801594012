import React, {useContext, useEffect, useState} from "react";
import {AcceptModalState} from "./enums/AcceptModalState";
import {AcceptModal} from "../../components/AcceptModal";
import {ButtonVariant} from "react-bootstrap/types";

export interface IAcceptModalUi {
    header?: string | JSX.Element,
    body?: string | JSX.Element,
    acceptButtonText?: string | JSX.Element,
    declineButtonText?: string | JSX.Element,
    hideAcceptButton?: boolean,
    acceptButtonVariant?: ButtonVariant,
    disableAcceptButton?: boolean,
    size?: 'sm' | 'lg' | 'xl',


}
const _useAcceptModalStates = () => {
    const [state, setState] = useState<AcceptModalState>(AcceptModalState.IDLE);
    const [acceptButtonDisabled, setAcceptButtonDisabled] = useState(false);
    const [acceptModalUI, setAcceptModalUI] = useState<IAcceptModalUi>();
    const [acceptFunction, setAcceptFunction] = useState<() => void>(() => NaN);

    useEffect(() => {
        switch (state) {
            // @ts-ignore
            case AcceptModalState.ACCEPTED:
                acceptFunction();
            // eslint-disable-next-line no-fallthrough
            case AcceptModalState.CANCELLED:
                setState(AcceptModalState.IDLE);
                setAcceptFunction(() => NaN);
                setTimeout(() => setAcceptModalUI(undefined), 200);
                break;
        }
        // eslint-disable-next-line
    }, [state])

    return {
        modalState: state,
        setModalState: setState,
        setAcceptFunction: setAcceptFunction,
        acceptModalUI: acceptModalUI,
        acceptButtonDisabled: acceptButtonDisabled,
        setAcceptButtonDisabled: setAcceptButtonDisabled,
        setAcceptModalUI: setAcceptModalUI
    };

}


interface IAcceptModalContext {
    trigger: (acceptFunction: () => void, acceptModalUi?: IAcceptModalUi) => void,
    open: (acceptModalUi?: IAcceptModalUi) => void,
    setAcceptFunction: (func: () => void) => void,
    disableAcceptButton: (value: boolean) => void,
    close: () => void,
    state: AcceptModalState,

}

const AcceptModalContext = React.createContext<IAcceptModalContext>({
    trigger: (_) => NaN,
    open: (_) => NaN,
    setAcceptFunction: () => NaN,
    disableAcceptButton: (_: boolean) => NaN,
    close: () => NaN,
    state: AcceptModalState.ACCEPTED
});

export const useAcceptModalContext = () => useContext<IAcceptModalContext>(AcceptModalContext);

interface IAcceptModalProviderProps {
    children: JSX.Element[] | JSX.Element;
}

export const AcceptModalProvider: React.FC<IAcceptModalProviderProps> = ({children}) => {
    const acceptModalStates = _useAcceptModalStates();


    return <AcceptModalContext.Provider value={{
        trigger: (acceptFunction, acceptModalUi) => {
            if(acceptModalUi) {
                acceptModalStates.setAcceptModalUI(acceptModalUi);
            }
            acceptModalStates.setAcceptFunction(() => acceptFunction);
            acceptModalStates.setAcceptButtonDisabled(!!acceptModalUi?.disableAcceptButton);
            acceptModalStates.setModalState(AcceptModalState.OPENED);
        },
        open: ( acceptModalUi) => {
            if(acceptModalUi) {
                acceptModalStates.setAcceptModalUI(acceptModalUi);
            }
            acceptModalStates.setAcceptButtonDisabled(!!acceptModalUi?.disableAcceptButton);
            acceptModalStates.setModalState(AcceptModalState.OPENED);
        },
        close: () => acceptModalStates.setModalState(AcceptModalState.CANCELLED),
        setAcceptFunction: (func) => acceptModalStates.setAcceptFunction(() => func),
        disableAcceptButton: acceptModalStates.setAcceptButtonDisabled,
        state: acceptModalStates.modalState
    }
    }>
        {children}
        <AcceptModal state={acceptModalStates.modalState} setModalState={acceptModalStates.setModalState} modalUi={acceptModalStates.acceptModalUI} disableAcceptButton={acceptModalStates.acceptButtonDisabled}/>
    </AcceptModalContext.Provider>;
}