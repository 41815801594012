import { AxiosError } from "axios";
import AxiosClient from "./api";

export const get = async <T>(url: string): Promise<{ data: T, status: number }> => {
    return await AxiosClient.get<T>(url).then((response) => {
        return {data: response.data, status: response.status};
    }).catch((error: AxiosError) => {
        return {data: {} as T, status: error.response?.status ?? 500};
    });
}

// export default {get}