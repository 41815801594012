import Container, { ContainerProps } from "react-bootstrap/esm/Container";

interface IMainContainer extends ContainerProps {

}

const MainContainer: React.FC<IMainContainer> = ({
    ...props }) => {

    return <Container
        fluid
        className={props.className}
    >
        {props.children}
    </Container>
}

export default MainContainer;