import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { DataRowType } from "../../../core/enums/enums";
import {
    IDataRow,
    IDataRowAssessmentScopeSeverityField,
    IDataRowNumericField,
    IDataRowRiskAssessmentField,
    IDataRowSignatureField,
    IDataRowTextField
} from "../../../core/interfaces/document";


const GetDataRow = ({dataRow}: { dataRow: IDataRow }) => {
    const { t } = useTranslation();

    let d;
    switch (dataRow.checklistFormDataRowTypeSymbol) {
        case DataRowType.TEXT_FIELD:
            return (<p>{(dataRow as IDataRowTextField).textValue}</p>)
        case DataRowType.SIGNATURE_FIELD:
            return (<p>{(dataRow as IDataRowSignatureField).signatureName}</p>)
        case DataRowType.RISK_ASSESSMENT_FIELD:
            d = dataRow as IDataRowRiskAssessmentField
            return (<>
                <Col>{d.riskAssessmentConsequence ?? t('DataRow.NOT_SELECTED_1')}</Col>
                <Col>{d.riskAssessmentProbability ?? t('DataRow.NOT_SELECTED_1')}</Col>
            </>)
        case DataRowType.NUMERIC_FIELD:
            d = dataRow as IDataRowNumericField
            return (<p>{d.numValue ?? "-"}</p>)
        case DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD:
            d = dataRow as IDataRowAssessmentScopeSeverityField
            return (<>
                <Col>{d.assessmentScope ?? t('DataRow.NOT_SELECTED_1')}</Col>
                <Col>{d.assessmentSeverity ?? t('DataRow.NOT_SELECTED_1')}</Col>
            </>)
    }

    return <p></p>
}
const DataRow = ({dataRow}: { dataRow: IDataRow }) => {
    return <Row className={"mt-3 mb-3 ms-2 me-2"}>
        <Col md={5}>
            <b><p>{dataRow.header}</p></b>
        </Col>
        <Col md={7}>
            <Row>
                <GetDataRow dataRow={dataRow}/>
            </Row>
        </Col>
    </Row>
}

export default DataRow