import {
    DndProvider,
    DropOptions,
    getBackendOptions,
    MultiBackend,
    NodeModel,
    Tree,
    TreeMethods
} from "@minoru/react-dnd-treeview";
import { useRef } from "react";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import { useMoveDocument, useMoveFolder } from "../../api/DocumentDirectory";
import When from "../../components/When";
import { CompanyRole } from "../../core/enums/enums";
import { isUndefined } from "../../core/helpers/utils";
import { IDocumentNodeModel, IFolderNodeModel } from "../../core/interfaces/directory";
import useAccessCheck from "../../hooks/useAccessCheck";
import DirectoryNode from "./Components/DirectoryNode";
import { isDeleted, isFolder, isPublished, isTrashbin } from "./Helpers/DirectoryHelper";


interface IDirectory {
    data: NodeModel<IFolderNodeModel | IDocumentNodeModel>[];
    selectedFolder: NodeModel<IFolderNodeModel> | null;
    onClickNode: (selectedNode: NodeModel<IFolderNodeModel | IDocumentNodeModel>) => void;
    isDragAndDropEnabled: boolean;
    setDragAndDropEnabled: (newVal: boolean) => void;
    expandedFolders: string[];
    handleExpandedFolders: (id: string) => void;
    handleExpandedFoldersToggle: (id: string) => void;
    handleCloseAll: () => void;
}

const Directory = (
    {
        data,
        selectedFolder,
        onClickNode,
        isDragAndDropEnabled,
        setDragAndDropEnabled,
        expandedFolders,
        handleExpandedFolders,
        handleExpandedFoldersToggle,
        handleCloseAll,
    }: IDirectory) => {
    const {t} = useTranslation();
    const ref = useRef<TreeMethods>(null);

    const [moveFolder, isMovingFolder] = useMoveFolder();
    const [moveDocument, isMovingDocument] = useMoveDocument();
    const [checkCompanyRole] = useAccessCheck();

    const handleDrop = async (
        newTreeData: NodeModel<IFolderNodeModel | IDocumentNodeModel>[],
        {dragSourceId, dropTargetId}: DropOptions<IFolderNodeModel | IDocumentNodeModel>
    ) => {
        handleExpandedFolders("" + dropTargetId);
        const nodesInParent = newTreeData.filter(d => d.parent === dropTargetId);
        const parentNode = newTreeData.find(d => d.id === dropTargetId);
        let newSequenceNumber = null;
        let node = null;
        nodesInParent.forEach((v, i) => {
            if (v.id === dragSourceId) {
                newSequenceNumber = ++i;
                node = v;
            }
        })
        if (node == null) return;
        if (isFolder(node)) {
            await moveFolder({
                newParentId: parentNode?.data?.id.toString() ?? null,
                folderId: (node as NodeModel<IFolderNodeModel>).data?.id.toString() ?? "",
                sequenceNumber: newSequenceNumber ?? -1,
            })
        } else {
            await moveDocument({
                newParentId: parentNode?.data?.id.toString() ?? null,
                documentId: (node as NodeModel<IFolderNodeModel>).data?.id.toString() ?? "",
                sequenceNumber: newSequenceNumber ?? -1,
            })
        }
    }

    return <>
        <When condition={checkCompanyRole(CompanyRole.ADMIN)}>
            <Button onClick={() => setDragAndDropEnabled(!isDragAndDropEnabled)}>
                <When condition={isDragAndDropEnabled} then={<span>{t('Directory.END_SORTING_1')}</span>}
                      otherwise={<span>{t('Directory.SORT_1')}</span>}/>
            </Button>
        </When>
        <Button onClick={handleCloseAll} className={"m-2"}>{t('Directory.MINIMIZE_ALL_1')}</Button>

        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <Tree
                classes={{
                    root: "treeRoot",
                    draggingSource: "draggingSource",
                    dropTarget: "dropTarget",
                    placeholder: "placeholderContainer"
                }}
                tree={data}
                ref={ref}
                rootId={0}
                sort={false}
                insertDroppableFirst={false}
                dropTargetOffset={5}
                placeholderRender={(node, {depth}) => (
                    <div className="placeholderLine" style={{left: depth * 12}}></div>
                )}
                canDrag={(node) => !isUndefined(node) && !isTrashbin(node!) && (isFolder(node!) || isPublished(node!)) && isDragAndDropEnabled && !(isMovingFolder || isMovingDocument) && checkCompanyRole(CompanyRole.ADMIN)}
                canDrop={(tree, {dragSource, dropTargetId}) => {
                    if (isDragAndDropEnabled && dragSource?.parent === dropTargetId) {
                        return true;
                    }
                }}
                render={(
                    node: NodeModel<IFolderNodeModel | IDocumentNodeModel>,
                    {
                        depth,
                        isOpen,
                        onToggle
                    }) => (
                    <When condition={isFolder(node) || !isDeleted(node)}>
                        <DirectoryNode
                            node={node}
                            depth={depth}
                            isOpen={isOpen}
                            isSelected={node.id === selectedFolder?.id}
                            onToggle={onToggle}
                            onClickNode={onClickNode}
                            handleExpandedFoldersToggle={handleExpandedFoldersToggle}
                        />
                    </When>
                )}
                onDrop={handleDrop}
                initialOpen={expandedFolders}
            />
        </DndProvider>
    </>
};

export default Directory;
