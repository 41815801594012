import { useTranslation } from "react-i18next";
import CustomTextAreaInput from "../../../components/FormComponents/CustomTextAreaInput";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function FollowUpForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={"formFollowUp"} title={t('FollowUpForm.FOLLOW_UP_1')} colAs={props.editMode ? 'large' : 'sm'}>
        <CustomTextAreaInput
            isEditMode={props.editMode}
            rows={5}
            name={"followUp"}
            value={props.newIncident.followUp ?? ''}
            onChange={props.onChange}
        />
    </FormProvider>
}
