import i18n from "i18next";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { Icon, IconSize } from "../../../../../../assets/Icons/IconClassNames";
import { CustomDropdownItem } from "../../../../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import DragAndDropComponent from "../../../../../../components/DragAndDropComponent/DragAndDropComponent";
import DropDownIconToggle from "../../../../../../components/DropDownIconToggle";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { DataRowMultiChoiceType, DataRowType } from "../../../../../../core/enums/enums";
import { IDataRow, IDocumentModuleChecklistForm } from "../../../../../../core/interfaces/document";
import { useChecklistContext } from "../../ChecklistContext";
import { ChecklistFormContextProvider } from "../ChecklistFormContext";
import ChecklistCanvas from "../Components/ChecklistCanvas";
import AddMultiChoice from "../Forms/AddMultiChoice";
import AddNumericDataRow from "../Forms/AddNumericDataRow";
import styles from "../checklistTable.module.scss";
import CheckListRow from "./CheckListRow";
import {
    DataRowTypeIcons,
    DataRowTypeNameTranslation,
    PossibleDataRows,
    dataRowTypeToGeneratorMap
} from "./DataRowGeneration";
import HeaderDivs from "./HeaderDivs";
import StickyDiv from "./StickyDiv";



const TableDropdown = ({relatedForms, dataRowLength}: { relatedForms: string[], dataRowLength: number }) => {
    const {t} = useTranslation();
    const {addDataRowToForms, documentContext: {isEditMode}} = useChecklistContext();

    const [showMultiChoiceCanvas, setShowMultiChoiceCanvas] = useState(false);
    const [showNumericDataRowCanvas, setShowNumericDataRowCanvas] = useState(false);
    const addMiddleWare = (dataRow: IDataRow, ids: string[]) => {
        if (!showMultiChoiceCanvas && !showNumericDataRowCanvas) {
            if (dataRow.checklistFormDataRowTypeSymbol === DataRowType.NUMERIC_FIELD) return setShowNumericDataRowCanvas(true);
            if (dataRow.checklistFormDataRowTypeSymbol === DataRowType.MULTI_CHOICE_FIELD) return setShowMultiChoiceCanvas(true);
        }
        setShowMultiChoiceCanvas(false);
        setShowNumericDataRowCanvas(false);
        return addDataRowToForms(dataRow, ids);
    }

    return (
        <When condition={isEditMode}>
            <Dropdown style={{position: 'absolute', right: "2px", top: dataRowLength > 0 ? "-16px" : "16px"}}
                      drop={'start'}>
                <DropDownIconToggle icon={Icon.SQUARE_PLUS} iconSize={IconSize.XL} title={t('TableView.ADD_COLUMN_1')}/>
                <DropdownMenu>
                    <Container fluid style={{zIndex: 999}}>
                        {PossibleDataRows.map((value) => <CustomDropdownItem
                            key={value}
                            icon={DataRowTypeIcons[value]}
                            text={t(DataRowTypeNameTranslation[value])}
                            onClick={() => addMiddleWare(dataRowTypeToGeneratorMap[value](), relatedForms)}
                        />)}

                    </Container>
                </DropdownMenu>
            </Dropdown>

            <ChecklistCanvas showCanvas={showMultiChoiceCanvas} setShowCanvas={setShowMultiChoiceCanvas}
                             title={t('TableView.MULTIPLE_CHOICE_OPTIONS_1')}>
                <AddMultiChoice
                    multiChoiceType={DataRowMultiChoiceType.CHECKLIST}
                    onAdd={(dataRow) => addMiddleWare(dataRow, relatedForms)}
                    onCancel={() => setShowMultiChoiceCanvas(false)}
                />
            </ChecklistCanvas>

            <ChecklistCanvas showCanvas={showNumericDataRowCanvas} setShowCanvas={setShowNumericDataRowCanvas}
                             title={t('TableView.NUMERICAL_FIELD_1')}>
                <AddNumericDataRow onAdd={(dataRow) => addMiddleWare(dataRow, relatedForms)}
                                   onCancel={() => setShowNumericDataRowCanvas(false)}/>
            </ChecklistCanvas>
        </When>)
}

const StickyHeaderDiv = (
    {
        width,
        form,
        ids,
        index,
        isHideHeaderColumnTableView
    }: {
        form: IDocumentModuleChecklistForm,
        width: DataRowWidth,
        ids: string[],
        index: number,
        isHideHeaderColumnTableView?: boolean
    }) => {
    const {changeTitleOnForms, documentContext: {isEditMode}} = useChecklistContext();

    const idleTimeoutRef = useRef<any>(null);
    const [internalTitle, setInternalTitle] = useState(form.title);

    useEffect(() => {
        setInternalTitle(form.title);
    }, [form.title]);

    const delayedUpdateTitle = () => {
        clearTimeout(idleTimeoutRef.current);
        idleTimeoutRef.current = setTimeout(() => {
            return changeTitleOnForms(internalTitle, ids);
        }, 500);
    };
    useEffect(() => {
        delayedUpdateTitle();
    }, [internalTitle]);

    return (
        <StickyDiv index={index}>
            <div className={'d-flex'} style={{backgroundColor: "#e9f1ff"}}>
                <div className={styles.tableHeader}
                     style={{minHeight: 40, width: isHideHeaderColumnTableView ? 0 : 250}}>
                    {!isHideHeaderColumnTableView && (
                        <CustomTextInput
                            checklistTableView={true}
                            style={{fontWeight: "bold", backgroundColor: "#e9f1ff"}}
                            isEditMode={isEditMode}
                            name={"textValue"}
                            value={internalTitle}
                            onChange={(e) => setInternalTitle(e.target.value)}
                            disableAutocomplete
                            onFocusEdit={isEditMode}
                            defaultValueDash={false}
                            showInputFieldInReadOnly
                        />
                    )}
                </div>
                {HeaderDivs(form.documentModuleChecklistFormDataRows, width, ids)}

                <TableDropdown dataRowLength={form.documentModuleChecklistFormDataRows.length} relatedForms={ids}/>
            </div>
        </StickyDiv>
    )
}

export interface DataRowWidth {
    [key: number]: number;
}

type DataRowPreference = {
    index: number;
    currentValue: number;
    minValue?: number;
    maxValue?: number;
    lockExpand: boolean;
    resultValue: number;
}

const getPreferenceWidth = (keys: IDocumentModuleChecklistForm, baseColumnWidth: number): DataRowPreference[] => {
    return keys.documentModuleChecklistFormDataRows.map((dataRow, index) => {
        switch (dataRow.checklistFormDataRowTypeSymbol) {
            case DataRowType.TEXT_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    minValue: 300,
                    lockExpand: false,
                    resultValue: baseColumnWidth < 300 ? 300 : baseColumnWidth
                };
            case DataRowType.NUMERIC_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 200,
                    lockExpand: true,
                    resultValue: 200
                };
            case DataRowType.SIGNATURE_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 200,
                    lockExpand: true,
                    resultValue: 200
                };
            case DataRowType.RISK_ASSESSMENT_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 250,
                    lockExpand: true,
                    resultValue: 250
                };
            case DataRowType.MULTI_CHOICE_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 250,
                    lockExpand: true,
                    resultValue: 250
                };
            case DataRowType.IMAGE_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 200,
                    lockExpand: true,
                    resultValue: 200
                };
            case DataRowType.DATE_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 250,
                    lockExpand: true,
                    resultValue: 250
                };
            case DataRowType.FILES_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 200,
                    lockExpand: true,
                    resultValue: 200
                };
            case DataRowType.USER_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 250,
                    lockExpand: true,
                    resultValue: 250
                };
            case DataRowType.ACTION_PLAN_FIELD:
                return {
                    index: keys.documentModuleChecklistFormDataRows.length - 1,
                    currentValue: baseColumnWidth,
                    maxValue: 300,
                    lockExpand: true,
                    resultValue: 300
                };
            case DataRowType.ASSESSMENT_SCOPE_SEVERITY_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 250,
                    lockExpand: true,
                    resultValue: 250
                };
            case DataRowType.USER_AND_DATE_FIELD:
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 250,
                    lockExpand: true,
                    resultValue: 250
                };
            default:
                console.info(i18n.t('TableView.DATA_ROW_NOT_HANDLED_BY_WIDTH_1'));
                return {
                    index: index,
                    currentValue: baseColumnWidth,
                    maxValue: 200,
                    lockExpand: true,
                    resultValue: 300
                };
        }
    }).filter(Boolean) as DataRowPreference[];
}

const HeaderTable = (
    {
        keys,
        values,
        index,
        formCount,
    }: {
        keys: IDocumentModuleChecklistForm,
        values: IDocumentModuleChecklistForm[],
        index: number,
        formCount: number
    }) => {
    const {moveForm, documentContext: {isEditMode}, isHideHeaderColumnTableView} = useChecklistContext();
    const tableRef = useRef<HTMLDivElement>(null);

    const [result, setResult] = useState<DataRowWidth>({});
    useEffect(() => {
        const actionPlanDataRowIdx = keys.documentModuleChecklistFormDataRows.findIndex((item) => item.checklistFormDataRowTypeSymbol === DataRowType.ACTION_PLAN_FIELD);
        if (actionPlanDataRowIdx > 0) {
            const objectToMove = keys.documentModuleChecklistFormDataRows[actionPlanDataRowIdx];
            keys.documentModuleChecklistFormDataRows.splice(actionPlanDataRowIdx, 1);
            keys.documentModuleChecklistFormDataRows.push(objectToMove);

        }
        let totalTableWidth = 0;
        let columnWidthResult: DataRowWidth = {};
        const headerColumnWidth = 250;

        if (tableRef.current) {
            totalTableWidth = tableRef.current.getBoundingClientRect().width;
        }

        if (!isHideHeaderColumnTableView) {
            totalTableWidth -= headerColumnWidth;
        }

        const numberOfColumns = keys.documentModuleChecklistFormDataRows.length;
        const widthPercentagePerColumn = 100 / (numberOfColumns + (isHideHeaderColumnTableView ? 0 : 1));
        const baseColumnWidth = (widthPercentagePerColumn / 100) * totalTableWidth;

        const dataRowPreferences = getPreferenceWidth(keys, baseColumnWidth);

        let widthLeftover = dataRowPreferences.filter(pref => pref.lockExpand)
            .reduce((total, pref) => total + (pref.currentValue - (pref.maxValue ?? 0)), 0);

        if (widthLeftover > 0) {
            const expandableColumns = dataRowPreferences.filter(pref => !pref.lockExpand);
            const widthLeftoverDivided = widthLeftover / Math.max(1, expandableColumns.length);

            dataRowPreferences.filter(pref => !pref.lockExpand)
                .forEach(pref => {
                    pref.resultValue += widthLeftoverDivided;
                });
        }

        dataRowPreferences.forEach(pref => {
            columnWidthResult[pref.index] = pref.resultValue;
        });

        const sumColumnWidths = dataRowPreferences.reduce((total, pref) => total + pref.resultValue, 0);

        if (Math.round(sumColumnWidths) < Math.round(totalTableWidth)) {
            const divideWidth = (totalTableWidth - sumColumnWidths) / Math.max(1, dataRowPreferences.length);
            dataRowPreferences.forEach(pref => {
                columnWidthResult[pref.index] += divideWidth;
            });
        }

        setResult(columnWidthResult);
    }, [isHideHeaderColumnTableView, keys.documentModuleChecklistFormDataRows]);


    const ids = useMemo<string[]>(() => {
        return values.map(v => v.id);
    }, [values])

    return (
        <div ref={tableRef} className={styles.checklistTableWrapper}>
            <div className={styles.checklistTable}>
                <StickyHeaderDiv width={result} ids={ids} form={keys} index={index}
                                 isHideHeaderColumnTableView={isHideHeaderColumnTableView}/>

                {values.map((v, index) => <ChecklistFormContextProvider form={v} key={v.id}>
                    <DndProvider backend={HTML5Backend}>
                        <DragAndDropComponent
                            allowedToDrag={isEditMode}
                            allowedToDrop={isEditMode}
                            type={"TABLE_ROW"}
                            id={v.id}
                            index={index}
                            onDrop={(dragIndex, moveIndex) => moveForm(dragIndex + formCount, moveIndex + formCount)}
                            style={{right: -19, bottom: 3, zIndex: 5}}
                        >
                            <CheckListRow form={v} width={result}/>
                        </DragAndDropComponent>
                    </DndProvider>
                </ChecklistFormContextProvider>)}

            </div>
        </div>

    )
}

const ConstructHeaders = (forms: IDocumentModuleChecklistForm[]) => {
    let previousCount: number | null = null;
    let previousRow: string | null = null;
    const headerHolders: Map<IDocumentModuleChecklistForm, IDocumentModuleChecklistForm[]> = new Map();
    let currentHeaderRef: IDocumentModuleChecklistForm | undefined = undefined;
    forms.forEach(form => {
        if (form.isDeleted) return;
        const headerTitle = form.title;
        const headers = form.documentModuleChecklistFormDataRows.map(v => `${v.header} ${v.checklistFormDataRowTypeSymbol}`);

        const headerCount = headers.length;
        const headerRow = headerTitle + ' ' + headers.join(' ');

        const renderHeader = headerRow !== previousRow || headerCount !== previousCount;

        if (previousRow == null || previousCount == null || renderHeader) {
            previousRow = headerRow;
            previousCount = headerCount;
        }

        if (renderHeader) currentHeaderRef = form;

        if (currentHeaderRef === undefined) return;

        if (!headerHolders.get(currentHeaderRef)) {
            headerHolders.set(currentHeaderRef, []);
        }
        headerHolders.get(currentHeaderRef)?.push(form);

    })
    const ElementHolder: JSX.Element[] = [];
    let formCount = 0;
    headerHolders.forEach((value, key) => {
        ElementHolder.push(<HeaderTable formCount={formCount} key={key.id} values={value} keys={key}
                                        index={ElementHolder.length}/>)
        formCount += value.length;
    })

    return ElementHolder;
}


const TableView = ({forms}: { forms: IDocumentModuleChecklistForm[] }) => {
    const [headerHolders, setHeaderHolders] = useState<JSX.Element[]>([]);

    useLayoutEffect(() => {
        if (forms != null) {
            setHeaderHolders(ConstructHeaders(forms))
        }
    }, [forms])


    return <>{headerHolders}</>;
};


export default TableView
