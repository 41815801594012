import { CompanyRole, EmploymentType, UserSkillCourseCompleted, UserSkillLevel } from "../../core/enums/enums";


export const employmentTypeMap: Record<EmploymentType, string> = {
    [EmploymentType.NOT_SPECIFIED]: 'util.NOT_SPECIFIED_1',
    [EmploymentType.NOT_EMPLOYED]: 'util.NOT_EMPLOYED_1',
    [EmploymentType.HIRED]: 'util.HIRED_1',
    [EmploymentType.CONSULTANT]: 'util.CONSULTANT_1',
    [EmploymentType.APPRENTICE]: 'util.APPRENTICE_1',
    [EmploymentType.INTERNSHIP]: 'util.WORK_EXPERIENCE_1',
    [EmploymentType.PROJECT_EMPLOYMENT]: 'util.PROJECT_EMPLOYMENT_1',
    [EmploymentType.PROBATIONARY_EMPLOYMENT]: 'util.TRIAL_EMPLOYMENT_1',
    [EmploymentType.SEASONAL_WORKER]: 'util.SEASONAL_EMPLOYMENT_1',
    [EmploymentType.PERMANENT]: 'util.UNTIL_FURTHER_NOTICE_1',
    [EmploymentType.HOURLY_EMPLOYEE]: 'util.PART_TIME_EMPLOYEE_1',
    [EmploymentType.SUBSTITUTE]: 'util.SUBSTITUTE_1',
    [EmploymentType.FIXED_TERM_EMPLOYMENT]: 'util.FIXED_TERM_EMPLOYMENT_1'
};

export const userRoleMap: Record<CompanyRole, string> = {
    [CompanyRole.ADMIN]: 'User.ADMIN_1',
    [CompanyRole.EDITOR]: 'User.EDITOR_1',
    [CompanyRole.READER]: 'User.READER_1',
}

export const skillCourseCompletedMap: Record<UserSkillCourseCompleted, string> = {
    [UserSkillCourseCompleted.NOT_SPECIFIED]: 'SKILL_COURSE_COMPLETED.NOT_SPECIFIED',
    [UserSkillCourseCompleted.YES]: 'SKILL_COURSE_COMPLETED.YES',
    [UserSkillCourseCompleted.NO]: 'SKILL_COURSE_COMPLETED.NO'
}

export const skillLevelMap: Record<UserSkillLevel, string> = {
    [UserSkillLevel.NOT_SPECIFIED]: 'SKILL_LEVEL.NOT_SPECIFIED',
    [UserSkillLevel.LEVEL_1]: 'SKILL_LEVEL.LEVEL_1',
    [UserSkillLevel.LEVEL_2]: 'SKILL_LEVEL.LEVEL_2',
    [UserSkillLevel.LEVEL_3]: 'SKILL_LEVEL.LEVEL_3',
    [UserSkillLevel.LEVEL_4]: 'SKILL_LEVEL.LEVEL_4',
    [UserSkillLevel.LEVEL_5]: 'SKILL_LEVEL.LEVEL_5'
}

export const getBlockedFileFormats = [".exe", ".com", ".bat", ".dll", ".cmd", ".msi", ".vb", ".vbs", ".ws", ".wsf", ".scf", ".scr", ".pif"];
export const getBlockedFileTypes = [ "application/x-msdownload", "application/octet-stream", "application/bat", "application/cmd", "application/exe", "application/dll", "application/vbscript", "application/vbs", "application/scriptlet", "application/wsf", "application/pif"];
