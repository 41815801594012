import React from "react";

interface MapProps<ObjectT> {
    list: ObjectT[];
    filterBy?: (object: ObjectT) => boolean,
    outElement: (object: ObjectT, index: number) => JSX.Element
}

const Map = <ObjectT, >(
    {
        list,
        filterBy = () => true,
        outElement,
    }: MapProps<ObjectT>) => {
    return (<>
            {list.filter(filterBy).map((object, index) => (
                <React.Fragment key={index}>
                    {outElement(object, index)}
                </React.Fragment>
            ))}
        </>
    );
}

export default Map;