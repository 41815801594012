import {IConfirmUserToken, ICreateUser, ISuperadmin} from "../interfaces/user";
import {CompanyRole} from "../enums/enums";

const createNewUser = (): ICreateUser => {
    return {
        companyRole: CompanyRole.READER, email: "", firstName: "", lastName: "", isExternal: false, positionLevels: []}
}
const createNewSuperadmin = (): ISuperadmin => {
    return {email: "", firstName: "", lastName: ""}
}

const createNewConfirmToken = (): IConfirmUserToken => {
    return {passwordAgain: "", email: "", password: "", token: ''}
}

const validateEmail = (email: string): boolean => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
}

const validatePassword = (password: string): boolean => {
    return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password);
}
export {createNewUser, createNewSuperadmin, createNewConfirmToken, validateEmail, validatePassword};