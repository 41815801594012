import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { get } from "../api/CommonApi";
import { queryStringBuilder } from "../core/helpers/queryStringBuilder";
import { toastError } from "../core/helpers/toastHelper";
import { IApiQuery } from "../core/interfaces/common";
import { addToast } from "../store/slices/uiSlice";

export const useApiBase = <T>(url: string, queryOptions?: IApiQuery): [T, boolean, () => void] => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({} as T);
    const [isFetching, setIsFetching] = useState(true);

    const fetch = () => {
        if (queryOptions) {
            url += queryStringBuilder(queryOptions);
        }
        get<T>(url).then(({data, status}) => {
            if (status !== 200) {
                dispatch(addToast(toastError(t('useApiBase.ERROR_OCCURRED_1'), t('useApiBase.UNEXPECTED_ERROR_OCCURRED_1'))));
            } else {
                setResponse(data)
            }
            setIsFetching(false)
        })
    }
    useEffect(() => fetch(), [url])

    return [response, isFetching, fetch]
}
