import { useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetDocumentById, useGetDocumentModulesById } from "../../api/DocumentApi";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import ActionsContainer from "../../components/ContainerComponents/ActionsContainer";
import LoadingSpinner from "../../components/LoadingSpinner";
import When from "../../components/When";
import { ChecklistView, DocumentTypes, PublishStatusType } from "../../core/enums/enums";
import { IChecklistView, IDocument, IDocumentModule } from "../../core/interfaces/document";
import DocumentForm from "../Document/DocumentForm";
import DocumentModules from "../Document/DocumentModules/DocumentModules";


interface IDocumentPreview {
    documentId: string;
    onClose: (val: boolean) => void;
}

const DocumentPreview = (
    {
        documentId,
        onClose,
    }: IDocumentPreview) => {

    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);

    // Fetch document
    const {
        isFetching: isFetchingDocument,
        data: documentResponse,
    } = useGetDocumentById(documentId);
    const [document, setDocument] = useState<IDocument>();
    useEffect(() => {
        if (documentResponse) {
            setDocument(documentResponse.data.data);
        }
    }, [documentResponse]);

    // Fetch modules
    const {
        data: modulesResponse,
        isFetching: isFetchingModules,
    } = useGetDocumentModulesById(documentId);
    const [modules, setModules] = useState<IDocumentModule[]>([]);
    useEffect(() => {
        if (modulesResponse) {
            setModules([...modulesResponse.data.data] ?? []);
        }
    }, [modulesResponse]);

    const handleClose = () => {
        setOpen(false);
        // wait until closed to render the fade-out
        setTimeout(function () {
            onClose(false);
        }, 500);
    }

    const returnVoidFunction = () => NaN;
    const goToDocument = () => {
        navigate(documentId, {state: {hash: location.hash}});
    }

    const [checklistView, setChecklistView] = useState<IChecklistView[]>([]);
    const getChecklistView = (moduleId: string): ChecklistView => checklistView.find(item => Object.keys(item)[0] === moduleId)?.[moduleId] ?? ChecklistView.TABLE;

    const handleSetChecklistView = (moduleId: string, value: ChecklistView) => {
        const index = checklistView.findIndex(item => Object.keys(item)[0] === moduleId);
        if (index === -1) {
            setChecklistView([...checklistView, {[moduleId]: value}])
        } else {
            const updatedChecklist = [...checklistView];
            updatedChecklist[index][moduleId] = value;
            setChecklistView(updatedChecklist);
        }
    }


    return <Offcanvas show={open} onHide={handleClose} placement="end">
        <OffcanvasHeader closeButton>
            <Offcanvas.Title>{document?.name}</Offcanvas.Title>
        </OffcanvasHeader>
        <OffcanvasBody>
            <When condition={isFetchingDocument}>
                <LoadingSpinner/>
            </When>
            <When condition={document !== undefined}>
                <ActionsContainer placement="top">
                    <Button onClick={goToDocument}>
                        {t('DocumentPreview.GO_TO_1')} {DocumentTypes.TEMPLATE === document?.documentType ? t('DocumentPreview.TEMPLATE_1') : t('DocumentPreview.DOCUMENT_1')}
                    </Button>
                    <div className={"pt-1 pe-4"}>
                        <When condition={PublishStatusType.CLOSED === document?.publishStatus}>
                            <span
                                className={getIcon(Icon.LOCK, IconSize.XL, "lightblue")}
                                title={t('DocumentPreview.CLEAR_MARKED_1')}
                            />
                        </When>
                    </div>
                </ActionsContainer>
                <When condition={document !== undefined}>
                    <DocumentForm
                        originalDocument={document!}
                        setNewDocument={returnVoidFunction}
                        newDocument={document!}
                        isEditMode={false}
                        setIsActiveForm={returnVoidFunction}
                    />

                    <DocumentModules
                        checklistView={getChecklistView}
                        setChecklistView={handleSetChecklistView}
                        isDocumentPreview={true}
                        modules={modules}
                        isFetchingModules={isFetchingModules}
                        refetchModules={returnVoidFunction}
                        isEditMode={false}
                        previewDocumentId={document?.id}
                        setIsActiveModule={returnVoidFunction}
                        setDisableQueries={() => false}
                        isDocumentArchived={document?.archived}
                    />
                </When>
            </When>
        </OffcanvasBody>
    </Offcanvas>
};

export default DocumentPreview;
