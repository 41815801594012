import React from "react";
import { useTranslation } from "react-i18next";
import { IDocumentAttachment } from "../../../core/interfaces/document";
import { Attachment } from "./Attachment";

interface IFileListProps {
    attachments: IDocumentAttachment[],
    isEditMode: boolean,
    documentId?: string,
}


export const AttachmentList: React.FC<IFileListProps> = ({attachments, isEditMode, documentId}) => {
    const {t} = useTranslation();
    return (
        <>
            {attachments.length > 0 &&
                <div className={'pt-1'}><strong>{t('AttachmentList.ATTACHED_FILES_1')}</strong></div>}
            <ul style={{
                listStyle: 'none',
                overflow: 'hidden',
                marginTop: isEditMode ? 1 : 0,
                paddingLeft: 1,
                marginBottom: 10
            }}>
                {attachments.map((attachment, i) =>
                    <Attachment key={i} attachment={attachment} isEditMode={isEditMode} documentId={documentId}/>
                )}
            </ul>
        </>)
}
