import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useGetCompanySkills } from "../../../../../../api/DocumentApi";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowProps, ISkillDataRow } from "../../../../../../core/interfaces/document";
import DataRowActionButtons from "./DataRowActionButtons";


const SkillDataRow = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        isFieldEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isAnswerMode = false,
        isActive,
        requiredSubType
    }: IDataRowProps<ISkillDataRow>) => {

    const {t} = useTranslation();
    const [skills] = useGetCompanySkills();

    const onChangeValue = (e: any) => {
        const value = (e.target.value ?? "");
        setDataRow({
            ...dataRow,
            [e.target.name]: value.length > 0 ? value : undefined
        });
    }
    return <>
        <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
            <When condition={!checklistViewTable}>
                <Col md={3}>
                    <CustomTextInput
                        isEditMode={isEditMode}
                        disabled={!isHeaderEnabled}
                        name={"header"}
                        placeholder={t('SkillDataRow.FOR_EXAMPLE_SKILLS_1')}
                        value={getDataRowHeader(dataRow)}
                        onChange={onChangeValue}
                        disableAutocomplete
                    />
                </Col>
            </When>
            <Col md={
                isEditMode && isActive
                    ? checklistViewTable ? 12 : 7
                    : checklistViewTable ? 12 : 9
            }>
                <CustomSelect
                    checklistViewTable={checklistViewTable}
                    required={requiredSubType.includes(dataRow.subType!)}
                    disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                    isEditMode={true}
                    name={"skillId"}
                    value={dataRow.skillId ?? '-'}
                    onChange={onChangeValue}
                    options={<>
                        <option key={''} value={''}>{t('SkillDataRow.SELECT_SKILL_1')}</option>
                        {skills.map(skill => <option key={skill.id} value={skill.id}>{skill.name}</option>)}
                    </>}
                />
            </Col>

            <When condition={isEditMode && isActive}>
                <DataRowActionButtons
                    dataRow={dataRow}
                    removeDataRow={removeDataRow}
                    copyDataRow={copyDataRow}
                />
            </When>
        </Row>

    </>
};



export default SkillDataRow;
