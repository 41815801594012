import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import getIcon, { Icon, IconSize } from "../../../../../assets/Icons/IconClassNames";
import CustomTextInput from "../../../../../components/FormComponents/CustomTextInput";
import { useDocumentModulesContext } from "../../DocumentModules";
import styles from "./checklistForm.module.scss";

interface IChecklistFormHeader {
    index: number;
    isActiveModule: boolean;
    isExpanded: boolean;
    setExpanded: (value: boolean) => void;
    title: string;
    setTitle: (value: string) => void;
    description: string;
    setDescription: (value: string) => void;
    isHeaderLocked: boolean;
}

const ChecklistFormHeader = (
    {
        index,
        isActiveModule,
        isExpanded,
        setExpanded,
        title,
        setTitle,
        description,
        setDescription,
        isHeaderLocked,
    }: IChecklistFormHeader) => {

    const { t } = useTranslation();

    const {isEditMode} = useDocumentModulesContext();

    return <>
        <div className={styles.checklistFormContainerHeader}>
            {isEditMode && <div><span style={{fontSize: "12px"}}>#{index + 1}</span></div>}
            <div></div>
        </div>

        <Row className={"mt-3 mb-3 ms-2 me-2"}>
            <Col md={3}>
                <CustomTextInput
                    disabled={isHeaderLocked}
                    isEditMode={isEditMode && isActiveModule}
                    name={"title"}
                    placeholder={t('ChecklistFormHeader.CHECKPOINT_1')}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    disableAutocomplete
                    required
                />
            </Col>
            <Col md={isEditMode && isActiveModule ? 7 : 9} style={{lineHeight: "24px", fontSize: "20px"}}>
                <CustomTextInput
                    disabled={isHeaderLocked || (!isActiveModule)}
                    isEditMode={isEditMode}
                    name={"description"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disableAutocomplete
                    required
                    showInputFieldInReadOnly={isEditMode}
                />
            </Col>
            {isEditMode && isActiveModule && <Col md={2} className={"d-flex justify-content-end"}>
                <span className={`cursor-pointer ${isExpanded ?
                    getIcon(Icon.CIRCLE_CHEVRON_DOWN, IconSize.X2) :
                    getIcon(Icon.CIRCLE_CHEVRON_UP, IconSize.X2)}`}
                      onClick={() => setExpanded(!isExpanded)}/>
            </Col>}
        </Row>
    </>
};

export default ChecklistFormHeader;