import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import CustomTextAreaInput from "../../../../../../components/FormComponents/CustomTextAreaInput";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowProps, IDataRowTextField } from "../../../../../../core/interfaces/document";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import DataRowActionButtons from "./DataRowActionButtons";


const TextFieldDataRow = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        isFieldEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isAnswerMode = false,
        isActive,
        requiredSubType,
    }: IDataRowProps<IDataRowTextField>) => {
    const { t } = useTranslation();
    const [internalDataRow, setInternalDataRow] = useState(dataRow);
    const debouncedDataRow = useDebounce<IDataRowTextField>(internalDataRow);

    useEffect(() => {
        setDataRow(debouncedDataRow);
    }, [debouncedDataRow]);

    useEffect(() => {
        setInternalDataRow({
            ...internalDataRow,
            header: dataRow.header
        });
    }, [dataRow.header]);

    const onChange = (e: any) => {
        if (!e.target) return;
        setInternalDataRow({
            ...internalDataRow,
            [e.target.name]: e.target.value
        });
    }

    return <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
        <When condition={!checklistViewTable}>
            <Col md={3}>
                <CustomTextInput
                    isEditMode={isEditMode}
                    disabled={!isHeaderEnabled}
                    name={"header"}
                    placeholder={t('TextFieldDataRow.EXAMPLE_1')}
                    value={getDataRowHeader(internalDataRow)}
                    onChange={onChange}
                    disableAutocomplete
                />
            </Col>
        </When>
        <Col md={
            isEditMode && isActive
                ? checklistViewTable ? 12 : 7
                : checklistViewTable ? 12 : 9
        }>
            <When condition={dataRow.rows === 1}
                otherwise={
                    <CustomTextAreaInput
                        checklistViewTable={checklistViewTable}
                        required={requiredSubType.includes(internalDataRow.subType!)}
                        disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                        isEditMode={true}
                        rows={dataRow.rows ?? 4}
                        name={"textValue"}
                        value={internalDataRow.textValue ?? ''}
                        onChange={onChange}
                        autoComplete={false}
                        onFocusEdit={false}
                        defaultValueDash={false}
                    />
                }>
                <CustomTextInput
                    checklistTableView={checklistViewTable}
                    required={requiredSubType.includes(internalDataRow.subType!)}
                    disabled={!isFieldEnabled || !(isAnswerMode || isEditMode)}
                    isEditMode={true}
                    maxLength={dataRow.maxLength}
                    name={"textValue"}
                    value={internalDataRow?.textValue ?? ""}
                    onChange={onChange}
                    disableAutocomplete
                    type={"text"}
                />
            </When>
        </Col>
        <When condition={isEditMode && isActive}>
            <DataRowActionButtons
                dataRow={internalDataRow}
                removeDataRow={removeDataRow}
                copyDataRow={copyDataRow}
            />
        </When>
    </Row>
};

export default TextFieldDataRow;
