import {Outlet} from "react-router-dom";
import {Container} from "react-bootstrap";

const Layout = () => {
    return (
        <Container fluid className="main">
            <Outlet />
        </Container>
    )
}

export default Layout;