import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosRequestConfig } from "axios";
import i18n from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CompanyRole, HTTP_STATUS } from "../core/enums/enums";
import { downloadFileResponse, fileResponse } from "../core/helpers/fileHelper";
import { toastError, toastSuccess } from "../core/helpers/toastHelper";
import { ICustomQuery, ICustomQueryKeys, IEntity, IPerson, IResponse } from "../core/interfaces/common";
import {
    ICompany,
    ICompanyInfo,
    ICompanyUserExtended,
    ICreateCompany,
    IEmploymentType,
    IUserSkill
} from "../core/interfaces/company";
import { IDataRow, IDocumentLightExtended, ViewedDocument } from "../core/interfaces/document";
import { ICreateUser } from "../core/interfaces/user";
import { AppQueryKey, GetQueryKey, useInvalidateQuery } from "../core/queryKeys";
import { useDelete, useGet, usePost, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient, { api } from "./api";
import {
    URL_COMPANIES,
    URL_COMPANY_LOGO,
    URL_COMPANY_PARENTS,
    URL_COMPANY_TOGGLE_ACTIVE,
    URL_COMPANY_UPDATE,
    URL_COMPANY_USERS,
    URL_COMPANY_USERS_EXTENDED,
    URL_COMPANY_USER_CHANGE_ROLE,
    URL_COMPANY_USER_CHANGE_UPDATE,
    URL_COMPANY_USER_DELETE,
    URL_COMPANY_USER_DOCUMENTS,
    URL_COMPANY_USER_DOWNLOAD_ATTACHMENT,
    URL_COMPANY_USER_LAST_DOC,
    URL_COMPANY_USER_SKILLS,
    URL_COMPANY_USER_SKILL_CREATE,
    URL_COMPANY_USER_SKILL_DELETE,
    URL_COMPANY_USER_SKILL_DOWNLOAD_FILE,
    URL_COMPANY_USER_SKILL_UPDATE,
    URL_COMPANY_USER_UPLOAD_ATTACHMENT,
    URL_EMPLOYMENT_TYPE
} from "./endpoints/endpoints";


export const getCompanies = async () => {
    return await AxiosClient.get<IResponse<ICompany[]>>(URL_COMPANIES).then((response) => {
        return {data: response.data.data, status: response.status};
    }).catch((error: AxiosError) => {
        return {
            data: [],
            status: error.response ?
                error.response.status :
                HTTP_STATUS.INTERNAL_SERVER_ERROR_500
        };
    });
}

export const getCompanyLogo = async (companyId: string) => {
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };
    return await AxiosClient.get<Blob>(URL_COMPANY_LOGO(companyId), config).then((response) => {
        return {data: response.data, type: response.headers["content-type"]};
    }).catch(() => {
        return {data: null, type: ""};
    })
}

export const getCompanyInformation = async (): Promise<IResponse<ICompany | null>> => {
    const allCompanies = await getCompanies();
    if (allCompanies.status !== HTTP_STATUS.OK_200) {
        return {data: null, status: allCompanies.status};
    }

    if (!allCompanies || !allCompanies.data || allCompanies.data.length === 0) {
        return {data: null, status: HTTP_STATUS.NOT_FOUND_404};
    }

    return {data: allCompanies.data[0], status: allCompanies.status};
}

export const getCompanyUsersExtended = async (companyId: string) => {
    return await AxiosClient.get<IResponse<ICompanyUserExtended[]>>(URL_COMPANY_USERS_EXTENDED(companyId)).then((response) => {
        return response.data.data;
    }).catch((error: AxiosError) => {
        return [];
    });
}

export const CreateCompany = async (company: ICreateCompany): Promise<IResponse<ICompany>> => {
    return await AxiosClient.post<ICompany>(URL_COMPANIES, company)
        .then(resp => ({data: resp.data, status: resp.status}))
        .catch((error: AxiosError) => ({data: {} as ICompany, status: error.response?.status ?? 500}));
}

export const CreateCompanyUser = async (user: ICreateUser, companyId: string): Promise<IResponse<IPerson>> => {
    return await AxiosClient.post<IResponse<IPerson>>(URL_COMPANY_USERS(companyId), user)
        .then(resp => ({data: resp.data.data, status: resp.status}))
        .catch((error: AxiosError) => ({data: {} as IPerson, status: error.response?.status ?? 500}));
}

export const useUpdateCompany = (companyId?: string): [((request: ICompany) => Promise<boolean>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANIES);

    const usePutMutation = usePut<ICompany>(
        URL_COMPANY_UPDATE(companyId ?? ''),
        queryKeys,
        true
    );

    const invalidateCompanies = useInvalidateQuery(AppQueryKey.COMPANIES);

    const request = (request: ICompany) => usePutMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.UPDATE_SUCCESSFUL_1'), i18n.t('CompanyApi.UPDATE_COMPANY_1'))));
            await invalidateCompanies();
        }
        return !!response.success;
    });
    return [request, usePutMutation.isLoading];
}

export const useGetCompanyParents = (): [ICompany[], boolean] => {
    const queryKeys = GetQueryKey(AppQueryKey.COMPANY_PARENTS);
    const query: ICustomQuery = {
        url: URL_COMPANY_PARENTS(),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<ICompany[]>(query);

    return [data?.data?.data ?? [], isFetching]
}

export const useGetCompanyLogo = (companyId: string): { logoUrl: string | undefined, isFetching: boolean } => {
    const queryKeys = GetQueryKey(AppQueryKey.COMPANIES, {extraKeys: [companyId, "logo"]});
    const query: ICustomQuery = {
        url: URL_COMPANY_LOGO(companyId),
        queryKeys: queryKeys,
        options: {
            staleTime: 3_600_000 // 1 hour
        }
    };

    const fetchBlob = () => {
        return api.get<IResponse<Blob>>(query.url, {responseType: "blob"})
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data as unknown as Blob;
                    if (data && data.size > 0) {
                        return URL.createObjectURL(response.data as unknown as Blob);
                    }
                    return "";
                }
                return "";
            });
    }

    const {data, isFetching} = useQuery([AppQueryKey.COMPANIES, companyId, "logo"], fetchBlob, {
            enabled: query.options?.enabled ?? true,
            staleTime: query.options?.staleTime,
            refetchInterval: query.options?.refetchInterval
        }
    );

    return {logoUrl: data ?? "", isFetching: isFetching};
}

export const useCreateUpdateCompanyLogo = (
    companyId: string
): [((request: FormData) => Promise<IResponse<boolean>>), boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANIES, {extraKeys: [companyId, "logo"]});

    const usePostMutation = usePost<FormData>(
        URL_COMPANY_LOGO(companyId),
        queryKeys,
        false,
    );

    const request = (request: FormData) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.LOGO_UPLOADED_1'), i18n.t('CompanyApi.LOGO_UPLOADED_2'))));
        }
        return response as unknown as IResponse<boolean>;
    }).catch(error => {
        console.log("error: ", error);
        dispatch(addToast(toastError(i18n.t('CompanyApi.ERROR_OCCURRED_1'), i18n.t('CompanyApi.UNEXPECTED_ERROR_1'))));
        return {} as any;
    });

    return [request, usePostMutation.isLoading];
}

export const useRemoveCompanyLogo = (
    companyId: string
): [() => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANIES, {extraKeys: [companyId, "logo"]});

    const useDeleteMutation = useDelete<string>(
        URL_COMPANY_LOGO(companyId),
        queryKeys,
        true,
    );

    const request = () => useDeleteMutation.mutateAsync("").then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.LOGO_DELETED_1'), i18n.t('CompanyApi.LOGO_DELETED_2'))));
        }
        return !!response.success;
    });

    return [request, useDeleteMutation.isLoading];
}

export const useGetCompanyUsersExtended = (): [ICompanyUserExtended[], boolean] => {
    const { cId } = useParams();

    const queryKeys = GetQueryKey(AppQueryKey.COMPANY_USERS_EXTENDED);

    const query: ICustomQuery = {
        url: URL_COMPANY_USERS_EXTENDED(cId!),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<ICompanyUserExtended[]>(query);

    return [data?.data?.data ?? [], isFetching]
}

export const useUpdateCompanyUser = (userId: string): [(companyInfo: ICompanyInfo) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANY_USERS_EXTENDED);

    const usePostMutation = usePut<ICompanyInfo>(
        URL_COMPANY_USER_CHANGE_UPDATE(cId!, userId),
        queryKeys,
        true
    );

    const invalidateCompanyUsers = useInvalidateQuery(AppQueryKey.COMPANY_USERS);

    const request = (companyInfo: ICompanyInfo) => usePostMutation.mutateAsync(companyInfo).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.UPDATE_SUCCESSFUL_2'), i18n.t('CompanyApi.USER_UPDATED_1'))));
            await invalidateCompanyUsers();
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}


export const useUpdateCompanyUserRole = (userId: string): [(companyRole: CompanyRole) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    // const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANY_USERS_EXTENDED);

    const usePostMutation = usePut<{ companyRole: CompanyRole }>(
        URL_COMPANY_USER_CHANGE_ROLE(cId!, userId),
        queryKeys,
        false
    );
    const request = (companyRole: CompanyRole) => usePostMutation.mutateAsync({companyRole}).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.SUCCESSFUL_UPDATE_1'), i18n.t('CompanyApi.ROLE_UPDATED_1'))));
        }
        return !!response.success;
    }).catch(_ => {
        dispatch(addToast(toastError(i18n.t('CompanyApi.UPDATE_FAILED_1'), i18n.t('CompanyApi.UPDATE_FAILED_2'))))
        return false;
    });
    return [request, usePostMutation.isLoading];
}

export const useDeleteCompanyUser = (userId: string): [() => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    // const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANY_USERS_EXTENDED);
    const useDeleteMutation = useDelete<void>(
        URL_COMPANY_USER_DELETE(cId!, userId),
        queryKeys,
        true
    );

    const invalidateCompanyUsers = useInvalidateQuery(AppQueryKey.COMPANY_USERS);

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.DELETION_SUCCESSFUL_1'), i18n.t('CompanyApi.USER_REMOVED_FROM_COMPANY_1'))));
            await invalidateCompanyUsers();
        }
        return !!response.success;
    });
    return [request, useDeleteMutation.isLoading];
}

export const useGetLastDocuments = (): [ViewedDocument[], boolean] => {
    const {cId} = useParams();
    const queryKeys: ICustomQueryKeys = {root: "lastDocuments", cId};
    const query: ICustomQuery = {
        url: URL_COMPANY_USER_LAST_DOC(cId!),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<ViewedDocument[]>(query);


    return [data?.data?.data ?? [], isFetching]
}

export const useGetEmploymentTypes = (): [IEmploymentType[], boolean] => {
    const queryKeys: ICustomQueryKeys = {root: "employmentType"};
    const query: ICustomQuery = {
        url: URL_EMPLOYMENT_TYPE(),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<IEmploymentType[]>(query);


    return [data?.data?.data ?? [], isFetching]
}

export const useToggleCompanyActive = (companyId: string): [() => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.COMPANIES, {includeCompanyId: false});

    const usePutMutation = usePut<void>(
        URL_COMPANY_TOGGLE_ACTIVE(companyId),
        queryKeys,
        true
    );

    const request = () => usePutMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.CUSTOMER_SUPPLIER_UPDATED_1'), i18n.t('CompanyApi.UPDATE_COMPLETED_1'))))
        }
        return !!response.success;
    });
    return [request, usePutMutation.isLoading];


}

export const useUploadAttachment = (): [((request: FormData) => Promise<IResponse<IEntity>>), boolean] => {
    const {cId, cUId} = useParams();
    const dispatch = useAppDispatch();

    const usePostMutation = usePost<FormData>(
        URL_COMPANY_USER_UPLOAD_ATTACHMENT(cId!, cUId!),
        GetQueryKey(AppQueryKey.COMPANY_USERS_EXTENDED, {extraKeys: [cId!]}),
        false
    );

    const invalidateDocument = useInvalidateQuery(AppQueryKey.COMPANY_USERS_EXTENDED);
    const request = (request: FormData) => usePostMutation.mutateAsync(request).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.UPLOAD_SUCCESS_1'), i18n.t('CompanyApi.UPLOAD_TO_DOCUMENT_1'))));
            await invalidateDocument();
        } else {
            if ((response.data as any).status === 422) {
                dispatch(addToast(toastError(i18n.t('CompanyApi.FILE_SIZE_OR_FORMAT_ISSUE_1'), i18n.t('CompanyApi.FILE_SIZE_OR_FORMAT_ERROR_1'))));
            }
        }
        return response as unknown as IResponse<IEntity>;
    });
    return [request, usePostMutation.isLoading];
}
export const useDownloadAttachmentById = (): [(attachmentId: string) => void, boolean] => {
    const {cId, cUId} = useParams();
    const [isDownloading, setIsDownloading] = useState(false);
    const dispatch = useAppDispatch();
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    const download = (attachmentId: string) => {
        setIsDownloading(true);
        AxiosClient.get(URL_COMPANY_USER_DOWNLOAD_ATTACHMENT(cId!, cUId!, attachmentId), config).then((response) => {
            downloadFileResponse(fileResponse(response))
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('CompanyApi.DOWNLOAD_FAILED_1'), i18n.t('CompanyApi.ERROR_TRY_AGAIN_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}

export const useUserDocuments = (companyUserId: string): [IDocumentLightExtended[], boolean] => {
    const queryKeys: ICustomQueryKeys = {root: AppQueryKey.USER_DOCUMENTS, extraKeys: [companyUserId]};

    const query: ICustomQuery = {
        url: URL_COMPANY_USER_DOCUMENTS(companyUserId),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<IDocumentLightExtended[]>(query);
    return [(data?.data ?? []) as any as IDocumentLightExtended[], isFetching]
}

export const useUserSkills = (companyId: string, companyUserId?: string): [IUserSkill[], boolean] => {
    const queryKeys: ICustomQueryKeys = { root: AppQueryKey.USER_SKILLS, extraKeys: [companyId, companyUserId ?? ''] };

    const query: ICustomQuery = {
        url: URL_COMPANY_USER_SKILLS(companyId, companyUserId ?? ''),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data
    } = useGet<IUserSkill[]>(query);
    return [(data?.data ?? []) as any as IUserSkill[], isFetching]
}

export const useCreateUserSkill = (userId: string): [(formDataRows: IDataRow[]) => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const { cId } = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.USER_SKILLS);

    const usePostMutation = usePost<{ formDataRows: IDataRow[] }>(
        URL_COMPANY_USER_SKILL_CREATE(cId!, userId),
        queryKeys,
        true
    );

    const request = (formDataRows: IDataRow[]) => usePostMutation.mutateAsync({ formDataRows }).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.USER_SKILL_CREATED_1'), i18n.t('CompanyApi.USER_SKILL_CREATED_2'))));
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useUpdateUserSkill = (userSkillId: string, companyUserId: string): [(formDataRows: IDataRow[]) => Promise<boolean>, boolean] => {
    const { cId, cUId } = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.USER_SKILLS);

    const usePostMutation = usePut<{ formDataRows: IDataRow[] }>(
        URL_COMPANY_USER_SKILL_UPDATE(cId!, cUId! || companyUserId, userSkillId),
        queryKeys,
        true
    );

    const invalidateUserSkills = useInvalidateQuery(AppQueryKey.USER_SKILLS);

    const request = (formDataRows: IDataRow[]) => usePostMutation.mutateAsync({
        formDataRows
    }).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.USER_SKILL_UPDATED_1'), i18n.t('CompanyApi.USER_SKILL_SAVED_1'))));
            await invalidateUserSkills();
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useDeleteUserSkill = (userSkillId: string, companyUserId: string): [() => Promise<boolean>, boolean] => {
    const { cId, cUId } = useParams();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.USER_SKILLS);
    const useDeleteMutation = useDelete<void>(
        URL_COMPANY_USER_SKILL_DELETE(cId!, cUId! || companyUserId, userSkillId),
        queryKeys,
        true
    );

    const invalidateUserSkills = useInvalidateQuery(AppQueryKey.USER_SKILLS);

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('CompanyApi.USER_SKILL_DELETED_1'), i18n.t('CompanyApi.USER_SKILL_DELETED_2'))));
            await invalidateUserSkills();
        }
        return !!response.success;
    });
    return [request, useDeleteMutation.isLoading];
}

export const useDownloadUserSkillDataRowFile = (
    dataRowFileId: string,
    userSkillId: string,
    companyUserId: string,
    companyId: string
): [() => void, boolean] => {

    const dispatch = useAppDispatch();
    const [isDownloading, setIsDownloading] = useState(false);

    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    const download = () => {
        setIsDownloading(true);
        AxiosClient.get(URL_COMPANY_USER_SKILL_DOWNLOAD_FILE(companyId, companyUserId, userSkillId, dataRowFileId), config).then((response) => {
            downloadFileResponse(fileResponse(response));
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('CompanyApi.COULD_NOT_FETCH_THE_FILE_1'), i18n.t('CompanyApi.ERROR_FETCHING_FILE_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}

