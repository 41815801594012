import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import { useTranslation } from "react-i18next";
import styles from "../ToolbarPlugin.module.scss";

const CompanyNameButton = () => {
    const {t} = useTranslation();
    const [editor] = useLexicalComposerContext();

    const setSelected = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        editor.update(() => {
            const selection = $getSelection();
            selection?.insertText("@companyName");
        });
    }
    return <button
        className={[styles.toolbarItem].join(" ")}
        onClick={setSelected}
        aria-label={"companyName"}
    >
        <span>{t('CompanyNameButton.COMPANY_NAME')}</span>
    </button>
};

export default CompanyNameButton;