import {
    DndProvider,
    DropOptions,
    getBackendOptions,
    MultiBackend,
    NodeModel,
    NodeRender,
    Tree,
    TreeMethods
} from "@minoru/react-dnd-treeview";
import { useRef } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useMoveTemplate } from "../../api/TemplateApi";
import When from "../../components/When";
import { NodeType, TemplateType } from "../../core/enums/enums";
import { isUndefined } from "../../core/helpers/utils";
import { IMoveTemplateRequest, ITemplateNodeData } from "../../core/interfaces/template";
import useAccessCheck from "../../hooks/useAccessCheck";
import TemplatesNode from "./TemplatesNode";

interface ITemplatesTree {
    templatesData: NodeModel<ITemplateNodeData>[];
    onClickNode: (node: NodeModel<ITemplateNodeData>) => void;
    selectedNode: NodeModel<ITemplateNodeData> | null;
    nodeTypeAllowedToSelect?: NodeType;
    isDragAndDropEnabled: boolean;
    setDragAndDropEnabled: (newVal: boolean) => void;
    expandedFolders: string[];
    handleExpandedFolders: (id: string) => void;
    handleExpandedFoldersToggle: (id: string) => void;
    handleCloseAll: () => void;
}

const TemplatesTree = (
    {
        templatesData,
        onClickNode,
        selectedNode,
        nodeTypeAllowedToSelect,
        isDragAndDropEnabled,
        setDragAndDropEnabled,
        expandedFolders,
        handleExpandedFolders,
        handleExpandedFoldersToggle,
        handleCloseAll,
    }: ITemplatesTree) => {

    const {t} = useTranslation();
    const ref = useRef<TreeMethods>(null);
    const [_, checkAccess] = useAccessCheck();

    const [moveTemplate, isMovingTemplate] = useMoveTemplate();
    // const [initOpen, setInitOpen] = useState<any>(false);

    const nodeRender: NodeRender<ITemplateNodeData> = (
        node: NodeModel<ITemplateNodeData>,
        {depth, isOpen, onToggle}
    ) => (
        <TemplatesNode
            node={node}
            depth={depth}
            isOpen={isOpen}
            nodeTypeAllowedToSelect={nodeTypeAllowedToSelect}
            isSelected={node === selectedNode}
            onToggle={onToggle}
            onClickNode={onClickNode}
            handleExpandedFoldersToggle={handleExpandedFoldersToggle}
        />
    );

    const handleDrop = async (
        newTreeData: NodeModel<ITemplateNodeData>[],
        {dragSourceId, dropTargetId}: DropOptions<ITemplateNodeData>
    ) => {
        handleExpandedFolders("" + dropTargetId);
        const nodesInParent = newTreeData.filter(d => d.parent === dropTargetId);
        const parentNode = newTreeData.find(d => d.id === dropTargetId);
        let newSequenceNumber = null;
        const node = nodesInParent.find((node, i) => {
            if (node.id === dragSourceId) {
                newSequenceNumber = ++i;
                return node;
            }
        });

        if (!node || !node.data) return;
        if (node.data.templateType !== (TemplateType.DOCUMENT || TemplateType.CHECKLIST)) return;

        const request: IMoveTemplateRequest = {
            templateType: TemplateType.DOCUMENT === node.data.templateType ? TemplateType.DOCUMENT : TemplateType.CHECKLIST,
            nodeType: NodeType.FOLDER === node.data.nodeType ? NodeType.FOLDER : NodeType.DOCUMENT,
            id: node.data.id,
            newParentId: parentNode?.data?.id.toString() ?? null,
            sequenceNumber: newSequenceNumber ?? -1,
        }

        await moveTemplate(request);
    };

    return <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Button onClick={() => setDragAndDropEnabled(!isDragAndDropEnabled)}>
            <When condition={isDragAndDropEnabled} then={<span>{t('TemplatesTree.END_SORTING_1')}</span>}
                otherwise={<span>{t('TemplatesTree.SORT_1')}</span>}/>
        </Button>
        <Button onClick={handleCloseAll}>{t('TemplatesTree.MINIMIZE_ALL_1')}</Button>
        <Tree
            classes={{
                root: "treeRoot",
                draggingSource: "draggingSource",
                dropTarget: "dropTarget",
                placeholder: "placeholderContainer"
            }}
            tree={templatesData}
            ref={ref}
            rootId={''}
            sort={false}
            insertDroppableFirst={false}
            dropTargetOffset={5}
            placeholderRender={(node, {depth}) => (
                <div className="placeholderLine" style={{left: depth * 12}}></div>
            )}
            canDrag={(node) => !isUndefined(node) && isDragAndDropEnabled && !isMovingTemplate}
            canDrop={(tree, {dragSource, dropTargetId, dropTarget}) => {
                if (isDragAndDropEnabled
                    && dropTarget
                    && dropTarget.droppable
                    && dragSource?.parent === dropTargetId
                    && dragSource.id !== dropTargetId) {
                    return true;
                }
            }}
            onDrop={handleDrop}
            render={nodeRender}
            initialOpen={expandedFolders}
        />
    </DndProvider>
};

export default TemplatesTree;
