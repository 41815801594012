import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { clearTokens, setRefreshToken, setToken } from "../core/auth/AuthToken";
import { URL_AUTHENTICATE } from "./endpoints/endpoints";
import i18n from "i18next";

const BASE_API_URL = process.env.REACT_APP_API_URL;
const REFRESH_TOKEN_URL = "/users/refresh-token";
const isDebug = process.env.REACT_APP_DEBUG;

const AxiosClient = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: isDebug ? 999999 : 25000,
});

AxiosClient.interceptors.request.use(async config => {
    const token = localStorage.token;

    config.headers = {
        'Authorization': token,
        'Accept': 'application/json',
        'Accept-Language': i18n.language,
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
    }
    return config;
}, error => {
    Promise.reject(error)
});


type IRequestCb = (token: string) => void;

let isRefreshing = false;
let refreshSubscribers: IRequestCb[] = [];

const subscribeTokenRefresh = (cb: IRequestCb) => refreshSubscribers.push(cb);
const onTokenRefreshed = (token: string) =>  {
    refreshSubscribers = refreshSubscribers.filter(cb => cb(token));
}

// Response interceptor for API calls
AxiosClient.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry) {
        if (originalRequest.url === URL_AUTHENTICATE) {
            return Promise.reject(error);
        }
        
        if (!isRefreshing) {
            isRefreshing = true;
            refreshAccessToken().then(access_token => {
                axios.defaults.headers.common['Authorization'] = access_token;
                onTokenRefreshed(access_token);
            }).catch(_ => {
                window.location.reload()
                return Promise.reject(error);
            }).finally(() => { 
                isRefreshing = false
            });
        }

        const retryOriginalRequest = new Promise((resolve) => {
            subscribeTokenRefresh((token: string) => {
                originalRequest._retry = true;
                originalRequest.headers['Authorization'] = token;
                resolve(AxiosClient(originalRequest));
            });
        });
        
        return retryOriginalRequest;
    }

    return Promise.reject(error);
});

export async function refreshAccessToken(): Promise<string> {
    let token = localStorage.token;
    let refreshInstance = axios.create({
        baseURL: process.env.BASE_API_URL,
        headers: {
            'Authorization': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });

    const refreshToken = {
        "refreshToken": localStorage.getItem("refreshToken"),
    };
    return new Promise<string>((resolve, reject) => {
        refreshInstance.post(BASE_API_URL + REFRESH_TOKEN_URL, refreshToken).then((res) => {
            setToken(`Bearer ${res.data.data.token}`);
            setRefreshToken(res.data.data.refreshToken);
            resolve(res.data.data.token);
        }).catch((_) => {
            clearTokens();
            reject();
        });
    });
}

export const setAxiosAuthHeader = (token: string) => {
    axios.defaults.headers.common["Authorization"] = token;
}

export const api = {
    get: async <T>(url: string, config?: AxiosRequestConfig) => await AxiosClient.get<T>(url, config).then((response) => {
        return {data: response.data, status: response.status};
    }).catch((error: AxiosError) => {
        return {data: {} as T, status: error.response?.status ?? 500};
    }),
    post: async <T>(url: string, data: any) => await AxiosClient.post<T>(url, data).then((response) => {
        return {data: response.data, status: response.status};
    }).catch((error: AxiosError) => {
        return {data: {} as T, status: error.response?.status ?? 500};
    }),
    put: async <T>(url: string, data: any) => await AxiosClient.put<T>(url, data).then((response) => {
        return {data: response.data, status: response.status};
    }).catch((error: AxiosError) => {
        return {data: error.response ? error.response.data as T : {} as T, status: error.response?.status ?? 500};
    }),
    delete: async <T>(url: string) => await AxiosClient.delete<T>(url).then((response) => {
        return {data: response.data, status: response.status};
    }).catch((error: AxiosError) => {
        return {data: {} as T, status: error.response?.status ?? 500};
    })
};

export default AxiosClient;