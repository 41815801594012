import { useTranslation } from "react-i18next";
import CustomTextAreaInput from "../../../components/FormComponents/CustomTextAreaInput";
import { IIncident } from "../../../core/interfaces/incident";
import FormProvider from "./FormProvider/FormProvider";


export function LongTermActionForm(props: { editMode: boolean, newIncident: IIncident, onChange: (e: any) => void }) {
    const {t} = useTranslation();
    return <FormProvider controlId={'formLognTermAction'} title={t('LongTermActionForm.LONG_TERM_ACTION_1')}
                         colAs={props.editMode ? 'large' : 'sm'}>
        <CustomTextAreaInput
            isEditMode={props.editMode}
            rows={5}
            name={"longTermAction"}
            value={props.newIncident.longTermAction ?? ''}
            onChange={props.onChange}
        />
    </FormProvider>
}
