import { Button, Col, Row, Spinner } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetIncidents } from "../../api/Incident";
import { Icon, IconSize, getRegularIcon } from "../../assets/Icons/IconClassNames";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import DataTableNoData from "../../components/DataTableComponents/DataTableNoData";
import When from "../../components/When";
import { DateToYYYYMMDD } from "../../core/helpers/dates/datesHelper";
import { StatusCodeToName, TypeToName } from "../../core/helpers/incidentHelper";
import { IIncidentLight } from "../../core/interfaces/incident";

const Incidents = () => {
    const {t} = useTranslation();
    const {cId} = useParams();
    const navigate = useNavigate();

    const {isFetching: isFetchingIncidents, data: incidents} = useGetIncidents(cId ?? "");

    const columns: TableColumn<IIncidentLight>[] = [
        {
            name: t('Incidents.DATE_1'),
            cell: cell => DateToYYYYMMDD(cell.date),
            sortable: true
        },
        {
            name: t('Incidents.DESCRIPTION_1'),
            selector: row => row.description,
            sortable: true,
        },
        {
            name: t('Incidents.REPORTED_BY_1'),
            selector: row => row.createdBy ?? "",
            sortable: true,
        },
        {
            name: t('Incidents.IMAGES_DOCUMENTS_1'),
            cell: (cell) => {
                return (<div
                    style={{display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'center', gap: 5}}>
                    <When condition={cell.files?.find(f => f?.type?.includes('image')) != null}>
                        <i className={getRegularIcon(Icon.IMAGE, IconSize.LARGE)}></i>
                    </When>
                    <When condition={cell.files?.find(f => f?.type?.includes('pdf')) != null}>
                        <i className={getRegularIcon(Icon.PDF, IconSize.LARGE)}></i>
                    </When>
                </div>)
            },
            sortable: false,
        },
        {
            name: t('Incidents.RESPONSIBLE_1'),
            selector: row => row.actionUser,
            sortable: true,
        },
        {
            name: t('Incidents.FOLLOW_UP_1'),
            cell: cell => cell.followUp,
            sortable: true,
        },
        {
            name: t('Incidents.TYPE_1'),
            selector: row => TypeToName(row.type),
            sortable: true,
            compact: true,
        },
        {
            name: t('Incidents.STATUS_1'),
            selector: row => StatusCodeToName(row.status),
            sortable: true,
            compact: true,
        },
    ]
    //TODO add pagination
    return <MainContainer>
        <HeaderContainer title={t('Incidents.INCIDENTS_AND_ACCIDENTS')}/>
        <Row>
            <Col xs={0} sm={6}></Col>
            <Col xs={12} sm={6} className={"d-flex justify-content-end pb-2"}><Link to="new">
                <Button>{t('Incidents.REPORT_INCIDENT_ACCIDENT_DEVIATION_1')}</Button>
            </Link>
            </Col>

        </Row>
        <ContentContainer>
            <DataTable
                onRowClicked={(incidentLight) => navigate(`${incidentLight.id}`)}
                columns={columns}
                progressPending={isFetchingIncidents}
                progressComponent={<Spinner animation={"border"}/>}
                data={incidents?.data.data ?? []}
                noDataComponent={<DataTableNoData/>}
                persistTableHead
                highlightOnHover
                pointerOnHover
                pagination
            />
        </ContentContainer>
    </MainContainer>
}

export default Incidents;
