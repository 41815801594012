import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from '@lexical/selection';
import { $getSelection, $isRangeSelection } from "lexical";
import { useState } from "react";
import { ColorResult, GithubPicker } from "react-color";
import { GithubPickerStylesProps } from "react-color/lib/components/github/Github";
import { useTranslation } from "react-i18next";
import { Classes } from "reactcss";
import getIcon, { Icon } from "../../../../../../../../assets/Icons/IconClassNames";
import styles from "../../../ToolbarPlugin.module.scss";

interface IColorPickerButton {
    selectedColor: string;
}


const DEFAULT_COLORS: string[] = [
    '#4D4D4D',
    '#999999',
    '#FFFFFF',
    '#F44E3B',
    '#FE9200',
    '#FCDC00',
    '#DBDF00',
    '#A4DD00',
    '#68CCCA',
    '#73D8FF',
    '#AEA1FF',
    '#FDA1FF',
    '#333333',
    '#808080',
    '#cccccc',
    '#D33115',
    '#E27300',
    '#FCC400',
    '#B0BC00',
    '#68BC00',
    '#16A5A5',
    '#009CE0',
    '#7B64FF',
    '#FA28FF',
    '#000000',
    '#666666',
    '#B3B3B3',
    '#9F0500',
    '#C45100',
    '#FB9E00',
    '#808900',
    '#194D33',
    '#0C797D',
    '#0062B1',
    '#653294',
    '#AB149E',
];
const ColorPickerButton = (
    {
        selectedColor,
    }: IColorPickerButton) => {
    const {t} = useTranslation();
    const [editor] = useLexicalComposerContext();
    const [isSketchPickerOpen, setSketchPickerOpen] = useState(false);
    const [changedColor, setChangedColor] = useState<string>(selectedColor);

    const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setSketchPickerOpen(!isSketchPickerOpen);
    }

    const onChangeComplete = (color: ColorResult, e: any) => {
        e.preventDefault();
        e.stopPropagation();
        editor.focus();
        editor.update(() => {
            const selection = $getSelection();
            setChangedColor(color.hex);
            if ($isRangeSelection(selection)) {
                $patchStyleText(selection, {
                    'color': color.hex,
                });
            }
            setSketchPickerOpen(false);
        });
    }

    const prevent = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        editor.focus();
    }

    const githubPickerStyles: Partial<Classes<GithubPickerStylesProps>> = {
        default: {
            card: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: '330px',
            }
        }
    }

    const Box = () => {
        return (
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: selectedColor,
                }}
            />
        );
    };

    return <>
        <button
            title={t('ColorPickerButton.CHOOSE_COLOR_1')}
            className={styles.toolbarItem}
            onClick={handleClick}
            aria-label={t('ColorPickerButton.CHOOSE_COLOR_2')}
        >
            <Box/> <span className={`ps-1 ${getIcon(Icon.FONT)}`}/>
        </button>

        <div className={styles.colorPicker} onClick={prevent}>
            {isSketchPickerOpen && (<>
                    <GithubPicker
                        styles={githubPickerStyles}
                        colors={DEFAULT_COLORS}
                        color={changedColor}
                        onChangeComplete={onChangeComplete}
                    />
                </>
            )}
        </div>
    </>
};

export default ColorPickerButton;
