import DataTable, { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetLastDocuments } from "../../../api/CompanyApi";
import DataTableNoData from "../../../components/DataTableComponents/DataTableNoData";
import { DateToYYYYMMDDHHMM } from "../../../core/helpers/dates/datesHelper";
import { ViewedDocument } from "../../../core/interfaces/document";


const LastDocumentTable = () => {
    const {t} = useTranslation();
    const [data, isFetching] = useGetLastDocuments();

    const columns: TableColumn<ViewedDocument>[] = [
        {
            name: t('LastDocumentTable.NAME_1'),
            cell: cell => <Link to={"documents/" + cell.document.id}>{cell.document.name}</Link>,
            sortable: false,
        },
        {
            name: t('LastDocumentTable.SHOWED_1'),
            cell: cell => DateToYYYYMMDDHHMM(cell.viewedAt),
            sortable: false,
        }
    ];

    return (
        <DataTable
            progressPending={isFetching}
            columns={columns}
            highlightOnHover
            pointerOnHover
            data={data}
            noDataComponent={<DataTableNoData/>}
        />
    )
}

export default LastDocumentTable
