import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import UserSkillTable from "../Users/components/UserSkillTable";

const CompanySkills = () => {
    const { t } = useTranslation();
    const { cId } = useParams();

    return (
        <>
            <HeaderContainer title={t('CompanySkills.SKILLS_1')} />
            <ContentContainer>
                <UserSkillTable companyId={cId!} />
            </ContentContainer>
        </>
    );
};

export default CompanySkills;