import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsers } from "../../../api/DocumentApi";
import GenericInput from "../../../components/FormComponents/GenericInput";
import When from "../../../components/When";
import { CompanyRole, PublishStatusType } from "../../../core/enums/enums";
import { IPerson } from "../../../core/interfaces/common";
import { IDocument } from "../../../core/interfaces/document";
import useAccessCheck from "../../../hooks/useAccessCheck";


interface IApprovedByForm {
    isEditMode: boolean;
    document: IDocument;
    onChange: (approvedBy: IPerson | null) => void;
}

interface AdjustedObj {
    adjustedApprovedBy?: string;
}

const ApprovedByForm = ({isEditMode, document, onChange}: IApprovedByForm) => {
    const {t} = useTranslation();
    const [companyUsers] = useGetCompanyUsers();
    const [checkAccess] = useAccessCheck();
    const [selected, setSelected] = useState<IPerson | undefined>(document.adjustedApprovedBy ?? document.approvedBy);
    const [object, setObject] = useState<AdjustedObj>({adjustedApprovedBy: selected?.id});

    const isApproved = PublishStatusType.APPROVED === document.publishStatus;

    const change = (obj: AdjustedObj) => {
        const approvedBy = companyUsers.find(v => v.userId === obj.adjustedApprovedBy);
        onChange(approvedBy ? {id: approvedBy?.userId, name: approvedBy.name} as IPerson : null);
        setObject(obj);
    }

    useEffect(() => {
        setObject({adjustedApprovedBy: selected?.id});
    }, [selected]);

    useEffect(() => {
        setSelected(document.adjustedApprovedBy ?? document.approvedBy);
    }, [document.adjustedApprovedBy, document.approvedBy]);

    return <Form.Group as={Row}>
        <Row>
            <Form.Label column className={"pt-1 pb-0"}><strong>{t('ApprovedByForm.APPROVED_BY_1')}</strong></Form.Label>
        </Row>
        <Row>
            <Col>
                <When
                    condition={isApproved && isEditMode && checkAccess(CompanyRole.ADMIN)}
                    otherwise={
                        <span>{isApproved ? (selected?.name ?? "-") : "-"}</span>}
                >
                    <GenericInput
                        type={'select'}
                        isEditMode={isEditMode}
                        name={'adjustedApprovedBy'}
                        object={object}
                        setValue={change}
                    >
                        <option
                            selected={selected?.id === document.approvedBy?.id}
                            value={document.approvedBy?.id}>{document.approvedBy?.name}</option>
                        <>{companyUsers.filter(v => v.userId !== document.approvedBy?.id).map(v =>
                            <option key={v.userId} selected={selected?.id === v.userId}
                                    value={v.userId}>{v.name}</option>)}</>
                    </GenericInput>
                </When>
            </Col>
        </Row>
    </Form.Group>
};

export default ApprovedByForm;
