import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../../../../components/FormComponents/CustomSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowProps, IDataRowRiskAssessmentField } from "../../../../../../core/interfaces/document";
import DataRowActionButtons from "./DataRowActionButtons";

const RiskAssessmentDataRow = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        isFieldEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isAnswerMode = false,
        isActive,
    }: IDataRowProps<IDataRowRiskAssessmentField>) => {

    const colorPositive = "rgba(0, 255, 0, 0.25)";
    const colorNegative = "rgba(255, 0, 0, 0.25)";
    const colorNeutral = "rgba(255, 193, 7, 0.5)";

    const {t} = useTranslation();
    const [result, setResult] = useState<string>("");

    const onChange = (e: any) => {
        setDataRow({
            ...dataRow,
            [e.target.name]: e.target.value
        });
    }

    const onChangeValue = (e: any) => {
        const value = !isNaN(e.target.value) ? parseInt(e.target.value) : undefined;
        setDataRow({
            ...dataRow,
            [e.target.name]: value
        });
    }

    const renderProbabilityOpt = () => {
        return <>
            <option value={undefined}>{t('RiskAssessmentDataRow.SELECT_PROBABILITY_1')}</option>
            <option value={1}>{t('RiskAssessmentDataRow.RARELY_1')}</option>
            <option value={2}>{t('RiskAssessmentDataRow.AT_SOME_POINT_1')}</option>
            <option value={3}>{t('RiskAssessmentDataRow.SOMETIMES_1')}</option>
            <option value={4}>{t('RiskAssessmentDataRow.EVERY_WEEK_1')}</option>
            <option value={5}>{t('RiskAssessmentDataRow.DAILY_1')}</option>
        </>;
    }

    const renderConsequenceOpt = () => {
        return <>
            <option value={undefined}>{t('RiskAssessmentDataRow.CHOOSE_CONSEQUENCE_1')}</option>
            <option value={1}>{t('RiskAssessmentDataRow.ACCEPTABLE_1')}</option>
            <option value={2}>{t('RiskAssessmentDataRow.SHOULD_BE_REVIEWED_1')}</option>
            <option value={3}>{t('RiskAssessmentDataRow.MINOR_DAMAGE_1')}</option>
            <option value={4}>{t('RiskAssessmentDataRow.UNACCEPTABLE_DAMAGE_1')}</option>
            <option value={5}>{t('RiskAssessmentDataRow.DEATH_1')}</option>
        </>;
    }

    const calculateRiskAssessment = (probability?: number, consequence?: number) => {
        if ((!probability || isNaN(probability)) || (!consequence || isNaN(consequence))) return setResult("");
        if (probability < 1 || probability > 5 || consequence < 1 || consequence > 5) setResult("");

        switch (probability) {
            case 1:
                if (consequence <= 4) return setResult(colorPositive);
                if (consequence === 5) return setResult(colorNeutral);
                break;
            case 2:
                if (consequence <= 3) return setResult(colorPositive);
                if (consequence >= 4) return setResult(colorNeutral);
                break;
            case 3:
                if (consequence <= 2) return setResult(colorPositive);
                if (consequence >= 3 && consequence <= 4) return setResult(colorNeutral);
                if (consequence === 5) return setResult(colorNegative);
                break;
            case 4:
                if (consequence === 1) return setResult(colorPositive);
                if (consequence >= 2 && consequence <= 3) return setResult(colorNeutral);
                if (consequence >= 4) return setResult(colorNegative);
                break;
            case 5:
                if (consequence <= 2) return setResult(colorNeutral);
                if (consequence >= 3) return setResult(colorNegative);
                break;
            default:
                return setResult("");
        }

        return setResult("");
    }

    useEffect(() => {
        calculateRiskAssessment(dataRow.riskAssessmentProbability, dataRow.riskAssessmentConsequence);
    }, [dataRow.riskAssessmentProbability, dataRow.riskAssessmentConsequence]);

    return <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
        <When condition={!checklistViewTable}>
            <Col md={3}>
                <CustomTextInput
                    disabled={!isHeaderEnabled}
                    isEditMode={isEditMode}
                    name={"header"}
                    placeholder={t('RiskAssessmentDataRow.RISK_ASSESSMENT_1')}
                    value={getDataRowHeader(dataRow)}
                    onChange={onChange}
                    disableAutocomplete
                />
            </Col>
        </When>
        <When condition={!!checklistViewTable}>
            <Col>
                <div>
                    <CustomSelect
                        checklistViewTable={checklistViewTable}
                        style={{backgroundColor: result}}
                        disabled={!isFieldEnabled}
                        isEditMode={isEditMode || isAnswerMode}
                        name={"riskAssessmentProbability"}
                        value={dataRow.riskAssessmentProbability}
                        onChange={onChangeValue}
                        showInputFieldInReadOnly
                        defaultValue={undefined}
                        options={renderProbabilityOpt()}
                    />
                </div>
                <div>
                    <CustomSelect
                        checklistViewTable={checklistViewTable}
                        style={{backgroundColor: result}}
                        disabled={!isFieldEnabled}
                        isEditMode={isEditMode || isAnswerMode}
                        name={"riskAssessmentConsequence"}
                        value={dataRow.riskAssessmentConsequence}
                        onChange={onChangeValue}
                        showInputFieldInReadOnly
                        defaultValue={undefined}
                        options={renderConsequenceOpt()}
                    />
                </div>
            </Col>
        </When>


        <When condition={!checklistViewTable}>
            <Col md={isEditMode && isActive ? 3 : 4}>
                <CustomSelect
                    style={{backgroundColor: result}}
                    disabled={!isFieldEnabled}
                    isEditMode={isEditMode || isAnswerMode}
                    name={"riskAssessmentProbability"}
                    value={dataRow.riskAssessmentProbability}
                    onChange={onChangeValue}
                    showInputFieldInReadOnly
                    defaultValue={undefined}
                    options={renderProbabilityOpt()}
                />
            </Col>
            <Col md={isEditMode && isActive ? 4 : 5}>
                <CustomSelect
                    style={{backgroundColor: result}}
                    disabled={!isFieldEnabled}
                    isEditMode={isEditMode || isAnswerMode}
                    name={"riskAssessmentConsequence"}
                    value={dataRow.riskAssessmentConsequence}
                    onChange={onChangeValue}
                    showInputFieldInReadOnly
                    defaultValue={undefined}
                    options={renderConsequenceOpt()}
                />
            </Col>
        </When>

        <When condition={isEditMode && isActive}>
            <DataRowActionButtons
                dataRow={dataRow}
                removeDataRow={removeDataRow}
                copyDataRow={copyDataRow}
            />
        </When>
    </Row>
};

export default RiskAssessmentDataRow;
