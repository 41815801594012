import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ForgotPassword as ForgotAPI } from "../../api/UserApi";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import CustomForm from "../../components/FormComponents/CustomForm";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import SpinnerButton from "../../components/SpinnerButton";
import When from "../../components/When";
import { validateEmail } from "../../core/helpers/userHelper";
import { IForgotPassword } from "../../core/interfaces/user";
import useUpdatableObject from "../../hooks/useUpdatableObject";
import scss from './ForgotPassword.module.scss';


const Group: React.FC<{ controlId?: string, title?: string, className?: string, children: JSX.Element }> = ({
                                                                                                                controlId = "",
                                                                                                                title = "",
                                                                                                                className,
                                                                                                                children
                                                                                                            }) =>
    <FormBase controlId={controlId} title={title}
              labelProps={{m: {span: 2, offset: 1}, lg: {span: 2, offset: 1}}}
              inputProps={{m: {span: 10}, lg: {span: 8}}}
              className={className + ' pb-2'}>
        {children}
    </FormBase>

const ForgotPassword = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const lState = location.state;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [updated, setUpdated, _, submit, loading] = useUpdatableObject<IForgotPassword, boolean>({
        email: "",
    }, ForgotAPI)

    const [error, setErrors] = useState({
        email: true,
    });

    const handleSubmit = () =>
        submit().finally(() => {
            navigate('/login', {
                state: {
                    fromForgot: true,
                }
            })
        })

    const validate = (forgotPassword: IForgotPassword) => {
        setErrors({
            email: !validateEmail(forgotPassword.email)
        })
        return forgotPassword;
    }


    const commonProps = {
        setValue: setUpdated,
        object: updated,
        isEditMode: true,
        errorObject: error,
        onChangeMiddleWare: validate
    }
    return (
        <MainContainer className={scss.container}>
            <ContentContainer>
                <Row>
                    <Col>
                        <h4 className="text-center">{t('ForgotPassword.FORGOT_PASSWORD_1')}</h4>
                    </Col>
                </Row>

                <Row className={'pt-4'}>
                    <When condition={!!lState?.fromConfirmed}>
                        <Group>
                            <Alert data-test-id="confirmation_expired" variant="danger">{t('ForgotPassword.TIME_EXPIRED_REQUEST_NEW_1')}</Alert>
                        </Group>
                    </When>
                    <CustomForm onSubmit={handleSubmit} useValidated={false}>
                        <Group controlId={t('ForgotPassword.EMAIL_1')} title={t('ForgotPassword.E_MAIL_1')}>
                            <GenericInput type={'email'} name={'email'} {...commonProps}
                                          description={t('ForgotPassword.RESET_LINK_EMAIL_1')}
                            />
                        </Group>
                        <Group className={'pt-2'}>
                            <SpinnerButton type={"submit"} text={t('ForgotPassword.SEND_1')} isLoading={loading}
                                           disabled={!!Object.values(error).filter(s => s).length}></SpinnerButton>
                        </Group>
                    </CustomForm>
                </Row>
            </ContentContainer>
        </MainContainer>)
}

export default ForgotPassword
