import { DefaultOptions, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

const options: DefaultOptions = {
    queries: {
        staleTime: 60000
    }
};
queryClient.setDefaultOptions(options);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        debug: true,
        lng: localStorage.getItem('language') || 'sv',
        fallbackLng: 'sv',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

i18n.on('languageChanged', (lng) => {
    localStorage.setItem('language', lng);
});

i18n.on('initialized', () => {
});

i18n.on('loaded', () => {
    root.render(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <React.StrictMode>
                    <React.Suspense fallback="Loading...">
                        <BrowserRouter>
                            <Routes>
                                <Route path="/*" element={<App />} />
                            </Routes>
                        </BrowserRouter>
                    </React.Suspense>
                </React.StrictMode>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
