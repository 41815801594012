import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { updateIncident } from "../../api/IncidentApi";
import { URL_INCIDENT } from "../../api/endpoints/endpoints";
import { getBase64 } from "../../core/helpers/fileHelper";
import { transformDatesToLocale, transformDatesToUTC } from "../../core/helpers/incidentHelper";
import { toastError } from "../../core/helpers/toastHelper";
import { IIncident, IIncidentToSend, IncidentFileToUpdate } from "../../core/interfaces/incident";
import { usePrompt } from "../../hooks/usePrompt";
import { useSingle } from "../../hooks/useSingle";
import { useAppDispatch } from "../../store/hooks";
import { addToast } from "../../store/slices/uiSlice";
import IncidentContainer from "./components/IncidentContainer/IncidentContainer";


const Incident = () => {
    let {cId, incidentId} = useParams();

    const {t} = useTranslation();
    const prompt = usePrompt();
    const [isEditMode, setEditMode] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useAppDispatch();


    const [incident, loading, reFetch] = useSingle<IIncident>(URL_INCIDENT(cId ?? "", incidentId ?? ""), transformDatesToLocale);

    const onSubmit = async (incident: IIncident, files?: File[]) => {
        setIsSubmitting(true);

        const newFiles: IncidentFileToUpdate[] = await Promise.all((files ?? []).map(async (file) =>
            ({
                base64: await getBase64(file),
                name: file.name,
                type: file.type,

            } as IncidentFileToUpdate)
        ));

        const incidentToUpdate: IIncidentToSend = {
            ...transformDatesToUTC(incident),
            newFiles
        };

        updateIncident(cId, incidentId, incidentToUpdate).then(data => {
            setIsSubmitting(false);
            if (data.status !== 200) {
                dispatch(addToast(toastError(t('Incident.ERROR_OCCURRED_1'), t('Incident.ERROR_UPDATING_OFFER_1'))))
            } else {
                setEditMode(false);
                reFetch()
            }
        })
    }

    const onCancel = () => {
        prompt(() => setEditMode(false));
    }

    if (loading) {
        return <>{t('Incident.LOADING_1')}</>;
    }

    if (incident.id === "") {
        return <>{t('Incident.NOT_FOUND_1')}</>
    }

    return <IncidentContainer isNewIncident={false}
                              incident={incident}
                              isEditMode={isEditMode}
                              isSubmitting={isSubmitting}
                              onEdit={() => setEditMode(true)}
                              onSubmit={onSubmit}
                              onCancel={onCancel}/>;

}

export default Incident;
