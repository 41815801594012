import {IApiQuery, IApiQuerySortCondition} from "../interfaces/common";

export function queryStringBuilder(queryObj: IApiQuery, first = true, prefix = ""): string {

    var prefixString = prefix === "" ? "" : prefix+"."
    var resultString = "";

    for (let [key, value] of Object.entries(queryObj)) {

        var string = ""

        if (value) {

            //Case sort: extract sort conditions from query and add them with comma as separator
            if (key === "sort") {

                let sortConditions: IApiQuerySortCondition[] = value
                let firstCondition = true
                let sortConditionsString = ""

                sortConditions.forEach((sort) => {
                    let sortString = ""
                    let direction = sort.sortDirection === "asc" ? "" : "-"
                    if (firstCondition) {
                        sortString += `${prefixString + key}=` + direction + sort.fieldName
                        firstCondition = false;
                    } else {
                        sortString += "," + direction + sort.fieldName
                    }

                    sortConditionsString = sortConditionsString.concat(sortString)
                })
                string = string.concat(sortConditionsString)
            }
                // TODO Case include sort
            //Case Array of values: query parameter with array of values that need to be comma separated
            else if (Array.isArray(value) && 0 < value.length) {

                let arrayValues = `${prefixString + key}=`

                for (let i = 0; i < value.length; i++) {
                    //Decide if comma is needed
                    arrayValues = i === 0 ?
                        arrayValues.concat(`${value[i]}`)
                        : arrayValues.concat(`,${value[i]}`)
                }

                string = arrayValues
            }
            //Ordinary query parameter fieldName and value
            else if (!(typeof value == "function") && !(typeof value == "object")) {
                string = `${prefixString + key}=${value}`
            }
            else if (typeof value === "object"){
                string = queryStringBuilder(value, first, key)
            }
        }

        //Decide prefix & or ? and add to resultString
        if (string !== "") {
            string = first ? "?" + string : "&" + string
            resultString = resultString.concat(string)
            first = false;
        }
    }

    resultString = resultString.replaceAll("&&", "&")
    resultString = resultString.replaceAll(" ", "+")

    return resultString;
}