import { useState } from 'react';
import { TableColumn } from "react-data-table-component";
import { useTranslation } from 'react-i18next';
import { useGetPositionLevels } from "../../api/PositionLevelApi";
import { Icon } from "../../assets/Icons/IconClassNames";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import PaginatedDataTableClientSide from "../../components/DataTableComponents/PaginatedDataTable";
import { IPositionLevel, IPositionLevelResponse } from '../../core/interfaces/positionLevel';
import styles from "../Users/Users.module.scss";
import EditPositionLevelModal from './EditPositionLevelModal';
import NewPositionLevelModal from "./NewPositionLevelModal";
import RemovePositionLevelModal from './RemovePositionLevelModal';

const PositionLevelTable = () => {
    const { t } = useTranslation();
    const [openNewPositionLevelModal, setOpenNewPositionLevelModal] = useState(false);
    const [data] = useGetPositionLevels();
    const [showEditPositionLevelModal, setShowEditPositionLevelModal] = useState(false);
    const [showRemovePositionLevelModal, setShowRemovePositionLevelModal] = useState(false);
    const [selectedPositionLevel, setSelectedPositionLevel] = useState<IPositionLevelResponse | null>(null);

    const columns: TableColumn<IPositionLevelResponse>[] = [
        {
            name: t('PositionLevelTable.POSITION_LEVEL_1'),
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: <span className={styles.clickable} onClick={() => setOpenNewPositionLevelModal(true)}> + {t('PositionLevelTable.ADD_POSITION_LEVEL_1')}</span>,
            right: true,
            cell: row => (
                <CustomDropdown toggleType={DropdownToggleType.GEAR}>
                    <CustomDropdownItem
                        icon={Icon.EDIT}
                        text={t('PositionLevelTable.EDIT_1')}
                        onClick={() => {
                            setSelectedPositionLevel(row)
                            setShowEditPositionLevelModal(true)
                        }}
                    />
                    <CustomDropdownItem
                        icon={Icon.REMOVE}
                        text={t('PositionLevelTable.DELETE_POSITION_LEVEL_1')}
                        onClick={() => {
                            setSelectedPositionLevel(row)
                            setShowRemovePositionLevelModal(true)
                        }}
                    />
                </CustomDropdown>
            )
        }
    ];

    return (
        <ContentContainer>
            <PaginatedDataTableClientSide
                data={data ?? []}
                progressPending={false}
                columns={columns}
            />

            <NewPositionLevelModal show={openNewPositionLevelModal} hide={() => setOpenNewPositionLevelModal(false)} />
            <EditPositionLevelModal show={showEditPositionLevelModal} hide={() => setShowEditPositionLevelModal(false)} positionLevel={selectedPositionLevel as IPositionLevel} />
            <RemovePositionLevelModal show={showRemovePositionLevelModal} hide={() => setShowRemovePositionLevelModal(false)} positionLevel={selectedPositionLevel} />
        </ContentContainer>
    );
};

export default PositionLevelTable;